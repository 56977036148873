import { Box } from '@material-ui/core/node_modules/@material-ui/system';
import { ReactNode, ReactElement } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@material-ui/core/styles';
import { BrandType } from '@types';

type ChatProps = {
  children: ReactNode;
  isUser: boolean;
  isProductSpecialist: boolean;
  isProducer: boolean;
  brandId: BrandType;
  isChatOpen?: boolean;
  isCameraActive?: boolean;
  isCameraNotificationActive?: boolean;
};

const Chat = (props: ChatProps): ReactElement => {
  const { children, isUser, isProductSpecialist, isProducer, brandId, isChatOpen, isCameraActive, isCameraNotificationActive } = props;
  const isCadillac = brandId === 'cadillac' || brandId === 'cadillaccan';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev';
  const isEVLiveCanada = brandId.toLowerCase() === 'evcan';
  const isEnergy = brandId === 'energy';
  const theme = useTheme();

  let chatStyles = {};
  const chatCadillacStyles = {
    chatContainer: {
      bottom: 165,
      '@media screen and (min-width: 345px)': {
        maxWidth: 'initial',
      },
      '@media screen and (min-width: 516px)': {
        width: 397,
        maxWidth: 397,
      },
      [theme.breakpoints.up('md')]: {
        bottom: 115,
      },
    },
    chatUserContainer: {
      height: 458,
      [theme.breakpoints.up('md')]: {
        height: 468,
      },
    },
  };
  const chatGMCStyles = {
    chatContainer: {
      left: 16,
      bottom: 160,
      width: 'calc(100% - 32px)',
      '@media screen and (min-width: 345px)': {
        maxWidth: 'initial',
      },
      '@media screen and (min-width: 516px)': {
        width: 397,
      },
      '@media screen and (min-width: 768px)': {
        width: 397,
        maxWidth: 397,
      },
      [theme.breakpoints.up('md')]: {
        left: 32,
        bottom: 123,
      },
      [theme.breakpoints.up('lg')]: {
        width: 443,
        maxWidth: 443,
      },
    },
    chatUserContainer: {
      height: 470,
      [theme.breakpoints.up('md')]: {
        height: 430,
      },
    },
  };
  const chatBuickStyles = {
    chatContainer: {
      left: 16,
      bottom: 160,
      width: 'calc(100% - 32px)',
      '@media screen and (min-width: 345px)': {
        maxWidth: 'initial',
      },
      '@media screen and (min-width: 516px)': {
        width: 397,
      },
      '@media screen and (min-width: 768px)': {
        width: 397,
        maxWidth: 397,
      },
      [theme.breakpoints.up('md')]: {
        left: 32,
        bottom: 123,
      },
      [theme.breakpoints.up('lg')]: {
        width: 443,
        maxWidth: 443,
      },
    },
    chatUserContainer: {
      height: 470,
      [theme.breakpoints.up('md')]: {
        height: 430,
      },
    },
  };
  const chatEVLiveStyles = {
    chatContainer: {
      left: 16,
      bottom: 173,
      width: 'calc(100% - 32px)',
      '@media screen and (min-width: 345px)': {
        maxWidth: 'initial',
      },
      '@media screen and (min-width: 516px)': {
        width: 397,
      },
      '@media screen and (min-width: 768px)': {
        width: 397,
        maxWidth: 397,
      },
      [theme.breakpoints.up('md')]: {
        left: 32,
        bottom: 142,
      },
      [theme.breakpoints.up('lg')]: {
        width: 449,
        maxWidth: 449,
      },
    },
    chatUserContainer: {
      height: 465,
      [theme.breakpoints.up('md')]: {
        height: 363,
      },
    },
  };
  const chatEVLiveCanadaStyles = {
    ...chatEVLiveStyles,
    chatContainer: {
      ...chatEVLiveStyles.chatContainer,
      bottom: 121,
    },
  };

  const chatEnergyStyles = {
    chatContainer: {
      left: 4,
      bottom: 66,
      width: 'calc(100% - 8px)',
      transform: 'rotate(180deg) scale(-1, 1)',
      '&::-webkit-scrollbar-track': {
        borderRadius: 26,
      },
      '&::-webkit-scrollbar-thumb': {
        border: '4px solid transparent',
        backgroundClip: 'content-box',
      },
      '@media screen and (min-width: 345px)': {
        maxWidth: 'calc(100% - 8px)',
      },
      '@media screen and (min-width: 516px)': {
        width: 'calc(100% - 8px)',
        maxWidth: 'calc(100% - 8px)',
      },
      [theme.breakpoints.up(1200)]: {
        left: 32,
        bottom: 118,
        width: 'calc(100% - 32px)',
        maxWidth: '429px',
      },
    },
    chatUserContainer: {
      height: 360,
      [theme.breakpoints.up('md')]: {
        height: 298,
      },
      [theme.breakpoints.up(1200)]: {
        height: 372,
      },
    },
    chatsProductSpecialistContainer: {
      height: 360,
      inlineSize: 'calc(100% - 8px)',
      maxInlineSize: '429px',
      [theme.breakpoints.up(1200)]: {
        height: 404,
      },
    },
    chatUserContainerChatOpenCameraActive: {
      bottom: 105,
      [theme.breakpoints.up(1200)]: {
        bottom: 118,
      },
    },
  };

  if (isCadillac) {
    chatStyles = {
      ...chatCadillacStyles,
    };
  }

  if (isGMC || isBuick) {
    chatStyles = {
      ...chatGMCStyles,
    };
  }

  if (isBuick) {
    chatStyles = {
      ...chatBuickStyles,
    };
  }

  if (isEVLive) {
    chatStyles = {
      ...chatEVLiveStyles,
    };
  }

  if (isEVLiveCanada) {
    chatStyles = {
      ...chatEVLiveCanadaStyles,
    };
  }

  if (isEnergy) {
    chatStyles = {
      ...chatEnergyStyles,
    };
  }

  const useStyles = makeStyles({
    chatContainer: {
      position: 'absolute',
      left: 20,
      bottom: 142,
      zIndex: 9,
      textAlign: 'center',
      height: 334,
      width: 'calc(100% - 40px)',
      // padding: '10px',
      // borderRadius: '10px',
      // backgroundColor: 'rgba(0.4, 0, 0.2, 0.2)',
      overflow: 'hidden auto',
      transform: 'rotate(180deg)',
      '&::-webkit-scrollbar': {
        width: 16,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.background.chatScrollTrack,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.background.chatScrollThumb,
        borderRadius: '20px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: theme.palette.background.chatScrollThumbHover,
      },
      '@media screen and (min-width: 345px)': {
        maxWidth: 345,
      },
      '@media screen and (min-width: 516px)': {
        width: 408,
        maxWidth: 408,
      },
      [theme.breakpoints.up('xl')]: {
        bottom: 88,
      },
    },
    chatUserContainer: {
      height: 334,
      [theme.breakpoints.up('xl')]: {
        height: 372,
      },
    },
    chatsProductSpecialistContainer: {
      [theme.breakpoints.up('xl')]: {
        height: 404,
      },
    },
    chatProducerContainer: {
      height: 327,
      [theme.breakpoints.up('xl')]: {
        height: 404,
      },
    },
    chatScrollOverflow: {
      overflow: 'hidden',
      transform: 'rotate(180deg)',
    },
    [theme.breakpoints.up('xs')]: {
      ...chatStyles,
    },
  });
  const styles = useStyles();

  return (
    <Box
      className={`
        ${styles.chatContainer}
        ${isUser ? styles.chatUserContainer : ' '}
        ${isProductSpecialist ? styles.chatsProductSpecialistContainer : ' '}
        ${isProducer ? styles.chatProducerContainer : ' '}
        ${(isUser && isChatOpen && isCameraActive && isCameraNotificationActive) ? styles.chatUserContainerChatOpenCameraActive : ' '}
      `}
    >
      <Box className={styles.chatScrollOverflow}>{children}</Box>
    </Box>
  );
};

export default Chat;
