export const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          // height: '100%',
          height: 'initial' // TO DO: check if it's okay
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
        // iframe: {
        //   display: 'none',
        // },
        // '.firebase-emulator-warning': {
        //   display: 'none',
        // },
        '.MuiTouchRipple-root': {
          display: 'none',
        },
        '.MuiCardActionArea-focusHighlight': {
          display: 'none',
        },
        '.asterisks': {
          color: '#0072CE',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          '& #errors': {
            '& .MuiListItemButton-root': {
              padding: 0,
              '& .MuiTypography-root': {
                fontFamily: 'Roboto',
                fontSize: '18px',
              },
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '.MuiLoadingButton-loadingIndicator':{
            color: '#FFFFFF',
          }

        }
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
};
