import { useTheme } from '@material-ui/core/styles';

const DealerReferralViewStyles = (brandId: string) => {
  const theme = useTheme();
  const chevyStyles = {
    root: {
      backgroundColor: theme.palette.background.formBackground,
      borderRadius: 0,
      boxShadow: 'none',
    },
    gridWrapper: {
      margin: '0 auto',
      paddingTop: '40px',
      paddingLeft: '15px',
      paddingRight: '15px',
      width: '100%',
      maxWidth: '940px',
    },
    errorElement: {
      paddingLeft: '24px',
      paddingRight: '24px',
      [theme.breakpoints.down(576)]: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    modalSubText: {
      maxWidth: '540px',
      marginBottom: '35px',
    },
    selectWrapper: {
      position: 'relative',
      '@global': {
        '.MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },
    '@global': {
      '.MuiFormLabel-root': {
        fontSize: '16px',
      },
      '.MuiIconButton-root': {
        width: '460px',
        height: '40px',
      },
      '.MuiFormControlLabel-label': {
        display: 'block',
        position: 'relative',
        paddingLeft: '0px',
        marginBottom: '0',
        color: theme.palette.text.primary,
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: '700',
        letterSpacing: '0px',
        lineHeight: '16px',
        textTransform: 'initial',
        cursor: 'pointer',
        userSelect: 'none',
      },
      '.MuiGrid-item': {
        paddingTop: '0 !important',
      },
      '.MuiTypography-modalSubText a': {
        fontWeight: 700,
        textDecoration: 'underline',
        color: theme.palette.color.privacyStatement,
      },
      '.MuiOutlinedInput-notchedOutline .notranslate': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  };

  const energyStyles = {
    topLeftBackgroundImage: {
      backgroundImage: theme.palette.background.greenTopLeftDesktop,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 253,
      height: 248,
      [theme.breakpoints.down(768)]: {
        display: 'none',
      },
    },
    errorElement: {
      maxWidth: '672px',
      paddingLeft: 0,
      paddingRight: 0,
      margin: '0 auto',
      '& .MuiContainer-root': {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      },
    },
    reportButton: {
      width: '315px',
      marginBottom: '32px',
    },
    form: {
      maxWidth: '672px',
    },
    formLabel: {
      display: 'none',
    },
    consumerConsentText: {
      marginLeft: '30px',
      fontSize: '12px',
      lineHeight: '1.25'
    },
    '@global': {
      '.MuiFormControlLabel-label': {
        fontFamily: 'Overpass',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
      },
      '.MuiFormControlLabel-root': {
        marginRight: 0,
      },
      '.MuiInputLabel-root': {
        marginLeft: '3px',
      },
      '.MuiGrid-item': {
        '&#orderNumber, &#firstName, &#lastName, &#phoneNumber, &#zipCode, &#confirmEmail, &#vin, &#voucherNumber': {
          padding: '0 !important',
        }
      },
      '#state': {
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },
      '#areaOfInterest': {
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
        '& .MuiGrid-root': {
          maxWidth: '328px',
          [theme.breakpoints.down(768)]: {
            maxWidth: '100%'
          },
        },
      },
      '#notes': {
        marginBottom: 0,
        '& .MuiInputBase-input': {
          '&::placeholder': {
            opacity: 1,
          },
        }
      },
      '#consumerConsent': {
        [theme.breakpoints.up(768)]: {
          marginBottom: '9px',
        },
      },
    },
  };

  const styles = {
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};

export default DealerReferralViewStyles;
