import { FC, ReactNode, useState } from 'react';
import * as firebase from 'firebase/auth';
// import { useSigninCheck } from 'reactfire';
import { useQueryString } from '@hooks';
import { userRepository } from '../../../lib/src/repositories/user';
import { LoadingScreen } from '@components';

interface AnonymousAuthGuardProps {
  children: ReactNode;
}

const AnonymousAuthGuard: FC<AnonymousAuthGuardProps> = (props) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const { data: signInCheckResult } = useSigninCheck();
  const { urlSearchParams } = useQueryString();
  const token = urlSearchParams.get('token');
  const id = urlSearchParams.get('id');
  const auth = firebase.getAuth();

  if (isAuthenticated) {
    return <>{children}</>;
  }

  const signInAnonymously = () => {
    firebase.signInAnonymously(auth).then(() => {
      setIsAuthenticated(true);
    });
  };

  if (token) {
    firebase
      .signInWithCustomToken(auth, token).then(() => {
        setIsAuthenticated(true);
      }).catch((err) => {
        // token is invalid
        console.log(err);
        // this is a messy (safe) fix for the token timout issue. In the future we
        // should regenerate the token in the server.
        if (id) {
          userRepository.generateToken(id).then((resp) => {
            firebase.signInWithCustomToken(auth, resp.token)
              .then(() => {
                setIsAuthenticated(true);
              })
              .catch(() => {
                signInAnonymously();
              });
          });
        } else {
          signInAnonymously();
        }
      });
  } else if (!auth.currentUser) {
    signInAnonymously();
  } else {
    setIsAuthenticated(true);
  }

  return <LoadingScreen />;
};

export default AnonymousAuthGuard;
