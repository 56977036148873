import { Box, Typography, Button } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalIconButton } from '@components';

type ScreenSharingErrorModalProps = {
  brandId: string;
  onClose?: () => void;
  errorTitle?: string;
  errorMessage?: string;
};

const ScreenSharingErrorModal = (props: ScreenSharingErrorModalProps): ReactElement => {
  const { onClose, brandId, errorMessage, errorTitle } = props;
  const { t } = useTranslation();

  return (
    <>
      <ModalIconButton onClose={onClose} brandId={brandId} dataDTM="screen share modal" />

      <Box className="modal-container">
        <Box className="modal-text-container">
          <Typography variant="modalTitle" component="h2">
            {errorTitle ? t(errorMessage) : t('There has been an error')}
          </Typography>

          <Typography variant="modalSubText" component="p">
            {errorMessage ? t(errorMessage) : t('Error Message')}
          </Typography>
        </Box>

        <Box className="modal-container-buttons">
          <Button
            className="stat-button-link"
            data-dtm="screen share modal"
            onClick={onClose}
            variant="primary"
            type="button"
            disableRipple
            aria-label="Resume"
          >
            {t('Resume')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ScreenSharingErrorModal;
