import { Box, Button, Typography } from '@mui/material';
import { ModalIconButton, TextButton } from '@components';
import { useTranslation } from 'react-i18next';
import { BrandType } from '@types';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';

type LeavingSessionTabConfirmationModalProps = {
  brandId: BrandType;
  onClose?: () => void;
  onOpenTab?: () => void;
};

const LeavingSessionTabConfirmationModal = (props: LeavingSessionTabConfirmationModalProps) => {
  const { onClose, onOpenTab, brandId } = props;
  const { t } = useTranslation();
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';
  const isChevy = brandId.toLowerCase() === 'chevy';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev';
  const isEVLiveCanada = brandId.toLowerCase() === 'evcan';
  const isEnergy = brandId.toLowerCase() === 'energy';
  const theme = useTheme();

  const useStyles = makeStyles({
    onMobile: {
      [theme.breakpoints.up(768)]: {
        display: 'none',
      },
    },
    onDesktop: {
      display: 'none',
      [theme.breakpoints.up(768)]: {
        display: 'block',
      },
    },
  });
  const styles = useStyles();

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
        />
      )}
      <Box className="modal-container">
        {!isEnergy && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
          />
        )}
        <Box className="modal-text-container">
          <Typography component="h2" variant="modalTitle"
            dangerouslySetInnerHTML={{
              __html: t('This link will open in a new tab')
            }}
          />

          {isGMC && <Box className="modal-border" />}

          <Typography component="p" variant="modalSubText" className={styles.onMobile}>
            {t(
              'You are about to temporarily leave this tour. Your tour will continue on this tab, but sound will be muted. You will have to navigate back to continue.',
            )}
          </Typography>

          <Typography component="p" variant="modalSubText" className={styles.onDesktop}>
            {t(
              'You are about to temporarily leave this tour. Your tour will continue on this tab and you will still be able to hear it, but you will have to navigate back to continue.',
            )}
          </Typography>
        </Box>
        <Box className="modal-container-buttons">
          {(isChevy || isEVLive || isEVLiveCanada) && (
            <TextButton
              className="stat-text-link"
              data-dtm="end session modal"
              onClick={onClose}
              variant="text"
              type="button"
              disableRipple
              sx={{ textDecoration: isEVLive || isEVLiveCanada ? 'underline !important' : 'none' }}
            >
              {t('Cancel')}
            </TextButton>
          )}

          {(isCadillacUS || isCadillacCanada || isGMC || isBuick || isEnergy) && (
            <Button
              className="stat-button-link"
              data-dtm="end session modal"
              onClick={onClose}
              variant={isCadillacUS || isCadillacCanada ? 'primary' : 'tertiary'}
              type="button"
              disableRipple
            >
              {t('Cancel')}
            </Button>
          )}

          <Button
            className="stat-button-link"
            data-dtm="end session modal"
            onClick={onOpenTab}
            variant="primary"
            type="button"
            disableRipple
            aria-label="Close modal"
          >
            {t('Follow link')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LeavingSessionTabConfirmationModal;
