import { Status } from '../types';
import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat';

const route = window.location.pathname;

const isOfflineForDatabase: Status = {
  state: 'offline',
  lastChanged: firebase.database.ServerValue.TIMESTAMP as number,
  route,
};

const isOnlineForDatabase: Status = {
  state: 'online',
  lastChanged: firebase.database.ServerValue.TIMESTAMP as number,
  route
};

/* *
 * Persists user status to Realtime Database.
 * From here, we listen for changes in the statusOnUpdate Firebase Function
 * and update the Firestore accordingly.
 * */

export const usePersistence = () => {
  const auth = getAuth();
  const statusDoc = `status/${auth?.currentUser?.uid}`;
  const db = firebase.database().ref(statusDoc);

  useEffect(() => {
    // listen for changes in connection. Update Realtime Database.
    if(auth){
      db.onDisconnect()
      .set(isOfflineForDatabase)
      .then(() => {
        db.set(isOnlineForDatabase).catch((err) => {
          console.log(err);
        });
      });
    }


    // on component unmount, change status to offline
    return () => {
      if(auth && db){
      db.set(isOfflineForDatabase).catch((err) => {
        console.log(err);
      });
    }
    };
  }, [db]);
};
