import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Box } from '@material-ui/core/node_modules/@material-ui/system';
import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { BrandType, Message } from '@types';
import { regexUrlHelper } from '@constants';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type ChatMessageProps = {
  isSender: boolean;
  message: Message;
  hasBecomeGroupChat: boolean;
  brandId: BrandType;
  isProductSpecialist: boolean;
  onLeavingSessionModal: (hyperlink: string, messageId: string, isUserClicked: string) => void;
};

const ChatMessage = (props: ChatMessageProps): JSX.Element => {
  const [text, setText] = useState<string>('');
  const { isSender, message, brandId, onLeavingSessionModal, hasBecomeGroupChat, isProductSpecialist } = props;
  const isCadillac = brandId === 'cadillac' || brandId === 'cadillaccan';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId === 'buick';
  const isEVLive = brandId === 'ev' || brandId === 'evcan';
  const isEnergy = brandId === 'energy';
  const theme = useTheme();
  const { t } = useTranslation();

  let chatMessageStyles = {};
  const chatMessagesCadillacStyles = {
    chatNowText: {
      marginTop: '20px',
    },
  };
  const chatMessageGMCStyles = {
    chatNowText: {
      marginTop: '20px',
      borderRadius: '0px',
    },
  };

  const chatMessageEVLiveStyles = {
    chatNowText: {
      marginTop: '22px',
      borderRadius: '25px',
      '@media screen and (min-width: 768px)': {
        marginTop: '10px',
      },
    },
  };

  const chatMessageEnergyStyles = {
    chatNowText: {
      width: '100%',
      maxWidth: 220,
      padding: 16,
      marginTop: 4,
      borderRadius: '16px',
      '@global': {
        strong: {
          color: '#000000',
          marginBottom: 4,
        },
      },
      [theme.breakpoints.up(1200)]: {
        marginTop: 8,
        borderRadius: 25,
      },
    },
    chatNowMessageAdmin: {
      justifyContent: 'flex-start',
    },
    chatNowMessageUser_isPS: {
      justifyContent: 'flex-end',
      marginTop: 8,
      borderRadius: 25,
      '@global': {
        '.MuiTypography-messageText': {
          maxInlineSize: 'max-content',
          textAlign: 'left !important',
          fontSize: 16,
          lineHeight: '20px',
        },
      },
    },
    chatNowMessageAdmin_isPS: {
      justifyContent: 'flex-start',
      marginTop: 8,
      borderRadius: 25,
      '@global': {
        '.MuiTypography-messageTextAdmin': {
          maxInlineSize: 'max-content',
          textAlign: 'left !important',
          fontSize: 16,
          lineHeight: '20px',
        },
      },
    },
    '@global': {
      a: {
        '&:is(:hover, :active)': {
          color: '#0009FF',
        },
      },
    },
  };

  if (isCadillac) {
    chatMessageStyles = {
      ...chatMessagesCadillacStyles,
    };
  }

  if (isGMC || isBuick) {
    chatMessageStyles = {
      ...chatMessageGMCStyles,
    };
  }

  if (isEVLive) {
    chatMessageStyles = {
      ...chatMessageEVLiveStyles,
    };
  }

  if (isEnergy) {
    chatMessageStyles = {
      ...chatMessageEnergyStyles,
    };
  }

  const useStyles = makeStyles({
    chatNowMessage: {
      display: 'flex',
    },
    chatNowMessageAdmin: {
      justifyContent: 'flex-end',
    },
    chatNowMessageUser: {
      justifyContent: 'flex-start',
    },
    chatNow: {
      display: 'flex',
      flexDirection: 'row',
    },
    chatNowText: {
      padding: '15px 35px',
      marginTop: 8,
      borderRadius: 30,
    },
    '@global': {
      a: {
        color: 'inherit',
      },
    },
    [theme.breakpoints.up('xs')]: {
      ...chatMessageStyles,
    },
  });
  const styles = useStyles();

  useEffect(() => {
    // Check if message contains a link
    if (regexUrlHelper.test(message.text)) {
      const renderedText = message.text
        .split(' ')
        .map((part) => {
          if (regexUrlHelper.test(part)) {
            // check if link has a title
            if (message.linkTitle) {
              const linkTitle = message.linkTitle.replaceAll('_', ' ');
              return `<a href=${part} label="Open link">${linkTitle}</a>`;
            }
            return `<a href=${part} label="Open link">${part}</a> `;
          }
          return part;
        })
        .join(' ');
      setText(renderedText);
    } else {
      setText(message.text);
    }
  }, [message]);

  const handleOpenTabModal = (event) => {
    event.preventDefault();
    const anchor = event.target.closest('a');
    if (anchor) {
      const isUserClicked = message.linkTitle && !isSender ? 'true' : '';
      onLeavingSessionModal(anchor, message.id, isUserClicked);
    }
  };

  return (
    <Box
      className={clsx(
        styles.chatNowMessage,
        !isProductSpecialist
          ? isSender
            ? styles.chatNowMessageAdmin
            : styles.chatNowMessageUser
          : '',
        isProductSpecialist
          ? isSender
            ? styles.chatNowMessageAdmin_isPS
            : styles.chatNowMessageUser_isPS
          : ''
      )}
    >
      <Box className={clsx(styles.chatNow)}>
        <Typography
          className={styles.chatNowText}
          variant={isSender ? 'messageTextAdmin' : 'messageText'}
          dangerouslySetInnerHTML={{
            __html: `
              <strong>
                ${
                  message.displayName === 'Product Specialist' ||
                  hasBecomeGroupChat ||
                  message.displayName === undefined
                    ? t(message.displayName) || 'Link'
                    : 'user'
                }
              </strong></br>
              ${text}
            `,
          }}
          onClick={handleOpenTabModal}
        />
      </Box>
    </Box>
  );
};


export default ChatMessage;
