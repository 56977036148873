import { useEffect, useState, useCallback } from 'react';
import { Symbl, StreamingAPIConnection, RealtimeSpeechData, RealtimeMessageData, SubscribeAPIConnection, StreamingAPIConnectionConfig } from '@symblai/symbl-web-sdk';
import { SymblType, DetectTracker, TranscriptFragment } from '@types';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface Caption {
  speakerName: string;
  text: string;
  isFinal: boolean;
}

export const useClosedCaption = (brandId: string, myUserName: string): any => {
  const [accessToken, setAccessToken] = useState<string>('');
  const [conversationConnection, setConversationConnection] = useState<StreamingAPIConnection | SubscribeAPIConnection>();
  const [closedCaption, setClosedCaption] = useState<Caption>({ speakerName: '', text: '', isFinal: false });
  const [desiredTopicCheck, setDesiredTopicCheck] = useState<boolean>(false);
  const [conversationId, setConversationId] = useState<string>('');
  const [finalizedTranscriptFragment, setFinalizedTranscriptFragment] = useState<TranscriptFragment>();
  const [currentTracker, setCurrentTracker] = useState<string>('');
  const [currentTrackerSpeaker, setCurrentTrackerSpeaker] = useState<string>('');
  const [symblError, setSymblError] = useState<string | null>(null);

  const [trackerF, setTrackerF] = useState<DetectTracker>();
  const [messageF, setMessageF] = useState<any>();

  const symbl = new Symbl();

  const connectionConfig: StreamingAPIConnectionConfig = {
    speaker: {
      name: myUserName || 'user',
    },
  };

  const getFinalSpeech = (speechData: RealtimeSpeechData, counter: number) => {
    if (speechData.isFinal || counter % 2 === 0) {
      const { punctuated } = speechData;
      setClosedCaption({
        speakerName: speechData.user.name,
        text: `${punctuated.transcript}`,
        isFinal: speechData.isFinal,
      });
    }
  };

  const initSymbl = useCallback(async () => {
    try {
      const functions = getFunctions();
      const getSymblAccessToken = httpsCallable(functions, 'getSymblAccessToken');
      const response = await getSymblAccessToken();
      const tmpAccessToken: string = String(response.data);
      if (!response.data) {
        throw new Error();
      }
      setAccessToken(tmpAccessToken);
    } catch (e) {
      setSymblError(e.toString());
      console.log('error in initialization', e);
    }
  }, []);

  const connectToExistingConnection = async (sessionId: string) => {
    try {
      if (accessToken) {
        symbl.init({ accessToken });

        const connection = await symbl.createConnection(sessionId);
        setConversationConnection(connection);
        await connection.startProcessing(connectionConfig);

        let hasFetchedId = false;
        let speechRecognitionCounter = 0;

        connection.on('speech_recognition', (speechData: RealtimeSpeechData) => {
          speechRecognitionCounter++;
          getFinalSpeech(speechData, speechRecognitionCounter);

          if (!hasFetchedId) {
            setConversationId(connection.getConversationId());
            hasFetchedId = true;
          }
        });
      }
    } catch (e) {
      setSymblError(e.toString());
    }
  };

  useEffect(() => {
    if (trackerF && messageF) {
      // filter trackers to match against message ref, if consumer spoke this will fire
      if (trackerF.matches[0].messageRefs[0].id === messageF.id && messageF.from.name !== 'deviceKit') {
        setCurrentTracker(trackerF.name);
        // Pass speaker to SessionView.tsx to filter according to if tracker matches speaker
        setCurrentTrackerSpeaker('consumer');
      }
      // filter trackers to match against message ref, if agent spoke this will fire
      if (trackerF.matches[0].messageRefs[0].id === messageF.id && messageF.from.name === 'deviceKit') {
        setCurrentTracker(trackerF.name);
        // Pass speaker to SessionView.tsx to filter according to if tracker matches speaker
        setCurrentTrackerSpeaker('agent');
      }
    }
  }, [messageF, trackerF]);

  const subscribeToExistingConnection = async (sessionId: string, isUser: boolean, symblConfigIds?: SymblType[]) => {
    try {
      if (accessToken) {
        symbl.init({ accessToken });
        const connection = await symbl.subscribeToConnection(sessionId);
        setConversationConnection(connection);

        if (isUser) {
          let speechRecognitionCounter = 0;
          connection.on('speech_recognition', (speechData: RealtimeSpeechData) => {
            speechRecognitionCounter++;
            getFinalSpeech(speechData, speechRecognitionCounter);
          });
        } else {
          if (symblConfigIds && symblConfigIds.includes('Trackers')) {
            connection.on('tracker', (trackerData: DetectTracker[]) => {
              setTrackerF(trackerData[0]);
            });
          }

          connection.on('message', (messages) => {
            setMessageF(messages[0]);
            messages.forEach((message: RealtimeMessageData) => {
              setFinalizedTranscriptFragment({
                id: message.id,
                startTime: new Date(message.duration.startTime),
                endTime: new Date(message.duration.endTime),
                from: message.from.name,
                text: message.payload.content
              });
            });
          });
        }
      }
    } catch (e) {
      setSymblError(e.toString());
    }
  };

  const disconnectSymbl = async () => {
    try {
      if (accessToken && conversationConnection) {
        conversationConnection.disconnect();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    accessToken,
    conversationId,
    closedCaption,
    desiredTopicCheck,
    finalizedTranscriptFragment,
    currentTracker,
    currentTrackerSpeaker,
    symblError,
    initSymbl,
    connectToExistingConnection,
    subscribeToExistingConnection,
    disconnectSymbl,
    setDesiredTopicCheck,
    setFinalizedTranscriptFragment,
    setCurrentTracker,
    setCurrentTrackerSpeaker
  };
};
