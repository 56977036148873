import { useEffect, useState } from 'react';

type Script = {
  id: string;
  src: string;
  async: boolean;
};

export const useScripts = (scripts: Script[]): boolean => {
  const [loaded, setLoaded] = useState(false);
  let loadedCount = 0;
  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    scripts.forEach((script) => {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.id = script.id;
      if (script.async) {
        scriptElement.async = script.async;
      }
      scriptElement.src = script.src;
      head.appendChild(scriptElement);
      scriptElement.onload = () => {
        loadedCount++;
        console.log(loadedCount);
        // TODO: look into better method for this
        setLoaded(loadedCount === scripts.length);
      };
    });
  }, [setLoaded]);

  return loaded;
};
