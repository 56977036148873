import { ReactElement, ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@material-ui/core/styles';

type SessionMenusProps = {
  children: ReactNode;
  brandId: string;
  isFrench?: boolean;
  onClick(): void;
  userCameraStatus?: boolean;
  isMoreMenuOpen?: boolean;
  isChatOpen?: boolean;
};

const SessionMenu = (props: SessionMenusProps): ReactElement => {
  const { children, brandId, isFrench, userCameraStatus, onClick, isMoreMenuOpen, isChatOpen } = props;
  const theme = useTheme();
  const isCadillacUS = brandId === 'cadillac';
  const isCadillacCanada = brandId === 'cadillaccan';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId === 'buick';
  const isEVLive = brandId === 'ev';
  const isEVLiveCanada = brandId === 'evcan';
  const isEnergy = brandId === 'energy';

  let sessionsMenusStyles = {};
  const cadillacStyles = {
    sessionsMenus: {
      bottom: 145,
      width: '241px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      [theme.breakpoints.up('md')]: {
        bottom: 91,
      },
    },
  };
  const gmcStyles = {
    sessionsMenus: {
      right: 16,
      bottom: 138,
      width: 'calc(100% - 32px)',
      maxWidth: '330px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      [theme.breakpoints.up('md')]: {
        right: 32,
        bottom: 112,
      },
    },
  };
  const buickStyles = {
    sessionsMenus: {
      right: 16,
      bottom: 148,
      paddingTop: '8.5px',
      paddingBottom: '8.5px',
      width: 'calc(100% - 32px)',
      maxWidth: '330px',
      backgroundColor: 'rgba(63, 63, 56, 0.9)',
      [theme.breakpoints.up('md')]: {
        right: 32,
        bottom: 112,
        width: '380px',
        maxWidth: '380px',
      },
    },
  };
  const evliveStyles = {
    sessionsMenus: {
      paddingTop: 10,
      paddingBottom: 10,
      right: 16,
      bottom: 147,
      width: 'calc(100% - 32px)',
      borderRadius: 10,
      backgroundColor: theme.palette.grey[300],
      '@media screen and (min-width: 368px)': {
        width: '317px',
      },
      [theme.breakpoints.up('md')]: {
        right: 32,
        bottom: 130
      },
    },
  };
  const evliveCanadaStyles = {
    sessionsMenus: {
      paddingTop: 10,
      paddingBottom: 10,
      right: 16,
      bottom: 95,
      width: 'calc(100% - 32px)',
      borderRadius: 10,
      backgroundColor: theme.palette.grey[300],
      '@media screen and (min-width: 368px)': {
        width: '317px',
      },
      [theme.breakpoints.up('md')]: {
        right: 32,
        bottom: 130,
        width: isFrench ? 360 : null,
      },
    },
  };
  const energyStyles = {
    sessionMenuUnderlay: {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      visibility: 'hidden',
      height: '100%',
      backgroundColor: 'rgba(0, 16, 82, 0.5)',
      zIndex: -1,
      opacity: 0,
      transitionProperty: 'visibility, zIndex, opacity',
      transitionDuration: '200ms',
      transitionTimingFunction: 'linear',
    },
    sessionMenuUnderlay_SlideIn: {
      visibility: 'visible',
      zIndex: 10,
      opacity: 1,
      transitionProperty: 'visibility, zIndex, opacity',
      transitionDuration: '200ms',
      transitionTimingFunction: 'linear',
    },
    sessionsMenus: {
      position: 'absolute',
      top: 4,
      bottom: 'unset',
      right: 0,
      marginRight: '-160px',
      width: '100%',
      maxWidth: 'fit-content',
      transitionProperty: 'right, margin-right',
      transitionDuration: '100ms',
      transitionTimingFunction: 'ease-out',
      [theme.breakpoints.up(1200)]: {
        // top: 'unset',
        // bottom: 126,
        top: 32,
      },
    },
    sessionsMenus_SlideIn: {
      // right: 4,
      marginRight: 4,
      transitionProperty: 'right, margin-right',
      transitionDuration: '100ms',
      transitionTimingFunction: 'ease-out',
      [theme.breakpoints.up(1200)]: {
        // right: userCameraStatus ? 282 : 32,
        marginRight: userCameraStatus ? 314 : 32,
        // marginRight: (userCameraStatus && isChatOpen) ? 32 : userCameraStatus ? 314 : 32,
      },
      [theme.breakpoints.up(1367)]: {
        // marginRight: (userCameraStatus && isChatOpen) ? 32 : userCameraStatus ? 282 : 32,
        marginRight: userCameraStatus ? 282 : 32,
      },
    },
    transitionProperty: 'right',
    transitionDuration: '100ms',
    transitionTimingFunction: 'ease out',
  };

  if (isCadillacUS || isCadillacCanada) {
    sessionsMenusStyles = {
      ...cadillacStyles,
    };
  }

  if (isGMC) {
    sessionsMenusStyles = {
      ...gmcStyles,
    };
  }

  if (isBuick) {
    sessionsMenusStyles = {
      ...buickStyles,
    };
  }

  if (isEVLive) {
    sessionsMenusStyles = {
      ...evliveStyles,
    };
  }

  if (isEVLiveCanada) {
    sessionsMenusStyles = {
      ...evliveCanadaStyles,
    };
  }

  if (isEnergy) {
    sessionsMenusStyles = {
      ...energyStyles,
    };
  }

  const useStyles = makeStyles({
    sessionsMenus: {
      position: 'absolute',
      bottom: 142,
      right: 20,
      zIndex: 11,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      flexDirection: 'column',
      width: 'auto',
      [theme.breakpoints.up(1200)]: {
        bottom: 102,
      },
    },
    [theme.breakpoints.up('xs')]: {
      ...sessionsMenusStyles,
    },
  });

  const styles = useStyles();

  return (
    <>
      {isEnergy ? (
        <>
          <Box
            className={`${styles.sessionMenuUnderlay} ${isMoreMenuOpen ? styles.sessionMenuUnderlay_SlideIn : ''}`}
            onClick={onClick}
          >
            <Box className={`${styles.sessionsMenus} ${isMoreMenuOpen ? styles.sessionsMenus_SlideIn : ''}`}>{children}</Box>
          </Box>
        </>
      ) : (
        <Box className={styles.sessionsMenus}>{children}</Box>
      )}
    </>
  );
};

export default SessionMenu;
