/* eslint-disable */
import firebase from 'firebase/compat';
import { collection, DocumentData } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export const useGetAllVehicles = (): any => {
    const firestore = useFirestore();

    const brandsQuery = collection(firestore, 'brands');
    const { data: brands } = useFirestoreCollectionData(brandsQuery, {
        idField: 'id',
    });

    const [allVehicles, setAllvehicles] = useState<Array<DocumentData>>([]);

    useEffect(() => {
        (async () => {
            brands && brands.forEach((brand) => {
                (async () => {
                    const snapshot = await firebase.firestore().collection(`brands/${brand.id.toLowerCase()}/vehicles`).get();
                    const newElement = snapshot.docs.map((docData) => { // eslint-disable-line arrow-body-style
                        let returnedVehicle = docData.data();
                        returnedVehicle.id = docData.id;
                        returnedVehicle.brand = brand.id;
                        return returnedVehicle;
                    });
                    if (newElement.length !== 0) {
                        setAllvehicles((pre) => [...pre, ...newElement]);
                    }
                })();
            });
        })();
    }, [brands]);

    return { allVehicles };
};
