/* eslint-disable require-jsdoc */
import { BrandType, Event } from '@types';
import { fetchJson } from './fetchJson';

class EventRepository {
  getBrandEvents = async (date: Date, brandId: BrandType): Promise<Event[]> => {
    const body = {
      date,
      brandId
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/getBrandEvents`, body);
  };

  getToastEvents = async (date: Date, brandId: BrandType): Promise<Event[]> => {
    const body = {
      date,
      brandId
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/getEvents`, body);
  };
}

export const eventRepository = new EventRepository();
