import { Box } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { Typography, Button } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleFeaturesStyles } from './VehicleFeatures.style';

type VehicleFeaturesProps = {
  onClick?: () => void;
  children: ReactNode;
  isActive: boolean;
  brandId: string;
};

const VehicleFeatures = (props: VehicleFeaturesProps): ReactElement => {
  const { onClick, children, isActive, brandId } = props;
  const { t } = useTranslation();
  const useStyles = makeStyles(VehicleFeaturesStyles(brandId));
  const styles = useStyles();

  return (
    <Box className={styles.vehicleFeatures}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        flexDirection: 'column',
      }}
      >
        <Box
          className={`stat-expand-icon ${styles.vehicleFeaturesTextButton} ${isActive ? styles.vehicleFeaturesTextButtonActive : ''}`}
          data-dtm="1-1 session"
          onClick={onClick}
        >
          <Typography
            className={styles.vehicleFeaturesText}
            variant="vehicleFeatureDropDown"
            component="span"
          >
            {t('Vehicle Features')}
          </Typography>
          <Button
            className={`vehicle-feature-icon ${styles.vehicleFeaturseButton} ${isActive ? styles.vehicleFeaturseActiveButton : ''}`}
            type="button"
            aria-label="Open vehicle features"
          />
        </Box>
        <>{children}</>
      </Box>
    </Box>
  );
};

export default VehicleFeatures;
