import { useTheme } from '@material-ui/core/styles';

export const VehicleFeaturesStyles = (brandId: string) => {
  const theme = useTheme();

  const chevyStyles = {
    vehicleFeatures: {
      overflowX: 'hidden',
      maxHeight: '80vh',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 484,
        maxWidth: 484,
      },
    },
    vehicleFeaturesTextButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.border.vehicleFeaturesTextButton}`,
      cursor: 'pointer',
      '&:hover': {
        '@global': {
          '.MuiTypography-vehicleFeatureDropDown': {
            color: theme.palette.color.vehicleFeaturesTextActive,
          },
          '.vehicle-feature-icon': {
            backgroundColor: theme.palette.background.vehicleFeaturseButtonHover,
            '&::before': {
              backgroundImage: theme.palette.background.featureArrowDownActive,
            },
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        width: 252,
      },
    },
    vehicleFeaturesTextButtonActive: {
      '&:hover': {
        '@global': {
          '.MuiTypography-vehicleFeatureDropDown': {
            color: theme.palette.color.vehicleFeaturesTextActive,
          },
          '.vehicle-feature-icon': {
            '&::before': {
              backgroundImage: theme.palette.background.featureArrowUp,
            },
          },
        },
      },
      '@global': {
        '.MuiTypography-vehicleFeatureDropDown': {
          color: theme.palette.color.vehicleFeaturesTextActive,
        },
        '.vehicle-feature-icon': {
          '&::before': {
            backgroundImage: theme.palette.background.featureArrowUpActive,
          },
        },
      },
    },
    vehicleFeaturesText: {
      width: '100%',
    },
    vehicleFeaturseButton: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 40,
      width: 70,
      minWidth: 70,
      backgroundColor: theme.palette.background.vehicleFeaturseButton,
      border: 'none',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        margin: '0 auto',
      },
      '&:hover': {
        backgroundColor: theme.palette.background.vehicleFeaturseButtonHover,
      },
    },
    vehicleFeaturseActiveButton: {
      backgroundColor: theme.palette.background.vehicleFeaturseButtonHover,
    },
    '@global': {
      '.vehicle-feature-icon': {
        '&::before': {
          // height: '7px',
          // width: '12px',
          height: '10px',
          width: '25px',
          backgroundImage: theme.palette.background.featureArrowDown,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center center',
          backgroundOrigin: 'content-box',
        }
      }
    },
  };

  const cadillacStyles = {
    vehicleFeaturesTextButton: {
      paddingLeft: '52px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '&:hover': {
        backgroundColor: '#fff',
      },
      [theme.breakpoints.up('md')]: {
        width: 252,
        paddingLeft: 0,
        textAlign: 'right',
      },
    },
    vehicleFeaturesTextButtonActive: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
    vehicleFeaturseButton: {
      height: '35px',
      width: '52px',
      minWidth: '52px',
      '&::before': {
        border: 'none',
      }
    },
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
    // vehicleFeaturesText: {
    //   paddingLeft: '20px',
    //   textAlign: 'center'
    // },
  };

  const gmcStyles = {
    vehicleFeatures: {
      maxHeight: '72vh',
      // GL-1470
      [theme.breakpoints.up('md')]: {
        maxHeight: 'calc(71vh - 32px)',
      },
    },
    vehicleFeaturesTextButton: {
      paddingLeft: '52px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      border: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      },
      [theme.breakpoints.up('md')]: {
        width: 300,
        height: 54,
        paddingLeft: '26px',
        textAlign: 'left',
      },
    },
    vehicleFeaturesTextButtonActive: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    vehicleFeaturseButton: {
      height: '35px',
      width: '52px',
      minWidth: '52px',
      backgroundColor: 'transparent',
      '&::before': {
        border: 'none',
      }
    },
  };

  const buickStyles = {
    vehicleFeatures: {
      maxHeight: '72vh',
    },
    vehicleFeaturesTextButton: {
      paddingLeft: '52px',
      backgroundColor: 'rgba(63, 63, 56, 0.9)',
      border: 'none',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      },
      [theme.breakpoints.up('md')]: {
        width: 300,
        height: 54,
        paddingLeft: '26px',
        textAlign: 'left',
      },
    },
    vehicleFeaturesTextButtonActive: {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    vehicleFeaturseButton: {
      height: '35px',
      width: '52px',
      minWidth: '52px',
      backgroundColor: 'transparent',
      '&::before': {
        border: 'none',
      }
    },
  };

  const evliveStyles = {
    vehicleFeatures: {
      // maxHeight: '72vh',
      maxHeight: '69.5vh',
      '@media screen and (min-width: 1440px)': {
        maxHeight: '76vh',
      },
    },
    vehicleFeaturesTextButton: {
      height: '37px',
      paddingLeft: 0,
      backgroundColor: theme.palette.grey[300],
      border: 'none',
      cursor: 'pointer',
      borderRadius: 25,
      '&:hover': {
        backgroundColor: '#fff',
      },
      [theme.breakpoints.up('md')]: {
        width: 300,
        height: 49,
        paddingLeft: 0,
        textAlign: 'left',
      },
    },
    vehicleFeaturesTextButtonActive: {
      position: 'absolute',
      left: 16,
      right: 16,
      bottom: 0,
      width: 'calc(100% - 32px)',
      backgroundColor: '#fff',
      '&:hover': {
        '@global': {
          '.vehicle-feature-icon': {
            backgroundColor: theme.palette.background.vehicleFeaturseButton,
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        left: 'unset',
        right: 'unset',
        bottom: 'unset',
        width: 300,
      },
    },
    vehicleFeaturseButton: {
      height: '37px',
      width: '70px',
      minWidth: '70px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 25,
      borderBottomRightRadius: 25,
      '&::before': {
        border: 'none',
      },
      [theme.breakpoints.up('md')]: {
        height: 49,
      },
    },
    '@global': {
      '.MuiTypography-vehicleFeatureDropDown': {
        paddingLeft: '37px',
        paddingRight: '37px',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
          paddingLeft: '30px',
          paddingRight: '30px',
        },
      },
      '.vehicle-feature-icon': {
        '&::before': {
          height: '8px',
          width: '10px',
        },
      },
    },
  };

  const energyStyles = {};

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};
