import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import { useCurrentBrand, useScrollReset } from '@hooks';
import routes from './routes';
import { createCustomTheme } from '../../lib/src/theme';
import { ReportingProvider } from '../../lib/src/contexts/ReportingContext';
import { Language } from '@components';
import { PresenceProvider } from '../../lib/src/contexts/PresenceContext';
import AnonymousAuthGuard from './components/AnonymousAuthGuard';
import { makeStyles } from '@mui/styles';

const Theme: FC = () => {
  const content = useRoutes(routes);
  useScrollReset();
  const brand = useCurrentBrand();

  const getTheme = () => createCustomTheme({
    theme: brand.id,
  });

  const useStyles = makeStyles({
    GMToast: {
      padding: 0,
      width: '100%',
      maxWidth: 288,
      backgroundColor: '#ffffff',
      border: '3px solid #8DF7FD',
      borderRadius: 0,
      boxShadow: 'none',
      '@media screen and (min-width: 800px)': {
        maxWidth: 420,
      },
      '@global': {
        '[role=status]': {
          margin: 0,
        },
      },
    },
  });
  const styles = useStyles();

  return (
    <AnonymousAuthGuard>
      {
        brand
        && <ThemeProvider theme={getTheme()}>
          <ReportingProvider brand={brand}>
            <CssBaseline />
            <Toaster
              position="top-center"
              toastOptions={{
                className: styles.GMToast,
                duration: 4000,
              }}
            />
            <Language>
              <PresenceProvider>
                {content}
              </PresenceProvider>
            </Language>
          </ReportingProvider>
          {/* eslint-disable-next-line react/jsx-closing-tag-location */}
        </ThemeProvider>
      }
    </AnonymousAuthGuard>);
};

export default Theme;
