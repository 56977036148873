import { Box } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { useTheme } from '@material-ui/core/styles';
import { Color } from '@types';
import { ReactElement } from 'react';

type VehicleColorsProps = {
  colors: Color[];
  onSelectColor: (selectedColor: Color) => void;
  colorState: any;
  vehicleStateName: string;
};

const VehicleColors = (props: VehicleColorsProps): ReactElement => {
  const theme = useTheme();
  const useStyles = makeStyles({
    colorSelectColors: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    colorSelectButton: {
      zIndex: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 8,
      padding: '1px 6px',
      height: 42,
      width: 42,
      minHeight: 42,
      minWidth: 42,
      backgroundColor: 'transparent',
      borderRadius: 25,
      border: `2px solid ${theme.palette.border.colorSelectButton}`,
      // boxShadow: ' 0 0 0 2px transparent',
      overflow: 'hidden',
      transition: 'all 0.3s',
      '&:hover': {
        // boxShadow: `0 0 0 2px ${theme.palette.boxShadow.colorSelectButtonHover}`,
        border: `2px solid ${theme.palette.border.colorSelectButtonHover}`,
      },
    },
    colorSelectButtonSelected: {
      // boxShadow: `0 0 0 2px ${theme.palette.boxShadow.colorSelectButtonSelected}`,
      border: `2px solid ${theme.palette.border.colorSelectButtonSelected}`,
    },
    colorSelectButtonImage: {
      height: 30,
      width: 30,
      borderRadius: 25,
    },
  });
  const styles = useStyles();

  const { onSelectColor, colors, colorState, vehicleStateName } = props;

  const handelSelectColor = (color: Color) => {
    onSelectColor(color);
  };

  return (
    <>
      {colors && (
        <Box className={styles.colorSelectColors}>
          {colors.map((color: Color) => (
            <Button
              key={color.id}
              onClick={() => handelSelectColor(color)}
              className={`stat-image-link ${styles.colorSelectButton} ${colorState.index === color.index ? styles.colorSelectButtonSelected : ' '}`}
              data-dtm={`colors:${vehicleStateName.toLowerCase()}`}
              disableRipple
              aria-label="Select model color"
            >
              <Box
                className={styles.colorSelectButtonImage}
                component="img"
                src={color.imageUrl}
                alt={color.name}
                draggable={false}
              />
            </Button>
          ))}
        </Box>
      )}
    </>
  );
};

export default VehicleColors;
