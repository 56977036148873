/**
 * Parses an HTML string and returns the text content.
 *
 * @param {string} htmlString - The HTML string to parse.
 * @returns {string} The text content of the parsed HTML string.
 */
export const parseHtmlString = (htmlString:string): string => {
  const parser = new DOMParser();
  const text = parser.parseFromString(htmlString, 'text/html');
  return text.body.textContent || '';
};
