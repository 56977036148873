import { Box } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import ThreeSixtyOutlinedIcon from '@mui/icons-material/ThreeSixtyOutlined';
import { useTheme } from '@material-ui/core/styles';
import { RotationImage } from '@types';
import { ReactElement, useState } from 'react';
import { Animated } from 'react-animated-css';

type RotationImagesProps = {
  rotationImages: RotationImage[];
  rotationIndex: number;
  onRotationIndexChanged: (rotationIndex: number) => void;
  vehicleModelVisible?: boolean;
  brandId: string;
  vehicleStateName: string;
};

const RotationImages = (props: RotationImagesProps): ReactElement => {
  const theme = useTheme();
  const { rotationImages, rotationIndex, onRotationIndexChanged, vehicleModelVisible, brandId, vehicleStateName } = props;
  const [visible, setVisible] = useState(true);
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';

  let rotationImagesStyles = {};
  const rotationImagesCadillacStyles = {
    vehicleModelImage: {
      height: '150px',
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        height: '200px',
      },
      [theme.breakpoints.up('lg')]: {
        height: '250px',
      },
    },
  };
  const rotationImagesGMCStyles = {
    vehicleModelImage: {
      height: '300px',
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        height: '450px',
      },
      [theme.breakpoints.up('lg')]: {
        height: '500px',
      },
    },
  };
  const rotationImagesBuickStyles = {
    vehicleModelImage: {
      height: '100px',
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        height: '150px',
      },
      [theme.breakpoints.up('lg')]: {
        height: '200px',
      },
    },
  };

  if (isCadillacUS || isCadillacCanada) {
    rotationImagesStyles = {
      ...rotationImagesCadillacStyles,
    };
  }

  if (isGMC) {
    rotationImagesStyles = {
      ...rotationImagesGMCStyles,
    };
  }

  if (isBuick) {
    rotationImagesStyles = {
      ...rotationImagesBuickStyles,
    };
  }

  const useStyles = makeStyles({
    vehicleModelContainer: {
      position: 'absolute',
      top: -60,
      left: 0,
      right: 0,
      paddingLeft: 15,
      paddingRight: 15,
      margin: '0 auto',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        top: -80,
        minHeight: 144,
      },
    },
    rotateButton: {
      position: 'absolute',
      bottom: -20,
      marginLeft: 250,
      minWidth: 39,
      padding: 0,
      border: 'none',
      backgroundColor: 'transparent',
      [theme.breakpoints.up('md')]: {
        marginLeft: 300,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 350,
      },
    },
    rotateButtonIcon: {
      height: 35,
      width: 'auto',
      color: theme.palette.color.vehicleRotateCloseIcon,
    },
    vehicleModelImageContainer: {
      position: 'relative',
      height: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        height: 144,
      },
    },
    vehicleModelImage: {
      height: '200px',
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        height: '300px',
      },
      [theme.breakpoints.up('lg')]: {
        height: '350px',
      },
    },
    [theme.breakpoints.up('xs')]: {
      ...rotationImagesStyles,
    },
  });

  const reAnimate = () => {
    setVisible(false);
    setTimeout(() => {
      setVisible(true);
    }, 1);
  };

  const styles = useStyles();

  const handelSelectRotationImage = () => {
    let newIndex = 0;
    if (rotationImages.length - 1 > rotationIndex) {
      newIndex = rotationIndex + 1;
    }
    onRotationIndexChanged(newIndex);
    reAnimate();
  };

  return (
    <>
      {rotationImages && rotationImages.length > 0 && (
        <Box className={styles.vehicleModelContainer}>
          <Box className={styles.vehicleModelImageContainer}>
            {rotationImages[rotationIndex] && rotationImages[rotationIndex]?.imageUrl && (
              <>
                <Animated animationIn="pulse" animationOut="fadeOut" isVisible={visible}>
                  <Box
                    className={styles.vehicleModelImage}
                    component="img"
                    src={rotationImages[rotationIndex]?.imageUrl}
                    alt={rotationImages[rotationIndex].name}
                    draggable={false}
                  />
                </Animated>
                <IconButton
                  className={`stat-icon-link ${styles.rotateButton}`}
                  data-dtm={`${vehicleModelVisible ? 'vehicle selector' : `colors:${vehicleStateName.toLowerCase()}`}`}
                  color="primary"
                  aria-label="Rotate model"
                  onClick={() => handelSelectRotationImage()}
                  component="span"
                  disableRipple
                >
                  <ThreeSixtyOutlinedIcon className={styles.rotateButtonIcon} />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default RotationImages;
