import { TextField } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const TextFieldForm = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    display: 'block',
    width: '100%',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    backgroundColor: `${alpha(theme.palette.background.paper, 1)}`,
    backgroundClip: 'paddingBox',
    border: 'none',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    height: 'calc(1.5em + 0.75rem + 4px)',
    paddingTop: '7px',
    paddingBottom: '7px',
    borderRadius: '0',
    '&.Mui-error': {
      border: `2px solid ${alpha(theme.palette.error.main, 1)}`,
    },
  },
  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& .MuiInputBase-multiline': {
    height: '100% !important',
    // padding: 0,
  },
}));

export default TextFieldForm;
