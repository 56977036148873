import { useTheme } from '@material-ui/core/styles';

export const SessionViewStyles = (brandId: string) => {
  const theme = useTheme();
  /**
   * new breakpoints in the future
   * md and tablet is gonna be 800 use [theme.breakpoints.up(800)]
   * xl and desktop is gonna be 1200 use [theme.breakpoints.up(1200)]
   * Change @media screen and (min-width: 768px), [theme.breakpoints.up(768)], [theme.breakpoints.up('md')] to [theme.breakpoints.up('xl')] or [theme.breakpoints.up(1200)] only for SessionView
   */
  const chevyStyles = {
    showroomOuter: {
      backgroundColor: theme.palette.background.paper,
    },
    showroomOuterActiveTestDrive: {
      height: '1100px',
      [theme.breakpoints.up(1200)]: {
        height: '1400px',
      },
    },
    showroomOuterActiveInviteAFriend: {
      height: '900px',
      [theme.breakpoints.up('md')]: {
        height: '1200px',
      },
    },
    // If showroom height gets updated, Update showroomProductSpecialist, OTPublisherUser (height)
    showroom: {
      position: 'relative',
      padding: 0,
      // height: 700,
      // width: '100%',
      height: 'auto',
      width: 'auto',
      aspectRatio: '1/1.5',
      maxWidth: '100%',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      transition: 'height 300ms ease',
      [theme.breakpoints.up('md')]: {
        aspectRatio: '1/1',
      },
      [theme.breakpoints.up(1200)]: {
        padding: '30px',
        height: 660,
        width: '100%',
        aspectRatio: 'unset',
      },
    },
    showroomProductSpecialist: {
      height: '100vh',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        aspectRatio: 'unset',
      },
      [theme.breakpoints.up(1200)]: {
        height: '100vh',
      },
      [theme.breakpoints.up(2388)]: {
        // for screens larger than 2388 (iPad Pro 11")
        height: '100vh',
      },
    },
    showroomContainer: {
      position: 'relative',
      height: '100%',
      backgroundColor: theme.palette.background.showroomContainer,
    },
    OTSessionContainer: {
      // position: 'absolute',
      // top: 0,
      // left: 0,
      // right: 0,
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      '@global': {
        '.OT_audio-only': {
          width: '1px !important',
          height: '1px !important',
          minWidth: '1px !important',
          minHeight: '1px !important',
        },
        // '.OT_publisher': {
        //   position: 'inherit',
        //   width: '1px !important',
        //   height: '1px !important',
        // },
        // '.OT_subscriber': {
        //   position: 'inherit',
        //   width: '100% !important',
        //   height: '100% !important',
        // },
        '.OT_video-element': {
          width: '100% !important',
          height: '100% !important',
        },
        '.OT_bar.OT_edge-bar-item.OT_mode-auto': {
          opacity: '0 !important',
        },
        '.OT_edge-bar-item.OT_mute.OT_mode-auto': {
          display: 'none !important',
        },
        '.OT_fit-mode-cover, .OT_video-element': {
          objectFit: 'cover',
        },
        '.OT_widget-container': {
          background: 'transparent !important',
        },
      },
    },
    OTSessionChatVisible: {
      display: 'none'
    },
    OTSessionMuted: {
      '@global': {
        '.OT_video-element': {
          [theme.breakpoints.up(1200)]: {
            padding: '78px 30px 30px 30px',
          },
        },
      },
    },
    OTSessionUnmuted: {
      '@global': {
        '.OT_video-element': {
          [theme.breakpoints.up(1200)]: {
            padding: 0,
          },
        },
      },
    },
    OTSessionIsOneToOneVR: {
      '@global': {
        '.OT_fit-mode-cover, .OT_video-element': {
          objectFit: 'contain !important',
          [theme.breakpoints.up(1200)]: {
            objectFit: 'cover !important',
          },
        },
      },
    },
    OTSessionIsUserScreenAndWebcamSharing: {
      '@global': {
        '.OT_fit-mode-cover, .OT_video-element': {
          objectFit: 'contain !important',
        },
        '.OTSubscriberContainer': {
          position: 'absolute !important',
        },
      },
    },
    OTSessionMirror: {
      '@global': {
        '.OT_subscriber': {
          transform: 'translateX(-50%) translateY(-50%) scaleX(-1)',
        },
        '.OT_publisher': {
          transform: 'translateX(-50%) translateY(-50%) scaleX(-1) !important',
        }
      },
    },
    OTSessionPiPMirror: {
      '@global': {
        '.OT_publisher': {
          transform: 'translateX(0%) translateY(0%) scaleX(-1) !important',
        }
      },
    },
    OTUserPublisherPiP: {
      '@global': {
        '.OT_fit-mode-cover, .OT_video-element': {
          objectFit: 'cover !important',
        },
        '.OT_publisher': {
          position: 'absolute',
          width: '120px !important',
          height: '180px !important',
          minWidth: '120px',
          minHeight: '180px',
          top: 'unset',
          left: 'unset',
          bottom: 65,
          right: '4px',
          transform: 'none',
          zIndex: 2,
          backgroundColor: 'rgba(36, 34, 31, 0.1)',
          [theme.breakpoints.up(1200)]: {
            bottom: '126px',
            right: '32px',
          }
        },
        '.OT_subscriber': {
          position: 'absolute',
          width: '120px !important',
          height: '180px !important',
          minWidth: '120px',
          minHeight: '180px',
          top: 'unset',
          left: 'unset',
          bottom: 65,
          right: '4px',
          transform: 'none',
          zIndex: 1,
          [theme.breakpoints.up(1200)]: {
            bottom: '126px',
            right: '32px',
          }
        },
      }
    },
    OTUserPublisherPiPLandscape: {
      '@global': {
        '.OT_fit-mode-cover, .OT_video-element': {
          objectFit: 'cover !important',
        },
        '.OT_publisher': {
          position: 'absolute',
          width: '180px !important',
          height: '120px !important',
          minWidth: '180px',
          minHeight: '120px',
          top: 'unset',
          left: 'unset',
          bottom: 65,
          right: '4px',
          transform: 'none',
          zIndex: 2,
          backgroundColor: 'rgba(36, 34, 31, 0.1)',
          [theme.breakpoints.up(1200)]: {
            bottom: '126px',
            right: '32px',
          }
        },
        '.OT_subscriber': {
          position: 'absolute',
          width: '180px !important',
          height: '120px !important',
          minWidth: '180px',
          minHeight: '120px',
          top: 'unset',
          left: 'unset',
          bottom: 65,
          right: '4px',
          transform: 'none',
          zIndex: 1,
          [theme.breakpoints.up(1200)]: {
            bottom: '126px',
            right: '32px',
          }
        },
      }
    },
    OTUserPublisherPiP_DefaultState: {
      '@global': {
        '.OT_publisher': {
          bottom: '-180px',
          // transitionDelay: '1.2s',
          transitionDuration: '1.2s',
          transitionProperty: 'bottom',
          transitionTimingFunction: 'ease',
          [theme.breakpoints.up(1200)]: {
            bottom: '126px',
          }
        },
        '.OT_subscriber': {
          bottom: '-180px',
          // transitionDelay: '1.2s',
          transitionDuration: '1.2s',
          transitionProperty: 'bottom',
          transitionTimingFunction: 'ease',
          [theme.breakpoints.up(1200)]: {
            bottom: '126px',
          }
        },
      },
    },
    OTUserPublisherPiP_SlideIn: {
      '@global': {
        '.OT_publisher': {
          bottom: '65px',
          transitionDelay: '1.2s',
          transitionDuration: '1.2s',
          transitionProperty: 'bottom',
          transitionTimingFunction: 'ease',
          [theme.breakpoints.up('md')]: {
            bottom: 72,
          },
          [theme.breakpoints.up(1200)]: {
            bottom: '126px',
            right: '32px',
          }
        },
        '.OT_subscriber': {
          bottom: '65px',
          transitionDelay: '1.2s',
          transitionDuration: '1.2s',
          transitionProperty: 'bottom',
          transitionTimingFunction: 'ease',
          [theme.breakpoints.up('md')]: {
            bottom: 72,
          },
          [theme.breakpoints.up(1200)]: {
            bottom: '126px',
            right: '32px',
          }
        },
      },
    },
    OTUserPublisherPiP_ClosedCaptionActiveSlideIn: {
      '@global': {
        '.OT_publisher': {
          bottom: '140px !important',
          transitionDelay: '1.2s',
          transitionDuration: '1.2s',
          transitionProperty: 'bottom',
          transitionTimingFunction: 'ease',
          [theme.breakpoints.up('md')]: {
            bottom: '72px !important',
          },
          [theme.breakpoints.up(1200)]: {
            bottom: '126px !important',
            right: '32px',
          }
        },
        '.OT_subscriber': {
          bottom: '140px !important',
          transitionDelay: '1.2s',
          transitionDuration: '1.2s',
          transitionProperty: 'bottom',
          transitionTimingFunction: 'ease',
          [theme.breakpoints.up('md')]: {
            bottom: '72px !important',
          },
          [theme.breakpoints.up(1200)]: {
            bottom: '126px !important',
            right: '32px',
          },
        },
      },
    },
    OTUserPublisherPiP_HidePiPOnChatOpen: {
      display: 'none',
    },
    screenSharingContainer: {
      padding: '5px 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.grey[800],
      position: 'absolute',
      zIndex: 1,
      top: 20,
      transform: 'translate(-50%, 0%)',
    },
    screenSharingPSIndicator: {
      height: 25,
      left: 'calc(50% - 40px)',
    },
    screenSharingUserIndicator: {
      height: 'auto',
      minHeight: 25,
      left: '50%',
      [theme.breakpoints.down(1070)]: {
        transform: 'translate(-75%, 0%)',
      },
      [theme.breakpoints.down('md')]: {
        transform: 'translate(-35%, 0%)',
      },
    },
    liveEndSessionContainer: {
      position: 'absolute',
      top: 20,
      left: 20,
      zIndex: 1,
    },
    liveEndSessionProductSpecialistContainer: {
      width: 160,
    },
    liveEndSessionFrenchContainer: {},
    liveContainer: {
      height: 25,
      marginBottom: 20,
      paddingRight: 15,
      paddingLeft: 15,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.background.live,
    },
    experienceButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginBottom: '4px',
      [theme.breakpoints.up(1200)]: {
        marginBottom: '16px',
      },
    },
    userEndSessionButton: {},
    transferButtons: {
      display: 'flex',
      // justifyContent: 'center',
      // flexDirection: 'column',
      justifyContent: 'space-between',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    transferButton: {
      marginTop: 0,
      marginBottom: 4,
      // width: '100%',
      [theme.breakpoints.up(1200)]: {
        marginBottom: 16,
      },
    },
    checkBrandButton: {
      marginTop: 0,
      marginBottom: 16,
      width: '100%',
      maxWidth: '160px',
      cursor: 'default',
      '&:hover': {
        '&.MuiButton-productSpecialistLight': {
          color: '#ffffff',
          borderColor: 'transparant',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        backgroundColor: 'inherit',
      },
    },
    muteVideoButton: {
      marginBottom: '16px',
      // width: '100%',
    },
    muteVideoButtonText: {
      width: 40,
    },
    chatNowNeedHelpContainer: {
      position: 'absolute',
      left: 20,
      bottom: 75,
      display: 'flex',
      zIndex: 1,
      width: 'calc(100% - 40px)',
      maxWidth: '345px',
      '@media screen and (min-width: 516px)': {
        width: 408,
        maxWidth: 408,
      },
      [theme.breakpoints.up(1200)]: {
        bottom: 20,
        width: 408,
        maxWidth: 408,
      },
    },
    chatNowNeedHelpContainerOpen: {
      zIndex: 2,
    },
    chatNow: {
      marginRight: 32,
      wordBreak: 'break-word',
      hyphens: 'auto',
    },
    chatNowFrench: {},
    chatNowIcon: {
      width: 'auto',
      height: '25px',
      marginBottom: '5px',
    },
    chatNowForm: {
      width: '100%',
    },
    chatNowInputContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 67,
      backgroundColor: theme.palette.background.paper,
    },
    chatNowInput: {
      '@global': {
        '.MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    chatNowInputButton: {
      height: '100%',
    },
    needHelpIconButtonMobileOnly: {
      [theme.breakpoints.up(1200)]: {
        display: 'none !important',
      },
    },
    needHelpIconButton: {
      display: 'none',
      padding: 0,
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
      },
    },
    needHelpIcon: {
      marginRight: 15,
      height: 28,
      width: 28,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
    bookTestDrive: {
      position: 'absolute',
      right: 20,
      bottom: 75,
      width: 'calc(100% - 127px)',
      maxWidth: 245,
      zIndex: 1,
      [theme.breakpoints.up(1200)]: {
        right: 122,
        bottom: 20,
      },
    },
    moreMenu: {
      position: 'absolute',
      top: '148px',
      left: 20,
      zIndex: 11,
      [theme.breakpoints.up(1200)]: {
        top: 'unset',
        left: 'unset',
        right: 20,
        bottom: 20,
      },
      '&:hover': {
        '@global': {
          '.menu-icon': {
            backgroundImage: theme.palette.background.menuIconActive,
          },
          // '.close-icon': {
          //   backgroundImage: theme.palette.background.closeIconActive,
          // },
        },
      },
    },
    moreMenuFrench: {},
    moreMenuActive: {},
    moreMenuProductSpecialist: {
      position: 'absolute',
      top: 393,
      left: 20,
      zIndex: 11,
      [theme.breakpoints.up(1200)]: {
        top: 'unset',
        left: 'unset',
        right: 20,
        bottom: 20,
      },
    },
    moreMenuProducer: {
      position: 'absolute',
      top: '66px',
      left: 20,
      zIndex: 1,
      [theme.breakpoints.up(1200)]: {
        top: 'unset',
        left: 'unset',
        right: 20,
        bottom: 20,
      },
    },
    moreMenuIcon: {
      width: 'auto',
      height: '25px',
      marginBottom: '5px',
    },
    vehicleFeaturesContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 2,
      width: '100%',
      paddingLeft: 15,
      paddingRight: 15,
      [theme.breakpoints.up(1200)]: {
        position: 'absolute',
        top: 20,
        left: 'initial',
        right: 20,
        bottom: 'initial',
        width: 'initial',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    vehicleSelectContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 3,
    },
    vehicleFeaturesItems: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      backgroundColor: 'white',
    },
    baInterfaceButtons: {
      position: 'absolute',
      top: 20,
      right: 20,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      [theme.breakpoints.up(1200)]: {
        top: 78,
      },
    },
    baInterfaceButton: {
      marginBottom: '16px',
      width: 252,
    },
    streamPause: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      paddingLeft: 15,
      paddingRight: 15,
      opacity: 0,
      visibility: 'hidden',
      transition: 'all 300ms ease',
      backgroundColor: theme.palette.background.streamPause,
      [theme.breakpoints.up(1200)]: {
        justifyContent: 'flex-start',
        // paddingLeft: 30,
        paddingLeft: 150,
      },
    },
    streamPauseActive: {
      opacity: 1,
      visibility: 'visible',
    },
    streamPauseContainer: {},
    streamPauseBorder: {},
    sessionMenuIcon: {
      height: '27px',
      width: '28px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
    sessionColorIcon: {
      backgroundImage: theme.palette.background.colorIcon,
    },
    sessionModelIcon: {
      backgroundImage: theme.palette.background.modelIcon,
    },
    noSession: {
      width: '100%',
      textAlign: 'center',
      margin: '0 auto',
      padding: '30px',
    },
    noSessionContainer: {
      textAlign: 'center',
      margin: '20px auto',
      maxWidth: 300,
    },
    noSessionButton: {
      width: '100%',
      maxWidth: 252,
    },
    noSessionButtonProductSpecialist: {},
    noSessionButtonUser: {},
    OTStreamPSHasBackground: {
      '@global': {
        '.OTSubscriberContainer': {
          backgroundColor: '#ffffff',
        },
      },
    },
    OTStreamUserHasBackground: {
      '@global': {
        '.OTSubscriberContainer': {
          backgroundColor: '#ffffff',
        },
        '.OT_fit-mode-cover': {
          display: 'none',
        },
      },
    },
    OTPublisherUser: {
      // height: '700px',
      // width: '100%',
      height: 'auto',
      width: 'auto',
      aspectRatio: '1/1.5',
      backgroundColor: '#ffffff',
      [theme.breakpoints.up(1200)]: {
        aspectRatio: 'unset',
        height: 660,
        width: '100%',
      },
      '@global': {
        '.OTPublisherContainer': {
          backgroundColor: '#ffffff',
        },
      },
    },
    OTUserPublisherPiPFullScreenBackground: {
      '@global': {
        '.OTPublisherContainer': {
          backgroundColor: '#ffffff',
        },
      },
    },
    OTPublisherSpecialist: {
      '@global': {
        '.OTPublisherContainer': {
          height: '100vh',
          width: '100%',
          backgroundColor: '#ffffff',
        },
      },
    },
    '@global': {
      '.mute-video-icon-off': {
        color: theme.palette.color.muteVideoIcon,
      },
      '.mute-video-icon-on': {
        color: theme.palette.color.muteVideoIconActive,
      },
      '.OTPublisherContainer': {
        position: 'absolute !important',
      },
      '.OT_publisher': {
        position: 'fixed',
        top: '50%',
        left: '50%',
        minWidth: '100%',
        minHeight: '100%',
        width: 'auto',
        height: 'auto',
        transform: 'translateX(-50%) translateY(-50%)',
        backgroundSize: 'cover',
        transition: '1s opacity',
      },
      '.OT_subscriber': {
        position: 'fixed',
        top: '50%',
        left: '50%',
        minWidth: '100%',
        minHeight: '100%',
        width: 'auto',
        height: 'auto',
        transform: 'translateX(-50%) translateY(-50%)',
        backgroundSize: 'cover',
        transition: '1s opacity',
      },
      // On the user side, When screen/webcam is shared, this will contain the video from bleeding over the div/box thus becoming full screen.
      // '.OTSubscriberContainer': {
      //   width: '100%',
      //   height: '700px !important',
      //   overflow: 'hidden !important',
      //   position: 'relative',
      //   top: 0,
      //   left: 0,
      //   right: 0,
      //   margin: 'auto',
      //   transform: 'translate(0, 0)',
      // },
      '.chat-now-icon': {
        marginBottom: 10,
        height: '16px',
        width: '20px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundImage: theme.palette.background.chatIcon,
      },
      '.menu-icon': {
        marginTop: 5,
        marginBottom: 5,
        height: 20,
        width: 15,
        backgroundImage: theme.palette.background.menuIcon,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      '.close-icon': {
        marginBottom: '2px',
        height: 30,
        width: 30,
        backgroundImage: theme.palette.background.closeIcon,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      '.camera-stream-stop-icon': {
        backgroundImage: theme.palette.background.cameraStreamStopIcon,
      },
      '.camera-stream-icon': {
        backgroundImage: theme.palette.background.cameraStreamIcon,
      },
      '.camera-stream-not-supported-icon': {
        backgroundImage: theme.palette.background.cameraStreamNotSupportedIcon,
      },
      '.book-test-drive-icon': {
        backgroundImage: theme.palette.background.bookTestDriveIcon,
      },
      '.contact-dealer-icon': {
        backgroundImage: theme.palette.background.contactDealerIcon,
      },
      '.screen-share-icon': {
        backgroundImage: theme.palette.background.screenShareIcon,
      },
      '.invite-a-friend-icon': {
        backgroundImage: theme.palette.background.inviteAFriendIcon,
      },
      '.stay-connected-icon': {
        backgroundImage: theme.palette.background.stayConnectedIcon,
      },
      '.help-icon': {
        backgroundImage: theme.palette.background.helpIcon,
      },
    },
    captionContainer: {
      position: 'absolute',
      bottom: '66px',
      left: 4,
      top: 'unset',
      zIndex: 1,
      display: 'flex',
      width: 'calc(100% - 8px)',
      wordBreak: 'break-word',
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      [theme.breakpoints.up('md')]: {
        left: '50%',
        bottom: 4,
        width: 315,
        maxWidth: 315,
        transform: 'translate(-50%, 0)',
      },
      [theme.breakpoints.up(1200)]: {
        bottom: '42px',
        width: '370px',
        maxWidth: '370px',
        backgroundColor: 'transparent',
      },
    },
    captionContainer_HideCaptionOnChatOpen: {
      display: 'none !important',
    },
    selfCaptionContainer: {
      position: 'absolute',
      bottom: '64px',
      display: 'flex',
      zIndex: 1,
      width: '100%',
    },
    captionWarningContainer: {
      position: 'absolute',
      top: '56px',
      left: '50%',
      display: 'flex',
      zIndex: 1,
      width: '370px',
      maxWidth: '370px',
      transform: 'translate(-50%, 0)',
      wordBreak: 'break-word',
      fontSize: '22px',
    },
    captionReminderContainer: {
      position: 'absolute',
      top: '8px',
      left: '50%',
      display: 'flex',
      zIndex: 1,
      width: '370px',
      maxWidth: '370px',
      transform: 'translate(-50%, 0)',
      wordBreak: 'break-word',
      fontSize: '22px',
    },
    // For CC caption, I had to some hacky css cause the background has opacity =(. Any tweaks, need to adjust the maxHeight, before and after (top and height position)
    caption: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      width: 'calc(100% - 8px)',
      maxWidth: '370px',
      // minHeight: 68,
      minHeight: '43.19px',
      maxHeight: 64, // 68
      padding: 9,
      margin: '0 auto',
      transition: 'height 300ms linear',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '-4px',
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        height: 4,
        width: '100%',
      },
      [theme.breakpoints.up(1200)]: {
        minHeight: '25.19px',
        maxHeight: '103px',
        padding: 0,
        margin: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        '&::before': {
          top: '-10px',
          height: 10,
          width: 'calc(100% - 8px)',
          maxWidth: '370px',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: '-10px',
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          height: 10,
          width: 'calc(100% - 8px)',
          maxWidth: '370px',
        },
      },
    },
    // captionIconButton: {
    //   bottom: '22px',
    //   right: '118px',
    //   position: 'absolute',
    //   display: 'flex',
    //   zIndex: 3,
    //   width: 'auto',
    //   padding: '0px',
    // },
    captionCloseIcon: {
      cursor: 'pointer',
    },
    // captionIconButtonChevy: {
    //   bottom: '22px',
    //   right: '380px',
    //   position: 'absolute',
    //   display: 'flex',
    //   zIndex: 3,
    //   width: 'auto',
    //   padding: '0px',
    // },
    captionIcon: {
      width: '50px',
      height: '50px',
    },
    userCamera: {
      display: 'none !important',
      [theme.breakpoints.up(1200)]: {
        display: 'flex !important',
      },
    },
    userCameraActive: {
      '@global': {
        '.MuiTypography-sessionMenuIconText': {
          color: '#f94712 !important',
        },
      },
    },
    userCameraNotSupported: {
      '@global': {
        '.MuiTypography-sessionMenuIconText': {
          color: '#24221F !important',
        },
      },
    },
    bottomNavBar: {
      position: 'absolute',
      left: 4,
      bottom: 4,
      zIndex: 1,
      width: 'calc(100% - 8px)',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        left: 4,
        bottom: 4,
        width: 'calc(100% - 8px)',
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.up(1200)]: {
        left: 32,
        bottom: 32,
        width: 'calc(100% - 64px)',
        backgroundColor: 'transparent',
      },
    },
    bottomNavBarHasCameraControls: {
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#000000',
      [theme.breakpoints.up('md')]: {
        backgroundColor: 'transparent',
      },
    },
    cameraNotification: {
      position: 'absolute',
      left: 4,
      bottom: 66,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 'calc(100% - 8px)',
      padding: '8px 16px 8px 8px',
      backgroundColor: '#FFCB32',
      [theme.breakpoints.up(1200)]: {
        display: 'none',
      },
    },
    cameraNotificationIconText: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cameraNotificationIcon: {
      height: 16,
      width: 16,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundImage: theme.palette.background.warningIcon,
    },
    cameraNotificationText: {
      height: 12,
    },
    cameraNotificationLinks: {},
    cameraNotificationLink: {
      height: 12,
      marginLeft: 16,
    },
    keyboardIconButton: {
      position: 'absolute',
      top: 4,
      left: 4,
      padding: 8,
      backgroundColor: '#000000',
      border: 'none',
      zIndex: 10,
      [theme.breakpoints.up(1200)]: {
        display: 'none',
      },
    },
    keyboardIcon: {
      height: 24,
      width: 24,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundImage: theme.palette.background.keyboardIcon,
    }
  };

  const cadillacStyles = {
    showroom: {
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
    screenSharingPSIndicator: {
      height: 37,
    },
    screenSharingUserIndicator: {
      transform: 'translate(-45%, 0%)',
      minHeight: 37,
    },
    liveEndSessionUserContainer: {
      display: 'flex',
      '@global': {
        '.live-box': {
          marginRight: '12px',
        },
        '.MuiButton-root': {
          fontSize: 14,
          letterSpacing: '2px',
        },
      },
    },
    liveContainer: {
      height: 37,
    },
    productSpecialistLiveFrenchText: {
      fontSize: 10,
    },
    screenSharingText: {
      fontSize: 10,
    },
    userEndSessionButton: {
      width: '205px',
      height: 37,
      minHeight: 37,
    },
    muteVideoButton: {
      '&:hover': {
        '@global': {
          '.mute-video-icon-off': {
            color: 'rgba(0, 0, 0, 1)',
          },
          '.mute-video-icon-on': {
            color: 'rgba(0, 0, 0, 0.5)',
          },
        },
        '&.MuiButton-experienceIconsLight': {
          '@global': {
            '.mute-video-icon-off': {
              color: 'rgba(0, 0, 0, 0.5)',
            },
            '.mute-video-icon-on': {
              color: 'rgba(0, 0, 0, 1)',
            },
          },
        },
      },
      '&.MuiButton-experienceIconsLight': {
        '@global': {
          '.mute-video-icon-off': {
            color: 'rgba(0, 0, 0, 0.5)',
          },
          '.mute-video-icon-on': {
            color: 'rgba(0, 0, 0, 1)',
          },
        },
      },
    },
    chatNowNeedHelpContainer: {
      position: 'absolute',
      left: 20,
      bottom: 75,
      display: 'flex',
      zIndex: 1,
      width: 'calc(100% - 120px)',
      maxWidth: 'calc(100% - 120px)',
      '@media screen and (min-width: 516px)': {
        width: '397px',
        maxWidth: '397px',
      },
      [theme.breakpoints.up('md')]: {
        bottom: 20,
      },
    },
    chatNow: {
      '&:hover': {
        '@global': {
          '.chat-now-icon': {
            backgroundImage: theme.palette.background.chatIconActive,
          },
        },
      },
    },
    chatNowForm: {
      marginLeft: '8px',
    },
    chatNowInputContainer: {
      height: 70,
      backgroundColor: 'transparent',
    },
    chatNowInput: {
      height: 70,
      backgroundColor: '#fff',
      justifyContent: 'center',
      '@global': {
        '.MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-input': {
          color: '#000000',
          fontSize: '14px !important',
          letterSpacing: '1px !important',
          fontFamily: 'CadillacGothic-Regular',
          textTransform: 'initial !important',
          '&.Mui-focused': {
            border: 'none !important',
          },
        },
      },
    },
    moreMenu: {
      top: 'unset',
      left: 'unset',
      right: 20,
      bottom: 75,
      [theme.breakpoints.up('md')]: {
        bottom: 20,
      },
    },
    noSession: {
      backgroundColor: '#000000',
    },
    needHelpIconButton: {
      textDecoration: 'none',
    },
    needHelpIcon: {
      height: 24,
      width: 36,
    },
    vehicleFeaturesItems: {
      backgroundColor: 'transparent',
    },
    sessionBookTestDriveIcon: {
      width: '24.29px',
      height: '25.2px',
    },
    sessionInviteAFriendIcon: {
      width: '29.03px',
      height: '22.4px',
      marginRight: '-5px',
    },
    sessionMenuIcon: {
      width: '25px',
      height: '24px',
      marginRight: '-5px',
    },
    sessionColorIcon: {
      width: '31.03px',
      height: '27.2px',
      marginRight: '-5px',
    },
    sessionModelIcon: {
      width: '26px',
      height: '26px',
    },
    checkBrandButton: {
      '&:hover': {
        '&.MuiButton-productSpecialistLight': {
          color: '#000000',
          borderColor: 'transparant',
          backgroundColor: '#ffffff',
        },
        color: '#ffffff',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
    '@global': {
      '.chat-now-icon': {
        marginBottom: '2px',
        height: 30,
        width: 30,
      },
      '.menu-icon': {
        marginTop: 0,
        marginBottom: 10,
        height: 15,
        width: 20,
      },
      '.close-icon': {
        marginTop: 0,
        marginBottom: 10,
        height: 11,
        width: 11,
      },
    },
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
    // chatNow: {
    //   ...cadillacStyles.chatNow,
    //   fontSize: '14px',
    //   lineHeight: '17px',
    // },
    // chatNowInputButton: {
    //   fontSize: '14px',
    // },
    // chatNowClose: {
    //   fontSize: '14px',
    // },
    // moreMenu: {
    //   ...cadillacStyles.moreMenu,
    //   fontSize: '14px',
    // },
    // '@global': {
    //   '.chat-now-icon': {
    //     marginBottom: '2px',
    //     height: '16px',
    //     width: '20px',
    //     backgroundSize: 'contain',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center center',
    //     backgroundImage: theme.palette.background.chatIcon,
    //   },
    //   '.MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-input': {
    //     color: '#000000',
    //     fontSize: '14px !important',
    //     letterSpacing: '1px !important',
    //     fontFamily: 'CadillacSansA-Light',
    //     textTransform: 'initial !important',
    //   },
    // },
    // noSession: {
    //   backgroundColor: '#fff',
    // },
  };

  const gmcStyles = {
    showroom: {
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
    screenSharingContainer: {
      top: 16,
      [theme.breakpoints.up('md')]: {
        top: 32,
      },
    },
    screenSharingPSIndicator: {
      height: 35,
      left: 'calc(50% - 55px)',
      [theme.breakpoints.up('md')]: {
        height: 54,
      },
    },
    screenSharingUserIndicator: {
      transform: 'translate(-75%, 0%)',
      minHeight: 35,
      [theme.breakpoints.up('md')]: {
        minHeight: 54,
        transform: 'translate(-50%, 0%)',
      },
    },
    screenSharingText: {
      fontSize: 14,
    },
    liveEndSessionContainer: {
      top: 16,
      left: 16,
      [theme.breakpoints.up('md')]: {
        top: 32,
        left: 32,
      },
    },
    liveEndSessionProductSpecialistContainer: {
      width: 156,
      [theme.breakpoints.up('md')]: {
        width: 176,
      },
    },
    liveEndSessionUserContainer: {
      display: 'flex',
      width: 'calc(100% - 32px)',
      '@global': {
        '.live-box': {
          marginRight: '12px',
        },
        // GL-1470 (remove .MuiButton-root);
        // '.MuiButton-root': {
        //   fontSize: '14px',
        //   lineHeight: '16px',
        //   [theme.breakpoints.up('md')]: {
        //     fontSize: '16px',
        //     lineHeight: '18px',
        //   },
        // },
      },
      [theme.breakpoints.up('md')]: {
        width: 'unset',
      },
    },
    liveContainer: {
      height: 35,
      [theme.breakpoints.up('md')]: {
        height: 54,
      },
    },
    userEndSessionButton: {
      position: 'absolute',
      right: 0,
      height: '35px',
      minHeight: '35px',
      // width: '165px',
      width: 200,
      border: 'none',
      '&:hover': {
        border: 'none',
      },
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        right: 'unset',
        height: '54px',
        minHeight: '54px',
        width: '250px',
      },
    },
    muteVideoButton: {
      '&:hover': {
        '@global': {
          '.mute-video-icon-off': {
            color: 'rgba(0, 0, 0, 1)',
          },
          '.mute-video-icon-on': {
            color: 'rgba(0, 0, 0, 0.5)',
          },
        },
        '&.MuiButton-experienceIconsLight': {
          '@global': {
            '.mute-video-icon-off': {
              color: 'rgba(0, 0, 0, 0.5)',
            },
            '.mute-video-icon-on': {
              color: 'rgba(0, 0, 0, 1)',
            },
          },
        },
      },
      '&.MuiButton-experienceIconsLight': {
        '@global': {
          '.mute-video-icon-off': {
            color: 'rgba(0, 0, 0, 0.5)',
          },
          '.mute-video-icon-on': {
            color: 'rgba(0, 0, 0, 1)',
          },
        },
      },
    },
    chatNowNeedHelpContainer: {
      position: 'absolute',
      left: 16,
      bottom: 68,
      display: 'flex',
      zIndex: 1,
      width: 'calc(100% - 120px)',
      maxWidth: 'calc(100% - 120px)',
      '@media screen and (min-width: 516px)': {
        width: '397px',
        maxWidth: '397px',
      },
      [theme.breakpoints.up('md')]: {
        left: 32,
        bottom: 32,
      },
      [theme.breakpoints.up('lg')]: {
        width: 443,
        maxWidth: 443,
      },
    },
    chatNow: {
      // GL-1470 (remove this)
      // fontSize: '14px',
      // lineHeight: '16px',
      // END GL-1470 (remove this)
      '&:hover': {
        '@global': {
          '.chat-now-icon': {
            backgroundImage: theme.palette.background.chatIconActive,
          },
        },
      },
      // GL-1470 (remove this)
      // [theme.breakpoints.up('md')]: {
      //   fontSize: '16px',
      //   lineHeight: '18px',
      // },
      // END GL-1470 (remove this)
    },
    // GL-1470 (remove this)
    // chatNowClose: {
    //   fontSize: '14px',
    //   lineHeight: '16px',
    //   [theme.breakpoints.up('md')]: {
    //     fontSize: '16px',
    //     lineHeight: '18px',
    //   },
    // },
    // END GL-1470 (remove this)
    chatNowForm: {
      marginLeft: '2px',
    },
    chatNowInputContainer: {
      height: 70,
      backgroundColor: 'transparent',
      [theme.breakpoints.up('md')]: {
        height: 80,
      },
    },
    chatNowInput: {
      height: 70,
      backgroundColor: '#fff',
      justifyContent: 'center',
      '@global': {
        '.MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-input': {
          color: '#2B2B2B',
          fontSize: '14px !important',
          letterSpacing: '1px !important',
          fontFamily: 'StratumGMC-Regular', // GL-1470
          paddingBottom: '0px !important', // GL-1470
          // fontFamily: 'muliregular', // GL-1470 (Remove)
          // textTransform: 'initial !important', // GL-1470 (Remove)
          '&.Mui-focused': {
            border: 'none !important',
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        height: 80,
      },
    },
    needHelpIconButton: {
      textDecoration: 'none',
    },
    needHelpIcon: {
      height: 24,
      width: 36,
    },
    bookTestDrive: {
      top: 60,
      right: 16,
      bottom: 'unset',
      height: '35px',
      minHeight: '35px',
      // width: '165px',
      width: 200,
      padding: '2px',
      // GL-1470 (remove this)
      // fontSize: '14px',
      // lineHeight: '16px',
      // END GL-1470 (remove this)
      [theme.breakpoints.up('md')]: {
        top: 'unset',
        right: 124,
        bottom: 32,
        height: '54px',
        // width: '203px',
        width: 250,
        maxWidth: 250,
        // GL-1470 (remove this)
        // fontSize: '16px',
        // lineHeight: '18px',
        // end GL-1470 (remove this)
      },
      [theme.breakpoints.up('lg')]: {
        width: 250,
        maxWidth: 250,
      },
    },
    moreMenu: {
      top: 'unset',
      left: 'unset',
      right: 16,
      bottom: 68,
      // GL-1470 (remove this)
      // fontSize: '14px',
      // lineHeight: '16px',
      // END GL-1470 (remove this)
      [theme.breakpoints.up('md')]: {
        right: 32,
        bottom: 32,
        // GL-1470 (remove this)
        // fontSize: '16px',
        // lineHeight: '18px',
        // END GL-1470 (remove this)
      },
      '&:hover': {
        '@global': {
          '.menu-icon': {
            backgroundImage: theme.palette.background.menuIconActive,
          },
        },
      },
    },
    vehicleFeaturesItems: {
      backgroundColor: 'transparent',
    },
    sessionBookTestDriveIcon: {
      width: '24.29px',
      height: '25.2px',
    },
    sessionInviteAFriendIcon: {
      width: '29.03px',
      height: '22.4px',
      marginRight: '-5px',
    },
    sessionMenuIcon: {
      width: '25px',
      height: '24px',
      marginRight: '-5px',
    },
    sessionColorIcon: {
      width: '31.03px',
      height: '27.2px',
      marginRight: '-5px',
    },
    sessionModelIcon: {
      width: '26px',
      height: '26px',
    },
    vehicleFeaturesContainer: {
      bottom: 12,
      [theme.breakpoints.up('md')]: {
        top: 32,
        right: 32,
        bottom: 'initial',
      },
    },
    noSession: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 530,
      backgroundColor: '#060505',
      [theme.breakpoints.up('md')]: {
        height: 768,
      },
    },
    noSessionContainer: {
      maxWidth: 700,
      '@global': {
        '.MuiButton-root': {
          border: '1px solid #fff',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#fff',
            color: '#000',
          },
        },
      },
    },
    baInterfaceButtons: {
      top: 16,
      right: 16,
      [theme.breakpoints.up('md')]: {
        top: 105,
        right: 32,
      },
    },
    baInterfaceButton: {
      width: 300,
    },
    streamPauseContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    streamPauseBorder: {
      marginTop: 20,
      height: 3,
      width: 200,
      backgroundColor: '#AA0000',
    },
    checkBrandButton: {
      '&:hover': {
        '&.MuiButton-productSpecialistLight': {
          color: '#000000',
          borderColor: 'transparant',
          backgroundColor: 'rgba(255,255,255, 0.5)',
        },
        color: '#ffffff',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
    '@global': {
      '.menu-icon': {
        marginTop: 0,
        marginBottom: 10,
        height: 24,
        width: 24,
      },
      '.close-icon': {
        marginTop: 0,
        marginBottom: 10,
        height: 24,
        width: 24,
      },
    },
  };

  const buickStyles = {
    showroom: {
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
    screenSharingContainer: {
      top: 16,
      [theme.breakpoints.up('md')]: {
        top: 32,
      },
    },
    screenSharingPSIndicator: {
      height: 35,
      transform: 'translate(-58%, 0%)',
      [theme.breakpoints.up('md')]: {
        height: 54,
      },
    },
    screenSharingUserIndicator: {
      transform: 'translate(-65%, 0%)',
      minHeight: 35,
      [theme.breakpoints.up('md')]: {
        minHeight: 54,
        transform: 'translate(-50%, 0%)',
      },
    },
    screenSharingText: {
      fontSize: 10,
    },
    liveEndSessionContainer: {
      top: 16,
      left: 16,
      [theme.breakpoints.up('md')]: {
        top: 32,
        left: 32,
      },
    },
    liveEndSessionProductSpecialistContainer: {
      width: 156,
      [theme.breakpoints.up('md')]: {
        width: 176,
      },
    },
    liveEndSessionUserContainer: {
      display: 'flex',
      width: 'calc(100% - 32px)',
      '@global': {
        '.live-box': {
          marginRight: '12px',
        },
        '.MuiButton-root': {
          fontSize: '14px',
          letterSpacing: '1.5px',
          paddingTop: '5px',
          [theme.breakpoints.up('md')]: {
            fontSize: '18px',
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        width: 'unset',
      },
    },
    liveContainer: {
      height: 35,
      [theme.breakpoints.up('md')]: {
        height: 54,
      },
    },
    userEndSessionButton: {
      position: 'absolute',
      right: 0,
      height: '35px',
      minHeight: '35px',
      width: '165px',
      border: 'none',
      '&:hover': {
        border: 'none',
      },
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        right: 'unset',
        height: '54px',
        minHeight: '54px',
        width: '250px',
      },
    },
    muteVideoButton: {
      '&:hover': {
        '@global': {
          '.mute-video-icon-off': {
            color: 'rgba(63, 63, 56, 1)',
          },
          '.mute-video-icon-on': {
            color: 'rgba(63, 63, 56, 0.5)',
          },
        },
        '&.MuiButton-experienceIconsLight': {
          '@global': {
            '.mute-video-icon-off': {
              color: 'rgba(63, 63, 56, 0.5)',
            },
            '.mute-video-icon-on': {
              color: 'rgba(63, 63, 56, 1)',
            },
          },
        },
      },
      '&.MuiButton-experienceIconsLight': {
        '@global': {
          '.mute-video-icon-off': {
            color: 'rgba(63, 63, 56, 0.5)',
          },
          '.mute-video-icon-on': {
            color: 'rgba(63, 63, 56, 1)',
          },
        },
      },
    },
    chatNowNeedHelpContainer: {
      position: 'absolute',
      left: 16,
      bottom: 68,
      display: 'flex',
      zIndex: 1,
      width: 'calc(100% - 120px)',
      maxWidth: 'calc(100% - 120px)',
      '@media screen and (min-width: 516px)': {
        width: '397px',
        maxWidth: '397px',
      },
      [theme.breakpoints.up('md')]: {
        left: 32,
        bottom: 32,
      },
      [theme.breakpoints.up('lg')]: {
        width: 443,
        maxWidth: 443,
      },
    },
    chatNowClose: {
      fontSize: '12px',
      lineHeight: '15px',
      [theme.breakpoints.up('md')]: {
        fontSize: '15px',
        lineHeight: '18px',
      },
    },
    chatNow: {
      fontSize: '12px',
      lineHeight: '15px',
      '&:hover': {
        '@global': {
          '.chat-now-icon': {
            backgroundImage: theme.palette.background.chatIconActive,
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '18px',
      },
    },
    chatNowForm: {
      marginLeft: '2px',
    },
    chatNowInputContainer: {
      height: 70,
      backgroundColor: 'transparent',
      [theme.breakpoints.up('md')]: {
        height: 80,
      },
    },
    chatNowInput: {
      height: 70,
      backgroundColor: '#fff',
      justifyContent: 'center',
      '@global': {
        '.MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-input': {
          color: '#3F3F38',
          fontSize: '14px !important',
          letterSpacing: '1px !important',
          fontFamily: 'AvenirNext-Regular',
          textTransform: 'initial !important',
          '&.Mui-focused': {
            border: 'none !important',
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        height: 80,
      },
    },
    needHelpIconButton: {
      textDecoration: 'none',
    },
    needHelpIcon: {
      height: 24,
      width: 36,
    },
    bookTestDrive: {
      top: '68px',
      right: '16px',
      height: '35px',
      minHeight: '35px',
      width: '165px',
      maxWidth: '165px',
      padding: '2px',
      fontSize: '14px',
      letterSpacing: '1.5px',
      borderColor: 'transparent',
      '&:hover': {
        border: 'transparent',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    moreMenu: {
      top: 'unset',
      left: 'unset',
      right: 16,
      bottom: 68,
      fontSize: '12px',
      lineHeight: '15px',
      [theme.breakpoints.up('md')]: {
        right: 32,
        bottom: 32,
        fontSize: '15px',
      },
    },
    moreBookTestDriveOnMobile: {
      display: 'none !important',
      [theme.breakpoints.up('md')]: {
        display: 'flex !important',
      },
    },
    vehicleFeaturesItems: {
      flexWrap: 'nowrap',
      backgroundColor: 'transparent',
      [theme.breakpoints.up('md')]: {
        overflowX: 'scroll',
      },
    },
    sessionBookTestDriveIcon: {
      width: '24.29px',
      height: '25.2px',
    },
    sessionInviteAFriendIcon: {
      width: '29.03px',
      height: '22.4px',
      marginRight: '-5px',
    },
    sessionMenuIcon: {
      width: '25px',
      height: '24px',
      marginRight: '-5px',
    },
    sessionColorIcon: {
      width: '31.03px',
      height: '27.2px',
      marginRight: '-5px',
    },
    sessionModelIcon: {
      width: '26px',
      height: '26px',
    },
    vehicleFeaturesContainer: {
      bottom: 12,
      [theme.breakpoints.up('md')]: {
        top: 32,
        right: 32,
        bottom: 'initial',
      },
    },
    noSession: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 530,
      backgroundColor: '#2B2B2B',
      [theme.breakpoints.up('md')]: {
        height: 768,
      },
    },
    noSessionContainer: {
      maxWidth: 700,
    },
    baInterfaceButtons: {
      top: 16,
      right: 16,
      [theme.breakpoints.up('md')]: {
        top: 105,
        right: 32,
      },
    },
    baInterfaceButton: {
      width: 300,
    },
    checkBrandButton: {
      maxWidth: 176,
      '&:hover': {
        '&.MuiButton-productSpecialistLight': {
          color: '#666666',
          borderColor: '#FFFFFF',
          backgroundColor: '#FFFFFF',
        },
        color: '#ffffff',
        backgroundColor: 'rgba(63, 63, 56, 0.9)',
      },
    },
    '@global': {
      '.chat-now-icon': {
        marginBottom: '10px',
        height: '16px',
        width: '20px',
      },
      '.menu-icon': {
        marginTop: 0,
        marginBottom: 10,
        height: 24,
        width: 24,
      },
      '.close-icon': {
        marginTop: 0,
        marginBottom: 10,
        height: 24,
        width: 24,
      },
    },
  };

  const evliveStyles = {
    showroomOuterActiveTestDrive: {
      height: '900px',
      [theme.breakpoints.up('md')]: {
        height: '1000px',
      },
    },
    showroomOuterActiveInviteAFriend: {
      height: '800px',
      [theme.breakpoints.up('md')]: {
        height: '850px',
      },
      [theme.breakpoints.up('lg')]: {
        height: '900px',
      },
    },
    showroom: {
      [theme.breakpoints.up('md')]: {
        padding: 32,
      },
    },
    screenSharingContainer: {
      borderRadius: 10,
      top: 16,
      [theme.breakpoints.up('md')]: {
        top: 32,
      },
    },
    screenSharingPSIndicator: {
      left: '50%',
      height: 35,
      [theme.breakpoints.up('md')]: {
        height: 49,
      },
    },
    screenSharingUserIndicator: {
      transform: 'translate(-65%, 0%)',
      minHeight: 35,
      [theme.breakpoints.up('md')]: {
        minHeight: 49,
        transform: 'translate(-50%, 0%)',
      },
    },
    screenSharingText: {
      fontSize: 12,
    },
    liveEndSessionContainer: {
      top: 16,
      left: 16,
      [theme.breakpoints.up('md')]: {
        top: 32,
        left: 32,
      },
    },
    liveEndSessionProductSpecialistContainer: {
      width: 146,
      [theme.breakpoints.up('md')]: {
        width: 194,
      },
    },
    liveEndSessionUserContainer: {
      display: 'flex',
      width: 'calc(100% - 32px)',
      '@global': {
        '.live-box': {
          marginRight: '12px',
        },
        '.MuiButton-root': {
          fontSize: '12px',
          lineHeight: '15px',
          [theme.breakpoints.up('md')]: {
            fontSize: '16px',
            lineHeight: '16px',
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        width: 'unset',
      },
    },
    liveEndSessionFrenchContainer: {
      '@global': {
        '.MuiButton-root': {
          fontSize: 11,
        },
        '.mute-video-button-text': {
          width: 80,
        },
      },
    },
    liveContainer: {
      borderRadius: 10,
      height: 35,
      [theme.breakpoints.up('md')]: {
        height: 49,
      },
    },
    productSpecialistLiveFrenchText: {
      fontSize: 14,
    },
    userEndSessionButton: {
      position: 'absolute',
      right: 0,
      height: '37px',
      minHeight: '37px',
      width: '165px',
      border: 'none',
      borderRadius: 25,
      '&:hover': {
        border: 'none',
      },
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        right: 'unset',
        height: '49px',
        minHeight: '49px',
        width: '250px',
      },
    },
    muteVideoButton: {
      '&:hover': {
        '@global': {
          '.mute-video-icon-off': {
            color: 'rgba(63, 63, 56, 1)',
          },
          '.mute-video-icon-on': {
            color: 'rgba(63, 63, 56, 0.5)',
          },
        },
        '&.MuiButton-experienceIconsLight': {
          '@global': {
            '.mute-video-icon-off': {
              color: 'rgba(63, 63, 56, 0.5)',
            },
            '.mute-video-icon-on': {
              color: 'rgba(63, 63, 56, 1)',
            },
          },
        },
      },
      '&.MuiButton-experienceIconsLight': {
        '@global': {
          '.mute-video-icon-off': {
            color: 'rgba(63, 63, 56, 0.5)',
          },
          '.mute-video-icon-on': {
            color: 'rgba(63, 63, 56, 1)',
          },
        },
      },
    },
    chatNowNeedHelpContainer: {
      position: 'absolute',
      left: 16,
      bottom: 68,
      display: 'flex',
      zIndex: 1,
      width: 'calc(100% - 32px)',
      maxWidth: 'calc(100% - 32px)',
      '@media screen and (min-width: 516px)': {
        width: '397px',
        maxWidth: '397px',
      },
      [theme.breakpoints.up('md')]: {
        left: 32,
        bottom: 32,
      },
      [theme.breakpoints.up('lg')]: {
        width: 443,
        maxWidth: 443,
      },
    },
    chatNowClose: {
      fontSize: '16px',
      lineHeight: '16px',
      color: '#FFFFFF',
      backgroundColor: theme.palette.grey[300],
      border: `3px solid ${theme.palette.grey[300]}`,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    chatNowCloseFrench: {
      fontSize: 11,
      lineHeight: '11px',
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
        lineHeight: '14px',
      },
    },
    chatNow: {
      fontSize: '16px',
      lineHeight: '16px',
      '&:hover': {
        '@global': {
          '.chat-now-icon': {
            backgroundImage: theme.palette.background.chatIconActive,
          },
        },
      },
    },
    chatNowFrench: {
      fontSize: 11,
      lineHeight: '11px',
      wordBreak: 'initial',
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
        lineHeight: '14px',
      },
    },
    chatNowForm: {
      overflow: 'hidden',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    chatNowInputContainer: {
      height: 70,
      backgroundColor: '#fff',
      [theme.breakpoints.up('md')]: {
        height: 88,
      },
    },
    chatNowInput: {
      height: 70,
      backgroundColor: '#fff',
      justifyContent: 'center',
      '@global': {
        '.MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-input': {
          color: theme.palette.grey[300],
          fontSize: '14px !important',
          letterSpacing: '1px !important',
          fontFamily: 'Overpass',
          fontWeight: '400',
          textTransform: 'initial !important',
          '&.Mui-focused': {
            border: 'none !important',
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        height: 88,
      },
    },
    chatNowInputButton: {},
    needHelpIconButton: {
      textDecoration: 'none',
    },
    needHelpIcon: {
      height: 24,
      width: 36,
    },
    bookTestDrive: {
      top: '68px',
      right: '16px',
      height: '37px',
      minHeight: '37px',
      width: '165px',
      maxWidth: '165px',
      padding: '2px',
      fontSize: '12px',
      lineHeight: '15px',
      letterSpacing: '3px',
      borderColor: 'transparent',
      borderRadius: 25,
      '&:hover': {
        border: 'transparent',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
        fontSize: '16px',
        lineHeight: '16px',
      },
    },
    moreMenu: {
      top: 'unset',
      left: 'unset',
      right: 16,
      bottom: 68,
      fontSize: '16px',
      lineHeight: '16px',
      [theme.breakpoints.up('md')]: {
        right: 32,
        bottom: 32,
      },
    },
    moreMenuFrench: {
      fontSize: 11,
      lineHeight: '11px',
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
        lineHeight: '14px',
      },
    },
    moreMenuActive: {
      color: '#0072CE !important',
    },
    moreMenuEVLiveMobileHidden: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    moreBookTestDriveOnMobile: {
      display: 'none !important',
      [theme.breakpoints.up('md')]: {
        display: 'flex !important',
      },
    },
    vehicleFeaturesItems: {
      marginBottom: '42px',
      backgroundColor: '#fff',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    sessionBookTestDriveIcon: {
      width: '24.29px',
      height: '25.2px',
    },
    sessionContactDealerIcon: {
      width: '24.29px',
      height: '25.2px',
    },
    sessionInviteAFriendIcon: {
      width: '29.03px',
      height: '22.4px',
      marginRight: '-5px',
    },
    sessionMenuIcon: {
      width: '25px',
      height: '24px',
      marginRight: '-5px',
    },
    sessionColorIcon: {
      width: '31.03px',
      height: '27.2px',
      marginRight: '-5px',
    },
    sessionModelIcon: {
      width: '26px',
      height: '26px',
    },
    vehicleFeaturesContainer: {
      bottom: 12,
      [theme.breakpoints.up('md')]: {
        top: 32,
        right: 32,
        bottom: 'initial',
      },
    },
    noSession: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 530,
      backgroundColor: '#2B2B2B',
      [theme.breakpoints.up('md')]: {
        height: 768,
      },
    },
    noSessionContainer: {
      maxWidth: 700,
    },
    baInterfaceButtons: {
      top: 16,
      right: 16,
      [theme.breakpoints.up('md')]: {
        top: 100,
        right: 32,
      },
    },
    baInterfaceButton: {
      width: 300,
      maxWidth: 300,
    },
    checkBrandButton: {
      maxWidth: 194,
      '&:hover': {
        '&.MuiButton-productSpecialistLight': {
          color: '#3D3935',
          borderColor: '#FFFFFF',
          backgroundColor: '#FFFFFF',
        },
        color: '#ffffff',
        backgroundColor: '#3D3935',
        borderColor: '#3D3935',
      },
    },
    '@global': {
      '.chat-now-icon': {
        marginBottom: '5px',
        height: '17px',
        width: '20px',
        [theme.breakpoints.up('md')]: {
          marginBottom: '10px',
          height: '22px',
          width: '24px',
        },
      },
      '.menu-icon': {
        marginTop: 0,
        marginBottom: 12,
        height: 24,
        width: 24,
      },
      '.close-icon': {
        marginTop: 0,
        marginBottom: 12,
        height: 24,
        width: 24,
      },
    },
  };

  const evliveCanadaStyles = {
    ...evliveStyles,
    chatNowNeedHelpContainer: {
      ...evliveStyles.chatNowNeedHelpContainer,
      bottom: 16,
    },
    moreMenu: {
      ...evliveStyles.moreMenu,
      bottom: 16,
    },
    baInterfaceButtons: {
      ...evliveStyles.baInterfaceButtons,
      [theme.breakpoints.up('md')]: {
        top: 32,
      },
    },
  };

  const energyStyles = {
    showroom: {
      [theme.breakpoints.up(1200)]: {
        padding: 0,
      },
    },
    screenSharingContainer: {
      top: 16,
      [theme.breakpoints.up(1200)]: {
        top: 32,
      },
    },
    screenSharingPSIndicator: {
      height: 30,
    },
    screenSharingUserIndicator: {
      minHeight: 30,
      transform: 'translate(-35%, 0%)',
    },
    liveEndSessionContainer: {
      top: 4,
      left: 4,
      [theme.breakpoints.up(1200)]: {
        top: 32,
        left: 32,
      },
    },
    liveEndSessionUserContainer: {
      '@global': {
        '.live-box': {
          width: 58,
          [theme.breakpoints.up(1200)]: {
            width: 78,
          },
        },
      },
    },
    liveEndSessionProductSpecialistContainer: {
      width: 120,
      [theme.breakpoints.up(1200)]: {
        width: 176,
      },
    },
    liveContainer: {
      height: '24px',
      marginBottom: '4px',
      paddingLeft: 8,
      paddingRight: 8,
      [theme.breakpoints.up(1200)]: {
        height: 30,
        marginBottom: 16,
      },
    },
    experienceButtons: {
      [theme.breakpoints.up(1200)]: {
        marginBottom: 16,
      },
    },
    transferButton: {
      [theme.breakpoints.up(1200)]: {
        marginBottom: 16,
      },
    },
    chatNowNeedHelpContainer: {
      bottom: 4,
      left: 62,
      width: 'auto',
      maxWidth: 'unset',
      backgroundColor: 'red',
      '@media screen and (min-width: 516px)': {
        width: 'auto',
        maxWidth: 'unset',
      },
      [theme.breakpoints.up(1200)]: {
        bottom: 32,
        left: 110,
      },
    },
    chatNowNeedHelpContainerOpen: {
      // width: 'calc(100% - 8px)',
      // [theme.breakpoints.up('md')]: {
      //   width: 'calc(100% - 32px)',
      //   maxWidth: '429px',
      // },
      width: 'calc(100% - 66px)',
      [theme.breakpoints.up(1200)]: {
        width: '351px',
        maxWidth: '351px',
      },
    },
    chatNowNeedHelpContainerOpen_PS: {
      zIndex: 2,
      inlineSize: 'calc(100% - 129px)',
      maxInlineSize: 'calc(429px - 58px)',
      [theme.breakpoints.up(1200)]: {
        inlineSize: '351px',
        maxInlineSize: '351px',
      },
    },
    chatNowInputContainer: {
      height: 58,
      [theme.breakpoints.up(1200)]: {
        height: 78,
      },
    },
    chatNow: {
      marginRight: 0,
    },
    chatNowInput: {
      '@global': {
        '.MuiInputBase-root': {
          paddingTop: '0px !important',
        },
        '.MuiOutlinedInput-root': {
          height: '58px !important',
          [theme.breakpoints.up(1200)]: {
            height: '78px !important',
          },
        },
      },
    },
    baInterfaceButtons: {
      top: 4,
      right: 4,
      [theme.breakpoints.up(1200)]: {
        top: 32,
        right: 32,
      },
    },
    moreMenu: {
      top: 94,
      left: 4,
      [theme.breakpoints.up(1200)]: {
        top: 'unset',
        bottom: 32,
        left: 'unset',
        right: 32,
      },
    },
    moreMenuCameraOn: {
      top: 94,
      left: 4,
      [theme.breakpoints.up(1200)]: {
        top: 'unset',
        bottom: 32,
        left: 'unset',
        right: 314,
      },
      [theme.breakpoints.up(1367)]: {
        top: 'unset',
        bottom: 32,
        left: 'unset',
        right: 282,
      },
    },
    // moreMenu_CameraAndChatAreActive: {
    //   [theme.breakpoints.up(1200)]: {
    //     right: 32,
    //   },
    // },
    moreMenuProductSpecialist: {
      top: 'unset',
      bottom: 4,
      left: 'unset',
      right: 4,
      [theme.breakpoints.up(1200)]: {
        bottom: 32,
        right: 32,
      },
    },
    vehicleFeaturesContainer: {
      display: 'none',
    },
    noSession: {
      backgroundColor: theme.palette.background.paper,
    },
    cameraControl: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '8px 4px',
      height: 58,
      width: 58,
      border: 'none',
      backgroundColor: '#000000',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 2,
        margin: '0 auto',
        backgroundColor: '#8DF7FD',
        transition: 'width linear 100ms',
        width: 0,
      },
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        right: 0,
        bottom: 0,
      },
      [theme.breakpoints.up(1200)]: {
        position: 'absolute',
        display: 'none',
        padding: '24px',
        height: 78,
        width: 78,
      },
      [theme.breakpoints.up(1367)]: {
        position: 'absolute',
        display: 'none',
        padding: '24px',
        height: 78,
        width: 78,
        '&:after': {
          content: 'none',
        },
      },
    },
    cameraControl_FlipCamera: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 13,
        left: -1,
        height: 32,
        width: 1,
        backgroundColor: '#707070',
      },
      [theme.breakpoints.up('md')]: {
        right: 124,
        '&::before': {
          content: 'none',
        },
      },
    },
    cameraControl_SwitchCamera: {
      [theme.breakpoints.up('md')]: {
        right: 62,
      },
    },
    cameraControl_OnCamera: {
      [theme.breakpoints.up('md')]: {
        right: 0,
      },
    },
    cameraControl_Active: {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 2,
        margin: '0 auto',
        backgroundColor: '#8DF7FD',
        transition: 'width linear 100ms',
        width: 32,
      },
      [theme.breakpoints.up(1367)]: {
        display: 'unset',
        '&:after': {
          content: 'none',
        },
      },
    },
    cameraControl_Active_FlipCamera: {
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
        right: 188,
      },
      [theme.breakpoints.up(1367)]: {
        display: 'unset',
        right: 156,
      },
    },
    cameraControl_Active_SwitchCamera: {
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
        right: 94,
      },
      [theme.breakpoints.up(1367)]: {
        display: 'unset',
        right: 78,
      },
    },
    cameraControl_Active_OnCamera: {
      [theme.breakpoints.up(1200)]: {
        display: 'flex',
        right: 0,
      },
      [theme.breakpoints.up(1367)]: {
        display: 'unset',
        right: 0,
      },
    },
    cameraControl_SideBorders: {
      [theme.breakpoints.up(1367)]: {
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 23,
          left: 0,
          height: 32,
          width: 2,
          backgroundColor: '#24221F',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 23,
          right: '-74px',
          height: 32,
          width: 2,
          backgroundColor: '#24221F',
        },
      },
    },
    cameraControlIcon: {
      minHeight: 24,
      minWidth: 24,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      [theme.breakpoints.up(1200)]: {
        minHeight: 34,
        minWidth: 34,
        marginBottom: '9px',
      },
      [theme.breakpoints.up(1367)]: {
        minHeight: 24,
        minWidth: 24,
        marginBottom: 0,
      },
    },
    cameraControlText: {
      marginTop: '2px',
      color: '#ffffff',
      fontFamily: 'Overpass',
      fontSize: 12,
      fontWeight: '700',
      lineHeight: '16px',
      [theme.breakpoints.up(1200)]: {
        fontSize: 14,
      },
      [theme.breakpoints.up(1367)]: {
        display: 'none',
      },
    },
    cameraControlText_Disabled: {
      color: '#707070',
    },
    flipCameraIcon: {
      backgroundImage: theme.palette.background.flipCamera,
    },
    flipCameraIcon_disabled: {
      backgroundImage: theme.palette.background.disableFlipCamera,
    },
    flipCameraIcon_back: {
      backgroundImage: theme.palette.background.filpBackCamera,
    },
    switchScreenIcon: {
      backgroundImage: theme.palette.background.switchScreen,
    },
    switchScreenIcon_disabled: {
      backgroundImage: theme.palette.background.disableSwitchScreen,
    },
    stopCameraIcon: {
      backgroundImage: theme.palette.background.stopCamera,
      // [theme.breakpoints.up(1200)]: {
      //   backgroundImage: theme.palette.background.cameraStreamStopIcon,
      // },
      [theme.breakpoints.up(1367)]: {
        backgroundImage: theme.palette.background.cameraStreamStopIcon,
      },
    },
    cameraIcon: {
      backgroundImage: theme.palette.background.cameraIcon,
    },
    sessionHelpIcon: {
      width: '24px',
      height: '24px',
      marginLeft: '4px',
    },
    checkBrandButton: {
      maxWidth: 176,
      marginBottom: 0,
      marginTop: 4,
      '&:hover': {
        '&.MuiButton-productSpecialistLight': {
          borderColor: '#D9D9D6',
          backgroundColor: '#D9D9D6',
        },
        borderColor: '#000000',
        backgroundColor: '#000000',
      },
      [theme.breakpoints.up(1200)]: {
        marginTop: 16,
      },
    },
    transferButtonsBrandMicrophone: {
      flexDirection: 'column-reverse',
    },
    '@global': {
      '.chat-now-icon': {
        marginBottom: 2,
        minWidth: 24,
        minHeight: 24,
        [theme.breakpoints.up(1200)]: {
          minWidth: 41,
          minHeight: 41,
        },
      },
      '.menu-icon': {
        marginTop: 0,
        marginBottom: 2,
        minWidth: 24,
        minHeight: 24,
        [theme.breakpoints.up(1200)]: {
          minWidth: 41,
          minHeight: 41,
        },
      },
      '.close-icon': {
        marginTop: 0,
        marginBottom: 2,
        minWidth: 24,
        minHeight: 24,
        [theme.breakpoints.up(1200)]: {
          minWidth: 41,
          minHeight: 41,
        },
      },
    },
    captionIcon: {
      marginBottom: 2,
      height: 24,
      width: 24,
      [theme.breakpoints.up(1200)]: {
        height: 34,
        width: 34,
        marginBottom: 8,
      },
    },
    captionIconButtonEnergy: {
      display: 'flex',
      width: 'auto',
      padding: '0px',
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        left: '62px',
        bottom: 0,
        zIndex: 3,
      },
      [theme.breakpoints.up(1200)]: {
        left: 'unset',
        right: '94px',
      },
    },
    closedCaptionTextCameraOn: {
      [theme.breakpoints.up(1200)]: {
        position: 'absolute',
        top: 'unset',
        bottom: 0,
        left: 'unset',
        right: 376,
      },
      [theme.breakpoints.up(1367)]: {
        position: 'absolute',
        top: 'unset',
        bottom: 0,
        left: 'unset',
        right: 344,
      },
    },
    captionIconButtonIsActive: {
      [theme.breakpoints.up(1200)]: {
        color: '#ffffff',
        backgroundColor: '#000000',
        border: '2px solid #000000',
        '&:hover': {
          backgroundColor: '#0009FF',
          border: '2px solid #0009FF',
        },
      },
    },
    captionCameraOn: {
      [theme.breakpoints.up(1200)]: {
        transform: 'translate(-70%, 0)',
      },
      [theme.breakpoints.up(1400)]: {
        transform: 'translate(-36%, 0)',
      },
      [theme.breakpoints.up(1420)]: {
        transform: 'translate(-33.3%, 0)',
      },
    },
    // closedCaption__CameraAndChatAreActive: {
    //   [theme.breakpoints.up(1200)]: {
    //     right: 94,
    //   },
    // },
    baNotiButton: {
      display: 'flex',
      flexDirection: 'column',
    },
    baNotiButtonText: {
      paddingTop: '1px',
      paddingBottom: '1px',
      fontSize: '14px',
    },
    sessionMenuIcon: {
      height: 24,
      width: 24,
    },
    GMToast: {
      padding: 0,
      width: '100%',
      maxWidth: 288,
      backgroundColor: '#ffffff',
      border: '3px solid #8DF7FD',
      borderRadius: 0,
      boxShadow: 'none',
      [theme.breakpoints.up(800)]: {
        maxWidth: 420,
      },
      '@global': {
        '[role=status]': {
          margin: 0,
        },
      },
    },
  };

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveCanadaStyles,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};
