import { BrandType, LanguageCode } from "@types";

export const getParentHost = (brandId: BrandType, languageCode: LanguageCode, parentHost: string): string => {
  const canadaFrench = languageCode === "en-FR" && (brandId === "cadillaccan" || brandId == "evcan" || brandId == "ev");
  const myWaySpanish = languageCode === "es-MX" && brandId === "chevy";
  const cadillacSpanish = languageCode === "es-MX" && brandId === "cadillac";

  if (canadaFrench) {
    const canadaFrenchParentHost = parentHost.replace("/en/", "/fr/");
    return canadaFrenchParentHost;
  } else if (myWaySpanish) {
    const myWaySpanishParentHost = parentHost.replace("www.chevrolet.com", "es.chevrolet.com");
    return myWaySpanishParentHost;
  } else if (cadillacSpanish) {
    const cadillacSpanishParentHost = parentHost.replace("www.cadillac.com", "es.cadillac.com");
    return cadillacSpanishParentHost;
  } else {
    return parentHost;
  }
};
