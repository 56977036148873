import { Box, Typography, Button } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalIconButton } from '@components';

type TwoWayCameraModalProps = {
  brandId: string;
  onClose?: () => void;
  onSubmit?: () => void;
};

const TwoWayCameraModal = (props: TwoWayCameraModalProps): ReactElement => {
  const { onClose, brandId, onSubmit } = props;
  const isEnergy = brandId.toLowerCase() === 'energy';
  const { t } = useTranslation();

  return (
    <>
      {isEnergy && <ModalIconButton onClose={onClose} brandId={brandId} dataDTM="modal:start two-way camera" />}

      <Box className="modal-container">
        {!isEnergy && <ModalIconButton onClose={onClose} brandId={brandId} dataDTM="camera share modal" />}
        <Box className="modal-text-container">
          <Typography variant="modalTitle" component="h2">
            {t('Start 2-way camera')}
          </Typography>
          <Typography variant="modalSubText" component="p">
            {t(
              'When you enable your two-way camera you will see the Home Energy Specialist and they will see you.',
            )}
          </Typography>
        </Box>

        <Box className="modal-container-buttons">
          <Button
            type="button"
            disableRipple
            aria-label="start camera modal"
            variant="tertiary"
            className="stat-button-link"
            data-dtm="modal:start two-way camera"
            onClick={onClose}
          >
            {t("No, don't start camera")}
          </Button>
          <Button
            type="button"
            disableRipple
            aria-label="start camera"
            variant="primary"
            className="stat-button-link"
            data-dtm="modal:start two-way camera"
            onClick={onSubmit}
          >
            {t('Yes, start camera')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TwoWayCameraModal;
