import { useLocation } from 'react-router-dom';

interface QueryStringProps {
  queryString: string;
  urlSearchParams: URLSearchParams;
}

export const useQueryString = (): QueryStringProps => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const queryString = urlSearchParams.toString().split('&').filter((e, i, a) => a.indexOf(e) === i).join('&');

  return { urlSearchParams, queryString };
};
