import { createContext } from 'react';
import type { FC, ReactNode } from 'react';
import { usePersistence } from '@hooks';

interface PresenceContextValue { }

interface PresenceProviderProps {
  children: ReactNode;
}

const PresenceContext = createContext<PresenceContextValue>({});

export const PresenceProvider: FC<PresenceProviderProps> = (props) => {
  const { children } = props;

  usePersistence();

  return <PresenceContext.Provider value={{}}>{children}</PresenceContext.Provider>;
};

export default PresenceContext;
