import { useState, ReactElement, useEffect } from 'react';
import { collection, doc, getDoc, orderBy, query, setDoc, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { Box, Typography, FormHelperText, Grid, InputLabel, FormControl, OutlinedInput, MenuItem, MenuProps, Checkbox, FormControlLabel, Card, Button, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@material-ui/core/styles';
import { TextFieldForm, ErrorComponent, GridWrapper } from '@components';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { BrandType, LanguageCode, DealerReferral, BrandSecure } from '@types';
import * as Scroll from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { regexEmailHelper, usStates } from '@constants';
import DealerReferralViewStyles from './DealerReferralView.style';
import { SelectBasicField } from './SelectField';
import { trimSpaces, parseHtmlString } from '@utils';
import clsx from 'clsx';

declare let window: any;

type DealerReferralViewProps = {
  brandId: BrandType;
  bacId?: string;
  agentId?: string;
  languageCode: LanguageCode;
  referralId: string;
};

/* const AREAS_OF_INTEREST = [
  'Power My Vehicle',
  'Power My Vehicle & Home',
  'Power My Home',
  'Power My Home & Energy Storage',
  'Home Energy Storage',
]; */

const EnergyMenuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: 260,
      overflowY: 'auto',
      boxShadow: 'none',
      borderTop: 'none',
      borderLeft: '2px solid #000',
      borderRight: '2px solid #000',
      borderBottom: '2px solid #000',
    },
  },
  autoFocus: true,
  disableAutoFocusItem: true,
  disableEnforceFocus: true,
  disableAutoFocus: true,
};

const DealerReferralView = (props: DealerReferralViewProps): ReactElement => {
  // props
  const { brandId, bacId, agentId, languageCode, referralId } = props;

  // local state
  const [validateErrors, setValidateErrors] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStateOpen, setIsStateOpen] = useState<boolean>(false);
  const [isAreaOfInterestOpen, setIsAreaOfInterestOpen] = useState<boolean>(false);
  const [isBookAppointment, setIsBookAppointment] = useState<boolean>(false);
  const [dealerReferralURL, setDealerReferralURL] = useState<string>();

  // hooks
  const theme = useTheme();
  const useStyles = makeStyles(DealerReferralViewStyles(brandId));
  const styles = useStyles();
  const { t } = useTranslation();

  // document queries and mutations
  const db = useFirestore();
  const dealerReferralRef = doc(db, 'dealerReferrals', referralId);

  const getDealerReferralURL = async () => {
    const brandSecureRef = doc(db, 'brandSecure', brandId);
    const brandSecureData = (await getDoc(brandSecureRef)).data() as BrandSecure;
    if (brandSecureData.dealerReferralRedirect) {
      setDealerReferralURL(brandSecureData.dealerReferralRedirect);
    }
  };

  useEffect(() => {
    getDealerReferralURL();
  }, []);
  
  const topicsQueryRef = collection(useFirestore(), `brands/${brandId}/topics`);
  const topicsQuery = query(
    topicsQueryRef,
    where('widgetIds', 'array-contains', 'Dealer Referral'),
    orderBy('index', 'asc')
  );
  const { data: topics } = useFirestoreCollectionData(topicsQuery, {
    idField: 'id',
  });

  const [areaOfInterests, setAreaOfInterests] = useState<string[]>([]);
  useEffect(() => {
    if (!topics) return;
    const tempAreas = topics.map((topic) => topic.name);
    setAreaOfInterests(tempAreas);
  }, [topics]);

  // helper functions
  const scroll = Scroll.animateScroll;
  const { Element } = Scroll;
  const executeScroll = () => {
    window.scrollTo(0, 0);
    scroll.scrollToTop('errorElement');
  };

  const isEnergy = brandId === 'energy';

  const trackErrorMessages = (errors: any) => {
    const errorMessages = [];
    let errorMessage = '';

    const orderNumber = 'Please enter an order number';
    const vin = 'Please enter valid VIN';
    const firstName = 'Please input valid first name';
    const lastName = 'Please input valid last name';
    const streetAddress = 'Please provide a street address';
    const city = 'Please input valid city';
    const state = 'Please input valid state';
    const zipCode = 'Please input valid zip code';
    const email = 'Please input valid email address';
    const confirmEmail = 'Please input valid confirm email';
    const phoneNumber = 'Please enter valid phone number';
    const areaOfInterest = 'Please input valid area of interest';
    const energyProvider = 'Please enter energy provider number';
    const notes = '';
    const consumerConsent = 'Please confirm the consent.';

    if (errors.orderNumber) errorMessages.push(orderNumber);
    if (errors.vin) errorMessages.push(vin);
    if (errors.firstName) errorMessages.push(firstName);
    if (errors.lastName) errorMessages.push(lastName);
    if (errors.streetAddress) errorMessages.push(streetAddress);
    if (errors.city) errorMessages.push(city);
    if (errors.state) errorMessages.push(state);
    if (errors.email) errorMessages.push(email);
    if (errors.confirmEmail) errorMessages.push(confirmEmail);
    if (errors.zipCode) errorMessages.push(zipCode);
    if (errors.phoneNumber) errorMessages.push(phoneNumber);
    if (errors.areaOfInterest) errorMessages.push(areaOfInterest);
    if (errors.energyProvider) errorMessages.push(energyProvider);
    if (errors.notes) errorMessages.push(notes);
    if (errors.consumerConsent) errorMessages.push(consumerConsent);

    errorMessage = errorMessages.join(', ');
    if (errorMessage) {
      window.digitalData.formInfo.errorMessage = errorMessage;
    }
  };

  const maxNotesLength = 600;

  const handleButtonDealerReport = () => {
    // const newWindow = window.open('https://lead-referral-dev.op-epg14mi.gm.com/#/Dashboard', '_blank', 'noopener,noreferrer');
    const newWindow = window.open('https://lead-referral.autopartners.net/?tonic_id=#/Dashboard', '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null; // Ensure no access to the parent window
      newWindow.target = '_top'; // Set the target to top-level browsing context
    }
  };

  return (
    <Formik
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        orderNumber: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(255, t('Vehicle Order Number must be at most 255 characters.'))
          .required(t('Please provide a Vehicle Order Number.')),
        vin: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(17, t('VIN Number must be at most 17 characters.'))
          .matches(/[(A-H|J-N|P|R-Z|0-9)]{17}/, t('VIN is not valid.')),
        firstName: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('First Name must be at most 40 characters.'))
          .matches(/^[aA-zZ\s]+$/, t('Contains invalid characters.'))
          .required(t('Please provide a first name.')),
        lastName: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('Last Name must be at most 40 characters.'))
          .matches(/^[aA-zZ\s]+$/, t('Contains invalid characters.'))
          .required(t('Please provide a last name.')),
        streetAddress: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(255, t('Street Address must be at most 255 characters.'))
          .required(t('Please provide a street address.')),
        city: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('City must be at most 40 characters.'))
          .required(t('Please provide a city.')),
        state: Yup.string().max(255).required(t('Please provide a state.')),
        zipCode: Yup.string()
          .min(5, t('Zip code is not valid.'))
          .max(5, t('Zip code must be at most 5 characters.'))
          .matches(/^\d{5}(?:[-\s]\d{4})?$/, t('Zip code is not valid.'))
          .required(t('Please provide a zip code.')),
        email: Yup.string()
          .email(t('Please provide a properly formatted email address.'))
          .max(80, t('Email must be at most 80 characters.'))
          .matches(regexEmailHelper, t('Email is incomplete or characters not permitted'))
          .required(t('Please provide an email address.')),
        confirmEmail: Yup.string()
          .oneOf([Yup.ref('email'), null], t('Email addresses do not match.'))
          .max(80, t('Email must be at most 80 characters.'))
          .matches(regexEmailHelper, t('Email is incomplete or characters not permitted'))
          .required(t('Please provide a confirmed email address.')),
        phoneNumber: Yup.string()
          .min(10, t('Please provide a valid phone number.'))
          .matches(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            t('Phone number is limited to Canada and USA'),
          )
          .required(t('Please provide a phone number.')),
        areaOfInterest: Yup.string()
          .max(255)
          .required(t('Please provide the main area of interest.')),
        energyProvider: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(100, t('Energy Provider must be at most 100 characters.'))
          .required(t('Please provide energy provider.')),
        notes: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(maxNotesLength, t('Notes must be at most 600 characters.')),
        consumerConsent: Yup.boolean()
          .required('Please confirm the consent.')
          .oneOf([true], 'Please confirm the consent.')
      })}
      initialValues={{
        orderNumber: '',
        vin: '',
        firstName: '',
        lastName: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        email: '',
        confirmEmail: '',
        phoneNumber: '',
        areaOfInterest: '',
        energyProvider: '',
        notes: '',
        consumerConsent: false,
        hasPromotionConsent: false,
        hasSMSConsent: false
      }}
      onSubmit={async (values, { setStatus, setSubmitting, resetForm }): Promise<void> => {
        setIsLoading(true);

        const dealerReferral: Partial<DealerReferral> = {
          ...values,
          brandId,
          bacId: bacId || '',
          agentId: agentId || '',
          languageCode,
          // sourceCodeId: values.hasPromotionConsent ? '' : 'GEB0KCARN',
          timezoneOffset: new Date().getTimezoneOffset(),
        };

        try {
          await setDoc(dealerReferralRef, dealerReferral);
          setIsLoading(false);
          resetForm();
          if (isBookAppointment && dealerReferralURL) {
            window.top.location.href = dealerReferralURL;
          }
        } catch (err) {
          console.error(err);
          toast.error(`Something went wrong! ${err.message}`);
          setStatus({ success: false });
          setIsLoading(false);
          setSubmitting(false);
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        validateForm,
        handleSubmit,
        setFieldValue,
        submitForm,
        setFieldTouched,
        values,
        touched,
        isValid,
        isSubmitting,
        errors,
      }): JSX.Element => {
        const handleCheckForm = (event: React.ChangeEvent<HTMLFormElement>) => {
          event.preventDefault();
          if (isValid) {
            handleSubmit(event);
          }
        };
        trackErrorMessages(errors);
        return (
          <Card className={styles.root}>
            <Grid className={clsx(styles.gridWrapper, 'grid-wrapper')}>
              {isEnergy && <Box className={styles.topLeftBackgroundImage} />}
              <Element
                name="errorElement"
                className={styles.errorElement}
              >
                <ErrorComponent
                  containerId="errors"
                  condition={!isValid && validateErrors}
                  errors={errors}
                  dataDTM="book dealer referral"
                />
              </Element>

              <Grid
                container
                justifyContent="end"
              >
                <Button
                  className={`stat-button-link ${styles.reportButton}`}
                  data-dtm="dealer report"
                  onClick={handleButtonDealerReport}
                  variant="tertiary"
                  type="button"
                  disableRipple
                  aria-label="Dealer report"
                >
                  {t('dealer report')}
                </Button>
              </Grid>

              <GridWrapper
                container
                className={clsx(styles.textBox, 'form-header--page')}
              >
                <Typography
                  variant="modalTitle"
                  component="h1"
                >
                  {t('GM Energy Dealer Referral Form')}
                </Typography>
                <Typography
                  variant="modalSubText"
                  component="p"
                  className={clsx(styles.modalSubText, 'modalSubText-DealerWidth')}
                >
                  {t(
                    "Please fill out the form with your customer's information. They will be contacted by a GM Home Energy Consultant. Once the customer purchases from GM Energy, your dealership will earn a credit. Please visit the GM Energy App on Global Connect for all program information.",
                  )}
                </Typography>
              </GridWrapper>

              <form
                id="dealer-referral"
                className={clsx(styles.form, 'form')}
                onSubmit={handleCheckForm}
                noValidate
              >
                <Grid container>
                  {/* Subtitle */}
                  <GridWrapper
                    item
                    md={12}
                    textAlign="center"
                  >
                    <Typography variant="h6">
                      {t("Customer's Vehicle Information")}
                    </Typography>
                  </GridWrapper>

                  {/* Order number, VIN */}
                  <Grid
                    container
                    item
                    gap="0 1rem"
                  >
                    <GridWrapper
                      item
                      md
                      xs={12}
                      id="orderNumber"
                    >
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm="book dealer referral"
                        error={Boolean(touched.orderNumber && errors.orderNumber)}
                        helperText={touched.orderNumber && errors.orderNumber}
                        name="orderNumber"
                        type="text"
                        onChange={handleChange}
                        onBlur={(e) => {
                          setFieldValue('orderNumber', trimSpaces(e.target.value));
                          handleBlur(e);
                        }}
                        value={values.orderNumber}
                        variant="filled"
                        label={t('Vehicle Order #')}
                        fullWidth
                        required
                      />
                    </GridWrapper>

                    <GridWrapper
                      item
                      md
                      xs={12}
                      id="vin"
                    >
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm="book dealer referral"
                        error={Boolean(touched.vin && errors.vin)}
                        helperText={touched.vin && errors.vin}
                        name="vin"
                        type="text"
                        onBlur={(e) => {
                          setFieldValue('vin', trimSpaces(e.target.value));
                          handleBlur(e);
                        }}
                        onChange={handleChange}
                        value={values.vin}
                        variant="filled"
                        label={t('VIN #')}
                        fullWidth
                        required
                      />
                    </GridWrapper>
                  </Grid>

                  {/* Subtitle */}
                  <GridWrapper
                    item
                    md={12}
                    textAlign="center"
                  >
                    <Typography variant="h6">
                      {t("Customer's Contact Information")}
                    </Typography>
                  </GridWrapper>

                  {/* First name, last name */}
                  <Grid
                    container
                    item
                    gap="0 1rem"
                  >
                    <GridWrapper
                      item
                      md
                      xs={12}
                      id="firstName"
                    >
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm="book dealer referral"
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        name="firstName"
                        type="text"
                        onChange={handleChange}
                        onBlur={(e) => {
                          setFieldValue('firstName', trimSpaces(e.target.value));
                          handleBlur(e);
                        }}
                        value={values.firstName}
                        variant="filled"
                        label={t('First Name')}
                        fullWidth
                        required
                      />
                    </GridWrapper>

                    <GridWrapper
                      item
                      md
                      xs={12}
                      id="lastName"
                    >
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm="book dealer referral"
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        name="lastName"
                        type="text"
                        onBlur={(e) => {
                          setFieldValue('lastName', trimSpaces(e.target.value));
                          handleBlur(e);
                        }}
                        onChange={handleChange}
                        value={values.lastName}
                        variant="filled"
                        label={t('Last Name')}
                        fullWidth
                        required
                      />
                    </GridWrapper>
                  </Grid>

                  {/* Street Address */}
                  <GridWrapper
                    item
                    xs={12}
                    id="streetAddress"
                  >
                    <TextFieldForm
                      className="stat-input-field"
                      data-dtm="book dealer referral"
                      error={Boolean(touched.streetAddress && errors.streetAddress)}
                      helperText={touched.streetAddress && errors.streetAddress}
                      name="streetAddress"
                      type="text"
                      onBlur={(e) => {
                        setFieldValue('streetAddress', trimSpaces(e.target.value));
                        handleBlur(e);
                      }}
                      onChange={handleChange}
                      value={values.streetAddress}
                      variant="filled"
                      label={t('Street Address')}
                      fullWidth
                      required
                    />
                  </GridWrapper>

                  {/* City, state, zip code */}
                  <Grid
                    container
                    item
                    gap="0 1rem"
                  >
                    <GridWrapper
                      item
                      md
                      xs={12}
                      id="city"
                    >
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm="book dealer referral"
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                        name="city"
                        type="text"
                        onBlur={(e) => {
                          setFieldValue('city', trimSpaces(e.target.value));
                          handleBlur(e);
                        }}
                        onChange={handleChange}
                        value={values.city}
                        variant="filled"
                        label={t('City')}
                        fullWidth
                        required
                      />
                    </GridWrapper>

                    {/* State */}
                    <GridWrapper
                      item
                      md={4}
                      xs={12}
                      id="state"
                    >
                      <InputLabel className={styles.formLabel}>
                        {t('State')}
                      </InputLabel>
                      <GridWrapper className={clsx(styles.selectWrapper, 'select-wrapper')}>
                        <FormControl
                          fullWidth
                          variant="filled"
                        >
                          <InputLabel>{t('State')}</InputLabel>
                          <SelectBasicField
                            className="stat-dropdown"
                            data-dtm="book dealer referral:state"
                            variant="outlined"
                            fullWidth
                            name="state"
                            value={values.state || ''}
                            error={Boolean(touched.state && errors.state && !isStateOpen)}
                            onBlur={handleBlur}
                            onFocus={() => setFieldTouched('state', true)}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            required
                            MenuProps={EnergyMenuProps}
                            open={isStateOpen}
                            onOpen={() => setIsStateOpen(true)}
                            onClose={() => setIsStateOpen(false)}
                          >
                            {usStates.map((state) => (
                              <MenuItem
                                key={state.abbreviation}
                                value={state.abbreviation}
                                sx={{
                                  py: '16px',
                                  pr: 0,
                                  fontWeight: values.state === state.abbreviation ? 'bold' : 'normal',
                                }}
                              >
                                {values.state === state.abbreviation && (
                                  <CheckIcon
                                    sx={{
                                      backgroundColor: theme.palette.color.checkboxGrey,
                                      color: theme.palette.grey[800],
                                      mr: '0.5rem',
                                      width: '1rem',
                                      height: '1rem',
                                    }}
                                  />
                                )}
                                {values.state !== state.abbreviation && (
                                  <CheckBoxOutlineBlankIcon
                                    sx={{
                                      backgroundColor: theme.palette.color.checkboxGrey,
                                      '& path': { display: 'none' },
                                      mr: '0.5rem',
                                      width: '1rem',
                                      height: '1rem',
                                    }}
                                  />
                                )}
                                {state.abbreviation}
                              </MenuItem>
                            ))}
                          </SelectBasicField>
                        </FormControl>
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundImage: theme.palette.background.chevronClosedStateIcon,
                            transform: isStateOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                          }}
                        />
                      </GridWrapper>

                      {touched.state && errors.state && !isStateOpen && (
                        <FormHelperText error>{errors.state}</FormHelperText>
                      )}
                    </GridWrapper>

                    <GridWrapper
                      item
                      md
                      xs={12}
                      id="zipCode"
                    >
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm="book dealer referral"
                        error={Boolean(touched.zipCode && errors.zipCode)}
                        helperText={touched.zipCode && errors.zipCode}
                        name="zipCode"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.zipCode}
                        variant="filled"
                        label={t('ZIP Code')}
                        fullWidth
                        required
                      />
                    </GridWrapper>
                  </Grid>

                  {/* Email, confirm email */}
                  <Grid
                    container
                    item
                    gap="0 1rem"
                    id="email"
                  >
                    <GridWrapper
                      item
                      md
                      xs={12}
                    >
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm="book dealer referral"
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        name="email"
                        type="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        variant="filled"
                        label={t('Email')}
                        fullWidth
                        required
                      />
                    </GridWrapper>

                    <GridWrapper
                      item
                      md
                      xs={12}
                      id="confirmEmail"
                    >
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm="book dealer referral"
                        error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                        helperText={touched.confirmEmail && errors.confirmEmail}
                        name="confirmEmail"
                        type="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmEmail}
                        variant="filled"
                        label={t('Confirm Email')}
                        fullWidth
                        required
                      />
                    </GridWrapper>
                  </Grid>

                  {/* Phone number, area of interest */}
                  <Grid
                    container
                    item
                    gap="0 1rem"
                    id="phoneNumber"
                  >
                    <GridWrapper
                      item
                      md
                      xs={12}
                      className={styles.phoneNumber}
                    >
                      <TextFieldForm
                        className="stat-input-field"
                        data-dtm="book dealer referral"
                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        name="phoneNumber"
                        type="tel"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phoneNumber}
                        variant="filled"
                        label={t('Phone number (Required):')}
                        fullWidth
                        required
                      />
                    </GridWrapper>

                    {/* Area of Interest */}
                    <GridWrapper
                      item
                      md
                      xs={12}
                      id="areaOfInterest"
                    >
                      <InputLabel className={styles.formLabel}>
                        {t<string>('Main Area of Interest')}
                      </InputLabel>
                      <GridWrapper className={clsx(styles.selectWrapper, 'select-wrapper')}>
                        <FormControl
                          fullWidth
                          variant="filled"
                        >
                          <InputLabel>{t<string>('Main Area of Interest')}</InputLabel>
                          <SelectBasicField
                            className="stat-dropdown"
                            data-dtm="book dealer referral:areaOfInterest"
                            variant="outlined"
                            fullWidth
                            name="areaOfInterest"
                            value={values.areaOfInterest}
                            error={Boolean(touched.areaOfInterest && errors.areaOfInterest && !isAreaOfInterestOpen)}
                            onBlur={handleBlur}
                            onFocus={() => setFieldTouched('areaOfInterest', true)}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            required
                            MenuProps={EnergyMenuProps}
                            open={isAreaOfInterestOpen}
                            onOpen={() => setIsAreaOfInterestOpen(true)}
                            onClose={() => setIsAreaOfInterestOpen(false)}
                          >
                            {areaOfInterests && areaOfInterests.map((areaOfInterest) => (
                              <MenuItem
                                key={areaOfInterest}
                                value={areaOfInterest}
                                sx={{
                                  py: '16px',
                                  pr: 0,
                                  fontWeight: values.areaOfInterest === areaOfInterest ? 'bold' : 'normal',
                                  whiteSpace: 'normal',
                                }}
                              >
                                {values.areaOfInterest === areaOfInterest && (<CheckIcon
                                  sx={{
                                    backgroundColor: theme.palette.color.checkboxGrey,
                                    color: theme.palette.grey[800],
                                    mr: '0.5rem',
                                    width: '1rem',
                                    height: '1rem',
                                  }}
                                />)}
                                {values.areaOfInterest !== areaOfInterest && (<CheckBoxOutlineBlankIcon
                                  sx={{
                                    backgroundColor: theme.palette.color.checkboxGrey,
                                    '& path': { display: 'none' },
                                    mr: '0.5rem',
                                    width: '1rem',
                                    height: '1rem',
                                  }}
                                />)}
                                {areaOfInterest}
                              </MenuItem>
                            ))}
                          </SelectBasicField>
                        </FormControl>
                        <div
                          className="chevron-icon"
                          style={{
                            backgroundImage: theme.palette.background.chevronClosedStateIcon,
                            transform: isAreaOfInterestOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                          }}
                        />
                      </GridWrapper>
                      {touched.areaOfInterest && errors.areaOfInterest && !isAreaOfInterestOpen && (
                        <FormHelperText error>{errors.areaOfInterest}</FormHelperText>
                      )}
                    </GridWrapper>
                  </Grid>

                  {/* Energy Provider */}
                  <GridWrapper
                    item
                    xs={12}
                    id="energyProvider"
                  >
                    <TextFieldForm
                      className="stat-input-field"
                      data-dtm="book dealer referral"
                      error={Boolean(touched.energyProvider && errors.energyProvider)}
                      helperText={touched.energyProvider && errors.energyProvider}
                      name="energyProvider"
                      type="text"
                      onBlur={(e) => {
                        setFieldValue('energyProvider', trimSpaces(e.target.value));
                        handleBlur(e);
                      }}
                      onChange={handleChange}
                      value={values.energyProvider}
                      variant="filled"
                      label={t('Energy Provider')}
                      fullWidth
                      required
                    />
                  </GridWrapper>

                  {/* Notes */}
                  <GridWrapper
                    item
                    xs={12}
                    id="notes"
                  >
                    <TextFieldForm
                      className="stat-input-field"
                      data-dtm="book dealer referral"
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      name="notes"
                      type="text"
                      onBlur={(e) => {
                        setFieldValue('notes', trimSpaces(e.target.value));
                        handleBlur(e);
                      }}
                      onChange={handleChange}
                      fullWidth
                      multiline
                      minRows={5}
                      inputProps={{ maxLength: maxNotesLength }}
                      value={values.notes}
                      variant="filled"
                      label={t('Notes')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={parseHtmlString(t(
                        "Kindly provide further details to better understand the customer's specific home energy requirements. Relevant information includes:"
                        + '\n&#8226 Dwelling Type: Detached, Semi-Detached, Townhouse, Condo, Apartment'
                        + '\n&#8226 Ownership Status: Owner or Renter'
                        + '\n&#8226 Garage Details: Attached/Detached or N/A (if one does exist)'
                        + '\n&#8226 Existing Energy Systems: e.g., Generators'
                        + '\n&#8226 Current Solar and Storage Solutions: e.g., Solar Panels, Tesla Powerwall'
                        + "\nThese insights will assist our sales agents in tailoring the home energy management system discussion to the customer's needs."
                      ))}
                    />
                    <Typography
                      mt={1}
                      component="h5"
                      variant="characterCount"
                      className={styles.characterCount}
                    >
                      {values.notes.length > 0 ? `${values.notes.length}/${maxNotesLength}` : t('600 Character Limit')}
                    </Typography>
                  </GridWrapper>

                  <GridWrapper
                    item
                    xs={12}
                    textAlign="left"
                    id="consumerConsent"
                    className="consent-checkbox"
                  >
                    <FormControlLabel
                      className={values.consumerConsent ? 'is-checkbox-checked' : ''}
                      sx={{ alignItems: 'flex-start', height: 'unset !important' }}
                      control={<Checkbox sx={{ mt: -1 }} />}
                      label={`${t('Yes, I received this customer’s verbal consent to be contacted by a GM Energy sales agent with offers and product information using the contact information collected above, and otherwise in accordance with the GM Energy Dealer Referral Agreement.')}`}
                      name="consumerConsent"
                      checked={values.consumerConsent === true}
                      onChange={handleChange}
                    />
                    {touched.consumerConsent && errors.consumerConsent && !values.consumerConsent && (
                      <FormHelperText error>
                        {t('Please confirm the consent.')}
                      </FormHelperText>
                    )}
                  </GridWrapper>

                  {/* <GridWrapper
                    item
                    xs={12}
                    textAlign="left"
                    id="hasPromotionConsent"
                    sx={{ mt: '15px' }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      sx={{ height: 'unset !important' }}
                      checked={values.hasPromotionConsent}
                      label={
                        <Typography
                          dangerouslySetInnerHTML={{ __html: `${t('Yes, this customer has ordered a 2024 Silverado EV First Edition RST.')}` }}
                        />
                      }
                      name="hasPromotionConsent"
                      onChange={handleChange}
                    />
                  </GridWrapper> */}

                  {/* <GridWrapper
                    item
                    xs={12}
                    textAlign="left"
                    id="hasSMSConsent"
                    sx={{ mt: '20px' }}
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={values.hasSMSConsent}
                      sx={{ height: "unset !important" }}
                      label={
                        <Typography
                          dangerouslySetInnerHTML={{ __html: `${t(`Yes, I want to text with GM Energy Consultants. By checking this box, I consent to receiving promotional and other text messages from GM Energy and GM, or those acting on their behalf, using an automatic telephone dialing system to the mobile telephone number provided above about GM Energy products and services. Consent is not required as a condition of purchase. Message frequency varies. Message and data rates may apply. For more information, visit <a href="https://www.gm.com/privacy-statement" target="_blank">GM's Privacy Policy</a> and <a href="https://www.gm.com/texting-policy" target="_blank">Texting Policy</a>`)}`}}
                        />
                      }
                      name="hasSMSConsent"
                      onChange={handleChange}
                    />
                  </GridWrapper> */}

                <GridWrapper
                  container
                  className={clsx(styles.textBox, 'form-header--page')}
                >
                  <Typography component="p" sx={{ mt: 3 }}>
                    {t(
                      "Please fill out the form with your customer’s information. To submit the referral AND book your customer an appointment to speak to a Home Energy Consultant to get personalized guidance for their home, click “Submit and Book Appointment.” To submit the referral without booking an appointment, click “Submit without Appointment.” Please visit the GM Energy App on Global Connect for all program information.",
                    )}
                  </Typography>
                </GridWrapper>

                  {/* Submit */}
                  <GridWrapper
                    container
                    className="form-button-container"
                  >
                    <LoadingButton
                      loading={isLoading}
                      className={clsx(styles.submitButton, '.submit-button')}
                      data-dtm="book dealer referral"
                      onClick={() => {
                        validateForm()
                          .then(() => trackErrorMessages(errors))
                          .then(() => setIsBookAppointment(true))
                          .then(() => submitForm())
                          .catch((e) => new Error(e));
                        setValidateErrors(true);
                        executeScroll();
                      }}
                      variant="primary"
                      disabled={isSubmitting}
                      aria-label="Submit & Book Appointment"
                    >
                      {t('Submit & Book Appointment')}
                    </LoadingButton>
                  </GridWrapper>

                  <GridWrapper
                    container
                    className="form-button-container"
                  >
                    <LoadingButton
                      loading={isLoading}
                      className={clsx(styles.submitButton, '.submit-button')}
                      data-dtm="book dealer referral"
                      onClick={() => {
                        validateForm()
                          .then(() => trackErrorMessages(errors))
                          .then(() => setIsBookAppointment(false))
                          .then(() => submitForm())
                          .catch((e) => new Error(e));
                        setValidateErrors(true);
                        executeScroll();
                      }}
                      variant="tertiary"
                      disabled={isSubmitting}
                      aria-label="Submit without Appointment"
                    >
                      {t('Submit without Appointment')}
                    </LoadingButton>
                  </GridWrapper>
                </Grid>
              </form>
            </Grid>
          </Card>
        );
      }}
    </Formik>
  );
};

export default DealerReferralView;
