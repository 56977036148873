export const useScrollToAnchor = (id: string): HTMLElement => {
  const element = document.getElementById(id);
  if (element && element.scrollIntoView) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }
  return element;
};
