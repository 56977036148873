import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandType, Faq } from '@types';
import ModalIconButton from './ModalIconButton';

type QuestionsModalProps = {
  brandId: BrandType;
  onClose?: () => void;
  faqs: Faq[];
};

const QuestionsModal = (props: QuestionsModalProps): ReactElement => {
  const { onClose, brandId, faqs } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isEnergy = brandId.toLowerCase() === 'energy';

  const useStyles = makeStyles({
    icon: {
      color: theme.palette.text.secondary,
      fontSize: 10,
    },
    '@global': {
      '.MuiListItemIcon-root': {
        marginRight: '7px',
      },
    },
  });

  const styles = useStyles();

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
        />
      )}
      <Box className="modal-container">
        {!isEnergy && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
          />
        )}
        <Typography
          variant="modalTitle"
          component="h2"
        >
          {brandId === 'chevy' ? t('CHEVY MY WAY ADVISOR FAQS') : t('CADILLAC LIVE ADVISOR FAQS')}
        </Typography>
        {isGMC && <Box className="modal-border" />}
        <Box>
          {faqs && faqs.map((question) => (
            <Box>
              <Typography
                variant="questionHeading"
                component="h3"
              >
                {t(question.heading)}
              </Typography>

              {question.answer && (
                <Typography
                  variant="questionAnswer"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t<string>(question.answer),
                  }}
                />
              )}

              {question.list && (
                <List>
                  {question.list.map((listItem) => (
                    <ListItem key={listItem.key}>
                      <ListItemIcon>
                        <FiberManualRecordIcon className={styles.icon} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="questionListItem"
                          component="h5"
                        >
                          {t(listItem.value)}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default QuestionsModal;
