import { ReactElement, useState } from 'react';
import { Box } from '@material-ui/core';
import { Topic, Vehicle, VehicleType } from '@types';
import StayUpdatedView from './StayUpdatedView';
import ModalIconButton from './ModalIconButton';
import CloseToastModal from './CloseToastModal';

type StayUpdatedModalProps = {
  brandId: string;
  languageCode: string;
  reservationType: string;
  vehicles: Vehicle[],
  vehicleTypes: VehicleType[],
  topics: Topic[],
  onSubmit: (stayUpdated) => void,
  onClose?: () => void,
  // onCloseStatus?: (stayUpdated: StayUpdated) => void;
};

const StayUpdatedModal = (props: StayUpdatedModalProps): ReactElement => {
  const { brandId, languageCode, reservationType, vehicles, vehicleTypes, topics, onSubmit, onClose,
    // onCloseStatus
  } = props;

  const [showCloseToastModal, setShowCloseToastModal] = useState<boolean>(false);

  const toggleShowModal = () => {
    setShowCloseToastModal(!showCloseToastModal);
  };

  return (
    <>
      {showCloseToastModal && (
      <Box sx={{ backgroundColor: 'white', textAlign: 'center' }}>
        <CloseToastModal
          onClose={() => {
            onClose();
          }}
          onOpen={() => toggleShowModal()}
        />
      </Box>
      )}
      <Box sx={{ display: showCloseToastModal ? 'none' : 'auto', overflowY: 'scroll' }}>
        <ModalIconButton
          onClose={toggleShowModal}
          brandId={brandId}
        />
        <Box className="modal-container">
          <StayUpdatedView
            brandId={brandId}
            languageCode={languageCode}
            reservationType={reservationType}
            vehicles={vehicles}
            vehicleTypes={vehicleTypes}
            topics={topics}
            isModal
            onSubmit={onSubmit}
            onClickNoThank={toggleShowModal}
            onClose={onClose}
          />
        </Box>
      </Box>
    </>
  );
};

export default StayUpdatedModal;
