import { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import { Box } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingScreen: FC = () => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.loadingScreen',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '100vh',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2000,
      }}
    >
      <CircularProgress
        disableShrink
        sx={{
          animationDuration: '550ms',
        }}
      />
    </Box>
  );
};

export default LoadingScreen;
