import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';

type GMToastProps = {
  variant: 'regular' | 'confirmation' | 'warning';
  message: string;
  dismiss: () => void;
  dataDTM?: string;
}

const GMToast = (props: GMToastProps) => {
  const { message, dismiss, variant, dataDTM } = props;
  const theme = useTheme();

  let icon = theme.palette.background.greenCheckmark;
  let textColour = '#000000';

  if (variant === 'confirmation') {
    icon = theme.palette.background.greenCheckmark;
    textColour = '#218322';
  } else if (variant === 'warning') {
    icon = theme.palette.background.warningRedIcon;
    textColour = '#C64804';
  }

  // Had to add inline styling, instead of adding classNames. Everything it would resize the page/window, it would revert the styling to default
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    }}>
      <Box sx={{
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '8px',
        paddingRight: '8px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.up(1200)]: {
          paddingTop: '16px',
          paddingBottom: '16px',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      }}>
        {variant !== 'regular' && (
          <Box sx={{
            height: '20px',
            width: '20px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundImage: icon,
          }} />
        )}
        <Typography sx={{
          marginLeft: '8px',
          fontSize: '14px',
          fontWeight: '700',
          fontFamily: 'Overpass',
          lineHeight: '20px',
          color: textColour,
          [theme.breakpoints.up(1200)]: {
            fontSize: '17px',
          },
        }}>{message}</Typography>
      </Box>
      <Button
        className="stat-button-close"
        data-dtm={dataDTM}
        sx={{
          height: '40px',
          width: '40px',
          minHeight: '40px',
          minWidth: '40px',
          padding: '8px',
          backgroundColor: '#000000',
          border: 'none',
          '&:hover': {
            backgroundColor: '#0009FF',
          },
          [theme.breakpoints.up(1200)]: {
            height: '56px',
            width: '56px',
            minHeight: '56px',
            minWidth: '56px',
            padding: '20px',
          },
        }}
        onClick={dismiss}
      >
        <Box
          sx={{
            minHeight: '24px',
            minWidth: '24px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundImage: theme.palette.background.closeIcon,
            [theme.breakpoints.up(1200)]: {
              minHeight: '34px',
              minWidth: '34px',
            },
          }}
        />
      </Button>
    </Box>
  )
}

export default GMToast;
