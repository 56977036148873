export const chevyColors = {
  black: '#000000',
  greyVeryDark: '#1a1a1a',
  greyDark: '#262626',
  greyMidDark: '#6e6e6e',
  grey: '#808080',
  greyMid: '#b3b3b3',
  greyLight: '#e6e6e6',
  greyVeryLight: '#f2f2f2',
  white: '#ffffff',
  gold: '#cd9834',
  goldLight: '#e6ac39',
  red: '#e25959',
  green: '#61af71',
  purple: '#6C52DA',
  orange: '#FF8A0D',
  blue: '#33A7E4',

  // new lounge
  mineShaft: '#333333',
  doveGray: '#6E6E6E',
  codGray: '#1A1A1A',
};

export const chevyColorsRGB = {
  black: '0, 0, 0',
  greyVeryDark: '26, 26, 26',
  greyDark: '38, 38, 38',
  greyMidDark: '110, 110, 110',
  grey: '128,128,128',
  greyMid: '179,179,179',
  greyLight: '230,230,230',
  greyVeryLight: '242,242,242',
  white: '255,255,255',
  gold: '205, 152, 52',
  goldLight: '230,172,57',
  red: '226,89,89',
  green: '97,175,113',
  purple: '108,82,218',
  orange: '255,138,13',
  blue: '51,167,228',
};

export const cadillacColors = {
  black: '#000000',
  greyVeryDark: '#1a1a1a',
  greyDark: '#262626',
  greyMidDark: '#6e6e6e',
  grey: '#808080',
  greyMid: '#b3b3b3',
  greyLight: '#e6e6e6',
  greyVeryLight: '#f2f2f2',
  white: '#ffffff',
  gold: '#cd9834',
  goldLight: '#e6ac39',
  red: '#e25959',
  green: '#61af71',
  purple: '#6C52DA',
  orange: '#FF8A0D',
  blue: '#33A7E4',
  // Red(s)
  torchRed: '#FA0037',
  Carmine: '#A00021',
  monza: '#C8002B',
  radicalRed: '#FA335E',
  // Gold(s)
  ronchi: '#F3C846',
  meteor: '#C8800D',
  fuelYellow: '#E8B01E',
  marzipan: '#F5D98F',
};

export const cadillacColorsRGB = {
  black: '0, 0, 0',
  greyVeryDark: '26, 26, 26',
  greyDark: '38, 38, 38',
  greyMidDark: '110, 110, 110',
  grey: '128,128,128',
  greyMid: '179,179,179',
  greyLight: '230,230,230',
  greyVeryLight: '242,242,242',
  white: '255,255,255',
  gold: '205, 152, 52',
  goldLight: '230,172,57',
  red: '226,89,89',
  green: '97,175,113',
  purple: '108,82,218',
  orange: '255,138,13',
  blue: '51,167,228',
  // Red(s)
  torchRed: '#FA0037',
  Carmine: '#A00021',
  monza: '#C8002B',
  radicalRed: '#FA335E',
  // Gold(s)
  ronchi: '#F3C846',
  meteor: '#C8800D',
  fuelYellow: '#E8B01E',
  marzipan: '#F5D98F',
};

export const gmcColors = {
  black: '#000000',
  greyVeryDark: '#464646',
  greyDark: '#262626',
  greyMidDark: '#6e6e6e',
  greyBlack: '#060505',
  grey: '#ebebeb',
  greyMid: '#b3b3b3',
  greyLight: '#e6e6e6',
  checkboxGrey: '#d4d4d4',
  // greyMidLight: '#464646',
  greyVeryLight: '#f2f2f2',
  white: '#ffffff',
  gold: '#cd9834',
  goldLight: '#e6ac39',
  red: '#AA0000',
  radicalRed: '#B50000',
  green: '#61af71',
  purple: '#6C52DA',
  orange: '#FF8A0D',
  blue: '#33A7E4',

  // black: '#000000',
  brandBlack: '#060505',
  brandBlackLighten: '#302F2F',
  // grey: '#B2B2B2',
  // greyLight: '#BFBFBF',
  greyExtraLight: '#EBEBEB',
  font: '#464646',
  fontLight: '#757575',
  // white: '#FFFFFF',
  whiteDarken: '#D4D4D4',
  brandRed: '#CC0000',
  brandRedDarken: '#AA0000',
  brandRedLighten: '#D52B2B',
  errorRed: '#B50000',
  successGreen: '#0A7305',
};

export const gmcColorsRGB = {
  black: '0, 0, 0',
  greyVeryDark: '70, 70, 70',
  greyDark: '38, 38, 38',
  greyMidDark: '110, 110, 110',
  greyBlack: '6, 5, 5',
  grey: '128,128,128',
  greyMid: '179,179,179',
  greyLight: '230,230,230',
  greyVeryLight: '242,242,242',
  white: '255,255,255',
  gold: '205, 152, 52',
  goldLight: '230,172,57',
  red: '170, 0, 0',
  green: '97,175,113',
  purple: '108,82,218',
  orange: '255,138,13',
  blue: '51,167,228',

  // black: '0, 0, 0',
  brandBlack: '6, 5, 5',
  brandBlackLighten: '48, 47, 47',
  // grey: '178, 178, 178',
  // greyLight: '191, 191, 191',
  greyExtraLight: '235, 235, 235',
  font: '70, 70, 70',
  fontLight: '117, 117, 117',
  // white: '255, 255, 255',
  whiteDarken: '212, 212, 212',
  brandRed: '204, 0, 0',
  brandRedDarken: '170, 0, 0',
  brandRedLighten: '213, 43, 43',
  errorRed: '181, 0, 0',
  successGreen: '10, 115, 5',
};

export const buickColors = {
  black: '#2B2B2B',
  blackLight: '#3F3F38',
  greyDark: '#666666',
  greyMid: '#999999',
  grey: '#CBCBCB',
  greyLight: '#D8D8D8',
  greyVeryLight: '#E6E6E6',
  whiteMid: '#F0F0F0',
  brandWhite: '#FBFBFB',
  white: '#ffffff',
  webOrange: '#C9480C',
  brandOrange: '#F26522',
  brandBlue: '#82B8C0',
  successGreen: '#0A7305',
  errorRed: '#B50000',
  whiteGrey: '#F5F5F5',
};

export const buickColorsRGB = {
  black: '43, 43, 43',
  blackLight: '63, 63, 56',
  greyDark: '102, 102, 102',
  greyMid: '153, 153, 153',
  grey: '203, 203, 203',
  greyLight: '216, 216, 216',
  greyVeryLight: '230, 230, 230',
  whiteMid: '240, 240, 240',
  brandWhite: '251, 251, 251',
  white: '255, 255, 255',
  webOrange: '201, 72, 12',
  brandOrange: '242, 101, 34',
  brandBlue: '130, 184, 192',
  successGreen: '10, 115, 5',
  errorRed: '181, 0, 0',
  whiteGrey: '245, 245, 245',
};

export const evliveColors = {
  darkBlue: '#11365F',
  blue: '#0072CE',
  lightBlue: '#05C3DD',
  darkGrey: '#3D3935', // grey[300]
  lightGrey: '#D9D9D6', // grey[200]
  offWhite: '#F0F3F5', // grey[100]
  white: '#FFFFFF', // grey[50]
  red: '#E35205',
  green: '#6CC24A',
  secondaryOne: '#C3D7EE',
  secondaryTwo: '#F0B323',
  coolGray: '#75787A',
  aqua: '#38E8FF',
};

export const evliveColorsRGB = {
  darkBlue: '17, 54, 95',
  blue: '0, 114, 206',
  lightBlue: '5, 195, 221',
  darkGrey: '61, 57, 53', // grey[300]
  lightGrey: '217, 217, 214', // grey[200]
  offWhite: '240, 243, 245', // grey[100]
  white: '255, 255, 255', // grey[50]
  red: '227, 82, 5',
  green: '108, 194, 74',
  secondaryOne: '195, 215, 238',
  secondaryTwo: '240, 179, 35',
  coolGray: '117, 120, 122',
  aqua: '56, 232, 255',
};

export const energyColors = {
  // Primary
  blue: '#0009FF',
  black: '#000000',
  offWhite: '#FFFCF1',
  coolWhite: '#F7F9FD',
  white: '#FFFFFF',
  // Secondary
  redOrange: '#F94914',
  orange: '#EE7118',
  yellow: '#FFCB32',
  magenta: '#D92BFC',
  pink: '#ED95FD',
  green: '#53FB94',
  blueLight: '#8DF7FD',
  blueMedium: '#0075CB',
  blueDark: '#001052',
  gmBlue: '#061D36',
  // System
  error: '#C64804',
  success: '#218322',
  // Neutrals
  grayOne: '#FAFAFA',
  grayTwo: '#D9D9D6',
  grayThree: '#B3B3B3',
  grayFour: '#707070',
  grayFive: '#57514B',
  graySix: '#474B51',
  graySeven: '#3D3935',
  grayEight: '#302D2A',
  grayNine: '#24221F',
};

export const energyColorsRGB = {
  // Primary
  blue: '0, 9, 255',
  black: '0, 0, 0',
  offWhite: '255, 252, 241',
  coolWhite: '247, 249, 253',
  white: '255, 255, 255',
  // Secondary
  redOrange: '249, 73, 20',
  orange: '238, 113, 24',
  yellow: '255, 203, 50',
  magenta: '217, 43, 252',
  pink: '237, 149, 253',
  green: '83, 251, 148',
  blueLight: '141, 247, 253',
  blueMedium: '0, 117, 203',
  blueDark: '0, 16, 82',
  // System
  error: '198, 72, 4',
  success: '33, 131, 34',
  // Neutrals
  grayOne: '250, 250, 250',
  grayTwo: '217, 217, 214',
  grayThree: '179, 179, 179',
  grayFour: '112, 112, 112',
  grayFive: '87, 81, 75',
  graySix: '71, 75, 81',
  graySeven: '61, 57, 53',
  grayEight: '48, 45, 42',
  grayNine: '36, 34, 31',
};
