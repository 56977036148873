import { Box } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Typography from '@mui/material/Typography';
import { BrandType, Color, RotationImage, Session, Vehicle, VehicleFeature } from '@types';
import { ReactElement, useEffect } from 'react';
import RotationImages from './RotationImages';
import VehicleColors from './VehicleColors';
import { Animated } from 'react-animated-css';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import {
  collection,
  CollectionReference,
  DocumentData,
  getDocs,
  orderBy,
  query,
  QuerySnapshot,
  where,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { VehicleSelectStyles } from './VehicleSelect.style';

type VehicleSelectProps = {
  brandId: BrandType;
  onClose: () => void;
  onChange: (vehicle: Vehicle) => void;
  onUpdateSession: (session: Partial<Session>) => void;
  vehicles: Vehicle[];
  session: Session;
  isProductSpecialist: boolean;
};

const VehicleSelect = (props: VehicleSelectProps): ReactElement => {
  const { onClose, onChange, session, vehicles, brandId, isProductSpecialist, onUpdateSession } = props;
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';
  const isChevy = brandId.toLowerCase() === 'chevy';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev' || brandId.toLowerCase() === 'evcan';
  const { t } = useTranslation();
  const useStyles = makeStyles(VehicleSelectStyles(brandId));
  const styles = useStyles();
  const firestore = useFirestore();

  const getCollection = (querySnapshot: QuerySnapshot<DocumentData>) =>
    querySnapshot.docs.map((d) => ({
      ...d.data(),
      ...{ id: d.id },
    }));

  const updateRotationImages = async (colorId: string, rotationImagesRef: CollectionReference<DocumentData>) => {
    const rotationImagesQuery = query(rotationImagesRef, where('colorId', '==', colorId), orderBy('index', 'asc'));
    const rotationImageSnapshot = await getDocs(rotationImagesQuery);

    const rotationImagesState = getCollection(rotationImageSnapshot) as RotationImage[];
    if(rotationImagesState){
      await onUpdateSession( { rotationImagesState }); 
    }

  };

  const loadData = async (vehicleState: Vehicle) => {
    if(vehicleState){
      await onUpdateSession( { vehicleState });
      const vehiclePath = `brands/${brandId}/vehicles/${vehicleState.id}`;
      const rotationImagesRef = collection(firestore, `${vehiclePath}/rotationImages`);
  
      const colorsRef = collection(firestore, `${vehiclePath}/colors`);
      const colorsQuery = query(colorsRef, orderBy('index', 'asc'));
      const colorsSnapshot = await getDocs(colorsQuery);
      const vehicleColorsState = getCollection(colorsSnapshot) as Color[];
      const colorState = vehicleColorsState[0] as Color;
      if (colorState && vehicleColorsState) {
        await onUpdateSession( { colorState, vehicleColorsState });
      }
  
      const vehicleFeaturesRef = collection(firestore, `${vehiclePath}/vehicleFeatures`);
      const vehicleFeaturesQuery = query(vehicleFeaturesRef, orderBy('index', 'asc'));
      const vehicleFeaturesSnapshot = await getDocs(vehicleFeaturesQuery);
      const vehicleFeaturesState = getCollection(vehicleFeaturesSnapshot) as VehicleFeature[];
      if (vehicleFeaturesState) {
        await onUpdateSession( { vehicleFeaturesState });
      }
      if (colorState) {
        updateRotationImages(colorState.id, rotationImagesRef);
      }
    }


  };

  useEffect(() => {
    if (!isProductSpecialist) {
      // TODO Fix this
      onUpdateSession( { countdown: 0 });
    }
    if (session) {
      loadData(session.vehicleState || vehicles[0]);
    }
  }, []);

  const handelSelectVehicle = (vehicle: Vehicle) => {
    loadData(vehicle);
    onChange(vehicle);
  };

  const handelSelectColor = (colorState: Color) => {
    if(colorState){
      onUpdateSession( { colorState });
      const vehiclePath = `brands/${brandId}/vehicles/${session.vehicleState.id}`;
      const rotationImagesRef = collection(firestore, `${vehiclePath}/rotationImages`);
      updateRotationImages(colorState.id, rotationImagesRef);
    }

  };

  const handelRotationIndexChanged = (rotationIndex: number) => {
    onUpdateSession( { rotationIndex });
  };

  return (
    <Animated
      animationIn="slideInUp"
      animationOut="slideInDown"
      isVisible={session.vehicleColorsVisible || session.vehicleModelVisible}
    >
      <Box
        className={`${styles.vehicleSelect} ${session.vehicleColorsVisible || session.vehicleModelVisible
          ? styles.vehicleSelectActive
          : ''
          }`}
      >
        <RotationImages
          onRotationIndexChanged={handelRotationIndexChanged}
          rotationIndex={session.rotationIndex}
          rotationImages={session.rotationImagesState}
          vehicleModelVisible={session.vehicleModelVisible}
          brandId={brandId}
          vehicleStateName={session?.vehicleState?.name || ''}
        />

        <IconButton
          onClick={onClose}
          className={`stat-button-close ${styles.closeButton}`}
          data-dtm={`${session.vehicleModelVisible ? 'vehicle selector' : 'colors'}`}
          color="primary"
          aria-label="Close tray"
          component="span"
          disableRipple
        >
          {isChevy && (
            <CancelOutlinedIcon className={styles.closeButtonIcon} />
          )}
          {(isCadillacUS || isCadillacCanada || isGMC || isBuick || isEVLive) && (
            <CloseIcon className={styles.closeButtonIcon} />
          )}
        </IconButton>

        {session.vehicleColorsVisible && session.colorState && (
          <>
            <Box>
              <VehicleColors
                colorState={session.colorState}
                onSelectColor={handelSelectColor}
                colors={session.vehicleColorsState as Color[]}
                vehicleStateName={session?.vehicleState?.name || ''}
              />
            </Box>

            <Box className={styles.modelColorContainer}>
              <Typography
                variant="vehicleSelectModel"
                component="h2"
              >
                {t(session.colorState.name)}
              </Typography>
              <Typography
                className={styles.colorNoteText}
                variant="vehicleSelectFootnote"
                component="p"
              >
                {t(session.colorState.costDisclaimer)}
              </Typography>
            </Box>
          </>
        )}

        {session.vehicleModelVisible && session.vehicleState && (
          <Box className={styles.modelSelectModelsContainer}>
            <Box className={styles.modelSelectModels}>
              {vehicles.map((v) => (
                <div key={v.id}>
                {
                  !v.hideModelTray && (
                    <Box
                      className={`stat-image-link ${styles.modelSelectModel} ${session.vehicleState.id === v.id ? styles.modelSelectModelSelected : ' '}`}
                      data-dtm="vehicle selector"
                    >
                      <Button
                        disableRipple
                        onClick={() => {
                          handelSelectVehicle(v);
                        }}
                        className={`${styles.modelSelectButton}`}
                        aria-label="Select model"
                      >
                        <Box
                          className={styles.modelSelectButtonImage}
                          component="img"
                          src={v.imageUrl}
                          alt={t(v.name)}
                          draggable={false}
                        />
                      </Button>
                    {(isCadillacUS || isCadillacCanada || isGMC || isBuick || isEVLive) && (
                      <Typography
                        variant="vehicleSelectModelAlt"
                        component="h2"
                      >
                        {t(v.name)}
                      </Typography>
                    )}
                  </Box>
                )}
                </div>
              ))}
            </Box>
            {isChevy && (
              <Box className={styles.modelColorContainer}>
                <Typography
                  variant="vehicleSelectModel"
                  component="h2"
                >
                  {t(session.vehicleState.name)}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {!session.vehicleState && <Typography
          variant="vehicleSelectModelAlt"
          component="h2"
        >
          Please Select A Focus Vehicle
        </Typography>}

      </Box>
    </Animated>
  );
};

export default VehicleSelect;
