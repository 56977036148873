import { Box, Button, Typography } from '@mui/material';
import { ModalIconButton, TextButton } from '@components';
import { useTranslation } from 'react-i18next';
import { BrandType } from '@types';

type EndSessionModalProps = {
  brandId: BrandType;
  onClose?: () => void;
  onEndSession?: () => void;
};

const EndSessionModal = (props: EndSessionModalProps) => {
  const { onClose, onEndSession, brandId } = props;
  const { t } = useTranslation();
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';
  const isChevy = brandId.toLowerCase() === 'chevy';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev';
  const isEVLiveCanada = brandId.toLowerCase() === 'evcan';
  const isEnergy = brandId.toLowerCase() === 'energy';

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
          dataDTM="end session modal"
        />
      )}
      <Box className="modal-container">
        {!isEnergy && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
            dataDTM="end session modal"
          />
        )}
        <Box className="modal-text-container">
          <Typography component="h2" variant="modalTitle">
            {t('ARE YOU SURE WANT TO LEAVE THIS TOUR?')}
          </Typography>

          {isGMC && <Box className="modal-border" />}

          {(!isEVLiveCanada && !isEnergy) && (
            <Typography component="p" variant="modalSubText">
              {t(
                'Once you exit, your session will end. If you change your mind, you will need to join a new tour.',
              )}
            </Typography>
          )}
        </Box>
        <Box className="modal-container-buttons">
          {(isChevy || isEVLive || isEVLiveCanada) && (
            <TextButton
              aria-label="End my experience"
              className="stat-text-link"
              data-dtm="end session modal"
              onClick={onEndSession}
              variant="text"
              type="button"
              disableRipple
              sx={{ textDecoration: isEVLive || isEVLiveCanada ? 'underline !important' : 'none' }}
            >
              {t('Yes, end my experience')}
            </TextButton>
          )}

          {(isCadillacUS || isCadillacCanada || isGMC || isBuick || isEnergy) && (
            <Button
              aria-label="End my experience"
              className="stat-button-link"
              data-dtm="end session modal"
              onClick={onEndSession}
              variant={isCadillacUS || isCadillacCanada ? 'primary' : 'tertiary'}
              type="button"
              disableRipple
            >
              {t('Yes, end my experience')}
            </Button>
          )}

          <Button
            className="stat-button-link"
            data-dtm="end session modal"
            onClick={onClose}
            variant="primary"
            type="button"
            disableRipple
            aria-label="Close modal"
          >
            {t('Nope, not yet')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EndSessionModal;
