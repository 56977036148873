export const themes = {
  light: 'light',
  dark: 'dark',
  chevy: 'chevy',
  cadillac: 'cadillac',
  cadillaccan: 'cadillaccan',
  gmc: 'gmc',
  buick: 'buick',
  ev: 'ev',
  evcan: 'evcan',
  energy: 'energy',
};
