import { Box, Button, Typography } from '@mui/material';
import { ModalIconButton } from '@components';
import { useTranslation } from 'react-i18next';
import { BrandType } from '@types';

type SymblErrorModalProps = {
  brandId: BrandType;
  onClose?: () => void;
};

const SymblErrorModal = (props: SymblErrorModalProps) => {
  const { onClose, brandId } = props;
  const { t } = useTranslation();
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isEnergy = brandId.toLowerCase() === 'energy';

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
          dataDTM="modal:captioning unavailable"
        />
      )}
      <Box className="modal-container">
        {!isEnergy && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
            dataDTM="modal:captioning unavailable"
          />
        )}
        <Box className="modal-text-container">
          <Typography
            component="h2"
            variant="modalTitle"
          >
            {t('Closed captioning is currently unavailable.')}
          </Typography>
          {isGMC && <Box className="modal-border" />}
          <Typography
            component="p"
            variant="modalSubText"
          >
            {t('We apologize for the inconvenience.')}
          </Typography>
        </Box>
        <Box className="modal-container-buttons">
          <Button
            className="stat-button-link"
            data-dtm="modal:captioning unavailable"
            onClick={onClose}
            variant="primary"
            type="button"
            disableRipple
            aria-label="Resume"
          >
            {t('Return')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SymblErrorModal;
