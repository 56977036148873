import { useTheme } from '@material-ui/core/styles';

export const VehicleFeatureViewStyles = (brandId: string) => {
  const theme = useTheme();

  const chevyStyles = {
    vehicleFeatureView: {
      width: '33.33%',
      cursor: 'pointer',
    },
    vehicleFeatureViewImageContainer: {
      position: 'relative',
      height: 118,
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(38, 38, 38, 0.7)',
        pointerEvents: 'none'
      }
    },
    vehicleFeatureViewImageContainerActive: {
      '&::after': {
        backgroundColor: 'rgba(38, 38, 38, 0)',
      },
    },
    vehicleFeatureViewImage: {
      position: 'relative',
      height: 118,
      width: '100%',
      overflow: 'hidden',
      objectFit: 'cover',
    },
    vehicleFeatureViewContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingTop: 16,
      paddingLeft: 12,
      paddingRight: 14,
      paddingBottom: 16,
      backgroundColor: theme.palette.background.vehicleFeatureViewContent,
    },
    vehicleFeatureViewContentActive: {
      backgroundColor: theme.palette.background.vehicleFeatureViewContentActive,
    },
    vehicleFeatureViewLink: {
      marginTop: 20,
      '&:hover': {
        textDecoration: 'none',
        opacity: 1,
      },
    },
    vehicleFeatureViewLinkActive: {
      opacity: 0,
    },
  };

  const cadillacStyles = {
    vehicleFeatureViewImageContainer: {
      '&::after': {
        background: 'transparent'
      },
    },
    vehicleFeatureViewLink: {
      opacity: 0,
      '&:hover': {
        opacity: 0,
      }
    },
    vehicleFeatureViewContent: {
      height: '48px',
      '@media screen and (min-width: 768px)': {
        height: '60px',
      },
    },
    vehicleFeatureViewContentActive: {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: '-1',
        height: '100%',
        width: '100%',
        backdropFilter: ' blur(6px)',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
      },
      '@global': {
        '.MuiTypography-vehicleFeatureCategory': {
          color: '#fff',
        },
      },
    },
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
  };

  const gmcStyles = {
    vehicleFeatureViewImageContainer: {
      '&::after': {
        background: 'transparent'
      },
    },
    vehicleFeatureViewLink: {
      opacity: 0,
      '&:hover': {
        opacity: 0,
      }
    },
    vehicleFeatureViewContent: {
      height: '48px',
      justifyContent: 'center',
      alignItems: 'center',
      '@media screen and (min-width: 768px)': {
        height: '60px',
      },
    },
    vehicleFeatureViewContentActive: {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: '-1',
        height: '100%',
        width: '100%',
        backdropFilter: ' blur(6px)',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
      },
      '@global': {
        '.MuiTypography-vehicleFeatureCategory': {
          color: '#fff',
        },
      },
    },
  };

  const buickStyles = {
    vehicleFeatureView: {
      width: '100%',
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        width: 160,
        minWidth: 160,
        maxWidth: 160,
      },
    },
    vehicleFeatureViewImageContainer: {
      '&::after': {
        background: 'transparent'
      },
    },
    vehicleFeatureViewLink: {
      opacity: 0,
      '&:hover': {
        opacity: 0,
      }
    },
    vehicleFeatureViewContent: {
      height: '48px',
      justifyContent: 'center',
      alignItems: 'center',
      '@media screen and (min-width: 768px)': {
        height: '60px',
      },
    },
    vehicleFeatureViewContentActive: {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: '-1',
        height: '100%',
        width: '100%',
        backdropFilter: ' blur(6px)',
        backgroundColor: 'rgba(63, 63, 56, 0.9)',
      },
      '@global': {
        '.MuiTypography-vehicleFeatureCategory': {
          color: '#fff',
        },
      },
    },
  };

  const evliveStyles = {
    vehicleFeatureView: {
      position: 'relative',
      width: 'calc(33.33% - 2.5px)',
    },
    vehicleFeatureViewImageContainer: {
      '&::after': {
        mixBlendMode: 'multiply',
        backgroundColor: 'rgba(17, 54, 95, 0.75)',
      },
    },
    vehicleFeatureViewImageContainerActive: {
      '&::after': {
        backgroundColor: 'rgba(17, 54, 95, 0)',
      },
    },
    vehicleFeatureViewLink: {
      opacity: 1,
      '&:hover': {
        opacity: 1,
      }
    },
    vehicleFeatureViewContent: {
      position: 'absolute',
      left: 9,
      bottom: 9,
      padding: 0,
    },
  };

  const energyStyles = {};

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};
