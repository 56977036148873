import { FC } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { Typography } from '@material-ui/core';
import { EnvironmentType } from '../types';

interface EnvironmentBannerProps {
  environment: EnvironmentType;
}

const EnvironmentBanner: FC<EnvironmentBannerProps> = (props) => {
  const { environment } = props;
  return (
    <>
      {environment === 'Release' ? null : (
        <AppBar
          position="sticky"
          enableColorOnDark
          sx={{
            backgroundColor: '#cd9834',
            boxShadow: ' 4px -2px 3px #171717',
          }}
        >
          <Toolbar variant="dense" sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold', color: '#FFFFFF' }}>
              GM Live {environment} Environment
            </Typography>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};
export default EnvironmentBanner;
