
import { QueryStringVariable } from '../types';
import { fetchJson } from './fetchJson';

export class BitlyLinkRepository {
  getBitlyLink = async (link: string, queryStringVariables?: QueryStringVariable[]): Promise<string> => {
    const body = { link, queryStringVariables };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/getBitlyLink`, body);
  };
}
export const bitlyLinksRepository = new BitlyLinkRepository();
