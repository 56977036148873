import { Box } from '@material-ui/core';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useTheme } from '@material-ui/core/styles';

interface ModalIconButtonProps {
  onClose: () => void;
  brandId: string;
  dataDTM?: string;
}

const ModalIconButton = (props: ModalIconButtonProps) => {
  const { onClose, brandId, dataDTM } = props;
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';
  const isChevy = brandId.toLowerCase() === 'chevy';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev' || brandId.toLowerCase() === 'evcan';
  const isEnergy = brandId.toLocaleLowerCase() === 'energy';
  const theme = useTheme();

  return (
    <Box className="modal-close-button-container">
      <IconButton
        onClick={(e) => {
          if (e.detail === 1) {
            onClose();
          }
        }}
        className="stat-button-close modal-close-button"
        color="primary"
        aria-label="Close modal"
        component="span"
        data-dtm={dataDTM}
      >
        {(isBuick || isEVLive) && <CloseIcon />}
        {(isChevy || isGMC) && <CancelOutlinedIcon />}
        {(isCadillacUS || isCadillacCanada || isEnergy) && (
          <Box
            className="modal-close-icon"
            sx={{
              backgroundImage: theme.palette.background.modalCloseWhiteIcon
            }}
          />
        )}
      </IconButton>
    </Box>
  )
}

export default ModalIconButton;
