import * as firebase from 'firebase/auth';
import { useState, useMemo } from 'react';
import { userRepository } from '@repositories';
import { useQueryString } from './useQueryString';
import {  useAuth } from 'reactfire';
import { generatePushID } from '@utils';

export const useReservationToken = (token: string, reservationId: string): string => {
  const [userId, setUserId] = useState<string>();
  const { urlSearchParams } = useQueryString();
  const reauth = urlSearchParams.get('reauth');
  const auth = useAuth();

  const generateToken = async (uid: string) => {
    const { token: userToken } = await userRepository.generateToken(uid);
    return userToken;
  };

  const getUserId = async () => {
    // let userToken = token;

    // if(reauth == 'false' &&  auth && auth.currentUser){
    
    //   setUserId(auth.currentUser.uid);
    // }
    // else{
    //   if (!reservationId && !token) {
    //     userToken = await generateToken(Date.now().toString());
    //   }
    //   firebase.signInWithCustomToken(firebase.getAuth(), userToken).then((credential) => {
    //     setUserId(credential.user.uid);
    //   }).catch(async () => {
    //     userToken = await generateToken(reservationId ||Date.now().toString());
    //     const credential = await firebase.signInWithCustomToken(firebase.getAuth(), userToken);
    //     setUserId(credential.user.uid);
    //   });

    // }

    if(token){
      firebase.signInWithCustomToken(firebase.getAuth(), token).then((credential) => {
        setUserId(credential.user.uid);
      }).catch(async () => {
        const userToken = await generateToken(reservationId || generatePushID().toString());
        const credential = await firebase.signInWithCustomToken(firebase.getAuth(), userToken);
        setUserId(credential.user.uid);
      });
    }
    else{
      setUserId(auth.currentUser.uid);
    }


  };

  useMemo(() => {
    getUserId();
  }, []);

  return userId;
};