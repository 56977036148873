import { Box, Typography, Button } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalIconButton } from '@components';

const CadillacLogo = require('../assets/images/modal/cadillac-logo.png').default;
const CadillacBrowserNotSupportedImage = require('../assets/images/modal/cadillac-browser-not-supported.png').default;

type BrowserNotSupportedModalProps = {
  brandId: string;
  onClose?: () => void;
  message?: string;
  isAdmin?: boolean;
};

const BrowserNotSupportedModal = (props: BrowserNotSupportedModalProps): ReactElement => {
  const { onClose, brandId, message, isAdmin } = props;
  const { t } = useTranslation();
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isEnergy = brandId.toLowerCase() === 'energy';

  const adminStyles: { [key: string]: React.CSSProperties } = {
    container: {
      padding: '34px',
    },
    resume: {
      padding: '10px 0px',
      position: 'relative',
      left: '-5px'
    },
    title: {
      marginBottom: '10px',
    },
  };

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
          dataDTM={isEnergy ? 'browser error modal' : 'modal:browser not supported'}
        />
      )}

      <Box
        className="modal-container"
        style={isAdmin && adminStyles.container}
      >
        {!isEnergy && !isAdmin && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
            dataDTM={isEnergy ? 'browser error modal' : 'modal:browser not supported'}
          />
        )}

        {(isCadillacUS || isCadillacCanada) && (
          <>
            <Box className="modal-image-container">
              <Box
                className="modal-image"
                component="img"
                src={CadillacBrowserNotSupportedImage}
                alt="Modal Banner"
              />
              {isCadillacUS && (
                <Box
                  className="modal-brand-logo"
                  component="img"
                  src={CadillacLogo}
                  alt="Cadillac"
                />
              )}
            </Box>
          </>
        )}

        <Box className="modal-text-container">
          <Typography
            variant="modalTitle"
            component="h2"
            style={isAdmin && adminStyles.title}
          >
            {t('Browser not supported')}
          </Typography>

          {isGMC && <Box className="modal-border" />}

          <Typography
            variant="modalSubText"
            component="p"
            sx={{
              pl: !isAdmin && '30px',
              pr: !isAdmin && '30px',
            }}
          > {message ? t(message)
            : t('Your browser is not currently supported. Use Google Chrome, Safari, Microsoft Edge, Firefox or Opera to experience Chevy MyWay. If you\'re on an iPhone, please use Safari.')}
          </Typography>
        </Box>

        <Box className="modal-container-buttons">
          <Button
            className="stat-button-link"
            data-dtm={isEnergy ? 'browser error modal' : 'modal:browser not supported'}
            onClick={onClose}
            variant="primary"
            type="button"
            disableRipple
            aria-label="Resume"
            style={isAdmin && adminStyles.resume}
          >
            {isEnergy ? t('Return') : t('Resume')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BrowserNotSupportedModal;
