import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BrandType } from '@types';
import ModalIconButton from './ModalIconButton';

type EnableYourAudioModalProps = {
  onClose?: () => void;
  brandId: BrandType;
};

const EnableYourAudioModal = (props: EnableYourAudioModalProps) => {
  const { onClose, brandId } = props;
  const { t } = useTranslation();
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isEnergy = brandId.toLowerCase() === 'energy';

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
        />
      )}
      <Box className="modal-container">
        {!isEnergy && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
          />
        )}
        <Box className="modal-text-container">
          <Typography
            component="h2"
            variant="modalTitle"
          >
            {t('Enable your audio')}
          </Typography>
          {isGMC && <Box className="modal-border" />}
          <Typography
            component="p"
            variant="modalSubText"
          >
            {t("If you'd like to speak to the Product Specialist directly, ensure your audio setting is enabled to hear the stream. You may update the audio permissions by clicking the lock icon in the address bar above or refreshing the page.")}
          </Typography>
        </Box>
        <Box className="modal-container-buttons">
          <Button
            onClick={onClose}
            variant="primary"
            type="button"
            disableRipple
            aria-label="Resume"
          >
            {t('Resume')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EnableYourAudioModal;
