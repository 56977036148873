import { brands } from '@constants';
import { collection, query, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

type Props = {
  t3Dealer: string,
  brandId: string
};

export function getT3Dealer({ t3Dealer, brandId }: Props): string {
  if (!t3Dealer) {
    return '';
  }

  const stringParts = t3Dealer.split('_');
  const prefix = stringParts[0];

  if (!brands.includes(prefix)) {
    return '';
  }

  const firestore = useFirestore();
  const queryStringVariablesRef = collection(firestore, `brands/${brandId}/queryStringVariables`);
  const queueQuery = query(queryStringVariablesRef, where('key', '==', 'dealer'));
  const { data: queryStringVariables } = useFirestoreCollectionData(queueQuery, { idField: 'id' });

  const suffixFound = queryStringVariables?.find((str) => str.queryStringOptions.some((option: { value: string; }) => option.value.toLowerCase() === t3Dealer.toLowerCase()));

  if (suffixFound) {
    return prefix;
  }

  return ''; // Return empty string if format is incorrect
};
