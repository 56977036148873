import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider, FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from './config';
import { initializeApp } from '@firebase/app';
import { getAuth, connectAuthEmulator } from '@firebase/auth';
import { connectFirestoreEmulator, getFirestore } from '@firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';
import { connectStorageEmulator, getStorage } from '@firebase/storage';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';

export const app = initializeApp(firebaseConfig);
// emulator for use in dev: firebase emulators:start

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(getAuth(app), 'http://localhost:9099');
  connectFirestoreEmulator(getFirestore(app), 'localhost', 8080);
  connectStorageEmulator(getStorage(app), 'localhost', 9199);
  connectFunctionsEmulator(getFunctions(app), 'localhost', 5001);
  connectDatabaseEmulator(getDatabase(app), 'localhost', 9001);
}

const auth = getAuth(app);

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <AuthProvider sdk={auth}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LocalizationProvider>
      </StyledEngineProvider>
    </AuthProvider>
  </FirebaseAppProvider>,
  document.getElementById('root'),
);
