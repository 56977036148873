import { fetchJson } from './fetchJson';
import { User } from '@types';

class AnalyticsRepository {
  getAnalytics = async (startDate:string, endDate: string, brands:string[]): Promise<any> => {
    const body = {
      startDate,
      endDate,
      brands,
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/getAnalytics`, body);
  };

  exportAnalytics = async (startDate:string, endDate: string, users: User[], brands: string[]): Promise<any> => {
    const body = {
      startDate,
      endDate,
      users,
      brands,
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/exportAnalytics`, body);
  };

  getAnalyticsRawData = async (startDate:string, endDate: string, brands: string[]): Promise<any> => {
    const body = {
      startDate,
      endDate,
      brands,
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/getAnalyticsRawData`, body);
  };
}

export const analyticsRepository = new AnalyticsRepository();
