import { useTheme } from '@material-ui/core/styles';

export const VehicleSelectStyles = (brandId: string) => {
  const theme = useTheme();

  const chevyStyles = {
    vehicleSelect: {
      position: 'relative',
      display: 'none',
      paddingTop: 97,
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 55,
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: '-1',
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(6px)',
        backgroundColor: theme.palette.background.vehicleSelectBackground,
      }
    },
    vehicleSelectActive: {
      display: 'block',
    },
    closeButton: {
      position: 'absolute',
      top: 55,
      right: 44,
      minWidth: 30,
      padding: 0,
      border: 'none',
      backgroundColor: 'transparent',
    },
    closeButtonIcon: {
      height: 35,
      width: 'auto',
      color: theme.palette.color.vehicleRotateCloseIcon,
    },
    modelSelectModelsContainer: {
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
      // flexDirection: 'column',
    },
    modelSelectModels: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
      overflowX: 'scroll',
      paddingBottom: '10px',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        overflowX: 'unset',
      },
      '&::-webkit-scrollbar': {
        width: 8,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.background.chatScrollThumb,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: theme.palette.background.chatScrollThumb,
      },
    },
    modelSelectModel: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      opacity: 0.4,
      '&:hover': {
        opacity: 1,
      },
    },
    modelSelectModelSelected: {
      opacity: 1,
    },
    modelSelectButton: {
      zIndex: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 7.5,
      padding: 0,
      height: '70px',
      width: '124px',
      minWidth: '124px',
      backgroundColor: 'transparent',
      border: 'none',
      transition: 'all 0.3s',
      overflow: 'hidden',
    },
    modelSelectButtonImage: {
      height: '80px',
      width: 'auto',
    },
    modelColorContainer: {
      marginTop: 25,
    },
    colorNoteText: {
      marginTop: 10,
      marginBottom: 0,
    },
  };

  const cadillacStyles = {
    modelColorContainer: {
      marginTop: '45px',
    },
    modelSelectButtonImage: {
      height: 'auto',
      width: '100%',
    },
    modelSelectModels: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        overflowX: 'scroll',
      },
    },
    modelSelectModel: {
      minWidth: '150px',
      maxWidth: '150px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.up('md')]: {
        margin: '10px',
      },
    }
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
  };

  const gmcStyles = {
    modelColorContainer: {
      marginTop: '45px',
    },
    modelSelectButtonImage: {
      height: 'auto',
      width: '220px',
    },
    modelSelectModels: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        overflowX: 'scroll',
      },
    },
    modelSelectModel: {
      minWidth: '150px',
      maxWidth: '150px',
      marginLeft: '10px',
      marginRight: '10px',
      [theme.breakpoints.up('md')]: {
        margin: '10px',
      },
    }
  };

  const buickStyles = {
    modelColorContainer: {
      marginTop: '45px',
    },
    modelSelectButtonImage: {
      height: 'auto',
      width: '100%',
    },
    modelSelectModelsContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    modelSelectModels: {
      '@media screen and (min-width: 500px)': {
        overflowX: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'unset',
        flexWrap: 'nowrap',
        overflowX: 'auto',
      },
    },
    modelSelectModel: {
      minWidth: '150px',
      maxWidth: '150px',
      paddingLeft: 10,
      paddingRight: 10,
      [theme.breakpoints.up('md')]: {
        padding: 10,
      },
    },
  };

  const evliveStyles = {
    modelColorContainer: {
      marginTop: '45px',
    },
    modelSelectButtonImage: {
      height: 'auto',
      width: '100%',
    },
    modelSelectModelsContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    modelSelectModels: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'unset',
        flexWrap: 'nowrap',
        overflowX: 'auto',
      },
    },
    modelSelectModel: {
      minWidth: '150px',
      maxWidth: '150px',
      paddingLeft: 10,
      paddingRight: 10,
      [theme.breakpoints.up('md')]: {
        padding: 10,
      },
    },
  };

  const energyStyles = {};

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };
  return styles[brandId];
};
