import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import type { Direction, Theme } from '@material-ui/core';
import { themes } from '@constants';
import '../assets/fonts/fonts.css';
import '../assets/css/animate.min.css';
import { cadillacCanadaTheme } from './cadillac-canada.theme';
import { cadillacTheme } from './cadillac.theme';
import { chevyTheme } from './chevy.theme';
import { darkTheme } from './dark.theme';
import { lightTheme } from './light.theme';
import { gmcTheme } from './gmc.theme';
import { baseOptions } from './baseOptions';
import { buickTheme } from './buick.theme';
import { evliveTheme } from './evlive.theme';
import { evliveCanadaTheme } from './evlive-canada.theme';
import { energyTheme } from './energy.theme';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    primaryInverse: true;
    primaryInverseLight: true;
    secondary: true;
    secondaryInverse: true;
    experienceIcons: true;
    experienceIconsLight: true;
    experience: true;
    experienceInverse: true;
    experienceInverseLight: true;
    experienceActive: true;
    statusOffline: true;
    statusOnline: true;
    statusWaiting: true;
    formOpen: true;
    post: true;
    view: true;
    tertiary: true;
    tertiaryInverse: true;
    toast: true;
    productSpecialist: true;
    productSpecialistLight: true;
    missedTourHeader: true;
    loungeWaiting: true;
    loungeWaitingInverse: true;
    reservationConformation: true;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    border: {
      vehicleFeaturesTextButton: string;
      vehicleFeaturesTextButtonBefore: string;
      colorSelectButton: string;
      colorSelectButtonHover: string;
      colorSelectButtonSelected: string;
      sessionBorder: string;
      groupShowroomBorder: string;
      splidePaginationBorder: string;
      vehicleTypeBorder: string;
    };
    boxShadow: {
      colorSelectButtonHover: string;
      colorSelectButtonSelected: string;
    };
    color: {
      sessionMenuIcon: string;
      vehicleRotateCloseIcon: string;
      previousNextRating: string;
      muteVideoIcon: string;
      muteVideoIconActive: string;
      sessionMenuIconTextMute: string;
      toastTitleNumber: string;
      toastButton: string;
      checkboxGrey: string;
      privacyStatement: string;
      vehicleFeaturesTextActive: string;
      missedTourTitleNumber: string;
      modalTitleBlueText: string;
      paginationDot: string;
      pagination: string;
      aqua: string;
    };
  }

  interface TypeBackground {
    formScrollTrack: string;
    formScrollThumb: string;
    formScrollThumbHover: string;
    chatScrollTrack: string;
    chatScrollThumb: string;
    chatScrollThumbHover: string;
    vehicleFeaturseButton: string;
    vehicleFeaturseButtonHover: string;
    vehicleFeatureContent: string;
    vehicleFeaturePreviewContent: string;
    vehicleFeatureViewContent: string;
    vehicleFeatureViewContentActive: string;
    queue: string;
    queueTextContainer: string;
    showroomContainer: string;
    arrowButton: string;
    live: string;
    toast: string;
    streamPause: string;
    groupShowroomButton: string;
    splidePaginationActive: string;
    formInputCustomIcon: string;
    selectBackground: string;
    selectBackgroundHover: string;
    formBackground: string;
    errorBackground: string;
    vehicleSelectBackground: string;
    hoursOfOperationBackground: string;
    loadingScreen: string;
    messageDots: string;
    messageDotAdmin: string;
    messageDotUser: string;

    // Start Icons (BackgroundImage)
    needHelpIcon: string;
    needHelpIconWhite: string;
    bookTestDriveIcon: string;
    inviteAFriendIcon: string;
    stayConnectedIcon: string;
    cameraStreamIcon: string;
    cameraStreamStopIcon: string;
    cameraStreamNotSupportedIcon: string;
    colorIcon: string;
    modelIcon: string;
    closeIcon: string;
    closeIconActive: string;
    closeIconAlt: string; // EV LIVE (US / CAN)
    closeIconAltActive: string; // EV LIVE (US / CAN)
    closeCircleIcon: string;
    rotateIcon: string;
    menuIcon: string;
    phoneIcon: string;
    menuIconActive: string;
    chatIcon: string;
    chatIconActive: string;
    chevronClosedStateIcon: string;
    chevronClosedDisabledStateIcon: string;
    chevronOpenStateIconTransfer: string;
    chevronDownBlackIcon: string;
    chevronOpenStateIcon: string;
    chevronActiveClosedStateIcon: string;
    chevronActiveOpenStateIcon: string;
    featureArrowUp: string;
    featureArrowDown: string;
    featureArrowUpActive: string;
    featureArrowDownActive: string;
    starIcon: string;
    starOutlineIcon: string;
    fullScreenIcon: string;
    modalCloseBlackIcon: string;
    modalCloseWhiteIcon: string;
    calendarIcon: string;
    calendarIconActive: string;
    modifyCalendarIcon: string;
    vehicleTrayCloseIcon: string;
    vehicleTrayCloseIconActive: string;
    playIcon: string;
    pauseIcon: string;
    toastCloseWhiteIcon: string;
    toastCloseBlackIcon: string;
    chevronLeftArrowIcon: string;
    chevronLeftArrowActiveIcon: string;
    chevronRightArrowIcon: string;
    checkCircleIcon: string;
    informationCircleIcon: string;
    chevronRightArrowActiveIcon: string;
    dateIcon: string;
    timeIcon: string;
    durationIcon: string;
    pinkTopLeftDesktop: string;
    pinkTopLeftMobile: string;
    greenBottomRightDesktop: string;
    greenBottomRightMobile: string;
    greenTopLeftDesktop: string;
    greenTopLeftMobile: string;
    chevronDownBlackWideArrow: string;
    flipCamera: string;
    filpBackCamera: string;
    switchScreen: string;
    disableFlipCamera: string;
    disableSwitchScreen: string;
    stopCamera: string;
    cameraIcon: string;
    helpIcon: string;
    warningIcon: string;
    warningRedIcon: string;
    keyboardIcon: string;
    inviteAFriendBlueIcon: string;
    helpBlueIcon: string;
    cameraStreamBlueIcon: string;
    contactDealerIcon: string;
    screenShareIcon: string;
    greenCheckmark: string;
    cameraStreamStopWhiteIcon: string;
    cameraStreamWhiteIcon: string;
    cameraStreamNotSupportedWhiteIcon: string;
    bookTestDriveWhiteIcon: string;
    contactDealerWhiteIcon: string;
    screenShareWhiteIcon: string;
    inviteAFriendWhiteIcon: string;
    stayConnectedWhiteIcon: string;
    helpWhiteIcon: string;
    // Scroller Arrows
    scrollerDefaultArrow: string;
    scrollerHoverArrow: string;
    scrollerFocusedArrow: string;
    scrollerDisabledArrow: string;
    // End Icons
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }

  interface TypographyVariants {
    h3: React.CSSProperties;
    body3: React.CSSProperties;
    link3: React.CSSProperties;
    // Toast
    toastHeadline: React.CSSProperties;
    toastBody: React.CSSProperties;
    // Lounges (1:1 and Group)
    queueNumber: React.CSSProperties;
    queueText: React.CSSProperties;
    queueTextBlack: React.CSSProperties;
    loungeEstimate: React.CSSProperties;
    loungeTitle: React.CSSProperties;
    // Lounges (V2)
    loungeWaitingTitle: React.CSSProperties;
    loungeWaitingWhileYouWait: React.CSSProperties;
    loungeWaitingYourTourWillBe: React.CSSProperties;
    loungeWaitingModel: React.CSSProperties;
    loungeWaitingChangeLink: React.CSSProperties;
    loungeWaitingBackFrontLine: React.CSSProperties;
    loungeWaitingSecondsRemaining: React.CSSProperties;
    loungeWaitingHowItWorks: React.CSSProperties;
    loungeWaitingPreview: React.CSSProperties;
    loungeWaitingDealership: React.CSSProperties;
    // Lounges - How It Works
    loungeInfoTitle: React.CSSProperties;
    loungeInfoBody: React.CSSProperties;
    loungeInfoFootnote: React.CSSProperties;
    // Session
    liveText: React.CSSProperties;
    iconTextLink: React.CSSProperties;
    sessionMenuIconText: React.CSSProperties;
    streamPause: React.CSSProperties;
    // Session - Vehicle Features
    vehicleFeatureDropDown: React.CSSProperties;
    vehicleFeatureCategory: React.CSSProperties;
    vehicleFeatureTitle: React.CSSProperties;
    vehicleFeatureCaption: React.CSSProperties;
    vehicleFeatureBody: React.CSSProperties;
    vehicleFeatureDisclaimer: React.CSSProperties;
    // Session - Vehicle Selector
    vehicleSelectModel: React.CSSProperties;
    vehicleSelectModelAlt: React.CSSProperties;
    vehicleSelectFootnote: React.CSSProperties;
    // Session - Chat
    messageText: React.CSSProperties;
    messageTextAdmin: React.CSSProperties;
    closedCaptionText: React.CSSProperties;
    closedCaptionTextBold: React.CSSProperties;
    // Reservation (Reservation, Group Reservation)
    timeTitle: React.CSSProperties;
    timeDateTitle: React.CSSProperties;
    legend: React.CSSProperties;
    yourInformation: React.CSSProperties;
    // Thank you
    thankYouHeader: React.CSSProperties;
    thankYouInputTitle: React.CSSProperties;
    characterCount: React.CSSProperties;
    RateYourExperience: React.CSSProperties;
    // Stay Updated
    formBody: React.CSSProperties;
    vehicleTypeHeading: React.CSSProperties;
    vehicleSelectHeading: React.CSSProperties;
    // Error Messages
    errorTitle: React.CSSProperties;
    // Modal(s) and Reservation
    modalTitle: React.CSSProperties;
    modalSubTextAlt: React.CSSProperties;
    modalSubTitle: React.CSSProperties;
    modalSubText: React.CSSProperties;
    modalFootnote: React.CSSProperties;
    modalConsentText: React.CSSProperties;
    // Modal - Shareable
    shareableHyperTitle: React.CSSProperties;
    shareableHyperLinksLink: React.CSSProperties;
    // Modal - Need Help
    needHelpTitle: React.CSSProperties;
    needHelpBody: React.CSSProperties;
    // Modal Troubleshoot
    troubleshootQuestion: React.CSSProperties;
    troubleshootAnswer: React.CSSProperties;
    troubleshootHeading: React.CSSProperties;
    troubleshootDT: React.CSSProperties;
    troubleshootDD: React.CSSProperties;
    questionHeading: React.CSSProperties;
    questionAnswer: React.CSSProperties;
    questionListItem: React.CSSProperties;
    // Modal - Vehicle / Topic Selector
    vehicleSelectorLoungeHeader: React.CSSProperties;
    vehicleLoungeImageName: React.CSSProperties;
    vehicleVideoTitle: React.CSSProperties;
    vehicleSelectorLoungeSubtitle: React.CSSProperties;
    vehicleSelectorLoungeDropdownSubtitle: React.CSSProperties;
    vehicleTypeHeadingSelectorModal: React.CSSProperties;
    vehicleSelectorTopicName: React.CSSProperties;
    vehicleSelectorTopicCat: React.CSSProperties;
    vehicleSelectorTopicDesc: React.CSSProperties;
    // Hours of Operations
    hoursOfOperation: React.CSSProperties;
    hoursOfOperationHeading: React.CSSProperties;
    // Missed Group Tour
    missedGroupTourHeaderTitle: React.CSSProperties;
    missedGroupTourHeaderBody: React.CSSProperties;
    // Reservation Flow - Modify Reservation
    modifyUserName: React.CSSProperties;
    yourEarly: React.CSSProperties;
    modifyTitle: React.CSSProperties;
    modifySubTitle: React.CSSProperties;
    modifyVehicleName: React.CSSProperties;
    modifyTourFocus: React.CSSProperties;
    earlyVehicleName: React.CSSProperties;
    modifyDate: React.CSSProperties;
    earlyDate: React.CSSProperties;
    modifyCancelUpdate: React.CSSProperties;
    // Reservation Flow - Calendar
    calendarOneToOneTitle: React.CSSProperties;
    calendarVehicleName: React.CSSProperties;
    calendarAddToCalendar: React.CSSProperties;
    calendarDates: React.CSSProperties;
    calendarSocialName: React.CSSProperties;
    vehicleSelectorTopicType: React.CSSProperties;
    // TODO: NEW TYPOGRAPHY FOR FORMS
    // formTitle: React.CSSProperties;
    // formBody: React.CSSProperties;
    // formPrivacy: React.CSSProperties;
    // formErrorMessageTitle: React.CSSProperties;
    // formErrorMesssageText: React.CSSProperties;
    // legendLabel: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h3?: React.CSSProperties;
    body3?: React.CSSProperties;
    link3?: React.CSSProperties;
    // Toast
    toastHeadline?: React.CSSProperties;
    toastBody?: React.CSSProperties;
    // Lounges (1:1 and Group)
    queueNumber?: React.CSSProperties;
    queueText?: React.CSSProperties;
    queueTextBlack?: React.CSSProperties;
    loungeEstimate?: React.CSSProperties;
    loungeTitle?: React.CSSProperties;
    // Lounges (V2)
    loungeWaitingTitle?: React.CSSProperties;
    loungeWaitingWhileYouWait?: React.CSSProperties;
    loungeWaitingYourTourWillBe?: React.CSSProperties;
    loungeWaitingModel?: React.CSSProperties;
    loungeWaitingChangeLink?: React.CSSProperties;
    loungeWaitingBackFrontLine?: React.CSSProperties;
    loungeWaitingSecondsRemaining?: React.CSSProperties;
    loungeWaitingHowItWorks?: React.CSSProperties;
    loungeWaitingPreview?: React.CSSProperties;
    loungeWaitingDealership?: React.CSSProperties;
    // Lounges - How It Works
    loungeInfoTitle?: React.CSSProperties;
    loungeInfoBody?: React.CSSProperties;
    loungeInfoFootnote?: React.CSSProperties;
    // Session
    liveText?: React.CSSProperties;
    iconTextLink?: React.CSSProperties;
    sessionMenuIconText?: React.CSSProperties;
    streamPause?: React.CSSProperties;
    // Session - Vehicle Features
    vehicleFeatureDropDown?: React.CSSProperties;
    vehicleFeatureCategory?: React.CSSProperties;
    vehicleFeatureTitle?: React.CSSProperties;
    vehicleFeatureCaption?: React.CSSProperties;
    vehicleFeatureBody?: React.CSSProperties;
    vehicleFeatureDisclaimer?: React.CSSProperties;
    // Session - Vehicle Selector
    vehicleSelectModel?: React.CSSProperties;
    vehicleSelectModelAlt?: React.CSSProperties;
    vehicleSelectFootnote?: React.CSSProperties;
    // Session - Chat
    messageText?: React.CSSProperties;
    messageTextAdmin?: React.CSSProperties;
    closedCaptionText?: React.CSSProperties;
    closedCaptionTextBold: React.CSSProperties;
    // Reservation (Reservation, Group Reservation)
    timeTitle?: React.CSSProperties;
    timeDateTitle?: React.CSSProperties;
    legend?: React.CSSProperties;
    yourInformation?: React.CSSProperties;
    // Thank you
    thankYouHeader?: React.CSSProperties;
    thankYouInputTitle?: React.CSSProperties;
    characterCount?: React.CSSProperties;
    RateYourExperience?: React.CSSProperties;
    // Stay Updated
    formBody?: React.CSSProperties;
    vehicleTypeHeading?: React.CSSProperties;
    vehicleSelectHeading?: React.CSSProperties;
    // Error Messages
    errorTitle?: React.CSSProperties;
    // Modal(s) and Reservation
    modalTitle?: React.CSSProperties;
    modalSubTextAlt?: React.CSSProperties;
    modalSubTitle?: React.CSSProperties;
    modalSubText?: React.CSSProperties;
    modalFootnote?: React.CSSProperties;
    modalConsentText?: React.CSSProperties;
    // Modal - Shareable
    shareableHyperTitle?: React.CSSProperties;
    shareableHyperLinksLink?: React.CSSProperties;
    // Modal - Need Help
    needHelpTitle?: React.CSSProperties;
    needHelpBody?: React.CSSProperties;
    // Modal Troubleshoot
    troubleshootQuestion?: React.CSSProperties;
    troubleshootAnswer?: React.CSSProperties;
    troubleshootHeading?: React.CSSProperties;
    troubleshootDT?: React.CSSProperties;
    troubleshootDD?: React.CSSProperties;
    questionHeading?: React.CSSProperties;
    questionAnswer?: React.CSSProperties;
    questionListItem?: React.CSSProperties;
    // Modal - Vehicle / Topic Selector
    vehicleSelectorLoungeHeader?: React.CSSProperties;
    vehicleLoungeImageName?: React.CSSProperties;
    vehicleVideoTitle?: React.CSSProperties;
    vehicleSelectorLoungeSubtitle?: React.CSSProperties;
    vehicleSelectorLoungeDropdownSubtitle?: React.CSSProperties;
    vehicleTypeHeadingSelectorModal?: React.CSSProperties;
    vehicleSelectorTopicName?: React.CSSProperties;
    vehicleSelectorTopicCat?: React.CSSProperties;
    vehicleSelectorTopicDesc?: React.CSSProperties;
    // Hours of Operations
    hoursOfOperation?: React.CSSProperties;
    hoursOfOperationHeading?: React.CSSProperties;
    // Missed Group Tour
    missedGroupTourHeaderTitle?: React.CSSProperties;
    missedGroupTourHeaderBody?: React.CSSProperties;
    // Reservation Flow - Modify Reservation
    modifyUserName?: React.CSSProperties;
    yourEarly?: React.CSSProperties;
    modifyTitle?: React.CSSProperties;
    modifySubTitle?: React.CSSProperties;
    modifyVehicleName?: React.CSSProperties;
    modifyTourFocus?: React.CSSProperties;
    earlyVehicleName?: React.CSSProperties;
    modifyDate?: React.CSSProperties;
    earlyDate?: React.CSSProperties;
    modifyCancelUpdate?: React.CSSProperties;
    // Reservation Flow - Calendar
    calendarOneToOneTitle?: React.CSSProperties;
    calendarVehicleName?: React.CSSProperties;
    calendarAddToCalendar?: React.CSSProperties;
    calendarDates?: React.CSSProperties;
    calendarSocialName?: React.CSSProperties;
    vehicleSelectorTopicType?: React.CSSProperties;
    // TODO: NEW TYPOGRAPHY FOR FORMS
    // formTitle?: React.CSSProperties;
    // formBody?: React.CSSProperties;
    // formPrivacy?: React.CSSProperties;
    // formErrorMessageTitle?: React.CSSProperties;
    // formErrorMesssageText?: React.CSSProperties;
    // legendLabel?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h3: true;
    body3: true;
    link3: true;
    // Toast
    toastHeadline: true;
    toastBody: true;
    // Lounges (1:1 and Group)
    queueNumber?: true;
    queueText: true;
    queueTextBlack: true;
    loungeEstimate: true;
    loungeTitle: true;
    // Lounges (V2)
    loungeWaitingTitle: true;
    loungeWaitingWhileYouWait: true;
    loungeWaitingYourTourWillBe: true;
    loungeWaitingModel: true;
    loungeWaitingChangeLink: true;
    loungeWaitingBackFrontLine: true;
    loungeWaitingSecondsRemaining: true;
    loungeWaitingHowItWorks: true;
    loungeWaitingPreview: true;
    loungeWaitingDealership: true;
    // Lounges - How It Works
    loungeInfoTitle: true;
    loungeInfoBody: true;
    loungeInfoFootnote: true;
    // Session
    liveText: true;
    iconTextLink: true;
    sessionMenuIconText: true;
    streamPause: true;
    // Session - Vehicle Features
    vehicleFeatureDropDown: true;
    vehicleFeatureCategory: true;
    vehicleFeatureTitle: true;
    vehicleFeatureCaption: true;
    vehicleFeatureBody: true;
    vehicleFeatureDisclaimer: true;
    // Session - Vehicle Selector
    vehicleSelectModel: true;
    vehicleSelectModelAlt: true;
    vehicleSelectFootnote: true;
    // Session - Chat
    messageText: true;
    messageTextAdmin: true;
    closedCaptionText: true;
    closedCaptionTextBold: true;
    // Reservation (Reservation, Group Reservation)
    timeTitle: true;
    timeDateTitle: true;
    legend: true;
    yourInformation: true;
    // Thank you
    thankYouHeader: true;
    thankYouInputTitle: true;
    characterCount: true;
    RateYourExperience: true;
    // Stay Updated
    formBody: true;
    vehicleTypeHeading: true;
    vehicleSelectHeading: true;
    // Error Messages
    errorTitle: true;
    // Modal(s) and Reservation
    modalTitle: true;
    modalSubTextAlt: true;
    modalSubTitle: true;
    modalSubText: true;
    modalFootnote: true;
    modalConsentText: true;
    // Modal - Shareable
    shareableHyperTitle: true;
    shareableHyperLinksLink: true;
    // Modal - Need Help
    needHelpTitle: true;
    needHelpBody: true;
    // Modal Troubleshoot
    troubleshootQuestion: true;
    troubleshootAnswer: true;
    troubleshootHeading: true;
    troubleshootDT: true;
    troubleshootDD: true;
    questionHeading: true;
    questionAnswer: true;
    questionListItem: true;
    // Modal - Vehicle / Topic Selector
    vehicleSelectorLoungeHeader: true;
    vehicleLoungeImageName: true;
    vehicleVideoTitle: true;
    vehicleSelectorLoungeSubtitle: true;
    vehicleSelectorLoungeDropdownSubtitle: true;
    vehicleTypeHeadingSelectorModal: true;
    vehicleSelectorTopicName: true;
    vehicleSelectorTopicCat: true;
    vehicleSelectorTopicDesc: true;
    // Hours of Operations
    hoursOfOperation: true;
    hoursOfOperationHeading: true;
    // Missed Group Tour
    missedGroupTourHeaderTitle: true;
    missedGroupTourHeaderBody: true;
    // Reservation Flow - Modify Reservation
    modifyUserName: true;
    yourEarly: true;
    modifyTitle: true;
    modifySubTitle: true;
    modifyVehicleName: true;
    modifyTourFocus: true;
    earlyVehicleName: true;
    modifyDate: true;
    earlyDate: true;
    modifyCancelUpdate: true;
    // Reservation Flow - Calendar
    calendarOneToOneTitle: true;
    calendarVehicleName: true;
    calendarAddToCalendar: true;
    calendarDates: true;
    calendarSocialName: true;
    vehicleSelectorTopicType: true;
    // TODO: NEW TYPOGRAPHY FOR FORMS
    // formTitle: true;
    // formBody: true;
    // formPrivacy: true;
    // formErrorMessageTitle: true;
    // formErrorMesssageText: true;
    // legendLabel: true;
  }
}

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const themesOptions: any = {
  [themes.light]: lightTheme,
  [themes.dark]: darkTheme,
  [themes.chevy]: chevyTheme,
  [themes.cadillac]: cadillacTheme,
  [themes.cadillaccan]: cadillacCanadaTheme,
  [themes.gmc]: gmcTheme,
  [themes.buick]: buickTheme,
  [themes.ev]: evliveTheme,
  [themes.evcan]: evliveCanadaTheme,
  [themes.energy]: energyTheme,
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[themes.light];
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      },
    ),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
