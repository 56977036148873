import { getIsIpad } from "./getIsIpad";

export const getIsMobile = () => {
  const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
  const isIpad = getIsIpad();

  return isMobileDevice || isIpad;
};
