import { UserInfo } from 'firebase/auth';
import { fetchJson } from './fetchJson';

class UserRepository {
  create = async (user: Partial<UserInfo>): Promise<UserInfo> => {


    const body = {
      user
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/createUser`, body);

    // const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/createUser`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     user,
    //   }),
    // });

    // return response.json();
  };

  getIP = async (): Promise<{ ip: string; blockedUser: boolean }> => {



    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/getUsersIP`);

    // const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUsersIP`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    // });

    // return response.json();
  };

  generateToken = async (uid: string): Promise<{ token: string }> => {

    const body = {
      uid
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/generateToken`, body);

    // const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generateToken`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     uid,
    //   }),
    // });

    // return response.json();
  };
}

export const userRepository = new UserRepository();
