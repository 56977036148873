import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LanguageCode, TransferRequest } from '@types';
import { useHost } from '@hooks';
import ModalIconButton from './ModalIconButton';

type TransferNowModalProps = {
  brandId: string;
  transferRequest: TransferRequest;
  onClose?: () => void;
  onRedirect?: () => void;
};

const TransferNowModal = (props: TransferNowModalProps) => {
  const { onClose, onRedirect, transferRequest, brandId } = props;
  const { t } = useTranslation();
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isEnergy = brandId.toLowerCase() === 'energy';
  const { host } = useHost(transferRequest.language as LanguageCode);

  const getTransferLink = () => {
    let link = `${transferRequest.transferHost}${transferRequest.transferPath}`;
    if (transferRequest.brand === transferRequest.fromBrand) {
      link = `${host}${transferRequest.transferPath}`;
    }

    return link;
  };

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
        />
      )}
      <Box className="modal-container">
        {!isEnergy && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
          />
        )}
        <Box className="modal-text-container">
          <Typography
            component="h2"
            variant="modalTitle"
          >
            {t('Would you like to transfer now?')}
          </Typography>

          {isGMC && <Box className="modal-border" />}

          <Typography
            component="p"
            variant="modalSubText"
            className="transfer-now-sub-text"
          >
            {t('Once you exit, your session will end. If you change your mind, you will need to join a new tour.')}
          </Typography>
        </Box>
        {transferRequest && (
          <Box
            onClick={onRedirect}
            className="modal-container-buttons transfer-container-buttons"
          >
            <a 
            className="modal-button-link"
            target="_parent"
            href={getTransferLink()}
            >
              <Button
                className="stat-button-link"
                data-dtm="transfer now modal"
                variant="primary"
                type="button"
                disableRipple
                aria-label="Transfer Now"
              >
                {t('Transfer Now')}
              </Button>
            </a>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TransferNowModal;
