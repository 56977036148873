import { Box, Typography, Button } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalIconButton } from '@components';

type ScreenSharingConsentModalProps = {
  brandId: string;
  onClose?: () => void;
  onSubmit?: () => void;
};

const ScreenSharingConsentModal = (props: ScreenSharingConsentModalProps): ReactElement => {
  const { brandId, onClose, onSubmit } = props;
  const { t } = useTranslation();
  // const isGMC = brandId.toLowerCase() === 'gmc';
  const isEnergy = brandId.toLowerCase() === 'energy';

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
          dataDTM="modal:share screen"
        />
      )}
      <Box className="modal-container">
        {!isEnergy && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
            dataDTM="screen sharing consent modal"
          />
        )}
        <Box className="modal-text-container">
          <Typography variant="modalTitle" component="h3">
            {t('Share my screen')}
          </Typography>
          {/* {isGMC && <Box className="modal-border" />} */}

          <Typography
            variant="modalSubText"
            component="p"
            sx={{
              pl: '30px',
              pr: '30px',
            }}
          >
            {t(
              'You are about to share your screen with our Home Energy Specialist to help us answer any questions as you navigate our site. Be sure to hide any information that you may not want to share.',
            )}
          </Typography>
          <Typography variant="modalFootnote" component="p" sx={{ mt: '10px' }}>
            {t('We recommend using Chrome for an optimal experience.')}
          </Typography>
        </Box>

        <Box className="modal-container-buttons">
          <Button
            className="stat-button-link"
            data-dtm="modal:share screen"
            onClick={onClose}
            variant="tertiary"
            type="button"
            disableRipple
            aria-label="Don't share screen"
          >
            {t("no, thanks")}
          </Button>

          <Button
            className="stat-button-link"
            data-dtm="modal:share screen"
            onClick={onSubmit}
            variant="primary"
            type="button"
            disableRipple
            aria-label="Share screen"
          >
            {t('yes, share my screen')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ScreenSharingConsentModal;
