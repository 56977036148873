import { Box, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Button, Typography } from '@mui/material';
import { ReactElement } from 'react';
import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import ModalIconButton from './ModalIconButton';
import { useTheme } from '@material-ui/core/styles';
import { getIsMobile } from '@utils';

interface NeedHelp {
  id: number;
  question: string;
  answer: string;
  footNote?: string;
}

type NeedHelpProps = {
  onClose?: () => void;
  brandId: string;
};

const NeedHelpModal = (props: NeedHelpProps): ReactElement => {
  const { onClose, brandId } = props;
  const { t } = useTranslation();
  let needHelpData: NeedHelp[];
  let needHelpDataMobile: NeedHelp[];
  const theme = useTheme();
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';
  const isChevy = brandId.toLowerCase() === 'chevy';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev' || brandId.toLowerCase() === 'evcan';
  const isEnergy = brandId.toLowerCase() === 'energy';

  const isMobile = getIsMobile();

  if (isCadillacUS || isCadillacCanada) {
    needHelpData = [
      {
        id: 0,
        question: 'What is Cadillac Live?',
        answer: 'Cadillac Live is a digital showroom that allows you to connect with a Cadillac Live agent in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a dealer, if you wish. All from the comfort of your home or office.',
      },
      {
        id: 1,
        question: 'Is my session going to be recorded?',
        answer: 'No, your session will not be recorded.',
      },
      {
        id: 2,
        question: 'How do I book a session?',
        answer: 'Please visit https://live.cadillaccanada.ca/make-reservation and we will make arrangements.',
      },
      {
        id: 3,
        question: 'How do I test drive a vehicle?',
        answer: 'Following your tour of the showroom, our Live agent will be happy to assist you in setting up at a time for a test drive with your preferred dealer.',
      },
      {
        id: 4,
        question: 'How long are wait times?',
        answer: 'Wait times may vary but we strive to keep them to a minimum. ',
      },
      {
        id: 5,
        question: 'I require special assistance.',
        answer: 'Please contact<br/>CADILLAC CUSTOMER CARE CENTRE:<br/>At 1-888-446-2000 (English, French)<br/>Or 1-800-263-3830 (TDD users) and we will happily try to accommodate your needs.',
      },
      {
        id: 6,
        question: 'Can the agent see me?',
        answer: 'No, you will only see the agent and the showroom from their perspective.',
      },
      {
        id: 7,
        question: 'I have another question or comment.',
        answer: 'Please contact CADILLAC CUSTOMER CARE CENTRE: At 1-888-446-2000 (English, French) Or 1-800-263-3830 (TDD users) and we will try to answer any of your concerns or questions.',
      },
      {
        id: 8,
        question: 'Will my information be shared with a third-party?',
        answer: 'No.',
      },
      {
        id: 9,
        question: 'How long is a session?',
        answer: 'You can end a session whenever you prefer. You are not committed to a pre-determined time.',
      },
      {
        id: 10,
        question: 'What are my obligations after a session?',
        answer: 'Following your session there are no obligations. You can choose to arrange to see a dealer for a test drive, but only if you wish.',
      },
      {
        id: 11,
        question: 'By signing up will I end up on a mailing list?',
        answer: 'No, unless you choose otherwise you will not receive additional communications from Cadillac.',
      },
      {
        id: 12,
        question: 'How much does a tour cost?',
        answer: 'Your experience with Cadillac Live is entirely complimentary and free of obligations.',
      },
      {
        id: 13,
        question: 'Is Cadillac Live accessible to everyone in Canada?',
        answer: 'Yes, anyone in Canada with a compatible electronic device is invited to experience Cadillac Live.',
      },
      {
        id: 14,
        question: 'What are the minimum system requirements and supported browsers?',
        answer: `
          SUPPORTED BROWSERS<br/>
          Google Chrome (latest release version)<br/>
          Google Chrome for Android (latest release version)<br/>
          Firefox (latest release version)<br/>
          Firefox for Android (latest release version)<br/>
          Microsoft Edge — version 79+<br/>
          Safari 11<br/>
          iOS 11<br/>
          Opera (latest release version)
          <br/>
          <br/>
          BANDWIDTH REQUIREMENTS
          <ul>
            <li>Video: 300 kbps per stream (recommended lowest level)</li>
            <li>Audio: 50 kbps per stream (recommended lowest level)</li>
          </ul>
          iOS<br/>
          Operating system<br/>
          <ul>
            <li>iOS 9 or higher</li>
          </ul>
          Hardware<br/>
          <ul>
            <li>iPhone X/XR/XS</li>
            <li>iPhone 8/8+</li>
            <li>iPhone 7/7+</li>
            <li>iPhone SE</li>
            <li>iPhone 6/6+/6S/6S+</li>
            <li>iPhone SE</li>
            <li>iPhone 5S</li>

            <li>iPad Pro 1st/2nd generation</li>
            <li>iPad (2017)</li>
            <li>iPad Air/Air 2</li>
            <li>iPad 4th generation</li>
            <li>iPad mini 2/3/4</li>

            <li>iPod</li>
            <li>iPod touch 6th generation</li>
          </ul>
          ANDROID<br/>
          Operating system<br/>
          <ul>
            <li>Any Android 4.1+ device (Jelly Bean, API Level 16)</li>
          </ul>
          Hardware<br/>
          <ul>
            <li>1.2 GHz quad-core ARM Cortex-A7 or better</li>
            <li>1 GB RAM (ex. Samsung Galaxy S3 - 2012)</li>
          </ul>
        `,
      },
    ];
  } else if (isChevy) {
    needHelpData = [
      {
        id: 0,
        question: t('What is Chevy MyWay?'),
        answer:
          t('Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet Product Specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a dealer all from the comfort of your home or office.'),
      },
      {
        id: 1,
        question: t('Is my tour going to be recorded?'),
        answer: t('No, your tour will not be recorded.'),
      },
      {
        id: 2,
        question: t('How do I book a tour?'),
        answer: t('Please visit chevrolet.com/myway/reservation and we will make arrangements.'),
      },
      {
        id: 3,
        question: t('How do I test drive a vehicle?'),
        answer:
          t('Following your tour of the showroom, our Product Specialist will be happy to assist you in setting up at a time for a test drive with your preferred dealer'),
      },
      {
        id: 4,
        question: t('How long are wait times?'),
        answer: t('Wait times may vary but we strive to keep them to a minimum.'),
      },
      {
        id: 5,
        question: t('I require special assistance.'),
        answer: t('Please contact the Chevrolet Customer Care center at 1-800-950-CHEV'),
      },
      {
        id: 6,
        question: t('Can the agent see me?'),
        answer: t('No, you will only see the agent and the showroom from their perspective.'),
      },
      {
        id: 7,
        question: t('I have another question or comment.'),
        answer: t('Please contact the Chevrolet Customer Care center at 1-800-950-CHEV'),
      },
      {
        id: 8,
        question: t('Will my information be shared with a third-party?'),
        answer: t('No.'),
      },
      {
        id: 9,
        question: t('How long is a tour?'),
        answer: t('You can end a tour whenever you prefer. You are not committed to a pre-determined time.')
      },
      {
        id: 10,
        question: t('What are my obligations after a tour?'),
        answer:
          t('Following your tour there are no obligations. You can choose to arrange to see a dealer for a test drive, but only if you wish.'),
      },
      {
        id: 11,
        question: t('By signing up will I end up on a mailing list?'),
        answer:
          t('No, unless you choose otherwise, you will not receive additional communications from Chevrolet.'),
      },
      {
        id: 12,
        question: t('How much does a tour cost?'),
        answer: t('Your experience with Chevy MyWay is entirely complimentary and free of obligations.')
      },
      {
        id: 13,
        question: t('Is Chevy MyWay available to everyone in the United States?'),
        answer: t('Yes, anyone over 18 in the United States is invited to experience Chevy MyWay.'),
      },
    ];
  } else if (isGMC) {
    needHelpData = [
      {
        id: 0,
        question: t('What is Chevy MyWay?'),
        answer:
          t('Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet Product Specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a dealer all from the comfort of your home or office.'),
      },
      {
        id: 1,
        question: t('Is my tour going to be recorded?'),
        answer: t('No, your tour will not be recorded.'),
      },
      {
        id: 2,
        question: t('How do I book a tour?'),
        answer: t('Please visit chevrolet.com/myway/reservation and we will make arrangements.'),
      },
      {
        id: 3,
        question: t('How do I test drive a vehicle?'),
        answer:
          t('Following your tour of the showroom, our Product Specialist will be happy to assist you in setting up at a time for a test drive with your preferred dealer'),
      },
      {
        id: 4,
        question: t('How long are wait times?'),
        answer: t('Wait times may vary but we strive to keep them to a minimum.'),
      },
      {
        id: 5,
        question: t('I require special assistance.'),
        answer: t('Please contact the Chevrolet Customer Care center at 1-800-950-CHEV'),
      },
      {
        id: 6,
        question: t('Can the agent see me?'),
        answer: t('No, you will only see the agent and the showroom from their perspective.'),
      },
      {
        id: 7,
        question: t('I have another question or comment.'),
        answer: t('Please contact the Chevrolet Customer Care center at 1-800-950-CHEV'),
      },
      {
        id: 8,
        question: t('Will my information be shared with a third-party?'),
        answer: t('No.'),
      },
      {
        id: 9,
        question: t('How long is a tour?'),
        answer: t('You can end a tour whenever you prefer. You are not committed to a pre-determined time.')
      },
      {
        id: 10,
        question: t('What are my obligations after a tour?'),
        answer:
          t('Following your tour there are no obligations. You can choose to arrange to see a dealer for a test drive, but only if you wish.'),
      },
      {
        id: 11,
        question: t('By signing up will I end up on a mailing list?'),
        answer:
          t('No, unless you choose otherwise, you will not receive additional communications from Chevrolet.'),
      },
      {
        id: 12,
        question: t('How much does a tour cost?'),
        answer: t('Your experience with Chevy MyWay is entirely complimentary and free of obligations.')
      },
      {
        id: 13,
        question: t('Is Chevy MyWay available to everyone in the United States?'),
        answer: t('Yes, anyone over 18 in the United States is invited to experience Chevy MyWay.'),
      },
    ];
  } else if (isBuick) {
    needHelpData = [
      {
        id: 0,
        question: t('What is Chevy MyWay?'),
        answer:
          t('Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet Product Specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a dealer all from the comfort of your home or office.'),
      },
      {
        id: 1,
        question: t('Is my tour going to be recorded?'),
        answer: t('No, your tour will not be recorded.'),
      },
      {
        id: 2,
        question: t('How do I book a tour?'),
        answer: t('Please visit chevrolet.com/myway/reservation and we will make arrangements.'),
      },
      {
        id: 3,
        question: t('How do I test drive a vehicle?'),
        answer:
          t('Following your tour of the showroom, our Product Specialist will be happy to assist you in setting up at a time for a test drive with your preferred dealer'),
      },
      {
        id: 4,
        question: t('How long are wait times?'),
        answer: t('Wait times may vary but we strive to keep them to a minimum.'),
      },
      {
        id: 5,
        question: t('I require special assistance.'),
        answer: t('Please contact the Chevrolet Customer Care center at 1-800-950-CHEV'),
      },
      {
        id: 6,
        question: t('Can the agent see me?'),
        answer: t('No, you will only see the agent and the showroom from their perspective.'),
      },
      {
        id: 7,
        question: t('I have another question or comment.'),
        answer: t('Please contact the Chevrolet Customer Care center at 1-800-950-CHEV'),
      },
      {
        id: 8,
        question: t('Will my information be shared with a third-party?'),
        answer: t('No.'),
      },
      {
        id: 9,
        question: t('How long is a tour?'),
        answer: t('You can end a tour whenever you prefer. You are not committed to a pre-determined time.')
      },
      {
        id: 10,
        question: t('What are my obligations after a tour?'),
        answer:
          t('Following your tour there are no obligations. You can choose to arrange to see a dealer for a test drive, but only if you wish.'),
      },
      {
        id: 11,
        question: t('By signing up will I end up on a mailing list?'),
        answer:
          t('No, unless you choose otherwise, you will not receive additional communications from Chevrolet.'),
      },
      {
        id: 12,
        question: t('How much does a tour cost?'),
        answer: t('Your experience with Chevy MyWay is entirely complimentary and free of obligations.')
      },
      {
        id: 13,
        question: t('Is Chevy MyWay available to everyone in the United States?'),
        answer: t('Yes, anyone over 18 in the United States is invited to experience Chevy MyWay.'),
      },
    ];
  } else if (isEVLive) {
    needHelpData = [
      {
        id: 0,
        question: t('What is Chevy MyWay?'),
        answer:
          t('Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet Product Specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a dealer all from the comfort of your home or office.'),
      },
      {
        id: 1,
        question: t('Is my tour going to be recorded?'),
        answer: t('No, your tour will not be recorded.'),
      },
      {
        id: 2,
        question: t('How do I book a tour?'),
        answer: t('Please visit chevrolet.com/myway/reservation and we will make arrangements.'),
      },
      {
        id: 3,
        question: t('How do I test drive a vehicle?'),
        answer:
          t('Following your tour of the showroom, our Product Specialist will be happy to assist you in setting up at a time for a test drive with your preferred dealer'),
      },
      {
        id: 4,
        question: t('How long are wait times?'),
        answer: t('Wait times may vary but we strive to keep them to a minimum.'),
      },
      {
        id: 5,
        question: t('I require special assistance.'),
        answer: t('Please contact the Chevrolet Customer Care center at 1-800-950-CHEV'),
      },
      {
        id: 6,
        question: t('Can the agent see me?'),
        answer: t('No, you will only see the agent and the showroom from their perspective.'),
      },
      {
        id: 7,
        question: t('I have another question or comment.'),
        answer: t('Please contact the Chevrolet Customer Care center at 1-800-950-CHEV'),
      },
      {
        id: 8,
        question: t('Will my information be shared with a third-party?'),
        answer: t('No.'),
      },
      {
        id: 9,
        question: t('How long is a tour?'),
        answer: t('You can end a tour whenever you prefer. You are not committed to a pre-determined time.')
      },
      {
        id: 10,
        question: t('What are my obligations after a tour?'),
        answer:
          t('Following your tour there are no obligations. You can choose to arrange to see a dealer for a test drive, but only if you wish.'),
      },
      {
        id: 11,
        question: t('By signing up will I end up on a mailing list?'),
        answer:
          t('No, unless you choose otherwise, you will not receive additional communications from Chevrolet.'),
      },
      {
        id: 12,
        question: t('How much does a tour cost?'),
        answer: t('Your experience with Chevy MyWay is entirely complimentary and free of obligations.')
      },
      {
        id: 13,
        question: t('Is Chevy MyWay available to everyone in the United States?'),
        answer: t('Yes, anyone over 18 in the United States is invited to experience Chevy MyWay.'),
      },
    ];
  } else if (isEnergy) {
    needHelpData = [
      {
        id: 0,
        question: t('Screen Sharing Permission'),
        answer:
          t("To start screen sharing, please make sure you've granted the necessary screen sharing permissions on your device."),
        footNote: t("We recommend using Chrome for an optimal experience."),
      },
      {
        id: 1,
        question: t('Freezing Screen or Unable to See Shared Content'),
        answer:
          t("If your screen is frozen or you are unable to see shared content, try refreshing your web browser or rebooting your device. Be sure to hide any information that you may not want to share."),
      },
    ];
  }

  if(isEnergy) {
    needHelpDataMobile = [
      {
        id: 0,
        question: t('Camera permission'),
        answer: 
          t("To start two-way camera, please make sure you've granted the necessary camera permissions on your device"),
      },
      {
        id: 1,
        question: t('Freezing screen'),
        answer:
          t("If your screen is frozen try refreshing your web browser or rebooting your device."),
      },
    ];
  }

  return (
    <>
      {isEnergy && <ModalIconButton onClose={onClose} brandId={brandId} dataDTM="modal:troubleshooting guide" />}
      <Box className="modal-container">
        {!isEnergy && <ModalIconButton onClose={onClose} brandId={brandId} dataDTM="modal:troubleshooting guide" />}

        <Typography variant="modalTitle" component="h2">
          {isEnergy ? t('Troubleshooting Guide') : t('Need Help?')}
        </Typography>
        {!isEnergy && (
          <Box>
            {needHelpData &&
              needHelpData.map((needHelp) => (
                <Accordion key={needHelp.id} elevation={0} disableGutters>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expandIcon={
                      // <RemoveIcon />
                      <AddIcon />
                    }
                  >
                    <Typography variant="needHelpTitle">{needHelp.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="needHelpBody">
                      <div dangerouslySetInnerHTML={{ __html: needHelp.answer || '' }} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        )}
        {isEnergy && (
          <Box>
            {isMobile ? (
              <Box>
              {needHelpDataMobile &&
                needHelpDataMobile.map((needHelp) => (
                  <Box key={needHelp.id} sx={{ marginTop: '25px' }}>
                    <Typography variant="needHelpTitle">{needHelp.question}</Typography>
                    <Typography variant="needHelpBody">
                      <div dangerouslySetInnerHTML={{ __html: needHelp.answer || '' }} />
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box sx={{ lineHeight: '1.5' }}>
              {needHelpData &&
                needHelpData.map((needHelp) => (
                  <Box key={needHelp.id} sx={{ marginTop: '25px' }}>
                    <Typography variant="needHelpTitle">{needHelp.question}</Typography>
                    <Typography variant="needHelpBody">
                      <div dangerouslySetInnerHTML={{ __html: needHelp.answer || '' }} />
                    </Typography>
                    {needHelp.footNote && (
                      <Typography variant="needHelpBody" sx={{ fontSize: '12px' }}>
                        <div dangerouslySetInnerHTML={{ __html: needHelp.footNote || '' }} />
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={onClose}
                variant="primary"
                type="button"
                aria-label="Cancel form"
                className="stat-button-link"
                data-dtm="modal:troubleshooting guide"
                sx={{
                  [theme.breakpoints.up(768)]: {marginTop: '32px'},
                  [theme.breakpoints.down(768)]: {maxWidth: '100% !important', marginTop: '32px'}
                }}
              >
                {t('Close')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default NeedHelpModal;
