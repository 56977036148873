import { lightShadows } from './shadows';

export const lightTheme = {
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e',
          },
        },
      },
    },
  },
  palette: {
    action: {
      active: '#6b778c',
    },
    background: {
      default: '#f4f5f7',
      paper: '#ffffff',
    },
    error: {
      contrastText: '#ffffff',
      main: '#f44336',
    },
    mode: 'light',
    primary: {
      contrastText: '#ffffff',
      main: '#5664d2',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.07)',
    },
    success: {
      contrastText: '#ffffff',
      main: '#4caf50',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
    },
    warning: {
      contrastText: '#ffffff',
      main: '#ff9800',
    },
  },
  shadows: lightShadows,
};
