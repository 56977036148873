import { Grid } from '@material-ui/core';
import { alpha, styled } from '@mui/material/styles';
import { useQueryString } from '@hooks';

const GridWrapperStyles = (theme: any) => {
  const { urlSearchParams } = useQueryString();
  const brandId = urlSearchParams.get('brandId');
  const isCadillac = brandId === 'cadillac' || brandId === 'cadillaccan';
  const isCadillacCanada = brandId === 'cadillaccan';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId === 'buick';
  const isEVLive = brandId === 'ev' || brandId === 'evcan';
  const isEnergy = brandId === 'energy';

  let gridWrapperStyles = {};
  const gridWrapperCadillacStyles = {
    '&.select-time-container.select-time-container--group': {
      paddingBottom: '10px',
      '.MuiRadio-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiFormControlLabel-root': {
        '& .MuiTypography-root': {
          paddingTop: '1.5px',
        },
      },
      '.cadillac-check-icon': {
        top: '-3px',
      },
    },
    '&#tourDate': {
      '& .MuiGrid-root': {
        '& .MuiTypography-root': {
          fontFamily: 'CadillacGothic-Bold',
          color: theme.palette.primary.main,
        },
      },
    },
    '&#ownershipType, &#offerType, &#legendaryTruckOwner, &#rewardsInformation': {
      '& .MuiFormControlLabel-root': {
        '& .MuiTypography-root': {
          fontWeight: 'normal',
          fontFamily: 'CadillacGothic-Regular',
          color: theme.palette.primary.main,
        },
        '& .MuiRadio-root': {
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
    '&#vehicleId, &#topicId': {
      '& .MuiOutlinedInput-input': {
        padding: '0 0 10px 0',
        width: 'calc(100% - 14px)',
      },
    },
    '&#purchaseDate, &#vehicleIdModal, &#dealershipId, &#productOfInterest': {
      '& .MuiOutlinedInput-input': {
        padding: '0',
        width: 'calc(100% - 10px)',
      },
      '& .MuiInputBase-root': {
        padding: '7px',
      },
      '& .MuiSelect-select': {
        paddingLeft: '10px !Important',
      },
    },
    '.calendar-icon-cadillac': {
      top: 4,
      right: 2,
      height: '30px',
      backgroundColor: theme.palette.grey[800],
    },
    '.text-field-vehicle-modal': {
      '& .MuiOutlinedInput-input': {
        fontFamily: 'CadillacGothic-Medium',
      },
    },
    '&#microphoneSelection': {
      width: '222px',
      '& .MuiInputBase-root': {
        padding: 0,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '11px !important',
      },
    },
  };
  const gridWrapperCadillacCanadaStyles = {
    ...gridWrapperCadillacStyles,
    // '&#tourDate': {
    //   '& .MuiGrid-root': {
    //     '& .MuiTypography-root': {
    //       fontFamily: 'CadillacGothic-Bold',
    //       color: theme.palette.grey[800],
    //     },
    //   },
    // },
    // '.calendar-icon-cadillac': {
    //   top: 4,
    //   right: 2,
    //   height: '30px',
    //   backgroundColor: theme.palette.grey[50],
    // },
  };
  const gridWrapperGMCStyles = {
    '&#vehicleId, &#topicId': {
      '& .MuiOutlinedInput-input': {
        padding: '0 0 10px 0',
        width: 'calc(100% - 14px)',
      },
      '& .MuiFormControlLabel-root': {
        borderBottom: 'none',
      },
      '.MuiFormGroup-root': {
        borderBottom: `2px solid ${theme.palette.grey[900]}`,
      },
      '.MuiFormControlLabel-root:first-of-type': {
        height: '53px',
        paddingTop: '13px',
      },
      '.MuiFormControlLabel-root:last-of-type': {
        height: '53px',
        paddingBottom: '13px',
      },
    },
    '&.select-time-container': {
      border: `2px solid ${theme.palette.grey[900]}`,
    },
    '.chevron-icon, .calendar-icon-gmc': {
      width: '54px',
      height: '54px',
      [theme.breakpoints.down(769)]: {
        width: '48px',
        height: '48px',
      },
    },
    '.chevron-icon': {
      backgroundColor: theme.palette.grey[900],
    },
    '&#tourDate': {
      '&.MuiGrid-root': {
        margin: '0 0 36px 0',
        '& .MuiTypography-root': {
          fontFamily: 'StratumGMC-Bold', // GL-1470
          // fontFamily: 'mulibold', // GL-1470 (remove)
          fontWeight: 'normal',
        },
        // Legend Labels // GL-1470
        '& .MuiTypography-body1': {
          fontSize: '14px',
        },
      },
      // GL-1470 (Remove)
      // '.calendar-icon-gmc': {
      //   top: '36px',
      //   [theme.breakpoints.down(768)]: {
      //     top: '30px',
      //   },
      // },
      // GL-1470
      '.calendar-icon-gmc': {
        top: '30px',
        [theme.breakpoints.down(768)]: {
          top: '31px',
        },
      },
      '& .MuiGrid-root': {
        borderLeft: `2px solid ${theme.palette.grey[900]}`,
        borderBottom: `2px solid ${theme.palette.grey[900]}`,
        borderRight: `2px solid ${theme.palette.grey[900]}`,
        backgroundColor: 'transparent',
        // GL-1470
        '& .MuiTypography-root': {
          letterSpacing: '1px',
        },
      },
    },
    '&.MuiGrid-item': {
      marginBottom: '26px',
      '@media screen and (min-width: 768px)': {
        marginBottom: '30px',
      },
    },
  };
  const gridWrapperBuickStyles = {
    '&#tourDate': {
      '&.MuiGrid-root': {
        margin: '0 0 30px 0',
      },
      '& .MuiGrid-root': {
        borderLeft: '1px solid rgba(153, 153, 153, 1)',
        borderBottom: '1px solid rgba(153, 153, 153, 1)',
        borderRight: '1px solid rgba(153, 153, 153, 1)',
        backgroundColor: 'transparent',
        '& .MuiTypography-root': {
          fontFamily: 'BuickFuturaND-Medium',
          fontWeight: 'normal',
          letterSpacing: '2px',
        },
      },
    },
    '.chevron-icon, .calendar-icon-gmc': {
      width: '52px',
      height: '52px',
      [theme.breakpoints.down(768)]: {
        width: '46px',
        height: '46px',
      },
    },
    '.chevron-icon': {
      backgroundColor: theme.palette.grey[800],
      backgroundSize: '20px 20px',
    },
    '&.select-time-container': {
      border: '1px solid rgba(153, 153, 153, 1)',
    },
    '&.MuiGrid-item': {
      marginBottom: '20px',
      '@media screen and (min-width: 768px)': {
        marginBottom: '30px',
      },
    },
  };
  const gridWrapperEVLiveStyles = {
    '&#date': {
      '& .MuiOutlinedInput-input': {
        padding: '0 0 10px 10px',
        width: 'calc(100% - 34px)',
      },
    },
    '.calendar-icon, .chevron-icon, .calendar-icon-gmc': {
      width: '69px',
      maxWidth: '69px',
      height: '40px',
      maxHeight: '40px',
      paddingTop: '5px',
      paddingLeft: '7px',
      backgroundColor: '#0072CE',
      [theme.breakpoints.down(768)]: {
        width: '69px',
        maxWidth: '69px',
        height: '40px',
        maxHeight: '40px',
      },
    },
    '.chevron-icon': {
      backgroundSize: '18px 25px',
    },
    '.calendar-icon': {
      '.MuiSvgIcon-root': {
        top: '10px',
        position: 'absolute',
        left: 0,
        right: 0,
        margin: 'auto',
      },
    },
    '&#tourDate': {
      '.calendar-icon': {
        top: '34px',
      },
      '& .MuiGrid-root': {
        backgroundColor: 'transparent',
        border: `1px solid ${alpha(theme.palette.grey[200], 1)}`,
        '& .MuiTypography-root': {
          fontFamily: 'Overpass',
          fontWeight: '500',
          letterSpacing: 0,
        },
        [theme.breakpoints.up('sm')]: {
          '& .MuiListItem-root': {
            width: '50%',
          },
        },
        [theme.breakpoints.up('md')]: {
          '& .MuiListItem-root': {
            width: '100%',
          },
        },
      },
    },
    '&.select-time-container': {
      border: `1px solid ${alpha(theme.palette.grey[200], 1)}`,
      '&::-webkit-scrollbar': {
        width: 15,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.background.formScrollTrack,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.background.formScrollThumb,
        borderRadius: '20px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: theme.palette.background.formScrollThumbHover,
      },
      '.MuiFormGroup-root': {
        borderBottom: 'none',
      },
    },
    '.MuiFormGroup-root': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    '&#microphoneSelection': {
      width: 194,
    },
  };
  const gridWrapperEnergyStyles = {
    '.chevron-icon': {
      height: '55px',
      backgroundSize: '15px 15px',
    },
    '.calendar-icon': {
      backgroundColor: 'transparent',
      backgroundImage: theme.palette.background.chevronClosedStateIcon,
      backgroundSize: '15px 15px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: '50px',
    },
    '.calendar-icon-disabled': {
      backgroundImage: theme.palette.background.chevronClosedDisabledStateIcon,
    },
    '&.select-time-container': {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: 0,
      '&--group': {
        // maxHeight: '270px',
        maxHeight: 'unset',
        overflowY: 'initial',
      },
    },
    '&.MuiGrid-item': {
      // marginBottom: '32px',
      marginBottom: '35px',
      [theme.breakpoints.down(768)]: {
        marginBottom: '16px',
      },
    },
    '#transferRequest': {
      paddingRight: '0px',
      paddingLeft: '0px',
    },
    '&#vehicleId, &#topicId, &#eventType, &#firstName, &#zipCode, &#email, &#orderNumber, &#tourDate, &#customerPhoneNumber':
    {
      paddingRight: '12px',
      [theme.breakpoints.down(768)]: {
        paddingRight: 0,
      },
    },
    '&#vehicleId, &#topicId, &#eventType, &#firstName, &#dateTransfer, &#lastNameTransfer, &#zipCode, &#confirmEmailTransfer, &#email, &#orderNumber, &#tourDate, &#customerPhoneNumber':
    {
      paddingRight: '12px',
      [theme.breakpoints.down(768)]: {
        paddingRight: 0,
      },
    },
    '&#date, &#eventId, &#lastName, &#phoneNumber, &#confirmEmail, &#vin, &#voucherNumber':
    {
      paddingLeft: '12px !important',
      [theme.breakpoints.down(768)]: {
        paddingLeft: '0px !important',
      },
    },
    '&#tourDate': {
      margin: 0,
      '& .MuiInputLabel-root': {
        marginBottom: '16px',
      },
      '& .calendar-icon': {
        top: '2px',
      },
      '& .MuiGrid-root': {
        backgroundColor: 'transparent',
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderRight: `1px solid ${theme.palette.primary.main}`,
        padding: '16px 16px 0px 16px',
      },
      '& .MuiTypography-body1': {
        fontFamily: 'Overpass !important',
        fontStyle: 'normal !important',
        fontWeight: '400 !important',
        color: `${theme.palette.primary.main} !important`,
      },
      '& .MuiListItem-root': {
        padding: 0,
      },
    },
    '.select-wrapper': {
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '& .MuiInputBase-root': {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
      '& .MuiSelect-filled': {
        width: 'initial',
      },
      '& .MuiOutlinedInput-root': {
        paddingTop: '0px !important',
        paddingBottom: 0,
      },
      '& .MuiSelect-select': {
        height: '30px',
        minHeight: '30px',
        paddingTop: '25px !important',
        paddingRight: '14px !important',
      },
    },
    '#phoneNumber': {
      '&.MuiGrid-item': {
        [theme.breakpoints.up(768)]: {
          marginBottom: '0px !important',
        },
      },
    },
    '.MuiFormHelperText-root.Mui-error': {
      '&:before': {
        backgroundImage: theme.palette.background.warningRedIcon,
      },
    },
    '&#eventId': {
      '& .MuiFormHelperText-root.Mui-error': {
        [theme.breakpoints.up(768)]: {
          position: 'relative !important',
          bottom: 0,
        },
      },
    },
    '&.consent-checkbox': {
      paddingTop: '12px !important',
      paddingBottom: '12px !important',
      marginTop: 16,
      marginBottom: 16,
      [theme.breakpoints.up(768)]: {
        marginTop: 32,
        marginBottom: 32,
      },
    },
    '&.readyOnlyField': {
      '.MuiFilledInput-root': {
        paddingBottom: '6px',
        backgroundColor: '#B3B3B3 !important',
        border: 'none !important',
      },
      '.MuiInputLabel-root, .MuiFilledInput-input': {
        color: '#ffffff !important',
      },
    },
    '& .mb-0': {
      marginBottom: '0px !important',
    },
    /**
     * Form header for (1:1, Group, Stay Updated, Dealer Referral and Powerclaim)
     **/
    '&.form-header--page': {
      paddingLeft: 0,
      '& .MuiTypography-modalTitle': {
        marginBottom: 32,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      '& .MuiTypography-modalSubText': {
        marginTop: 0,
        marginBottom: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        maxWidth: '100%',
        [theme.breakpoints.up(768)]: {
          maxWidth: '503px',
        },
      },
      '& .MuiTypography-modalSubText a': {
        fontWeight: 700,
        textDecoration: 'underline',
        color: theme.palette.color.privacyStatement,
      },
      // Apply this when theres two paragraph and assign this to the second paragraph
      '& .modalSubTextBottom': {
        maxWidth: '100%',
        marginTop: 0,
        marginBottom: 0,
        fontFamily: 'Overpass',
        fontSize: '17px !important',
        lineHeight: '20px',
        color: '#000000',
        [theme.breakpoints.up(768)]: {
          maxWidth: '451px',
        },
      },
      '& .modalSubText-StayUpdatedWidth': {
        [theme.breakpoints.up(768)]: {
          maxWidth: '451px',
        },
      },
      '& .modalSubText-DealerWidth': {
        marginBottom: 0,
        [theme.breakpoints.up(768)]: {
          maxWidth: '562px',
        },
      },
      '& .blue-text': {
        color: theme.palette.color.modalTitleBlueText,
      },
    },
    /**
     * Forms (1:1, Group, Stay Updated, Dealer Referral and Powerclaim)
     **/
    '&.form-button-container': {
      marginTop: 32,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiGrid-item': {
        marginBottom: 0,
      },
      '& .MuiButton-root': {
        height: '48px',
        maxWidth: '100%',
        [theme.breakpoints.up(768)]: {
          maxWidth: '315px',
        },
      },
      '& .submit-button': {},
      '& .no-thanks-button': {
        marginTop: 16,
        textDecoration: 'none',
      },
      '& .cancel-reservation-button': {
        marginTop: '16px',
        textDecoration: 'none',
        [theme.breakpoints.up(768)]: {
          marginTop: 0,
          marginRight: '16px',
        },
      },
    },
    '&.form-buttons-container': {
      width: '315px',
      [theme.breakpoints.down(768)]: {
        width: '100%',
      },
    },
    /**
     * Modify reservations (1:1 and Group)
     **/
    '&.form-button-container--modify': {
      '& .no-thanks-button': {
        [theme.breakpoints.up(768)]: {
          marginTop: 0,
        },
      },
      [theme.breakpoints.up(768)]: {
        alignItems: 'center',
      },
    },
    '&.form-buttons-container--modify': {
      width: '100%',
      flexDirection: 'column-reverse',
      [theme.breakpoints.up(768)]: {
        flexDirection: 'row',
        marginBottom: '32px',
        maxWidth: '646px',
      },
    },
  };

  if (isCadillac) {
    gridWrapperStyles = {
      ...gridWrapperCadillacStyles,
    };
  }

  if (isCadillacCanada) {
    gridWrapperStyles = {
      ...gridWrapperCadillacCanadaStyles,
    };
  }

  if (isGMC) {
    gridWrapperStyles = {
      ...gridWrapperGMCStyles,
    };
  }

  if (isBuick) {
    gridWrapperStyles = {
      ...gridWrapperBuickStyles,
    };
  }

  if (isEVLive) {
    gridWrapperStyles = {
      ...gridWrapperEVLiveStyles,
    };
  }

  if (isEnergy) {
    gridWrapperStyles = {
      ...gridWrapperEnergyStyles,
    };
  }

  const styles = {
    '.dropdown-icon, .minus-icon, .plus-icon, .calendar-icon': {
      position: 'absolute',
      top: '0',
      right: '0',
      width: '69px',
      height: '40px',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.grey[400],
      pointerEvents: 'none',
    },
    '.dropdown-icon-cadillac, .calendar-icon-cadillac, .minus-icon-cadillac, .plus-icon-cadillac, .minus-icon-cadillac-events, .plus-icon-cadillac-events, .chevron-icon':
    {
      position: 'absolute',
      top: '0',
      right: '0',
      width: '50px',
      height: '40px',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
    },
    '.minus-icon-cadillac-events, .plus-icon-cadillac-events': {
      top: '20px',
    },
    '.chevron-icon': {
      backgroundSize: '25px 25px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundOrigin: 'content-box',
    },
    '&.select-time-container': {
      maxHeight: '200px',
      overflowY: 'scroll',
      padding: '19px 16px',
      border: `1px solid ${alpha(theme.palette.grey[300], 1)}`,
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.background.formScrollTrack,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.background.formScrollThumb,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: theme.palette.background.formScrollThumbHover,
      },
    },
    '& .MuiNativeSelect-select': {
      paddingRight: '0 !important',
    },
    '&#tourDate': {
      margin: '0 0 36px auto',
      '.calendar-icon': {
        top: '27px',
      },
      [theme.breakpoints.down(768)]: {
        margin: '0 0 36px auto',
      },
      '& .MuiGrid-root': {
        padding: '10px 10px 0 10px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        borderLeft: `1px solid ${alpha(theme.palette.grey[300], 1)}`,
        borderBottom: `1px solid ${alpha(theme.palette.grey[300], 1)}`,
        borderRight: `1px solid ${alpha(theme.palette.grey[300], 1)}`,
        '& .MuiListItemIcon-root': {
          height: '10px',
          width: '10px',
          borderRadius: '5px',
          marginRight: '5px',
        },
        '& .MuiTypography-root': {
          fontStyle: 'initial',
          fontFamily: 'louisbold',
          fontSize: '12px',
          lineHeight: '12px',
          letterSpacing: '1.5px',
          color: theme.palette.grey[600],
          fontWeight: 'normal',
        },
        '& .MuiListItem-root': {
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          width: '100%',
          marginBottom: '10px',
        },
        '& .MuiList-root': {
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
        },
        [theme.breakpoints.up('sm')]: {
          '& .MuiList-root': {
            flexDirection: 'row',
          },
          '& .MuiListItem-root': {
            width: '50%',
          },
        },
        [theme.breakpoints.up('md')]: {
          '& .MuiListItem-root': {
            width: '33%',
          },
        },
      },
    },
    '&#tourDatesAvailability': {
      '& .MuiCheckbox-root': {
        backgroundColor: 'transparent',
      },
      '& .Mui-error.select-time-container': {
        border: `3px solid ${alpha(theme.palette.error.main, 1)}`,
      },
    },
    '&#ownershipType, &#offerType, &#legendaryTruckOwner, &#rewardsInformation': {
      '& .MuiFormControlLabel-root': {
        '& .MuiTypography-root': {
          fontSize: '16px',
          fontWeight: 400,
          fontFamily: 'Roboto',
          color: `${alpha(theme.palette.grey[500], 1)}`,
          cursor: 'pointer',
        },
        '& .MuiTouchRipple-root': {
          display: 'none',
        },
        '& .MuiSvgIcon-root': {
          display: 'block',
        },
      },
    },
    '&.MuiGrid-item': {
      paddingTop: '0 !important',
      marginBottom: '36px',
    },
    '&.MuiGrid-root': {
      position: 'relative',
    },
    '&#purchaseDate, &#vehicleIdModal, &#transferIdModal, &#dealershipId, &#productOfInterest': {
      '& .MuiOutlinedInput-input': {
        padding: '0 0 10px 14px',
        width: 'calc(100% - 14px)',
      },
    },
    '&#vehicleId': {
      '& .MuiOutlinedInput-input': {
        padding: '0 0 10px 10px',
        width: 'calc(100% - 14px)',
      },
    },
    '&#microphoneSelection': {
      width: 176,
      margin: 0,
      '& .MuiInputBase-root': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiOutlinedInput-input': {
        padding: '0 !important',
        paddingLeft: '10px !important',
        maxWidth: '200px',
        letterSpacing: '0.8px !important',
        fontSize: '12px',
      },
    },
    '#dealershipId': {
      '.MuiSvgIcon-root': {
        display: 'none',
      },
    },
    '&#vehicleType': {
      '& span.minus-icon, span.plus-icon': {
        [theme.breakpoints.between(992, 1200)]: {
          '&::after': {
            right: '1.6vw !important',
          },
        },
        [theme.breakpoints.between(768, 992)]: {
          '&::after': {
            right: '1.1vw !important',
          },
        },
        [theme.breakpoints.between(640, 768)]: {
          '&::after': {
            right: '2.9vw !important',
          },
        },
        [theme.breakpoints.between(547, 640)]: {
          '&::after': {
            right: '3.6vw !important',
          },
        },
        [theme.breakpoints.between(450, 640)]: {
          '&::after': {
            right: '3.5vw !important',
          },
        },
        [theme.breakpoints.between(400, 450)]: {
          '&::after': {
            right: '3.1vw !important',
          },
        },
        [theme.breakpoints.between(370, 400)]: {
          '&::after': {
            right: '2.9vw !important',
          },
        },
        [theme.breakpoints.between(345, 370)]: {
          '&::after': {
            right: '2.5vw !important',
          },
        },
        [theme.breakpoints.between(310, 345)]: {
          '&::after': {
            right: '2vw !important',
          },
        },
        [theme.breakpoints.down(310)]: {
          '&::after': {
            right: '1.5vw !important',
          },
        },
      },
    },
    '&#tourDate, &#date': {
      '& span.calendar-icon': {
        [theme.breakpoints.between(768, 1200)]: {
          '&::after': {
            right: '2vw !important',
          },
        },
        [theme.breakpoints.between(768, 992)]: {
          '&::after': {
            right: '1.6vw !important',
          },
        },
        [theme.breakpoints.between(640, 768)]: {
          '&::after': {
            right: '3.5vw !important',
          },
        },
        [theme.breakpoints.between(547, 640)]: {
          '&::after': {
            right: '3.9vw !important',
          },
        },
        [theme.breakpoints.between(450, 547)]: {
          '&::after': {
            right: '4.8vw !important',
          },
        },
        [theme.breakpoints.between(380, 450)]: {
          '&::after': {
            right: '4.2vw !important',
          },
        },
        [theme.breakpoints.between(340, 380)]: {
          '&::after': {
            right: '3.8vw !important',
          },
        },
        [theme.breakpoints.between(300, 340)]: {
          '&::after': {
            right: '3.5vw !important',
          },
        },
        [theme.breakpoints.down(300)]: {
          '&::after': {
            right: '3vw !important',
          },
        },
      },
    },
    '&#vehicleId, &#topicId, &#purchaseDate, &#productOfInterest': {
      '& span.dropdown-icon': {
        [theme.breakpoints.between(992, 1200)]: {
          '&::after': {
            right: '2.3vw !important',
          },
        },
        [theme.breakpoints.between(768, 992)]: {
          '&::after': {
            right: '1.8vw !important',
          },
        },
        [theme.breakpoints.between(640, 768)]: {
          '&::after': {
            right: '4vw !important',
          },
        },
        [theme.breakpoints.between(547, 640)]: {
          '&::after': {
            right: '4.5vw !important',
          },
        },
        [theme.breakpoints.between(450, 547)]: {
          '&::after': {
            right: '5.3vw !important',
          },
        },
        [theme.breakpoints.between(380, 450)]: {
          '&::after': {
            right: '5vw !important',
          },
        },
        [theme.breakpoints.between(340, 380)]: {
          '&::after': {
            right: '4.5vw !important',
          },
        },
        [theme.breakpoints.between(300, 340)]: {
          '&::after': {
            right: '4.2vw !important',
          },
        },
        [theme.breakpoints.down(300)]: {
          '&::after': {
            right: '4vw !important',
          },
        },
      },
    },
    '&#vehicleIdModal, &#dealershipId, &#transferIdModal,': {
      '& span.dropdown-icon': {
        '&::after': {
          right: '25px !important',
        },
        [theme.breakpoints.between(768, 992)]: {
          '&::after': {
            right: '2.5vw !important',
          },
        },
        [theme.breakpoints.between(640, 768)]: {
          '&::after': {
            right: '5.5vw !important',
          },
        },
        [theme.breakpoints.between(547, 640)]: {
          '&::after': {
            right: '5.1vw !important',
          },
        },
        [theme.breakpoints.between(450, 547)]: {
          '&::after': {
            right: '4.9vw !important',
          },
        },
        [theme.breakpoints.down(450)]: {
          '&::after': {
            right: '4.2vw !important',
          },
        },
      },
    },
    '.mui-calendar-picker-container': {
      '&.mui-calendar-picker-container--french': {
        '.MuiCalendarPicker-root': {
          '& .MuiTypography-caption:nth-of-type(1)': {
            '&::after': {
              content: '"im"',
              display: 'inline-block',
            },
          },
          '& .MuiTypography-caption:nth-of-type(2)': {
            '&::after': {
              content: '"un"',
              display: 'inline-block',
            },
          },
          '& .MuiTypography-caption:nth-of-type(3)': {
            '&::after': {
              content: '"ar"',
              display: 'inline-block',
            },
          },
          '& .MuiTypography-caption:nth-of-type(4)': {
            '&::after': {
              content: '"er"',
              display: 'inline-block',
            },
          },
          '& .MuiTypography-caption:nth-of-type(5)': {
            '&::after': {
              content: '"eu"',
              display: 'inline-block',
            },
          },
          '& .MuiTypography-caption:nth-of-type(6)': {
            '&::after': {
              content: '"en"',
              display: 'inline-block',
            },
          },
          '& .MuiTypography-caption:nth-of-type(7)': {
            '&::after': {
              content: '"am"',
              display: 'inline-block',
            },
          },
        },
      },
    },
    [theme.breakpoints.up('xs')]: {
      ...gridWrapperStyles,
    },
  };

  return {
    styles,
  };
};

const GridWrapper = styled(Grid)(({ theme }) => GridWrapperStyles(theme).styles);

export default GridWrapper;
