import { doc } from 'firebase/firestore';
import { useFirestoreDocData, useFirestore } from 'reactfire';

export const usePostSessionRedirect = (brandId: string, eventId: string): string => {
  if (eventId !== 'oneOnOne') {
    const firestore = useFirestore();
    const eventRef = doc(firestore, `brands/${brandId}/events/${eventId}`);
    const { data: event } = useFirestoreDocData(eventRef, { idField: 'id' });

    if (event && event.postSessionRedirect) {
      return event.postSessionRedirectUrl;
    }
  }
  return '';
};
