import { useTheme } from '@material-ui/core/styles';
// import { palette } from '@material-ui/system';

export const TransferViewStyles = (brandId: string) => {
  const theme = useTheme();

  const chevyStyles = {
    form: {
      marginTop: '30px',
    },
    modalSubText: {
      maxWidth: '540px',
      marginBottom: '70px',
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down(768)]: {
        alignItems: 'center',
      },
    },
    phoneNumberInput: {
      width: '50%',
      paddingRight: '15px',
      [theme.breakpoints.down(768)]: {
        width: '100%',
        paddingRight: 0,
      },
      '@global': {
        '.MuiOutlinedInput-input': {
          padding: '0 0 10px 0',
        },
      },
    },
    textArea: {
      height: '100%',
    },
    inputLabel: {
      [theme.breakpoints.between(768, 900)]: {
        whiteSpace: 'break-spaces',
        height: '40px',
      },
      [theme.breakpoints.down(480)]: {
        whiteSpace: 'break-spaces',
      },
    },
    timePickerContainer: {
      marginTop: '27px',
    },
    selectWrapper: {
      position: 'relative',
      '@global': {
        '.MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },
    datePicker: {
      '@global': {
        '.MuiInputBase-root': {
          background: theme.palette.background.selectBackground,
          '&:hover': {
            background: theme.palette.background.selectBackgroundHover,
          },
        },
      },
    },
    calendarOpen: {
      '@global': {
        '.MuiInputBase-root': {
          background: theme.palette.background.selectBackgroundHover,
        },
      },
    },
    checkIcon: {
      position: 'absolute',
      top: '6px',
      right: '5px',
    },
    textField: {},
    errorElement: {},
    selectBrand: {
      marginBottom: '30px !important',
    },
  };

  const cadillacStyles = {
    modalSubText: {
      maxWidth: '540px',
      marginBottom: '15px',
    },
    buttonWrapper: {
      alignItems: 'center',
    },
    selectBrand: {
      marginTop: '36px !important',
      marginBottom: '36px !important',
      [theme.breakpoints.down(768)]: {
        marginBottom: '0 !important',
      },
    },
    selectLanguage: {
      marginTop: '36px !important',
      marginBottom: '36px !important',
    },
    characterCount: {
      marginBottom: '55px',
      [theme.breakpoints.down(768)]: {
        marginBottom: '65px',
      },
    },
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
  };

  const gmcStyles = {
    '@global': {
      '.calendar-icon-gmc': {
        backgroundColor: theme.palette.grey[900],
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        right: 0,
      },
      '.MuiGrid-item': {
        paddingTop: '26px !important',
        [theme.breakpoints.up(768)]: {
          paddingTop: '30px !important',
        },
      },
      '.MuiOutlinedInput-root': {
        height: '54px',
      },
      '.modal-border': {
        marginBottom: '60px !important',
      },
    },
    buttonWrapper: {
      [theme.breakpoints.up(768)]: {
        justifyContent: 'center',
        flexDirection: 'row',
      },
    },
    submitButton: {
      backgroundColor: theme.palette.primary,
      width: '240px',
      [theme.breakpoints.up('md')]: {
        width: 'calc(50% + 30px)',
        maxWidth: 'calc(50% + 30px)',
      },
      [theme.breakpoints.up('lg')]: {
        width: 350,
        maxWidth: 350,
      },
    },
    saveButton: {
      width: '100%',
      marginTop: '25px',
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: '45px',
        width: 'calc(50% + 30px)',
        maxWidth: 'calc(50% + 30px)',
      },
      [theme.breakpoints.up('lg')]: {
        width: 380,
        maxWidth: 380,
      },
    },
    inputLabel: {
      [theme.breakpoints.between(768, 900)]: {
        height: '28px',
      },
    },
    textField: {
      '@global': {
        '.MuiOutlinedInput-root': {
          height: '125px !important',
          padding: '20px',
          [theme.breakpoints.up('md')]: {
            height: '175px !important',
          },
        },
        '.MuiOutlinedInput-input': {
          height: '90px !important',
          padding: '0px !important',
          textTransform: 'initial !important',
          [theme.breakpoints.up('md')]: {
            height: '140px !important',
          },
        },
      },
    },
    selectLanguage: {
      paddingLeft: '45px !important',
      [theme.breakpoints.down(768)]: {
        paddingLeft: '30px !important',
      },
    },
    selectProductSpecialist: {
      paddingLeft: '45px !important',
      [theme.breakpoints.down(768)]: {
        paddingLeft: '30px !important',
      },
    },
    characterCount: {
      marginBottom: '55px',
      [theme.breakpoints.down(768)]: {
        marginBottom: '30px',
      },
    },
  };

  const buickStyles = {
    buttonWrapper: {
      alignItems: 'center',
    },
    submitButton: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 380,
        maxWidth: 380,
      },
    },
    saveButton: {
      width: '100%',
      marginTop: '25px',
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: '50px',
        width: 'calc(50% + 25px)',
        maxWidth: 'calc(50% + 25px)',
      },
      [theme.breakpoints.up('lg')]: {
        width: 380,
        maxWidth: 380,
      },
    },
    inputLabel: {
      [theme.breakpoints.between(768, 900)]: {
        height: '27px',
      },
    },
    textField: {
      '@global': {
        '.MuiOutlinedInput-root': {
          height: '125px !important',
          padding: '20px',
          [theme.breakpoints.up('md')]: {
            height: '175px !important',
          },
        },
        '.MuiOutlinedInput-input': {
          height: '90px !important',
          padding: '0px !important',
          textTransform: 'initial !important',
          [theme.breakpoints.up('md')]: {
            height: '140px !important',
          },
        },
      },
    },
    selectBrand: {
      marginBottom: '20px !important',
    },
    characterCount: {
      marginBottom: '65px',
      [theme.breakpoints.down(768)]: {
        marginBottom: '35px',
      },
    },
    '@global': {
      '.calendar-icon-gmc': {
        backgroundColor: theme.palette.grey[900],
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        right: 0,
      },
      '.MuiGrid-container': {
        width: 'calc(100% + 20px)',
        marginLeft: '-20px',
        [theme.breakpoints.up(768)]: {
          width: 'calc(100% + 50px)',
          marginLeft: '-50px',
        },
      },
      '.MuiGrid-item': {
        paddingTop: '20px !important',
        paddingLeft: '20px !important',
        [theme.breakpoints.up(768)]: {
          paddingTop: '30px !important',
          paddingLeft: '50px !important',
        },
      },
    },
  };

  const evliveStyles = {
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up(768)]: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      },
    },
    submitButton: {
      width: '100%',
      marginBottom: 12,
    },
    saveButton: {
      width: '100%',
      textDecoration: 'underline',
      [theme.breakpoints.up(768)]: {
        width: 'auto',
        maxWidth: 'none',
      },
    },
    selectBrand: {
      marginTop: '30px',
      marginBottom: '36px !important',
      [theme.breakpoints.down(768)]: {
        marginBottom: '0px !important',
      },
    },
    selectLanguage: {
      marginTop: '30px',
      marginBottom: '36px !important',
      [theme.breakpoints.down(768)]: {
        marginBottom: '30px !important',
      },
    },
    topicVehicleSelect: {
      [theme.breakpoints.down(768)]: {
        marginBottom: '30px !important',
      },
    },
    selectProductSpecialist: {
      [theme.breakpoints.down(768)]: {
        marginBottom: '30px !important',
      },
    },
    characterCount: {
      marginBottom: '36px !important',
    },
    '@global': {
      '.MuiInputBase-root': {
        '&:hover': {
          background: 'transparent !important',
        },
        '&.Mui-focused': {
          background: 'transparent !important',
        },
      },
    },
  };

  const energyStyles = {
    characterCount: {
      marginTop: '8px',
      marginBottom: '48px !important',
      [theme.breakpoints.down(768)]: {
        marginBottom: '32px !important',
      },
    },
    form: {
      marginTop: '62px',
    },
    '@global': {
      '.MuiInputBase-root': {
        backgroundColor: 'transparent',
        '&:hover': {
          '&& fieldset': {
            border: `2px solid ${theme.palette.color.modalTitleBlueText}`,
          },
        },
      },
      '.MuiSelect-standard': {
        backgroundColor: 'transparent',
      },
      '.MuiInputLabel-shrink': {
        top: '7px !important',
      },
      '.MuiOutlinedInput-root': {
        backgroundColor: 'transparent !important',
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },
      '.MuiInputLabel-root': {
        paddingLeft: '14px',
        top: '-3px',
        '&.Mui-focused': {
          top: '7px',
        },
      },
      '.MuiInputBase-inputMultiline': {
        padding: '0 !important',
      },
      '#transferIdModal': {
        '&.MuiGrid-item': {
          [theme.breakpoints.down(768)]: {
            marginBottom: '32px !important',
          },
        },
      },
      '.MuiButton-primary': {
        [theme.breakpoints.down(768)]: {
          width: '100%',
          maxWidth: '100%',
        },
      },
    },
  };

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};
