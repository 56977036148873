import { useTheme } from '@material-ui/core/styles';

export const VehicleFeaturePreviewStyles = (brandId: string) => {
  const theme = useTheme();

  const chevyStyles = {
    vehicleFeatureContent: {
      width: '100%',
      backgroundColor: theme.palette.background.vehicleFeatureContent,
    },
    vehicleFeaturePreviewImage: {
      width: '100%',
      objectFit: 'cover',
      display: 'none',
      maxHeight: 190,
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    vehicleFeaturePreviewImageContent: {
      paddingTop: '8px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    vehicleFeaturePreviewContent: {
      padding: '15px',
      backgroundColor: theme.palette.background.vehicleFeaturePreviewContent,
    },
    vehicleFeaturePreviewBody: {
      marginTop: 10,
    },
    vehicleFeaturePreviewDisclaimer: {
      marginTop: 10,
    },
    [theme.breakpoints.up('md')]: {
      vehicleFeaturePreviewImageContent: {
        paddingLeft: '30px',
        paddingRight: '30px',
      },
      vehicleFeaturePreviewContent: {
        padding: '15px 30px',
      },
    },
  };

  const cadillacStyles = {
    vehicleFeatureContent: {
      position: 'relative',
      backgroundColor: 'transparent',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: '-1',
        height: '100%',
        width: '100%',
        backdropFilter: ' blur(6px)',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
      },
    },
    vehicleFeaturePreviewContent: {
      padding: '37px 20px',
      [theme.breakpoints.up('md')]: {
        padding: '22px 30px',
      }
    },
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
  };

  const gmcStyles = {
    vehicleFeatureContent: {
      position: 'relative',
      backgroundColor: 'transparent',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: '-1',
        height: '100%',
        width: '100%',
        backdropFilter: ' blur(6px)',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
      },
    },
    vehicleFeaturePreviewContent: {
      padding: '37px 20px',
      [theme.breakpoints.up('md')]: {
        padding: '22px 30px',
      }
    },
  };

  const buickStyles = {
    vehicleFeatureContent: {
      position: 'relative',
      backgroundColor: 'transparent',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: '-1',
        height: '100%',
        width: '100%',
        // backdropFilter: ' blur(6px)',
        backgroundColor: 'rgba(63, 63, 56, 0.9)',
      },
    },
    vehicleFeaturePreviewContent: {
      padding: '37px 20px',
      [theme.breakpoints.up('md')]: {
        padding: '22px 30px',
      }
    },
  };

  const evliveStyles = {
    vehicleFeaturePreviewImageContent: {
      backgroundColor: '#fff',
      padding: '20px 30px 0px 30px',
      [theme.breakpoints.up('md')]: {
        padding: '13px 13px 0px 13px',
      }
    },
    vehicleFeatureContent: {
      position: 'relative',
      backgroundColor: 'transparent',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      overflow: 'hidden',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: '-1',
        height: '100%',
        width: '100%',
        backdropFilter: ' blur(6px)',
        backgroundColor: 'rgba(63, 63, 56, 0.9)',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 10,
      },
    },
    vehicleFeaturePreviewContent: {
      padding: '20px 30px 25px 30px',
      [theme.breakpoints.up('md')]: {
        padding: '13px 13px 20px 13px',
      }
    },
  };

  const energyStyles = {};

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};
