import { Box } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import { VehicleFeature } from '@types';
import { useTranslation } from 'react-i18next';
import { VehicleFeaturePreviewStyles } from './VehicleFeaturePreview.style';

type VehicleFeaturePreviewProps = {
  brandId: string;
  vehicleFeature: VehicleFeature;
};

const VehicleFeaturePreview = (props: VehicleFeaturePreviewProps): ReactElement => {
  const { vehicleFeature, brandId } = props;
  const { t } = useTranslation();
  const useStyles = makeStyles(VehicleFeaturePreviewStyles(brandId));
  const styles = useStyles();

  return (
    <Box className={styles.vehicleFeatureContent}>

      <Box sx={{ lineHeight: '0px' }}>
        <Box
          className={styles.vehicleFeaturePreviewImage}
          component="img"
          src={vehicleFeature.imageUrl}
          alt={vehicleFeature.title}
        />
        {vehicleFeature.caption && (
          <Box className={styles.vehicleFeaturePreviewImageContent}>
            <Typography
              variant="vehicleFeatureCaption"
              component="p"
              dangerouslySetInnerHTML={{ __html: t(vehicleFeature.caption) }}
            />
          </Box>
        )}
      </Box>

      <Box className={styles.vehicleFeaturePreviewContent}>
        {vehicleFeature.title && (
          <Typography
            variant="vehicleFeatureTitle"
            component="h2"
            dangerouslySetInnerHTML={{ __html: t(vehicleFeature.title) }}
          />
        )}
        {vehicleFeature.bodyCopy && (
          <Typography
            className={styles.vehicleFeaturePreviewBody}
            variant="vehicleFeatureBody"
            component="p"
            dangerouslySetInnerHTML={{ __html: t(vehicleFeature.bodyCopy) }}
          />
        )}
        {vehicleFeature.description && (
          <Typography
            className={styles.vehicleFeaturePreviewBody}
            variant="vehicleFeatureBody"
            component="p"
            dangerouslySetInnerHTML={{ __html: t(vehicleFeature.description) }}
          />
        )}
        {vehicleFeature.disclaimer && (
          <Typography
            className={styles.vehicleFeaturePreviewDisclaimer}
            variant="vehicleFeatureDisclaimer"
            component="p"
            dangerouslySetInnerHTML={{ __html: t(vehicleFeature.disclaimer) }}
          />
        )}
      </Box>

    </Box>
  );
};

export default VehicleFeaturePreview;
