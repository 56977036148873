import { makeStyles } from '@mui/styles';
import { Box } from '@material-ui/core/node_modules/@material-ui/system';
import { useTheme } from '@material-ui/core/styles';
import { BrandType } from '@types';
import clsx from 'clsx';

type ChatMessageProps = {
  isSender: boolean;
  brandId: BrandType;
  isProductSpecialist?: boolean;
};

const ChatMessageDots = (props: ChatMessageProps): JSX.Element => {
  const { isSender, brandId, isProductSpecialist } = props;
  const isCadillac = brandId === 'cadillac' || brandId === 'cadillaccan';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId === 'buick';
  const isEVLive = brandId === 'ev' || brandId === 'evcan';
  const isEnergy = brandId === 'energy';
  const theme = useTheme();

  let chatMessageDotsStyles = {};
  const chatMessageDotsCadillacStyles = {
    chatNow: {
      marginTop: '20px',
    },
  };
  const chatMessageDotsGMCStyles = {
    chatNow: {
      marginTop: '20px',
    },
    '@global': {
      '.dots': {
        borderRadius: '0px',
      },
    }
  };
  const chatMessageDotsEVLiveStyles = {
    chatNow: {
      marginTop: '8px',
    },
    '@global': {
      '.dots': {
        borderRadius: '25px',
      },
    }
  };
  const chatMessageDotsEnergyStyles = {
    chatNowMessageUser: {
      justifyContent: 'flex-start',
    },
    chatNowMessageAdmin: {
      justifyContent: 'flex-start',
    },
    chatNowMessageUser_isPS: {
      justifyContent: 'flex-end',
    },
  };

  if (isCadillac) {
    chatMessageDotsStyles = {
      ...chatMessageDotsCadillacStyles,
    };
  }

  if (isGMC || isBuick) {
    chatMessageDotsStyles = {
      ...chatMessageDotsGMCStyles,
    };
  }

  if (isEVLive) {
    chatMessageDotsStyles = {
      ...chatMessageDotsEVLiveStyles,
    };
  }

  if (isEnergy) {
    chatMessageDotsStyles = {
      ...chatMessageDotsEnergyStyles,
    };
  }

  const useStyles = makeStyles({
    chatNowMessage: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    chatNowMessageAdmin: {
      justifyContent: 'flex-end',
    },
    chatNowMessageUser: {
      justifyContent: 'flex-start',
    },
    chatNow: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 8,
    },
    '@global': {
      '.dots': {
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: 0,
        height: '50px',
        width: '112px',
        borderRadius: 30,
      },
      '.dots-admin': {
        backgroundColor: theme.palette.background.messageDotAdmin,
      },
      '.dots-user': {
        backgroundColor: theme.palette.background.messageDotUser,
      },
      '.dot': {
        marginLeft: '3px',
        marginRight: '3px',
        height: '10px',
        width: '10px',
        backgroundColor: theme.palette.background.messageDots,
        borderRadius: '20px',
        animationName: 'blink',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationFillMode: 'both',
        animationTimingFunction: 'ease',
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginRight: 0
        },
        '&:nth-child(1)': {
          animationDelay: '0.2s',
        },
        '&:nth-child(2)': {
          animationDelay: '0.4s',
        },
        '&:nth-child(3)': {
          animationDelay: '0.6s',
        },
      },
      '@keyframes blink': {
        '0%': {
          marginTop: '6px',
          opacity: 0.2
        },
        '50%': {
          marginTop: '3px',
          opacity: 1
        },
        '100%': {
          marginTop: '6px',
          opacity: 0.2
        },
      },
    },
    [theme.breakpoints.up('xs')]: {
      ...chatMessageDotsStyles,
    },
  });
  const styles = useStyles();

  return (
    <Box
      className={clsx(
        styles.chatNowMessage,
        !isProductSpecialist
          ? isSender
            ? styles.chatNowMessageAdmin
            : styles.chatNowMessageUser
          : '',
        isProductSpecialist
          ? isSender
            ? ''
            : styles.chatNowMessageUser_isPS
          : ''
      )}
    >
      <Box className={styles.chatNow}>
        <ul className={`dots ${isSender ? 'dots-admin' : 'dots-user'}`}>
          <li className="dot" />
          <li className="dot" />
          <li className="dot" />
        </ul>
      </Box>
    </Box>
  );
};

export default ChatMessageDots;
