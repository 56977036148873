import { FC, ReactNode, useEffect } from 'react';
import * as firebase from 'firebase/auth';
import { useReporting } from '@hooks';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

const ReportPage: FC<Props> = (props) => {
  const { children } = props;
  const location = useLocation();
  const { page } = useReporting();
  const auth = firebase.getAuth();

  useEffect(() => {
    if (auth && auth.currentUser && auth.currentUser.uid) {
      console.log('tracking', location.pathname);
      page(location.pathname, auth.currentUser.uid);
    }
  }, []);

  return <>{children}</>;
};

export default ReportPage;
