import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { brands } from '@constants';
import { useCurrentLanguageParam, useQueryString } from '@hooks';
import { getParentHost } from '@utils';
import { BrandType, LanguageCode } from '@types';

type BrandData = {
  host?: string,
  loaded: boolean
};

export const useHost = (langOverride?:LanguageCode): BrandData => {
  const { urlSearchParams } = useQueryString();
  const brandId = urlSearchParams.get('brandId') || brands[0];
  // const languageCode = urlSearchParams.get('lang') || languages[0];
  const { lang } = useCurrentLanguageParam();

  const firestore = useFirestore();
  const brandQuery = doc(firestore, 'brands', brandId);
  const { data: brand } = useFirestoreDocData(brandQuery, {
    idField: 'id',
  });

  const parentHost = getParentHost(brandId as BrandType, langOverride || (lang as LanguageCode), brand.parentHost);

  if (!brand) {
    return { loaded: false };
  }
  return { host: window.self === window.top ? brand.host : parentHost, loaded: true };
};
