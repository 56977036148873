import { Box, Typography, Button } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalIconButton } from '@components';

type CameraBrowserNotSupportedProps = {
    brandId: string;
    onClose?: () => void;
};

const CameraBrowserNotSupportedModal = (props: CameraBrowserNotSupportedProps): ReactElement => {
    const { onClose, brandId } = props;
    const isEnergy = brandId.toLowerCase() === 'energy';
    const { t } = useTranslation();

    const listItems = [
        'Safari 11+ on iOS',
        'Most recent version of Google Chrome for Android',
        'Most recent version of Firefox for Android'
    ];

    return (
        <>
            {isEnergy && <ModalIconButton onClose={onClose} brandId={brandId} dataDTM="modal:device incompatible" />}

            <Box className="modal-container">
                {!isEnergy && <ModalIconButton onClose={onClose} brandId={brandId} dataDTM="modal:device incompatible" />}
                <Box className="modal-text-container">
                    <Typography
                        className='heading-camera-browser-not-supported'

                        variant="modalTitle" component="h2">
                        {t('We’re sorry but your current browser isn’t supported. To experiencee Energy LIVE with your 2-way camera please switch to the following:')}
                    </Typography>
                    <ul
                        className='list-camera-browser-not-supported'
                    >
                        {listItems.map((item, index) => (
                            <li
                                key={index}
                                className='list-item-camera-browser-not-supported'
                            >
                                {item}
                            </li>
                        ))}
                    </ul>
                </Box>

                <Box className="modal-container-buttons">
                    <Button
                        type="button"
                        disableRipple
                        aria-label="close modal"
                        variant="primary"
                        className="stat-button-link"
                        data-dtm="modal:device incompatible"
                        onClick={onClose}
                    >
                        {t('Close')}
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default CameraBrowserNotSupportedModal;
