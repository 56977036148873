import { FC, ReactElement, useEffect, useState } from 'react';
import * as firebase from 'firebase/auth';
import { useQueryString } from '@hooks';
import LoadingScreen from './LoadingScreen';

interface Props {
  children: ReactElement<any, any>;
}

const TokenAuthGuard: FC<Props> = (props) => {
  const { children } = props;

  const { urlSearchParams } = useQueryString();
  const [loaded, setLoaded] = useState(false);

  const token = urlSearchParams.get('token');
  const auth = firebase.getAuth();

  useEffect(() => {
    if (token) {
      firebase
        .signInWithCustomToken(auth, token).then(() => {
          setLoaded(true);
        }).catch((err) => {
          console.log(err);
        });
    } else {
      setLoaded(true);
    }
  }, []);

  return loaded ? children : <LoadingScreen />;
};

export default TokenAuthGuard;
