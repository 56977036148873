const request = async (url: string, body?: any) => {
  const data: any = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }

  if (body) {
    data.body = JSON.stringify(body);
  }

  const response = await fetch(url, data);

  return response.json();
}


export const fetchJson = async (url: string, body?: any) => {

  let retries = 0;
  let maxRetries = 3;

  try {
    return request(url, body);
  }
  catch (err) {
    if(retries > maxRetries){
      console.log("Max retries reached");
      throw new Error(err);
    }
    else{
      console.log("Retrying request");
      retries = retries+ 1;
      return request(url, body);
    }

  }
}

