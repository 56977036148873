import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQueryString } from '@hooks';

const TextButtonStyles = (theme: any) => {
  const { urlSearchParams } = useQueryString();
  const brandId = urlSearchParams.get('brandId');
  const isCadillac = brandId === 'cadillac' || brandId === 'cadillaccan';
  const isCadillacCanada = brandId === 'cadillaccan';
  const isGMC = brandId === 'gmc';

  let textButtonStyles;
  let textButtonCadillacStyles;
  const textButtonCadillacCanadaStyles = {
    ...textButtonCadillacStyles,
    color: '#000',
    '&:hover': {
      color: '#000',
    },
  };
  const textButtonGMCStyles = {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    }
  };

  if (isCadillac) {
    textButtonStyles = {
      ...textButtonCadillacStyles,
    };
  }

  if (isCadillacCanada) {
    textButtonStyles = {
      ...textButtonCadillacCanadaStyles,
    };
  }

  if (isGMC) {
    textButtonStyles = {
      ...textButtonGMCStyles,
    };
  }

  const styles = {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.up(768)]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    [theme.breakpoints.up('xs')]: {
      ...textButtonStyles,
    },
  };

  return {
    styles,
  };
};

const TextButton = styled(Button)(({ theme }) => TextButtonStyles(theme).styles);

export default TextButton;
