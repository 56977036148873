import { Box } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { Typography, Link } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandType, ShareableHyperlink } from '@types';
import ModalIconButton from './ModalIconButton';

type ShareableHyperlinksModalProps = {
  brandId: BrandType;
  shareableHyperlinks: ShareableHyperlink[];
  onClose?: () => void;
  onSend: (hyperlink: ShareableHyperlink) => void;
  onLeavingSessionModal: (hyperlink: string) => void;
};

const ShareableHyperlinksModal = (props: ShareableHyperlinksModalProps): ReactElement => {
  const { onClose, onSend, onLeavingSessionModal, brandId, shareableHyperlinks } = props;
  const { t } = useTranslation();
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEnergy = brandId.toLowerCase() === 'energy';

  const groupBy = (array) => {
    const groupedLinks = array.reduce((res: { [key: string]: ShareableHyperlink[] }, hyperlink) => {
      const title = hyperlink.title || 'noTitle';
      (res[title] = res[title] || []).push(hyperlink);
      return res;
    }, {});

    return Object.entries(groupedLinks); // returns [key, value] pairs in array
  };
  const useStyles = makeStyles({
    hyperlinks: {
      marginBottom: (isGMC || isBuick) ? 40 : 0,
    },
    linkItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: isEnergy ? 16 : 0,
      marginBottom: isEnergy ? 16 : 0,
    },
  });
  const styles = useStyles();

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
        />
      )}
      <Box className="modal-container">
        {!isEnergy && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
          />
        )}
        <Typography
          variant="modalTitle"
          component="h2"
        >
          {t('Shareable Hyperlinks')}
        </Typography>
        {isGMC && <Box className="modal-border" />}
        <Box>
          {shareableHyperlinks && groupBy(shareableHyperlinks).map(([title, hyperlinks]) => (
            <Box
              className={styles.hyperlinks}
              key={title}
            >
              {(isGMC || isBuick) && (
                <Typography
                  variant="shareableHyperTitle"
                  component="h2"
                >
                  {t(title)}
                </Typography>
              )}
              {hyperlinks && (hyperlinks as ShareableHyperlink[]).map((hyperlink) => (
                <Box
                  className={styles.linkItem}
                  key={hyperlink.href}
                >
                  <Link
                    variant="shareableHyperLinksLink"
                    component="h2"
                    underline="none"
                    onClick={() => onLeavingSessionModal(hyperlink.href)}
                    aria-label="Open link"
                  >
                    {t(hyperlink.name)}
                  </Link>
                  {hyperlink.href && (
                    <Typography
                      variant="shareableHyperLinksLink"
                      component="h2"
                      onClick={() => {
                        onSend(hyperlink);
                        onClose();
                      }}
                      aria-label="Send link"
                    >
                      {t('Send')}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ShareableHyperlinksModal;
