import { useTheme } from '@material-ui/core/styles';

export const NameplatePopupModalStyles = (brandId: string) => {
  const theme = useTheme();

  const chevyStyles = {
    formGroup: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: '100%',
      '@media screen and (min-width: 768px)': {
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'left',
      }
    },
    formGroupBrandAwareness: {},
    allNameplatesText: {},
    selectAllNameplatesText: {
      marginBottom: '10px',
    },
    buttonContainer: {
      marginTop: 43,
      textAlign: 'right',
    },
    errorElement: {},
    cancelButton: {},
  };

  const cadillacStyles = {
    '.MuiTypography-modalTitle': {
      paddingBottom: '50px',
    },
    formGroup: {
      justifyContent: 'center',
      alignItems: 'center',
      '@media screen and (min-width: 768px)': {
        textAlign: 'center',
      }
    },
    formGroupBrandAwareness: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '30px',
    },
    allNameplatesText: {
      textAlign: 'center',
      fontSize: 16,
      fontFamily: 'CadillacGothic-Medium',
    },
    selectAllNameplatesText: {
      marginTop: '32px',
      marginBottom: '62px',
      textAlign: 'center',
      fontSize: 16,
      fontFamily: 'CadillacGothic-Regular',
      textTransform: 'uppercase',
      letterSpacing: '3px',
      lineHeight: '20px',
    },
    buttonContainer: {
      marginTop: 100,
      textAlign: 'center',
    },
    checkIcon: {
      position: 'absolute',
      top: '6px',
      right: '5px',
    },
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
    // allNameplatesText: {
    //   ...cadillacStyles.allNameplatesText,
    //   color: '#000',
    //   fontFamily: 'CadillacSansA-Medium',
    // },
    // selectAllNameplatesText: {
    //   ...cadillacStyles.selectAllNameplatesText,
    //   color: '#000',
    //   fontFamily: 'CadillacSansA-Light',
    // }
  };

  const gmcStyles = {
    buttonContainer: {
      textAlign: 'center',
    },
    allNameplatesText: {
      fontSize: 16,
      lineHeight: '24px',
      '@media screen and (min-width: 768px)': {
        fontSize: '20px',
      }
    },
    selectAllNameplatesText: {
      fontSize: 16,
      lineHeight: '24px',
      '@media screen and (min-width: 768px)': {
        fontSize: '20px',
      }
    },
  };

  const buickStyles = {};

  const evliveStyles = {
    formGroup: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: '100%',
      '@media screen and (min-width: 768px)': {
        flexDirection: 'column',
        flexWrap: 'nowrap',
      }
    },
    selectAllNameplatesText: {
      fontWeight: '500',
      fontSize: 16,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    cancelButton: {
      marginTop: 11,
      width: 0,
    },
    '@global': {
      '#appliedNameplate': {
        marginBottom: 10,
      },
      '.MuiFormControlLabel-label': {
        fontWeight: '300 !important',
      },
      '.check-icon': {
        fontSize: '1.3rem',
        color: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[200],
      },
      '.checkbox-outlined-icon': {
        '& path': { display: 'none' },
        fontSize: '1.3rem',
        backgroundColor: theme.palette.grey[200],
      },
      '.checkbox-square-icon': {
        display: 'none !important',
      },
    },
  };

  const energyStyles = {};

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};
