import { useState, useCallback, useEffect, ReactElement } from 'react';
import { collection, doc, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import { Card, Box, InputLabel, Container, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import { useBrandQueryString, useReporting } from '@hooks';
import { TextFieldForm, GridWrapper, TextButton, ErrorComponent, LoadingScreen } from '@components';
// @ts-ignore
import { hoursOfOperationRepository } from '@repositories';
import {
  Brand,
  BrandType,
  LanguageCode,
  DealerReservation,
  ReservationBookedTime,
  Topic,
  TransferRequest,
} from '@types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { regexEmailHelper } from '@constants';
import ReservationViewStyles from './ReservationView.style';
import * as Scroll from 'react-scroll';
import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { useSearchParams } from 'react-router-dom';
import { ExceptionDayLocations } from '../types/exceptionDay';

const CalendarIcon = require('../assets/images/gmc/calendar.png');

declare let window: any;

type DealerReservationViewProps = {
  brandId: BrandType;
  languageCode: LanguageCode;
  dealerReservationId: string;
  transferRequest?: TransferRequest;
  token?: string;
  bacId?: string;
  agentId?: string;
  onCancel?: () => void;
  onGoBack?: () => void;
  onSubmit?: (reservation: Partial<DealerReservation>) => void;
};

const DealerReservationView = (props: DealerReservationViewProps): ReactElement => {
  // props
  const {
    brandId,
    languageCode,
    dealerReservationId,
    token,
    transferRequest,
    bacId,
    agentId,
    onSubmit,
    onCancel,
  } = props;

  // url state
  const queryParams = useBrandQueryString(brandId);

  // hooks
  const theme = useTheme();
  const useStyles = makeStyles(ReservationViewStyles(brandId));
  const styles = useStyles();
  const today = new Date();
  const { t } = useTranslation();
  const { trackAdobe } = useReporting();
  const db = useFirestore();
  const [searchParams, setSearchParams] = useSearchParams();

  // constants
  const dateFormat = languageCode !== 'en-US' ? 'EEEE, d MMMM, yyyy' : 'EEEE, MMMM d, yyyy';
  const locale = languageCode === 'es-MX' ? es : languageCode === 'en-FR' ? fr : en;
  const location: ExceptionDayLocations = 'Reservation';

  // local state
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [loadingHours, setLoadingHours] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTopic, setSelectedTopic] = useState();
  const [timeSlots, setTimeSlots] = useState<Date[]>();
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [validateErrors, setValidateErrors] = useState<boolean>(false);
  const [vehicleSelectOpen, setVehicleSelectOpen] = useState<boolean>(false);
  const [isPreSelected, setIsPreSelected] = useState<boolean>(false);
  const [selectedTourId, setSelectedTourId] = useState<string>('');
  const [addDates, setAddDates] = useState(today.getTime() + 14 * 24 * 60 * 60 * 1000);
  const [maxSlots, setMaxSlots] = useState<number>(6);
  const [warningSlots, setWarningSlots] = useState<number>(3);
  const [blackoutDates, setBlackoutDates] = useState({});
  const [selectedTimeslot, setSelectedTimeslot] = useState<Date>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVR, setIsVR] = useState<boolean>(false);
  const [maxSlotsLimit, setMaxSlotsLimit] = useState<number>(6);
  const [bookLater, setBookLater] = useState<boolean>(false);

  // get data from firestore
  // get reservation data
  const bookedTimesRef = collection(db, 'reservationBookedTimes');
  const bookedTimesQuery = query(bookedTimesRef, where('brandId', '==', brandId));
  const { data: reservationBookedTimes } = useFirestoreCollectionData(bookedTimesQuery, {
    idField: 'id',
  });

  // get topic data
  const topicsRef = collection(db, `brands/${brandId}/topics`);
  const topicsQuery = query(topicsRef, orderBy('index', 'asc'));
  const { data: topics } = useFirestoreCollectionData(topicsQuery, {
    idField: 'id',
  });

  // get brand data
  const brandRef = doc(db, 'brands', brandId);
  const { data: brand, status: brandLoadingStatus } = useFirestoreDocData(brandRef, {
    idField: 'id',
  });

  // get reservation data
  const reservationRef = doc(db, 'reservations', dealerReservationId);
  const { data: reservations, status: loadingStatus } = useFirestoreDocData(reservationRef, {
    idField: 'id',
  });

  // get future reservation data
  const futureReservationsRef = doc(db, 'futureReservations', dealerReservationId);

  // helper functions
  const handleDateToggle = useCallback(() => {
    if (selectedTourId) {
      setCalendarOpen((prevShow) => !prevShow);
    }
  }, [selectedTourId]);
  const handleToggle = useCallback(() => setVehicleSelectOpen((prevShow) => !prevShow), []);

  // update max slots by vehicle config
  const updateMaxSlots = () => {
    const maxTimeslots = brand.maxReservationSlots || 6;
    const warningTimeslots = brand.reservationSlotWarning || 3;

    const maxTimeslotsLimit = brand.maxReservationSlots || 6;
    const isVRVehicle = false;

    setMaxSlots(maxTimeslots);
    setWarningSlots(warningTimeslots);
    setMaxSlotsLimit(maxTimeslotsLimit);
    setIsVR(isVRVehicle);
  };
  useEffect(() => {
    // update max slots and scheduler look ahead by brand config (default 2 weeks)
    if (brand && brandLoadingStatus === 'success') {
      const brandDoc = brand as Brand;
      const schedulerLookAhead = Number(brandDoc.schedulerLookAhead) || 14;
      setAddDates(new Date().getTime() + schedulerLookAhead * 24 * 60 * 60 * 1000);
      updateMaxSlots();
    }
  }, [brand, brandLoadingStatus]);

  const getReservationRemaining = (timeslot: Date) => {
    let numOfTakenReservations = 0;
    let numOfTakenVRReservations = 0;

    for (const reservationDoc of reservationBookedTimes) {
      const reservation = reservationDoc as ReservationBookedTime;
      if (reservation.date.toDate().getTime() === new Date(timeslot).getTime()) {
        numOfTakenReservations++;
        if (reservation.isVR) {
          numOfTakenVRReservations++;
        }
      }
    }

    if (isVR) {
      if (maxSlots <= numOfTakenVRReservations) {
        return maxSlots - numOfTakenVRReservations;
      }
    }

    if (maxSlotsLimit <= numOfTakenReservations) {
      return maxSlots;
    }
    return maxSlots - numOfTakenReservations;
  };

  const handelCancelReservation = async () => {
    await updateDoc(reservationRef, { status: 'Cancelled' });
    onCancel();
  };

  const renderStats = () => {
    const days = document.querySelectorAll('.MuiPickersDay-root');
    days.forEach((day) => {
      day.classList.add('stat-text-link');
      day.setAttribute('data-dtm', 'book 1-1 session:date');
    });
  };

  useEffect(() => {
    renderStats();
    if (calendarOpen) {
      renderStats();
    }
  });

  useEffect(() => {
    if (reservations && token) {
      if (reservations.topicId) {
        setSelectedTourId(reservations.topicId);
      }
      setSelectedDate(reservations.date.toDate());
    }
  }, [reservations]);

  const scroll = Scroll.animateScroll;
  const { Element } = Scroll;
  const executeScroll = () => {
    window.scrollTo(0, 0);
    scroll.scrollToTop('errorElement');
  };

  const getReservationSpots = async (date: Date) => {
    const dateString = moment(date).format('MM-DD-YYYY');
    const reservationSpots = await hoursOfOperationRepository.getReservationsByDay(
      brandId,
      dateString,
      date.getTimezoneOffset(),
      selectedTourId,
    );
    setTimeSlots(reservationSpots);
  };

  const getVehicleBlackoutDates = async () => {
    const vehicleBlackoutDates = await hoursOfOperationRepository.getReservationBlackoutDates(
      today,
      brandId,
      today.getTimezoneOffset(),
      selectedTourId,
      location
    );
    setBlackoutDates(vehicleBlackoutDates);
    setShowTimePicker(false);
    setTimeSlots([]);
    setSelectedDate(undefined);
    setCalendarOpen(true);
  };

  useEffect(() => {
    if (selectedTourId) {
      getVehicleBlackoutDates();
      updateMaxSlots();
    }
  }, [selectedTourId]);

  const isBlackoutDate = (day) => {
    const startDate = new Date(new Date().setHours(0, 0, 0, 0));
    const endDate = new Date(addDates);
    if (blackoutDates && startDate <= day && day <= endDate) {
      const key = moment(day).format('MM-DD-YYYY');
      return blackoutDates[key];
    }
    return false;
  };

  const trackErrorMessages = (errors: any) => {
    const errorMessages = [];
    let errorMessage = '';

    const orderNumber = 'Please enter an order number';
    const vin = 'Please enter valid VIN';
    const firstName = 'Please input valid first name';
    const lastName = 'Please input valid last name';
    const email = 'Please input valid email address';
    const confirmEmail = 'Please input valid confirm email';
    const zipCode = 'Please input valid zip code';
    const phoneNumber = 'Please enter valid phone number';
    const topicId = 'Please select a topic';
    const date = 'Please select date';
    const time = 'Please select time';

    if (errors.orderNumber) errorMessages.push(orderNumber);
    if (errors.vin) errorMessages.push(vin);
    if (errors.firstName) errorMessages.push(firstName);
    if (errors.lastName) errorMessages.push(lastName);
    if (errors.email) errorMessages.push(email);
    if (errors.confirmEmail) errorMessages.push(confirmEmail);
    if (errors.zipCode) errorMessages.push(zipCode);
    if (errors.phoneNumber) errorMessages.push(phoneNumber);
    if (errors.topicId) errorMessages.push(topicId);
    if (errors.date) errorMessages.push(date);
    if (errors.time) errorMessages.push(time);

    errorMessage = errorMessages.join(', ');
    window.digitalData.formInfo.errorMessage = errorMessage;
  };

  const isModifyReservation = token && reservations;

  if (loadingStatus === 'loading') return <LoadingScreen />;
  if (loadingStatus === 'success') {
    return (
      <Formik
        enableReinitialize={isPreSelected}
        validateOnChange={false}
        validationSchema={() => {
          const bookNowValidationShape = {
            firstName: Yup.string()
              .max(255)
              .required(t('Please provide a first name.'))
              .matches(/^[aA-zZ\s]+$/, t('Contains invalid characters.')),
            lastName: Yup.string()
              .max(255)
              .required(t('Please provide a last name.'))
              .matches(/^[aA-zZ\s]+$/, t('Contains invalid characters.')),
            zipCode: Yup.string()
              .min(5, t('Zip Code is not valid.'))
              .max(5, t('Zip Code must be at most 5 characters.'))
              .matches(/^\d{5}(?:[-\s]\d{4})?$/, t('Zip Code is not valid.'))
              .required(t('Zip Code is not valid.')),
            email: Yup.string()
              .email(t('Please provide a properly formatted email address.'))
              .matches(regexEmailHelper, t('Email is incomplete or characters not permitted'))
              .required(t('Please provide a properly formatted email address.')),
            confirmEmail: Yup.string()
              .oneOf([Yup.ref('email'), null], t('Email addresses do not match.'))
              .matches(regexEmailHelper, t('Email is incomplete or characters not permitted'))
              .required(t('Email addresses do not match.')),
            phoneNumber: Yup.string()
              .min(10, t('Please provide a valid phone number.'))
              .matches(
                /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                t('Phone number is limited to Canada and USA'),
              ),
            date: Yup.date()
              .required(t('Please select a date and time.'))
              .typeError(t('Please select a date and time.')),
            topicId: Yup.string().required(t('Please select a topic.')),
            orderNumber: Yup.string().required(t('Please provide an order number.')),
            vin: Yup.string()
              .max(17)
              .matches(/[(A-H|J-N|P|R-Z|0-9)]{17}/, t('VIN is not valid.')),
            sendText: Yup.boolean(),
          };
          const bookLaterValidationShape = {
            ...bookNowValidationShape,
            date: Yup.date(),
            topicId: Yup.string(),
          };

          return bookLater
            ? Yup.object().shape(bookLaterValidationShape)
            : Yup.object().shape(bookNowValidationShape);
        }}
        initialValues={{
          id: '',
          languageCode,
          orderNumber: token && reservations ? reservations.orderNumber : '',
          vin: token && reservations ? reservations.vin : '',
          firstName: token && reservations ? reservations.firstName : '',
          lastName: token && reservations ? reservations.lastName : '',
          email: token && reservations ? reservations.email : '',
          confirmEmail: token && reservations ? reservations.confirmEmail : '',
          zipCode: token && reservations ? reservations.zipCode : '',
          phoneNumber: token && reservations ? reservations.phoneNumber : '',
          sendText: token && reservations ? reservations.sendText : false,
          topicId: token && reservations ? reservations.topicId : '',
          tourName: token && reservations ? reservations.tourName : '',
          date: token && reservations ? reservations.date.toDate() : '',
          time: token && reservations ? reservations.time : '',
          localDate: token && reservations ? reservations.localDate : '',
          dateString: null,
          status: reservations ? 'Modified' : 'Active',
          reminderDate: null,
          sendReminder: false,
        }}
        onSubmit={async (values, { setStatus, setSubmitting, resetForm }): Promise<void> => {
          setIsLoading(true);
          const dateString = values.date.toString();
          const localDate = moment(dateString).format('YYYYMMDD').toString();
          const time = moment(dateString).format('HH:mm').toString();

          if (!values.tourName && !bookLater) {
            const topic = topics.find((t) => t.id === selectedTourId) as Topic;
            values.tourName = topic.name;
          }

          if (bookLater) {
            values.reminderDate = new Date();
            values.sendReminder = true;
          }

          // double check reservation time is available
          if (getReservationRemaining(values.date) <= 0) {
            setStatus({ success: false });
            setIsLoading(false);
            setSubmitting(false);
            toast.error(
              `${t('This reservation time has already been booked. Please select another time.')}`,
            );
            return;
          }

          const reservation: Partial<DealerReservation> = {
            ...values,
            id: dealerReservationId,
            dateString,
            localDate,
            timezoneOffset: new Date().getTimezoneOffset(),
            time,
            brandId,
            queryStringVariables: queryParams,
            bacId,
            agentId,
            bookLater,
          };
          if (transferRequest) {
            reservation.transferRequest = transferRequest;
          }
          try {
            await setDoc(bookLater ? futureReservationsRef : reservationRef, reservation, {
              merge: true,
            });
            trackAdobe('single-tour');
            setIsLoading(false);
            resetForm();
            setSearchParams({ ...searchParams, brandId, uid: reservation.id });
          } catch (err) {
            console.error(err);
            toast.error(`Something went wrong! ${err.message}`);
            setStatus({ success: false });
            setIsLoading(false);
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          validateForm,
          submitForm,
          isSubmitting,
          touched,
          values,
          isValid,
        }): JSX.Element => {
          const handleCheckForm = (event: React.ChangeEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (isValid) {
              handleSubmit(event);
            }
          };
          trackErrorMessages(errors);
          return (
            <Card className={styles.root}>
              <Grid className={styles.gridWrapper}>
                <Element name="errorElement" className={styles.errorElement}>
                  <ErrorComponent
                    containerId="errors"
                    condition={!isValid && validateErrors}
                    errors={errors}
                    dataDTM="book 1-1 session"
                  />
                </Element>

                <Box className={styles.textBox}>
                  <Typography variant="modalTitle" component="h2" className={styles.modalTitle}>
                    {t('Book Your')} <span className="blue-text">{t('1-on-1 Demo')}</span>
                  </Typography>

                  <Typography variant="modalSubText" component="p" className={styles.modalSubText}>
                    {t(
                      "Curious about all things Ultium Home? Get your questions answered by a Home Energy Specialist in real-time. Simply select a date and the topic you're most interested in.",
                    )}
                  </Typography>

                  <Typography
                    variant="modalSubText"
                    component="p"
                    className={clsx(
                      styles.modalSubText,
                      styles.modalSubTextBottomEnergy,
                      styles.modalSubTextBottom,
                    )}
                    dangerouslySetInnerHTML={{
                      __html: t<string>(
                        'By providing my contact information below, I consent that GM and/or a GM dealer can contact me with offers and product information.',
                      ),
                    }}
                  />
                </Box>

                <form
                  id="single-tour"
                  onSubmit={handleCheckForm}
                  noValidate
                  className={styles.form}
                >
                  <Box className={styles.boxContainer}>
                    <Container className={styles.wrapperContainer}>
                      <Grid>
                        <Grid container spacing={3} className={styles.gridContainer}>
                          {/* Order Number */}
                          <GridWrapper item md={6} xs={12} id="orderNumber">
                            <InputLabel className={styles.formLabel}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t('Vehicle Purchased Order Number (Required):'),
                                }}
                              />
                            </InputLabel>

                            <TextFieldForm
                              className="stat-input-field"
                              data-dtm="book 1-1 session"
                              error={Boolean(touched.orderNumber && errors.orderNumber)}
                              helperText={touched.orderNumber && errors.orderNumber}
                              name="orderNumber"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.orderNumber}
                              variant="filled"
                              label={t('Vehicle Purchased Order Number')}
                              fullWidth
                              required
                            />
                          </GridWrapper>

                          {/* VIN */}
                          <GridWrapper item md={6} xs={12} id="vin">
                            <InputLabel className={styles.formLabel}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t('VIN (Optional):'),
                                }}
                              />
                            </InputLabel>

                            <TextFieldForm
                              className="stat-input-field"
                              data-dtm="book 1-1 session"
                              error={Boolean(touched.vin && errors.vin)}
                              helperText={touched.vin && errors.vin}
                              name="vin"
                              type="text"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.vin}
                              variant="filled"
                              label={t('VIN (Optional)')}
                              fullWidth
                              required
                            />
                          </GridWrapper>

                          {/* First Name */}
                          <GridWrapper item md={6} xs={12} id="firstName">
                            <InputLabel className={styles.formLabel}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t('First Name (Required):'),
                                }}
                              />
                            </InputLabel>

                            <TextFieldForm
                              className="stat-input-field"
                              data-dtm="book 1-1 session"
                              error={Boolean(touched.firstName && errors.firstName)}
                              helperText={touched.firstName && errors.firstName}
                              name="firstName"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                              variant="filled"
                              label={t('First Name')}
                              fullWidth
                              required
                            />
                          </GridWrapper>

                          {/* Last Name */}
                          <GridWrapper item md={6} xs={12} id="lastName">
                            <InputLabel className={styles.formLabel}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t('Last Name (Required):'),
                                }}
                              />
                            </InputLabel>

                            <TextFieldForm
                              className="stat-input-field"
                              data-dtm="book 1-1 session"
                              error={Boolean(touched.lastName && errors.lastName)}
                              helperText={touched.lastName && errors.lastName}
                              name="lastName"
                              type="text"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.lastName}
                              variant="filled"
                              label={t('Last Name')}
                              fullWidth
                              required
                            />
                          </GridWrapper>

                          {/* Email */}
                          <GridWrapper item md={6} xs={12} id="email">
                            <InputLabel className={styles.formLabel}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t('Email Address (Required):'),
                                }}
                              />
                            </InputLabel>

                            <TextFieldForm
                              className="stat-input-field"
                              data-dtm="book 1-1 session"
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              name="email"
                              type="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              variant="filled"
                              label={t('Email')}
                              fullWidth
                              required
                            />
                          </GridWrapper>

                          {/* Confirm Email */}
                          <GridWrapper item md={6} xs={12} id="confirmEmail">
                            <InputLabel className={styles.formLabel}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t('Confirm Email Address (Required):'),
                                }}
                              />
                            </InputLabel>

                            <TextFieldForm
                              className="stat-input-field"
                              data-dtm="book 1-1 session"
                              error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                              helperText={touched.confirmEmail && errors.confirmEmail}
                              name="confirmEmail"
                              type="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.confirmEmail}
                              variant="filled"
                              label={t('Confirm Email')}
                              fullWidth
                              required
                            />
                          </GridWrapper>

                          {/* Zip Code */}
                          <GridWrapper item md={6} xs={12} id="zipCode">
                            <InputLabel className={styles.formLabel}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: t('Zip Code (Required):'),
                                }}
                              />
                            </InputLabel>

                            <TextFieldForm
                              className="stat-input-field"
                              data-dtm="book 1-1 session"
                              error={Boolean(touched.zipCode && errors.zipCode)}
                              helperText={touched.zipCode && errors.zipCode}
                              name="zipCode"
                              type="text"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.zipCode}
                              variant="filled"
                              label={t('Zip Code')}
                              fullWidth
                              required
                            />
                          </GridWrapper>

                          {/* Phone Number */}
                          <GridWrapper item md={6} xs={12} id="phoneNumber">
                            <TextFieldForm
                              className="stat-input-field"
                              data-dtm="book 1-1 session"
                              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                              helperText={touched.phoneNumber && errors.phoneNumber}
                              name="phoneNumber"
                              type="tel"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.phoneNumber}
                              variant="filled"
                              label={t('Phone Number')}
                              fullWidth
                              required
                            />

                            {/* https://application.dentsu.com/browse/ENERGYL-126 - Suppress checkbox */}
                            {/* <Box className={styles.textReminderContainer}>
                                  <Checkbox
                                    className={styles.checkbox}
                                    checked={values.sendText}
                                    onChange={handleChange}
                                    size="small"
                                    name="sendText"
                                    color="primary"
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />

                                  <Typography
                                    component="p"
                                    className={styles.modalSubTextBottomEnergy}
                                  >
                                    {t('Text me a reminder about my upcoming demo.')}
                                  </Typography>
                                </Box> */}
                          </GridWrapper>

                          {/* Topic */}
                          {/* <GridWrapper item md={6} xs={12} id="topicId">
                                <GridWrapper
                                  className={clsx(
                                    styles.selectWrapper,
                                    vehicleSelectOpen
                                      ? styles.selectOpenState
                                      : styles.selectCloseState,
                                  )}
                                >
                                  <TextFieldForm
                                    id="topic"
                                    type="text"
                                    className={clsx(
                                      styles.vehicleSelect,
                                      vehicleSelectOpen ? styles.vehicleSelectOpen : null,
                                      selectedTopic && styles.topicSelected,
                                      'stat-expand-icon',
                                    )}
                                    error={Boolean(touched.topicId && errors.topicId)}
                                    data-dtm="book 1-1 session:topic"
                                    variant="filled"
                                    label={t<string>('Topic')}
                                    InputProps={{
                                      onClick: handleToggle,
                                      readOnly: true,
                                      value: selectedTopic
                                        ? t(selectedTopic).toString()
                                        : !selectedTopic
                                        ? ''
                                        : t<string>('Topic'),
                                    }}
                                  />

                                  {vehicleSelectOpen && (
                                    <RadioField
                                      name="topic"
                                      value={values.topicId || t('Available Topics')}
                                      onChange={async (event) => {
                                        const topicId = event.target.value;
                                        const topic = topics.find((x) => x.id === topicId) as Topic;

                                        setFieldValue('topicId', topicId);
                                        setFieldValue('tourName', topic.name);
                                        setFieldValue('dateString', '');
                                        setFieldValue('date', ''); // reset the date GL-767
                                        setSelectedTimeslot(null); // reset the date GL-767
                                        handleChange(event);
                                        setSelectedTourId(topicId);
                                        setIsPreSelected(false);
                                        setSelectedTopic(topic.name as any);
                                      }}
                                    >

                                    {topics.map(
                                        (topicCategory) =>
                                          topicCategory.topics &&
                                          topicCategory.topics
                                            .filter((t) => t.language === languageCode)
                                            .map((topic) => (
                                              <FormControlLabel
                                                data-dtm="book tour:topic"
                                                key={topicCategory.id}
                                                value={topicCategory.id}
                                                className={clsx(
                                                  'stat-checkbox',
                                                  topicCategory.id === selectedTourId
                                                    ? 'topic-selected'
                                                    : null,
                                                )}
                                                control={
                                                  <Radio
                                                    value={topicCategory.id}
                                                    checkedIcon={
                                                      <CheckIcon
                                                        className={clsx(
                                                          styles.checkIcon,
                                                          'check-icon',
                                                        )}
                                                      />
                                                    }
                                                    icon={
                                                      <CheckBoxOutlineBlankIcon
                                                        className={styles.checkboxOutline}
                                                      />
                                                    }
                                                    checked={topicCategory.id === selectedTourId}
                                                  />
                                                }
                                                label={t(topic.topicName).toString()}
                                              />
                                            )),
                                      )}
                                    </RadioField>
                                  )}
                                  <div
                                    className="chevron-icon"
                                    style={{
                                      backgroundImage: `${
                                        vehicleSelectOpen
                                          ? theme.palette.background.chevronOpenStateIcon
                                          : theme.palette.background.chevronClosedStateIcon
                                      }`,
                                    }}
                                  ></div>
                                </GridWrapper>

                                {touched.topicId && errors.topicId && (
                                  <FormHelperText error>
                                    {t('Please select a topic.')}
                                  </FormHelperText>
                                )}
                              </GridWrapper> */}

                          {/* Date */}
                          {/* <GridWrapper item md={6} xs={12} id="date">
                                <GridWrapper className={styles.selectWrapper}>
                                  <TextFieldForm
                                    type="text"
                                    error={Boolean(touched.date && errors.date)}
                                    className={clsx(
                                      styles.datePicker,
                                      calendarOpen ? styles.calendarOpen : null,
                                      selectedDate ? styles.dateSelected : null,
                                    )}
                                    variant="filled"
                                    label={t<string>('Select A Date')}
                                    InputProps={{
                                      readOnly: true,
                                      onClick: handleDateToggle,
                                      value: selectedDate
                                        ? format(selectedDate, dateFormat, { locale })
                                        : '',
                                    }}
                                    required
                                  />
                                  {touched.date && errors.date && !calendarOpen && (
                                    <FormHelperText error>
                                      {t('Please select a date and time.')}
                                    </FormHelperText>
                                  )}
                                  {calendarOpen && (
                                    // TODO: use adapterLocale instead of locale
                                    <div
                                      className={`mui-calendar-picker-container ${
                                        languageCode === 'en-FR'
                                          ? 'mui-calendar-picker-container--french'
                                          : ''
                                      }`}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={locale}
                                      >
                                        <StaticDatePicker
                                          displayStaticWrapperAs="desktop"
                                          minDate={today}
                                          maxDate={addDates}
                                          openTo="day"
                                          value={values.dateString}
                                          shouldDisableDate={isBlackoutDate}
                                          onChange={async (value) => {
                                            setLoadingHours(true);
                                            setCalendarOpen((prevShow) => !prevShow), [];
                                            setShowTimePicker(true);
                                            const dateString = value.toString();
                                            setFieldValue('dateString', dateString);
                                            setSelectedDate(value);

                                            await getReservationSpots(value);
                                            setLoadingHours(false);
                                          }}
                                          label=" "
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              error={Boolean(touched.date && errors.date)}
                                              fullWidth
                                              helperText={touched.date && errors.date}
                                              name="date"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              variant="outlined"
                                              required
                                            />
                                          )}
                                        />
                                      </LocalizationProvider>
                                    </div>
                                  )}

                                  {loadingHours && <LinearProgress />}

                                  {showTimePicker && (
                                    <Grid className={styles.timePickerContainer}>
                                      <Typography
                                        component="p"
                                        variant="timeTitle"
                                        dangerouslySetInnerHTML={{
                                          __html: t('Select your preferred tour time'),
                                        }}
                                      />
                                      <GridWrapper className="select-time-container">
                                        <Typography component="p" variant="timeDateTitle">
                                          {format(selectedDate, dateFormat, { locale })}
                                        </Typography>
                                        {timeSlots && showTimePicker && timeSlots.length > 0 ? (
                                          <RadioGroup
                                            name="date"
                                            onChange={(event) => {
                                              const date = event.currentTarget.value;
                                              setFieldValue('date', new Date(date));
                                            }}
                                          >
                                            {timeSlots.map((reservationSpot) => {
                                              const remaining =
                                                getReservationRemaining(reservationSpot);

                                              const handleTimeSlotClick = () => {
                                                if (reservationSpot === selectedTimeslot) {
                                                  setSelectedTimeslot(null);
                                                  setFieldValue('date', null);
                                                } else {
                                                  setSelectedTimeslot(reservationSpot);
                                                }
                                              };

                                              return (
                                                <FormControlLabel
                                                  className={clsx(
                                                    'stat-checkbox',
                                                    styles.timeWrapper,
                                                    selectedTimeslot === reservationSpot
                                                      ? styles.timeSelected
                                                      : '',
                                                  )}
                                                  data-dtm="book 1-1 session:time"
                                                  key={reservationSpot.toString()}
                                                  value="date"
                                                  control={
                                                    <Radio
                                                      disabled={remaining <= 0}
                                                      value={reservationSpot}
                                                      checked={
                                                        selectedTimeslot === reservationSpot
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={handleTimeSlotClick}
                                                      checkedIcon={
                                                        <CheckBoxIcon
                                                          className={
                                                            styles.checkBoxOutlineBlankIcon
                                                          }
                                                        />
                                                      }
                                                      icon={
                                                        <CheckBoxOutlineBlankIcon
                                                          className={
                                                            styles.checkBoxOutlineBlankIcon
                                                          }
                                                        />
                                                      }
                                                    />
                                                  }
                                                  // TODO: The below box should be a reusable component
                                                  label={
                                                    <Box
                                                      sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        fontSize: {
                                                          xs: '0.9rem',
                                                          md: '1rem',
                                                        },
                                                      }}
                                                    >
                                                      {languageCode !== 'en-US'
                                                        ? moment(reservationSpot).format('HH:mm')
                                                        : moment(reservationSpot).format('hh:mm A')}
                                                      {remaining <= warningSlots ? (
                                                        <Box className={styles.numberOfOpenSlots}>
                                                          {remaining} {t('Remaining')}
                                                        </Box>
                                                      ) : (
                                                        ''
                                                      )}
                                                    </Box>
                                                  }
                                                />
                                              );
                                            })}
                                          </RadioGroup>
                                        ) : (
                                          <Typography component="p" variant="timeTitle">
                                            {t('No times available')}
                                          </Typography>
                                        )}
                                      </GridWrapper>
                                    </Grid>
                                  )}
                                </GridWrapper>
                              </GridWrapper> */}
                        </Grid>

                        {/* Submit Buttons */}
                        <Box
                          className={`${styles.buttonContainer} ${
                            isModifyReservation ? styles.buttonContainerisModifyReservation : ''
                          }`}
                          gap={2}
                        >
                          <Box
                            className={`${styles.buttonsContainer} ${
                              isModifyReservation ? styles.buttonsContainerIsModifyReservation : ''
                            }`}
                          >
                            {isModifyReservation && (
                              <TextButton
                                className={`${styles.cancelReservation} stat-text-link`}
                                data-dtm="book 1-1 session"
                                onClick={handelCancelReservation}
                                disableRipple
                                variant="tertiary"
                                type="button"
                                aria-label="Cancel Reservation"
                              >
                                {t('Cancel Reservation')}
                              </TextButton>
                            )}

                            {/* <LoadingButton
                                  loading={isLoading}
                                  className={clsx('stat-button-link', styles.submitButton)}
                                  data-dtm="book 1-1 session"
                                  onClick={() => {
                                    setBookLater(false);
                                    validateForm()
                                      .then((errors) => {
                                        return trackErrorMessages(errors);
                                      })
                                      .then(() => submitForm())
                                      .catch((e) => new Error(e));
                                    setValidateErrors(true);
                                    executeScroll();
                                  }}
                                  disabled={isSubmitting}
                                  variant="primary"
                                  aria-label="Book Now"
                                >
                                  {t('Book Now')}
                                </LoadingButton> */}
                          </Box>

                          <LoadingButton
                            loading={isLoading}
                            className={clsx('stat-button-link', styles.submitButton)}
                            data-dtm="book 1-1 session"
                            onClick={() => {
                              setBookLater(true);
                              validateForm()
                                .then((errors) => trackErrorMessages(errors))
                                .then(() => submitForm())
                                .catch((e) => new Error(e));
                              setValidateErrors(true);
                              executeScroll();
                            }}
                            variant="primary"
                            disabled={isSubmitting}
                            aria-label="Customer To Book Later"
                          >
                            {t('Customer To Book Later')}
                          </LoadingButton>
                        </Box>
                      </Grid>
                    </Container>
                  </Box>
                </form>
              </Grid>
            </Card>
          );
        }}
      </Formik>
    );
  }
};

export default DealerReservationView;
