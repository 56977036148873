import { createContext, useEffect, useReducer, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes, { any } from 'prop-types';
import * as firebase from 'firebase/auth';
import { User, Role } from '../types';
import { userRepository } from '../repositories/user';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'Firebase';
  createUser: (user: Partial<firebase.UserInfo>) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInAnonymously: () => Promise<void>;
  logout: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<any>;
  resolver: any;
  setResolverValue: (object: any) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type Action = AuthStateChangedAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state: State, action: Action): State => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Firebase',
  createUser: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInAnonymously: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
  resolver: any,
  setResolverValue: () => Promise.resolve(),
});

// TODO: We should be using the firebase auth provider or all of this
export const AdminAuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.onAuthStateChanged(firebase.getAuth(), async (user) => {
        if (user) {
          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.
          const idTokenResult = await user.getIdTokenResult(true);
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                id: user.uid,
                avatar: user.photoURL,
                email: user.email,
                name: user.displayName,
                role: idTokenResult.claims.role as Role,
                brands: ['cadillac'],
              },
            },
          });
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [dispatch],
  );

  const signInWithEmailAndPassword = (
    email: string,
    password: string,
  ): Promise<firebase.UserCredential> =>
    firebase.signInWithEmailAndPassword(firebase.getAuth(), email, password);

  const signInAnonymously = (): Promise<any> => firebase.signInAnonymously(firebase.getAuth());

  const createUser = async (user: Partial<firebase.UserInfo>): Promise<firebase.UserInfo> =>
    userRepository.create(user);

  const logout = async (): Promise<void> => {
    await firebase.getAuth().signOut();
  };

  const sendPasswordResetEmail = (email: string): Promise<void> =>
    firebase.sendPasswordResetEmail(firebase.getAuth(), email);

  const [resolver, setResolver] = useState();

  const setResolverValue = (object: any) => {
    setResolver(object);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        createUser,
        signInWithEmailAndPassword,
        signInAnonymously,
        logout,
        sendPasswordResetEmail,
        resolver,
        setResolverValue,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AdminAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
