import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { brands } from '@constants';
import { Language } from '@types';
import { useQueryString } from './useQueryString';
import { useCurrentLanguageParam } from './useCurrentLanguageParam';

export const useCurrentLanguage = (brandOverride?: string): any => {
    const { urlSearchParams } = useQueryString();
    const brandId = brandOverride || urlSearchParams.get('brandId') || brands[0];
    const { lang } = useCurrentLanguageParam();

    const languageRef = doc(useFirestore(), `languages/${lang}`);
    const { data: language } = useFirestoreDocData(languageRef, {
        idField: 'id',
    });

    const brandLanguageRef = doc(useFirestore(), `brands/${brandId}/languages/${lang}`);
    const { data: brandLanguage } = useFirestoreDocData(brandLanguageRef, {
        idField: 'id',
    });

    let resources = {};
    resources[lang] = {
        translation: {}
    }
    let translationValues = {};

    if (language && brandLanguage) {

        // TODO Move this to a function
        (language as Language).translations.forEach(t => {
            translationValues[t.key] = t.value;
        });
        (brandLanguage as Language).translations.forEach(t => {
            translationValues[t.key] = t.value;
        });

        resources[lang].translation = translationValues;

        return { lang, resources };
    }

    return null;
}
