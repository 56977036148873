import { useCallback, useEffect, useState } from 'react';
import { hoursOfOperationRepository } from '../repositories/hoursOfOperation';
import { BrandType } from '@types';
import { ShowroomHour, Hours } from '../types/hours';

export const useHours = (date: Date, brandId: BrandType) => {
  const [hours, setHours] = useState<Hours>();
  const [weeklyHours, setWeeklyHours] = useState<ShowroomHour[]>([]);

  const getHours = useCallback(async () => {
    await hoursOfOperationRepository.getHoursOfOperation(date, brandId)
      .then((hour: Hours) => {
        setHours(hour);
      });

    await hoursOfOperationRepository
      .getWeeklyHoursOfOperation(date, brandId, date.getTimezoneOffset())
      .then((showroomHour: any) => {
        setWeeklyHours(showroomHour);
      });
  }, [hoursOfOperationRepository]);

  useEffect(() => {
    getHours();
  }, [getHours]);

  return { hours, weeklyHours };
};
