import { useTheme } from '@material-ui/core/styles';

export const InviteAFriendModalStyles = (brandId: string) => {
  const theme = useTheme();

  const chevyStyles = {
    form: {
      marginTop: '35px',
    },
    formLegend: {},
    modalDisclaimer: {},
    modalProvideEmailAddress: {
      marginTop: '20px',
    },
    buttonContainer: {
      marginTop: 43,
      textAlign: 'right',
    },
    errorElement: {},
    '@global': {
      '.MuiTypography-modalSubText a': {
        fontWeight: 700,
        textDecoration: 'underline',
        color: theme.palette.color.privacyStatement,
      },
      '.privacy-statement-link': {
        fontWeight: 700,
        textDecoration: 'underline',
        color: theme.palette.color.privacyStatement,
      },
      '.MuiOutlinedInput-notchedOutline .notranslate': {
        display: 'none',
      },
    },
    submitButton: {},
    cancelBtn: {},
  };

  const cadillacStyles = {
    modalSubTitle: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: '34px',
      paddingRight: '34px',
    },
    modalDisclaimer: {
      padding: '13px',
      height: 90,
      maxHeight: 90,
      overflowY: 'scroll',
      border: '1px solid #424242',
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(255, 255, 255, 1)',
      },
    },
    buttonContainer: {
      marginTop: '65px',
      textAlign: 'center',
      '@media screen and (min-width: 768px)': {
        marginTop: '55px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      },
    },
    cancelBtn: {
      marginTop: '10px',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  };

  const cadillaccanStyles = {
    ...cadillacStyles,
    // modalDisclaimer: {
    //   ...cadillacStyles.modalDisclaimer,
    //   '&::-webkit-scrollbar-thumb': {
    //     backgroundColor: 'rgba(0, 0, 0, 0.2)',
    //   },
    //   '&::-webkit-scrollbar-thumb:hover': {
    //     backgroundColor: 'rgba(0, 0, 0, 1)',
    //   },
    // },
  };

  const gmcStyles = {
    modalSubTitle: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '15px',
      textAlign: 'center',
      '@media screen and (min-width: 768px)': {
        paddingLeft: '34px',
        paddingRight: '34px',
        paddingBottom: '20px',
      },
    },
    modalDisclaimer: {},
    buttonContainer: {
      marginTop: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '@media screen and (min-width: 768px)': {
        marginTop: '55px',
        justifyContent: 'flex-start',
        flexDirection: 'row',
      },
    },
    submitButton: {
      width: 240,
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 350,
      },
    },
    cancelBtn: {
      width: 240,
      marginTop: '25px',
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: '45px',
        width: '100%',
        maxWidth: 350,
      },
    },
    '@global': {
      // GL-1470
      '.MuiTypography-modalSubText': {
        fontSize: 12,
        lineHeight: '15px',
        '@media screen and (min-width: 768px)': {
          fontSize: 14,
          lineHeight: '18px',
        },
      },
      // END
      '.MuiTypography-modalSubText a': {
        display: 'table',
        fontWeight: 'inherit !important',
      },
      '.MuiContainer-root': {
        padding: '0px !important',
      },
      '.MuiGrid-container': {
        width: 'calc(100% + 25px)',
        marginLeft: '-25px',
        [theme.breakpoints.up(768)]: {
          width: 'calc(100% + 45px)',
          marginLeft: '-45px',
        },
      },
      '.MuiGrid-container.error-wrapping-container, .error-container': {
        width: '100%',
        marginLeft: '0',
        [theme.breakpoints.up(768)]: {
          width: '100%',
          marginLeft: '0',
        },
      },
      '.MuiGrid-item': {
        paddingTop: '26px !important',
        paddingLeft: '25px !important',
        [theme.breakpoints.up(768)]: {
          paddingTop: '30px',
          paddingLeft: '45px !important',
        },
      },
    },
  };

  const buickStyles = {
    form: {
      marginTop: '35px',
      '@media screen and (min-width: 768px)': {
        marginTop: '40px',
      },
    },
    modalSubTitle: {
      width: '100%',
      paddingBottom: 10,
      textAlign: 'center',
      '@media screen and (min-width: 768px)': {
        paddingBottom: 20,
        textAlign: 'left',
      },
    },
    modalProvideEmailAddress: {
      marginTop: 10,
      '@media screen and (min-width: 768px)': {
        marginTop: 20,
      },
    },
    buttonContainer: {
      marginTop: '35px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '@media screen and (min-width: 768px)': {
        marginTop: '65px',
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    },
    submitButton: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc(50% + 30px)',
        maxWidth: 'calc(50% + 30px)',
      },
      [theme.breakpoints.up('lg')]: {
        width: 380,
        maxWidth: 380,
      },
    },
    cancelBtn: {
      width: '100%',
      marginTop: '25px',
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: '45px',
        width: 'calc(50% + 30px)',
        maxWidth: 'calc(50% + 30px)',
      },
      [theme.breakpoints.up('lg')]: {
        width: 380,
        maxWidth: 380,
      },
    },
    '@global': {
      '.MuiTypography-modalSubText': {
        textAlign: 'center',
        [theme.breakpoints.up(768)]: {
          textAlign: 'left',
        },
      },
      '.MuiTypography-modalSubText a': {
        display: 'table',
        width: '100%',
        fontWeight: 'inherit !important',
        [theme.breakpoints.up(768)]: {
          textAlign: 'left',
          width: 'unset',
        },
      },
      '.MuiGrid-container': {
        width: 'calc(100% + 20px)',
        marginLeft: '-20px',
        [theme.breakpoints.up(768)]: {
          width: 'calc(100% + 50px)',
          marginLeft: '-50px',
        },
      },
      '.MuiGrid-item': {
        paddingTop: '20px !important',
        paddingLeft: '20px !important',
        [theme.breakpoints.up(768)]: {
          paddingTop: '30px !important',
          paddingLeft: '50px !important',
        },
      },
    },
  };

  const evliveStyles = {
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: '48px',
      [theme.breakpoints.up(768)]: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      },
    },
    submitButton: {
      width: '100%',
      marginBottom: 12,
    },
    cancelBtn: {
      width: '100%',
      textDecoration: 'underline',
      [theme.breakpoints.up(768)]: {
        width: 'auto',
        maxWidth: 'none',
      },
    },
    '@global': {
      '.MuiTypography-modalSubText': {
        textAlign: 'center',
        [theme.breakpoints.up(768)]: {
          textAlign: 'left',
        },
      },
      '.MuiTypography-modalSubText a': {
        width: '100%',
        [theme.breakpoints.up(768)]: {
          textAlign: 'left',
          width: 'unset',
        },
      },
    },
  };

  const evliveCanadaStyles = {
    ...evliveStyles,
    modalSubTitle: {
      paddingBottom: 16,

      [theme.breakpoints.up(768)]: {
        paddingBottom: 14,
      },
    },
    modalDisclaimer: {
      '@global': {
        '.MuiTypography-modalSubText': {
          marginBottom: 10,
        },
      },
    },
    modalProvideEmailAddress: {
      marginTop: 0,
      marginBottom: 0,
    },
    // Might create a typography variant for this
    formLegend: {
      marginBottom: 20,
      fontFamily: 'Overpass',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: 18,
      lineHeight: '23px',
      color: theme.palette.grey[300],
      [theme.breakpoints.up(768)]: {
        marginBottom: 24,
      },
    },
    '@global': {
      '.MuiTypography-modalSubText': {
        textAlign: 'left',
      },
    },
  };

  const energyStyles = {
    form: {
      marginTop: '16px',
      '@media screen and (min-width: 768px)': {
        marginTop: '32px',
      },
    },
    buttonContainer: {
      marginTop: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up(768)]: {
        marginTop: '48px',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
      },
    },
    submitButton: {
      width: '218px',
      maxWidth: '218px',
      [theme.breakpoints.down(768)]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
    yourInformation: {
      marginTop: 0,
      marginBottom: 32,
      fontFamily: 'Overpass',
      fontSize: 22,
      fontWeight: 700,
      lineHeight: '28px',
      [theme.breakpoints.up(768)]: {
        marginBottom: 57,
      },
    },
    yourFriendInformation: {
      marginBottom: 0,
    },
    yourFriendInformationRequire: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: '12px !important',
      lineHeight: '16px !important',
      textAlign: 'left',
    },
    yourFriendInformationCont: {
      marginTop: '32px',
      marginBottom: '32px',
      [theme.breakpoints.up(768)]: {
        marginTop: '0px',
        marginBottom: '25px',
      },
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      [theme.breakpoints.up(768)]: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
      },
    },
    divider: {
      display: 'none',
      marginLeft: 36,
      marginRight: 36,
      width: '1px',
      height: 415,
      border: '1px solid #B3B3B3',
      [theme.breakpoints.up(768)]: {
        display: 'block',
      },
    },
    formContainerLeft: {
      [theme.breakpoints.up(768)]: {
        width: '326px',
      },
    },
    formContainerRight: {
      [theme.breakpoints.up(768)]: {
        width: '326px',
      },
    },
    cancelBtn: {
      marginTop: '16px',
      width: '100%',
      maxWidth: '100%',
      [theme.breakpoints.up(768)]: {
        marginTop: 0,
        marginRight: 10,
        width: '218px',
        maxWidth: '218px',
      },
    },
    '@global': {
      '.MuiGrid-item': {
        paddingTop: '16px !important',
        [theme.breakpoints.up(768)]: {
          paddingTop: '35px !important',
        },
      },
      '.MuiInputLabel-root': {
        fontFamily: 'TestFoundersGrotesk-Regular',
      },
      '.MuiFormHelperText-root.Mui-error': {
        '&:before': {
          backgroundImage: theme.palette.background.warningRedIcon,
        },
        '@media screen and (min-width: 768px)': {
          position: 'relative',
          bottom: 0,
        },
      },
    },
  };

  const styles = {
    chevy: {
      ...chevyStyles,
    },
    cadillac: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillacStyles,
      },
    },
    cadillaccan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...cadillaccanStyles,
      },
    },
    gmc: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...gmcStyles,
      },
    },
    buick: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...buickStyles,
      },
    },
    ev: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveStyles,
      },
    },
    evcan: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...evliveCanadaStyles,
      },
    },
    energy: {
      ...chevyStyles,
      [theme.breakpoints.up('xs')]: {
        ...energyStyles,
      },
    },
  };

  return styles[brandId];
};
