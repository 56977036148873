import { Box } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { VehicleFeature } from '@types';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleFeatureViewStyles } from './VehicleFeatureView.style';

type VehicleFeatureViewProps = {
  vehicleFeature: VehicleFeature;
  onSelectVehicleFeature: (vehicleFeature: VehicleFeature) => void;
  vehicleFeatureState: any;
  brandId: string;
  vehicleStateName: string;
};

const VehicleFeatureView = (props: VehicleFeatureViewProps): ReactElement => {
  const { vehicleFeature, onSelectVehicleFeature, vehicleFeatureState, brandId, vehicleStateName } = props;
  const { t } = useTranslation();
  const useStyles = makeStyles(VehicleFeatureViewStyles(brandId));
  const styles = useStyles();

  return (
    <Box
      className={`stat-image-link vehicle-feature-view ${styles.vehicleFeatureView}`}
      data-dtm={`vehicle features:${vehicleStateName.toLowerCase()}`}
    >
      <Box className={`${styles.vehicleFeatureViewImageContainer} ${vehicleFeatureState.id === vehicleFeature.id ? styles.vehicleFeatureViewImageContainerActive : ''}`}>
        <Box
          className={styles.vehicleFeatureViewImage}
          component="img"
          src={vehicleFeature.imageUrl}
          alt={vehicleFeature.title}
          onClick={() => {
            onSelectVehicleFeature(vehicleFeature);
          }}
          aria-label="Select vehicle feature"
        />
      </Box>
      <Box
        className={`${styles.vehicleFeatureViewContent} ${vehicleFeatureState.id === vehicleFeature.id ? styles.vehicleFeatureViewContentActive : ''}`}
        onClick={() => {
          onSelectVehicleFeature(vehicleFeature);
        }}
      >
        <Typography
          variant="vehicleFeatureCategory"
          component="h2"
        >
          {t(vehicleFeature.category)}
        </Typography>
        {brandId === 'chevy' && (
          <Button
            className={`${styles.vehicleFeatureViewLink} ${vehicleFeatureState.id === vehicleFeature.id ? styles.vehicleFeatureViewLinkActive : ''
              }`}
            variant="view"
            disableRipple
            aria-label="View vehicle feature"
          >
            {t('View')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default VehicleFeatureView;
