import { Box, Typography, IconButton, Button } from '@mui/material';
import { BrandType } from '@types';
import ModalIconButton from './ModalIconButton';

const CadillacLogo = require('../assets/images/modal/cadillac-logo.png');
const CadillacBookTestDriveSubmittedImage = require('../assets/images/modal/book-test-drive-submitted.png');

type BookTestDriveSubmittedModalProps = {
  brandId: BrandType;
  onClose?: () => void;
};

const BookTestDriveSubmittedModal = (props: BookTestDriveSubmittedModalProps) => {
  const { brandId, onClose } = props;
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';

  return (
    <Box className="modal-container">

      <ModalIconButton
        onClose={onClose}
        brandId={brandId}
      />

      {(isCadillacUS || isCadillacCanada) && (
        <>
          <Box className="modal-image-container">
            <Box
              className="modal-image"
              component="img"
              src={CadillacBookTestDriveSubmittedImage.default}
              alt="Modal Banner"
            />
            {isCadillacUS && (
              <Box
                className="modal-brand-logo"
                component="img"
                src={CadillacLogo.default}
                alt="Cadillac"
              />
            )}
          </Box>
        </>
      )}

      <Box className="modal-text-container">
        <Typography
          variant="modalTitle"
          component="h2"
        >
          Your request for a test drive has been submitted
        </Typography>
      </Box>

      <Box className="modal-container-buttons">
        <Button
          onClick={onClose}
          variant="primary"
          type="button"
          disableRipple
          aria-label="close modal"
        >
          Return
        </Button>
      </Box>

    </Box>
  );
};

export default BookTestDriveSubmittedModal;