import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ConsentModalProps = {
  onClose?: () => void;
  // onLeavingSessionModal?: (hyperlink: string) => void;
};

const ConsentModal = (props: ConsentModalProps) => {
  const { onClose } = props;
  const { t } = useTranslation();

  // const handleCheckUrl = (event) => {
  //   const textElement = document.getElementById('modalConsentText');
  //   if (textElement.querySelector('a') && event.target.tagName === 'A') {
  //     const linkHref = textElement.querySelector('a').href;
  //     onLeavingSessionModal(linkHref);
  //     event.preventDefault();
  //   }
  // };

  return (
    <Box className="consent-container">
      <Box className="consent-text-contianer">
        {/* <Typography variant="modalConsentText">
          By continuing, you consent to the information you provide being monitored and recorded by GM or those acting on GMs behalf, subject to the <a class="stat-text-link" data-dtm="modal:legal" href="https://www.gm.com/privacy-statement?evar25=gmenergy_energy-live_showroom" target="_blank" rel="noreferrer">GM Privacy Statement</a>
        </Typography> */}
        <Typography
          id="modalConsentText"
          variant="modalConsentText"
          // onClick={handleCheckUrl}
          dangerouslySetInnerHTML={{
            __html: t('Consent: By continuing, you consent to the information you provide being monitored and recorded by GM or those acting on GMs behalf, subject to the GM Privacy Statement')
          }}
        />
      </Box>
      <Box className="consent-button-contianer">
        <Button
          onClick={onClose}
          type="button"
          variant="primary"
          className="stat-button-link"
          data-dtm="modal:legal"
          disableRipple
          aria-label="Close"
        >
          {t('Close')}
        </Button>
      </Box>
    </Box>
  );
};

export default ConsentModal;
