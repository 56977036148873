import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { useScrollReset } from '@hooks';
import routes from './routes';
import { getFirestore } from 'firebase/firestore';
import { FirestoreProvider, StorageProvider, DatabaseProvider, useFirebaseApp } from 'reactfire';
import { getStorage } from 'firebase/storage';
import { PreloadScripts } from '@components';
import Theme from './Theme';
import { getDatabase } from 'firebase/database';

const App: FC = () => {
  const content = useRoutes(routes);
  useScrollReset();
  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(useFirebaseApp());
  const storage = getStorage(app);
  const databaseInstance = getDatabase(app);

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <PreloadScripts>
        <StorageProvider sdk={storage}>
          <DatabaseProvider sdk={databaseInstance}>
            <Theme>{content}</Theme>
          </DatabaseProvider>
        </StorageProvider>
      </PreloadScripts>
    </FirestoreProvider>
  );
};

export default App;
