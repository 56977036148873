/* eslint-disable */
import React, { useState, useCallback, useEffect, ReactElement } from 'react';
import { collection, doc, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import {
  Card,
  Box,
  InputLabel,
  TextField,
  Container,
  FormHelperText,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Button,
  FormControl,
  ListItemText,
  MenuItem,
  Checkbox,
  Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useBrandQueryString, useQueryString, useRedirectURL, useReporting } from '@hooks';
import {
  TextFieldForm,
  GridWrapper,
  TextButton,
  ErrorComponent,
  LoadingScreen,
} from '@components';
// @ts-ignore
import { hoursOfOperationRepository } from '@repositories';
import {
  Brand,
  BrandType,
  LanguageCode,
  Reservation,
  ReservationBookedTime,
  Topic,
  TransferRequest,
} from '@types';
import clsx from 'clsx';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import { evliveColors, regexEmailHelper } from '@constants';
import CheckIcon from '@mui/icons-material/Check';
import ReservationViewStyles from './ReservationView.style';
import * as Scroll from 'react-scroll';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { format } from 'date-fns';
import { getT3Dealer, trimSpaces } from '@utils';
import { ExceptionDayLocations } from '../types/exceptionDay';
import mergeWith from 'lodash/mergeWith';

const CalendarIcon = require('../assets/images/gmc/calendar.png');

declare let window: any;

type ReservationViewProps = {
  brandId: BrandType;
  languageCode: LanguageCode;
  reservationId: string;
  transferRequest?: TransferRequest;
  token?: string;
  preSelectedTour?: string;
  onCancel?: (t3RedirectUrl) => void;
  onGoBack?: (t3RedirectUrl) => void;
  onSubmit?: (reservation: Partial<Reservation>) => void;
  onTransferReq?: () => void;
  sessionBrand?: string;
};

const ReservationView = (props: ReservationViewProps): ReactElement => {
  // props
  const {
    brandId,
    languageCode,
    reservationId,
    token,
    preSelectedTour,
    transferRequest,
    onSubmit,
    onCancel,
    onGoBack,
    onTransferReq,
    sessionBrand,
  } = props;
  const dateFormat = languageCode !== 'en-US' ? 'EEEE, d MMMM, yyyy' : 'EEEE, MMMM d, yyyy';

  //constants
  const location: ExceptionDayLocations = 'Reservation';

  // hooks
  const theme = useTheme();
  const today = new Date();
  const { trackAdobe } = useReporting();
  const { t } = useTranslation();

  // state
  const [vehicleSelectOpen, setVehicleSelectOpen] = useState<boolean>(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [showTimePicker, setShowTimePicker] = useState<boolean>(false);
  const [timeSlots, setTimeSlots] = useState<Date[]>();
  const [loadingHours, setLoadingHours] = useState<boolean>(false);
  const [validateErrors, setValidateErrors] = useState<boolean>(false);
  const [isPreSelected, setIsPreSelected] = useState<boolean>(false);
  const [selectedTourIds, setSelectedTourIds] = useState<string[]>([]);
  const [addDates, setAddDates] = useState(today.getTime() + 14 * 24 * 60 * 60 * 1000);
  const [maxSlots, setMaxSlots] = useState<number>(6);
  const [warningSlots, setWarningSlots] = useState<number>(3);
  const [locale, setLocale] = useState(en);
  const [blackoutDates, setBlackoutDates] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVR, setIsVR] = useState<boolean>(false);
  const [maxSlotsLimit, setMaxSlotsLimit] = useState<number>(6);
  const [maxTopicsSelected, setMaxTopicsSelected] = useState<boolean>(false);

  useEffect(() => {
    switch (languageCode) {
      case 'es-MX':
        setLocale(es);
        break;
      case 'en-FR':
        setLocale(fr);
        break;
      default:
        setLocale(en);
        break;
    }
  }, [languageCode]);

  if (locale && locale.options) {
    locale.options.weekStartsOn = 0;
  }

  const queryParams = useBrandQueryString(brandId);
  const { urlSearchParams } = useQueryString();
  const subsetId = urlSearchParams.get('subset');

  //t3 query strings
  const dealer = urlSearchParams.get('dealer');
  const bac = urlSearchParams.get('bac');
  const evar122 = urlSearchParams.get('evar122');
  const evar109 = urlSearchParams.get('evar109');
  const evar25 = urlSearchParams.get('evar25');
  let t3RedirectUrl;

  if (dealer && bac) {
    const t3DealerBrand = getT3Dealer({ t3Dealer: dealer, brandId });
    t3RedirectUrl = useRedirectURL({ closedMode: { dealer: t3DealerBrand, bac, isOpen: false } });
  }
  
  const isCadillacUS = brandId === 'cadillac';
  const isCadillacCanada = brandId === 'cadillaccan';
  const isChevy = brandId === 'chevy';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId === 'buick';
  const isEVLive = brandId === 'ev' || brandId === 'evcan';
  const isEVLiveCanada = brandId === 'evcan';
  const receiveUpdates = (isCadillacCanada || isEVLiveCanada) && { receiveUpdates: '' };
  const isEnergy = brandId === 'energy';

  let receiveUpdatesFormCondition = null;

  if (isCadillacCanada || isEVLiveCanada) {
    receiveUpdatesFormCondition = Yup.string().required(t('Please make a selection.'));
  }

  //   --------- reservation modal -----------
  const isChevyBrandIdUrl = window.location.toString().includes('brandId=chevy');

  const vehicleSubsetRef = collection(useFirestore(), `brands/${brandId}/vehicleSubsets`);
  const vehicleSubsetQuery = query(
    vehicleSubsetRef,
    where('vehicleSubset', '==', subsetId)
  );
  const { data: vehicleSubset } = useFirestoreCollectionData(vehicleSubsetQuery, {
    idField: 'id',
  });

  const vehicleRef = collection(useFirestore(), `brands/${brandId}/vehicles`);
  const vehicleQuery = subsetId && vehicleSubset && vehicleSubset[0] && vehicleSubset[0].subsetType==='vehicle' && vehicleSubset[0].widgetIds.includes('Reservation')
    ? query(
      vehicleRef,
      where('subsetIds', 'array-contains', vehicleSubset[0].id),
      orderBy('index', 'asc')
    )
    : query(
      vehicleRef,
      where('widgetIds', 'array-contains', 'Reservation'),
      orderBy('index', 'asc')
    );
  const { data: vehicles } = useFirestoreCollectionData(vehicleQuery, {
    idField: 'id',
  });

  const reservationsRef = collection(useFirestore(), 'reservationBookedTimes');
  const reservationQuery = query(reservationsRef, where('brandId', '==', brandId));
  const { data: reservationBookedTimes } = useFirestoreCollectionData(reservationQuery, {
    idField: 'id',
  });

  const topicsRef = collection(useFirestore(), `brands/${brandId}/topics`);

  const topicsQuery = subsetId && vehicleSubset && vehicleSubset[0] && vehicleSubset[0].subsetType==='topic' && vehicleSubset[0].widgetIds.includes('Reservation')
    ? query(
      topicsRef,
      where('subsetIds', 'array-contains', vehicleSubset[0].id),
      orderBy('index', 'asc')
    )
    : query(
      topicsRef,
      where('widgetIds', 'array-contains', 'Reservation'),
      orderBy('index', 'asc')
    );

  const { data: topics } = useFirestoreCollectionData(topicsQuery, {
    idField: 'id',
  });

  const firestore = useFirestore();

  const brandRef = doc(firestore, 'brands', brandId);
  const { data: brand, status: brandLoadingStatus } = useFirestoreDocData(brandRef, {
    idField: 'id',
  });

  const ref = doc(firestore, 'reservations', reservationId);
  const { data: reservation, status: loadingStatus } = useFirestoreDocData(ref, { idField: 'id' });

  const handleDateToggle = useCallback(() => {
    if (selectedTourIds) {
      setCalendarOpen((prevShow) => !prevShow);
    }
  }, [selectedTourIds]);

  const handleToggle = useCallback(() => setVehicleSelectOpen((prevShow) => !prevShow), []);

  // update max slots by vehicle config
  const updateMaxSlots = () => {
    let maxTimeslots = brand.maxReservationSlots || 6;
    let warningTimeslots = brand.reservationSlotWarning || 3;

    let maxTimeslotsLimit = brand.maxReservationSlots || 6;
    let isVRVehicle = false;
  
    // if (!(isEVLive || isEnergy) && vehicles) {
    //   const vehicle = vehicles.find((v) => v.id === selectedTourId) as Vehicle;
    //   if (vehicle && vehicle.overrideReservationSpots) {
    //     maxTimeslots = vehicle.maxReservationSlots || 6;
    //     warningTimeslots = vehicle.reservationSlotWarning || 3;
    //     if (brand.maxReservationSlots <= vehicle.maxReservationSlots) {
    //       maxTimeslotsLimit = vehicle.maxReservationSlots;
    //     }
    //   }
    //   if (vehicle && vehicle.allowWebcamStreaming) {
    //     isVRVehicle = true;
    //   }
    // }

    if (topics && selectedTourIds) {
      // multiple topics, use brand config
      if (selectedTourIds.length > 1) {
        maxTimeslots = brand.maxReservationSlots;
        warningTimeslots = brand.reservationSlotWarning;
        maxTimeslotsLimit = brand.maxReservationSlots;
      } else {
        // single topic
        const topic = topics.find((v) => v.id === selectedTourIds[0]) as Topic;
        if (topic && topic.overrideReservationSpots) {
          maxTimeslots = topic.maxReservationSlots;
          warningTimeslots = topic.reservationSlotWarning;
          maxTimeslotsLimit = topic.maxReservationSlots;
        }
      }
    }

    setMaxSlots(maxTimeslots);
    setWarningSlots(warningTimeslots);
    setMaxSlotsLimit(maxTimeslotsLimit);
    setIsVR(isVRVehicle);
  };

  const getAllReservationRemaining = (timeslot: Date, tourIds: string[]) => {
    const remainingSlotsArray: number[] = [];

    // loop through tourIds to get remaining slots
    tourIds.forEach((tour: string) => {
      const selectedTime = new Date(timeslot).getTime();
      let numOfTakenReservations = 0;
      let numOfTakenVRReservations = 0;

      for (const reservationDoc of reservationBookedTimes) {
        const reservation = reservationDoc as ReservationBookedTime;
        if (reservation.date.toDate().getTime() === selectedTime) {
          numOfTakenReservations++;
          if (reservation.isVR) {
            numOfTakenVRReservations++;
          }
        }
      }

      const totalRemaining = maxSlotsLimit - numOfTakenReservations < 0 ? 0 : maxSlotsLimit - numOfTakenReservations;
      const VRremaining = maxSlots - numOfTakenVRReservations < 0 ? 0 : maxSlots - numOfTakenVRReservations;
      const selectedTourId = reservation?.topicId || reservation?.vehicleId;
      const prefilledTime = reservation && reservation.date.toDate().getTime();
      const prefilledUnselectedRemaining = prefilledTime === selectedTime && selectedTourId === tour ? 1 : 0;

      if (isVR) {
        if (totalRemaining > 0) {
          if (VRremaining > 0 && totalRemaining < VRremaining) {
            remainingSlotsArray.push(totalRemaining + prefilledUnselectedRemaining);
          }
          remainingSlotsArray.push(VRremaining + prefilledUnselectedRemaining);
        }
      }
      remainingSlotsArray.push(totalRemaining + prefilledUnselectedRemaining);
    });

    // get and return lowest value from tourIds array as remaining value
    const lowestRemaining = Math.min(...remainingSlotsArray);
    return lowestRemaining;
  };

  useEffect(() => {
    // update max slots and scheduler look ahead by brand config (default 2 weeks)
    if (brand && brandLoadingStatus === 'success') {
      const brandDoc = brand as Brand;
      const schedulerLookAhead = Number(brandDoc.schedulerLookAhead) || 14;
      setAddDates(new Date().getTime() + schedulerLookAhead * 24 * 60 * 60 * 1000);
      updateMaxSlots();
    }
  }, [brand, brandLoadingStatus]);

  const useStyles = makeStyles(ReservationViewStyles(brandId));
  const styles = useStyles();

  const handelCancelReservation = async () => {
    await updateDoc(ref, { status: 'Cancelled' });
    onCancel(t3RedirectUrl);
  };

  const goBack = () => {
    //if in modify state redirect back to our landing page
    isModifyReservation ? onGoBack('') : onGoBack(t3RedirectUrl);
  };

  const renderStats = () => {
    const days = document.querySelectorAll('.MuiPickersDay-root');
    days.forEach((day) => {
      day.classList.add('stat-text-link');
      day.setAttribute('data-dtm', isEnergy ? 'book your 1-1 demo:date' : 'book 1-1 session:date');
    });
  };

  useEffect(() => {
    renderStats();
    if (calendarOpen) {
      renderStats();
    }
  });

  // useEffect(() => {
  //   if (!vehicles) return;
  //   if (preSelectedTour && !isPreSelected) {
  //     if (!isEVLive || !isEnergy) {
  //       const vehicle = vehicles.find((v) => v.name === preSelectedTour) as Vehicle;
  //       if (vehicle) {
  //         setIsPreSelected(true);
  //         setSelectedTourId(vehicle.id);
  //       }
  //       setCalendarOpen(true);
  //       setVehicleSelectOpen(true);
  //     }
  //   }
  // }, [vehicles, subsetId, vehicleSubset]);

  useEffect(() => {
    if (!topics) return;
    if (preSelectedTour && !isPreSelected) {
      if (isEVLive || isEnergy) {
        const topic = topics.find((v) => v.name.trim() === preSelectedTour);
        if (topic) {
          setIsPreSelected(true);
          setSelectedTourIds([topic.id]);
        }
        setCalendarOpen(true);
        setVehicleSelectOpen(true);
      }
    }
  }, [topics]);

  const scroll = Scroll.animateScroll;
  const { Element } = Scroll;

  const executeScroll = () => {
    window.scrollTo(0, 0);
    scroll.scrollToTop('errorElement');
  };

  const getArrayOccurance = (array: any[], value: any) => {
    let count = 0;
    array.forEach((v) => (v === value && count++));
    return count;
  };

  let combinedReservations: any[] = [];

  const getCombinedReservationSpots = async (date: Date, tourIds: string[]) => {
    if (date) {
      let allReservations: any[] = [];
      const dateString = moment(date).format('MM-DD-YYYY');

      // get all time spots for selected day
      await Promise.all(tourIds.map(async (tourId: string) => {
        const reservationSpots = await hoursOfOperationRepository.getReservationsByDay(
          brandId,
          dateString,
          date.getTimezoneOffset(),
          tourId,
        );
        reservationSpots.forEach((res: any) => {
          allReservations.push(res);
        });
      }));

      // check if all tours contain time spot
      allReservations.forEach((item: any) => {
        const occuranceCount = getArrayOccurance(allReservations, item);
        // if number of occurances = length of tourIDs array, time spot is available in all tours
        if (occuranceCount === tourIds.length && !combinedReservations.includes(item)) {
          combinedReservations.push(item);
        }
      });

      setTimeSlots(combinedReservations);
      return combinedReservations;
    }
    return [];
  };

  const loadAvailableTimes = async (value: Date, values: any, setFieldValue, tourIds: string[]) => {
    setLoadingHours(true);
    // get available times for the selected date
    try {
      // get combined spots
      const spots = await getCombinedReservationSpots(value, tourIds);
      const remaining = getAllReservationRemaining(value, tourIds);

      // reset selected date and time if it is not available
      const filtered = spots.filter((spot) => moment(spot).format('HH:mm') === values.time.toString());

      // if the time has passed or if the remaining spots are taken, reset the date and time
      if (filtered.length <= 0 || remaining <= 0) {
        setFieldValue('date', '');
        setFieldValue('time', '');
      }
    } catch (err) {
      console.error(err);
    }

    setLoadingHours(false);
  };

  useEffect(() => {
    if (reservation && token) {
      // if (reservation.vehicleId) {
      //   setSelectedTourId(reservation.vehicleId);
      //   setVehicleSelectOpen(true);
      // }
      if (reservation.topicId) {
        const reservedTopics = Array.isArray(reservation.topicId) ? reservation.topicId : [reservation.topicId];
        setSelectedTourIds(reservedTopics);
        setVehicleSelectOpen(true);

        if (reservation.topicId.length === 5) {
          setMaxTopicsSelected(true);
        }
      }

      const date = reservation.date.toDate();
      setSelectedDate(date);
      const reservedTopics = Array.isArray(reservation.topicId) ? reservation.topicId : [reservation.topicId];
      getCombinedReservationSpots(date, reservedTopics);

      if (date) {
        setShowTimePicker(true);
        setCalendarOpen(true);
      }
    }
  }, [reservation]);

  if (reservation && reservation.t3RedirectUrl) {
    t3RedirectUrl = reservation.t3RedirectUrl;
  }

  // /-------------------------------------------------------------------------------------------------------------
  //  Keep this function in case user wants to hide dates with reservation not available
  // /-------------------------------------------------------------------------------------------------------------
  /*  const getReservationsForDates = async (brandId: BrandType, dates: string[], timezoneOffset: number, selectedTourId: string) => {
     const promises = dates.map(async (date) => {
       const reservationSpots = await hoursOfOperationRepository.getReservationsByDay(
         brandId,
         date,
         timezoneOffset,
         selectedTourId
       );
       return { date, hasReservations: reservationSpots.length >= 1 };
     });

     return await Promise.all(promises);
   }; */
  // ---------------------------------------------------------------------------------------------------------------

  const mergeCustomizer = (objValue: any, srcValue: any) => {
    return objValue === true ? objValue : srcValue;
  };

  const getVehicleBlackoutDates = async () => {
    const timezoneOffset = today.getTimezoneOffset();
    let tempBlackoutDates = {};
    await Promise.all(selectedTourIds.map(async (tourId: string) => {
      const vehicleBlackoutDates = await hoursOfOperationRepository.getReservationBlackoutDates(
        today,
        brandId,
        timezoneOffset,
        tourId,
        location
      );
      const megedBlackoutDates = mergeWith(tempBlackoutDates, vehicleBlackoutDates, mergeCustomizer);
      tempBlackoutDates = megedBlackoutDates;
    }));

    // const vehicleBlackoutDates = await hoursOfOperationRepository.getReservationBlackoutDates(
    //   today,
    //   brandId,
    //   timezoneOffset,
    //   selectedTourId,
    //   location
    // );

    // /-------------------------------------------------------------------------------------------------------------
    //  Keep this fragment in case user wants to hide dates when reservation are not available
    // /-------------------------------------------------------------------------------------------------------------

    /* const datesWithReservations = await getReservationsForDates(
      brandId,
      // Filter dates where vehicleBlackoutDates[date] are weekends and blackouts
      Object.keys(vehicleBlackoutDates).filter((date) => !vehicleBlackoutDates[date]),
      timezoneOffset,
      selectedTourId,
    );

    // Update the blackout status in the vehicleBlackoutDates object only if hasReservations is false
    // meaning there are no available spots or no hours
    datesWithReservations.forEach(({ date, hasReservations }) => {
      if (!hasReservations) {
        vehicleBlackoutDates[date] = true;
      }
    }); */
    // --------------------------------------------------------------------------------------------------------------

    setBlackoutDates(tempBlackoutDates);

    // reset date if selected date is a blackout date
    if (isBlackoutDate(selectedDate)) {
      setShowTimePicker(false);
      setTimeSlots([]);
      setSelectedDate(undefined);
    }
  };

  useEffect(() => {
    if (selectedTourIds) {
      getVehicleBlackoutDates();
      updateMaxSlots();
    }
  }, [selectedTourIds]);

  const isBlackoutDate = (day) => {
    const startDate = new Date(new Date().setHours(0, 0, 0, 0));
    const endDate = new Date(addDates);
    if (blackoutDates && startDate <= day && day <= endDate) {
      const key = moment(day).format('MM-DD-YYYY');
      return blackoutDates[key];
    }
    return false;
  };

  const trackErrorMessages = (errors: any) => {
    const errorrMessages = [];
    let errorMessage = '';
    const vehicleId = 'Please select vehicle';
    const topicId = 'Please select vehicle';
    const date = 'Please select date';
    const time = 'Please select time';
    const firstName = 'Please input valid first name';
    const lastName = 'Please input valid last name';
    const zipCode = 'Please input valid zip code';
    const email = 'Please input valid email address';
    const confirmEmail = 'Please input valid confirm email';
    const phoneNumber = 'Please enter valid phone number';

    if (errors.vehicleId) errorrMessages.push(vehicleId);
    if (errors.topicId) errorrMessages.push(topicId);
    if (errors.date) errorrMessages.push(date);
    if (errors.time) errorrMessages.push(time);
    if (errors.firstName) errorrMessages.push(firstName);
    if (errors.lastName) errorrMessages.push(lastName);
    if (errors.zipCode) errorrMessages.push(zipCode);
    if (errors.email) errorrMessages.push(email);
    if (errors.confirmEmail) errorrMessages.push(confirmEmail);
    if (errors.phoneNumber) errorrMessages.push(phoneNumber);

    errorMessage = errorrMessages.join(', ');
    window.digitalData.formInfo.errorMessage = errorMessage;
  };

  const isModifyReservation = !!token && !!reservation;
  const hideInputData = !!isEnergy && !!isModifyReservation;

  const transferStylesInline = (
    session_Transfer: string,
  ): { [key: string]: React.CSSProperties } => {
    if (!transferRequest) {
      return {};
    }

    const evStyles: { [key: string]: React.CSSProperties } = {
      card: {
        margin: '0px',
        padding: '50px 0px 0px',
      },
      gridWrapper: {
        padding: '0px',
      },
      form: {
        margin: '40px 0px',
      },
      boxContainer: {
        marginBottom: '0px',
      },
      wrapperContainer: {
        padding: '0',
      },
      title: {
        textAlign: 'left',
        margin: '20px 0px',
      },
      formLabel: {
        height: 'auto',
        display: 'block',
        visibility: 'visible',
        fontSize: '16px',
        fontFamily: 'inherit',
        color: 'inherit !important',
      },
      rightField: {
        paddingLeft: '24px !important',
      },
      textBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      modalSubText: {
        textAlign: 'start',
        margin: '0px 0px 14px 0px',
        maxWidth: '100%',
      },
      yourInfo: {
        margin: '18px 0px 36px 0px',
      },
      indicatesRequiredFields: {
        fontSize: '14px',
        marginBottom: '44px',
      },
      calendarIcon: {
        backgroundColor: evliveColors.blue,
        width: '69px',
      },
      checkIcon: {
        top: 'initial',
        right: 'initial',
        color: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[200],
        fontSize: '1.3rem',
      },
      checkIconBlank: {
        top: 'initial',
        right: 'initial',
        color: 'transparent',
        backgroundColor: theme.palette.grey[200],
        fontSize: '1.3rem',
      },
      checkboxOutline: {
        backgroundColor: theme.palette.grey[200],
        fontSize: '1.3rem',
        visibility: 'hidden',
      },
      timeWrapper: {
        marginLeft: '-8px',
      },
      timeTitle: {
        paddingTop: '14px',
        paddingLeft: '14px',
      },
      buttonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        margin: '32px 0px 0px 0px',
      },
      optWrapper: {
        marginLeft: '12px',
      },
      bookNow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
      },
      submitButton: {
        maxWidth: '220px',
      },
      cancelButton: {
        width: 'auto',
        marginTop: '12.5px',
      },
    };

    switch (session_Transfer) {
      case 'chevy':
        return {
          paddingRight: {
            paddingRight: '14px',
          },
          calendarIcon: {
            height: '100%',
          },
        };
      case 'chevy_gmc':
      case 'chevy_buick':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          title: {
            textAlign: 'center',
          },
          modalSubText: {
            fontSize: '18px',
            maxWidth: '100%',
            color: '#808080',
          },
          buttonsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
          },
        };
      case 'chevy_chevy':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          title: {
            textAlign: 'center',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          calendarIcon: {
            width: '69px',
          },
          modalSubText: {
            fontSize: '18px',
            maxWidth: '100%',
          },
        };
      case 'chevy_evcan':
      case 'chevy_ev':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          title: {
            textAlign: 'center',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          calendarIcon: {
            width: '69px',
          },
          modalSubText: {
            fontSize: '18px',
            maxWidth: '100%',
          },
          selectArrowContainer: {
            backgroundColor: '#808080',
            width: '69px'
          }
        };
      case 'chevy_cadillaccan':
      case 'chevy_cadillac':
        return {
          modalSubText: {
            fontSize: '18px',
            maxWidth: '100%',
            paddingLeft: '24px',
            paddingRight: '24px',
          },
          card: {
            margin: '50px 0px 0px',
          },
          title: {
            textAlign: 'center',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          indicatesRequiredFields: {
            paddingLeft: '24px',
          },
        };
      case 'chevy_energy':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          radioGroup: {
            paddingLeft: '14px',
          },
          modalSubText: {
            fontSize: '18px',
            margin: '0px',
            maxWidth: '100%',
          },
          modalSubTextOne: {
            marginBottom: '20px',
          },
          formLabel: {
            display: 'block',
            visibility: 'visible',
          },
          bookNow: {
            flexDirection: 'column',
          },
          selectArrowContainer: {
            backgroundColor: '#808080',
          },
          calenderIcon: {
            backgroundColor: '#808080',
          },
        };
      case 'energy_buick':
        return {
          radioGroup: {
            paddingLeft: '14px',
          },
          card: {
            padding: '0px 20px',
            margin: '10px 0px 0px',
            marginBottom: '70px',
            paddingLeft: '44px',
          },
          modalSubText: {
            textAlign: 'left'
          },
          calendarIcon: {
            backgroundColor: 'black',
          },
          submitButton: {
            backgroundColor: 'black',
            color: 'white'
          },
          selectDropDown: {
            backgroundColor: 'black',
          },
          indicatesRequiredFields: {
            textAlign: 'left'
          }
        };
      case 'energy_gmc':
        return {
          radioGroup: {
            paddingLeft: '14px',
          },
          card: {
            padding: '0px 20px',
            margin: '0px 0px 0px',
            marginBottom: '70px',
            paddingLeft: '40px',
          },
          modalSubText: {
            textAlign: 'left'
          },
          calendarIcon: {
            backgroundColor: 'black',
          },
          submitButton: {
            backgroundColor: 'black',
            color: 'white',
            maxWidth: '269px',
            marginBottom: '30px'
          },
          cancelButton: {
            maxWidth: '269px',
            marginLeft: '0px'
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly'
          },
          selectDropDown: {
            backgroundColor: 'black',
          },
          indicatesRequiredFields: {
            textAlign: 'left'
          }
        };
      case 'energy_energy':
      case 'energy_cadillaccan':
        return {
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          card: {
            margin: '10px 0px 0px',
            marginBottom: '0px',
            paddingLeft: '17px',
          },
          radioGroup: {
            paddingLeft: '14px',
          },
          formLabel: {
            display: 'block',
            visibility: 'visible',
          },
          modalSubText: {
            fontSize: '18px',
            textAlign: 'center',
            maxWidth: '100%',
          },
          selectArrowConatiner: {
            height: '100%',
            width: '50px',
            backgroundColor: '#808080',
          },
          calendarIcon: {
            backgroundColor: 'black',
          },
          submitButton: {
            backgroundColor: 'black',
            color: 'white'
          }
        };
      case 'energy_ev':
        return {
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          card: {
            margin: '10px 0px 0px',
            marginBottom: '60px',
            paddingLeft: '18px',
          },
          radioGroup: {
            paddingLeft: '14px',
          },
          formLabel: {
            display: 'block',
            visibility: 'visible',
          },
          modalSubText: {
            fontSize: '18px',
            textAlign: 'center',
            maxWidth: '100%',
          },
          selectArrowConatiner: {
            height: '100%',
            width: '50px',
            backgroundColor: '#808080',
          },
          calendarIcon: {
            backgroundColor: 'black',
          },
          submitButton: {
            backgroundColor: 'black',
            color: 'white'
          }
        };
      case 'energy_evcan':
        return {
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          card: {
            margin: '10px 0px 0px',
            marginBottom: '0px',
            paddingLeft: '18px',
          },
          radioGroup: {
            paddingLeft: '14px',
          },
          formLabel: {
            display: 'block',
            visibility: 'visible',
          },
          modalSubText: {
            fontSize: '18px',
            textAlign: 'left',
            maxWidth: '100%',
          },
          selectArrowConatiner: {
            height: '100%',
            width: '50px',
            backgroundColor: '#808080',
          },
          calendarIcon: {
            backgroundColor: 'black',
          },
          submitButton: {
            backgroundColor: 'black',
            color: 'white'
          },
          indicatesRequiredFields: {
            textAlign: 'left'
          },
        };
      case 'energy_cadillac':
        return {
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          card: {
            margin: '10px 0px 0px',
            paddingLeft: '18px',
            marginBottom: '60px'
          },
          radioGroup: {
            paddingLeft: '14px',
          },
          formLabel: {
            display: 'block',
            visibility: 'visible',
          },
          modalSubText: {
            fontSize: '18px',
            textAlign: 'left',
            maxWidth: '100%',
          },
          selectArrowConatiner: {
            height: '100%',
            width: '50px',
            backgroundColor: '#808080',
          },
          calendarIcon: {
            backgroundColor: 'black',
          },
          submitButton: {
            backgroundColor: 'black',
            color: 'white'
          },
          indicatesRequiredFields: {
            textAlign: 'left',
          },
        };
      case 'energy_chevy':
        return {
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          card: {
            margin: '20px 0px 0px',
            paddingLeft: '18px',
          },
          radioGroup: {
            paddingLeft: '14px',
          },
          formLabel: {
            display: 'block',
            visibility: 'visible',
          },
          modalSubText: {
            fontSize: '18px',
            textAlign: 'left',
            maxWidth: '100%',
          },
          selectArrowConatiner: {
            height: '100%',
            width: '50px',
            backgroundColor: 'black',
          },
          textBox: {
            paddingLeft: '24px',
            paddingRight: '36px',
          },
          submitButton: {
            backgroundColor: 'black',
            color: 'white'
          },
          calendarIcon: {
            backgroundColor: 'black',
          }
        };
      case 'gmc_chevy':
        return {
          card: {
            margin: '0px',
            marginTop: '34px',
          },
          modalSubText: {
            fontSize: '18px',
            margin: '0px',
            maxWidth: '100%',
            paddingRight: '24px',
          },
          modalSubTextOne: {
            marginBottom: '20px',
            marginTop: '20px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          selectArrowConatiner: {
            height: '100%',
            backgroundColor: 'black',
          },
          title: {
            marginBottom: '14px',
            marginTop: '34px',
          },
          calendarIcon: {
            backgroundColor: 'black',
            width: '69px',
          },
        };
      case 'gmc_energy':
        return {
          card: {
            margin: '0px',
            paddingTop: '50px',
          },
          radioGroup: {
            paddingLeft: '14px',
          },
          modalSubText: {
            fontSize: '18px',
            marginTop: '0px',
            marginBottom: '0px',
            marginLeft: '0px',
            marginRight: '0px',
            maxWidth: '100%',
            textAlign: 'center',
          },
          modalSubTextOne: {
            marginBottom: '20px',
          },
          formLabel: {
            display: 'block',
            visibility: 'visible',
          },
          bookNow: {
            flexDirection: 'column',
          },
          title: {
            marginBottom: '14px',
          },
          calendarIcon: {
            backgroundColor: 'black',
          },
        };
      case 'gmc_gmc':
      case 'gmc_ev':
      case 'gmc_evcan':
      case 'gmc_cadillac':
      case 'gmc_cadillaccan':
        return {
          title: {
            marginBottom: '14px',
            marginTop: '34px',
          },
          card: {
            margin: '0px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          calendarIcon: {
            backgroundColor: 'black',
          },
          selectDropDown: {
            backgroundColor: 'black',
          },
          cancelButton: {
            margin: '0px',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '130px',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          modalSubText: {
            paddingLeft: '24px',
            paddingRight: '24px',
            textAlign: 'center',
          },
          indicatesRequiredFields: {
            paddingLeft: '24px',
            paddingRight: '24px',
            textAlign: 'center',
          },
        };
      case 'gmc_buick':
        return {
          title: {
            marginBottom: '14px',
            marginTop: '34px',
          },
          card: {
            margin: '40px 0px 0px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          calendarIcon: {
            backgroundColor: 'black',
          },
          selectDropDown: {
            backgroundColor: 'black',
          },
          cancelButton: {
            margin: '0px',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '130px',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          modalSubText: {
            paddingLeft: '24px',
            paddingRight: '24px',
            textAlign: 'left',
          },
          indicatesRequiredFields: {
            paddingLeft: '24px',
            paddingRight: '24px',
            textAlign: 'left',
          },
        };
      case 'buick_chevy':
        return {
          card: {
            margin: '0px',
          },
          title: {
            textAlign: 'center',
            marginBottom: '14px',
            marginTop: '34px',
          },
          modalSubText: {
            fontSize: '18px',
            marginTop: '0px',
            marginBottom: '0px',
            marginLeft: '0px',
            marginRight: '0px',
            maxWidth: '100%',
            paddingLeft: '24px',
            paddingRight: '24px',
          },
          modalSubTextOne: {
            marginBottom: '20px',
            marginTop: '20px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          selectArrowConatiner: {
            height: '100%',
            width: '52px',
            backgroundColor: '#3F3F38',
          },
        };
      case 'buick_energy':
        return {
          title: {
            marginBottom: '14px',
            marginTop: '34px',
            textAlign: 'center',
          },
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            textAlign: 'center',
            padding: '0px',
            maxWidth: '100%',
            fontSize: '18px',
            margin: '0px 0px 26px 0px',
          },
          formLabel: {
            display: 'block',
            visibility: 'visible',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          radioField: {
            marginTop: '10px',
          },
        };
      case 'buick_ev':
      case 'buick_cadillac':
      case 'buick_cadillaccan':
      case 'buick_buick':
        return {
          title: {
            marginBottom: '14px',
            marginTop: '34px',
          },
          card: {
            margin: '0px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '150px',
            alignItems: 'center',
          },
          cancelButton: {
            margin: 0,
          },
          modalSubText: {
            fontSize: '16px',
          },
          selectDropDown: {
            backgroundColor: '#3F3F38',
            width: '50px',
          },
          indicatesRequiredFields: {
            marginBottom: '8px',
            fontSize: '16px'
          },
        };
      case 'buick_evcan':
        return {
          title: {
            marginBottom: '14px',
            marginTop: '34px',
            textAlign: 'center',
          },
          card: {
            margin: '0px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          modalSubText: {
            textAlign: 'center',
          },
          indicatesRequiredFields: {
            textAlign: 'center',
          },
        };
      case 'buick_gmc':
        return {
          card: {
            margin: '0px',
          },
          buttonsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
          },
        };
      case 'cadillac_chevy':
        return {
          modalSubText: {
            maxWidth: '100%',
            padding: '0px 24px',
            fontSize: '16px',
            marginBottom: '20px',
            textAlign: 'center',
          },
          card: {
            margin: '50px 0px 0px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          selectArrowConatiner: {
            display: 'none'
          },
          textBox: {
            paddingLeft: '0px'
          }
        };
      case 'cadillac_buick':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            color: 'white',
            fontSize: '16px',
          },
          indicatesRequiredFields: {
            fontSize: '12px',
          },
          selectDropDown: {
            backgroundColor: 'transparent'
          }
        };
      case 'cadillac_energy':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            color: 'white',
            fontSize: '16px',
            margin: '0px',
            paddingBottom: '20px',
            maxWidth: '100%',
            textAlign: 'center',
          },
          formLabel: {
            visibility: 'visible',
            display: 'block',
          },
          calendarIcon: {
            backgroundColor: 'transparent',
          },
          radioField: {
            marginTop: '10px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          selectArrowContainer: {
            backgroundColor: 'black',
            top: '1.5px',
            right: '1.5px',
            height: '36px',
          },
          timeTitle: {
            paddingTop: '6px',
            paddingLeft: '6px',
          },
          title: {},
        };
      case 'ev_buick': // start fallthrough
      case 'ev_cadillaccan':
      case 'evcan_buick': // end fallthrough
        return evStyles;
      case 'ev_cadillac': // start fallthrough
      case 'ev_chevy':
      case 'ev_ev':
      case 'ev_gmc':
      case 'ev_evcan':
      case 'evcan_chevy':
      case 'evcan_cadillac':
      case 'evcan_cadillaccan':
      case 'evcan_ev':
      case 'evcan_gmc':
      case 'evcan_evcan': // end fallthrough
        return {
          ...evStyles,
          modalSubText: {
            textAlign: 'start',
            margin: '0px 0px 14px 0px',
            maxWidth: '100%',
          },
          indicatesRequiredFields: {
            fontSize: '14px',
            marginBottom: '14px',
          },
        };
      case 'ev_energy': // start fallthrough
      case 'evcan_energy': // end fallthrough
        return {
          ...evStyles,
          gridContainer: {
            width: '100%',
            marginLeft: '-24px',
          },
        };
      case 'cadillac_ev':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        };
      case 'cadillac_cadillac':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            fontSize: '16px',
            textAlign: 'center',
          },
          indicatesRequiredFields: {
            fontSize: '12px',
            textAlign: 'center',
          },
        };
      case 'cadillac_cadillaccan':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            fontSize: '16px',
            textAlign: 'center',
          },
          indicatesRequiredFields: {
            fontSize: '12px',
            textAlign: 'center',
          },
        };
      case 'cadillac_evcan':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            fontSize: '16px',
            textAlign: 'center',
          },
          indicatesRequiredFields: {
            fontSize: '12px',
            textAlign: 'center',
          },
          optIn: {
            position: 'relative',
            left: '2px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        };
      case 'cadillac_gmc':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            fontSize: '16px',
            textAlign: 'center',
          },
          formLabel: {
            color: 'white',
          },
          indicatesRequiredFields: {
            fontSize: '14px',
            textAlign: 'center',
          },
          buttonsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
          },
        };
      case 'cadillaccan_evcan':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          optIn: {
            position: 'relative',
            left: '2px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        };
      case 'cadillaccan_cadillaccan':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            fontSize: '16px',
            textAlign: 'center',
            paddingLeft: '24px',
            paddingRight: '24px',
          },
          indicatesRequiredFields: {
            fontSize: '14px',
            textAlign: 'center',
            marginBottom: '15px',
          },
        };
      case 'cadillaccan_buick':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            fontSize: '16px',
            color: 'white',
            textAlign: 'center',
          },
          indicatesRequiredFields: {
            fontSize: '14px',
            textAlign: 'center',
          },
          buttonsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
          },
        };
      case 'cadillaccan_cadillac':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            fontSize: '16px',
            textAlign: 'center',
          },
          indicatesRequiredFields: {
            fontSize: '14px',
            textAlign: 'center',
            marginBottom: '15px',
          },
        };
      case 'cadillaccan_chevy':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            fontSize: '16px',
            textAlign: 'center',
            maxWidth: '100%',
            paddingLeft: '24px',
            paddingRight: '24px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          textBox: {
            paddingLeft: '0px'
          },

        };
      case 'cadillaccan_ev':
        return {
          card: {
            margin: '50px 0px 0px',
          },
          indicatesRequiredFields: {
            fontSize: '14px',
            marginBottom: '15px',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        };
      case 'cadillaccan_energy':
        return {
          formLabel: {
            display: 'block',
            visibility: 'visible',
          },
          card: {
            margin: '50px 0px 0px',
          },
          modalSubText: {
            fontSize: '16px',
            textAlign: 'center',
            maxWidth: '100%',
          },
          bookNow: {
            flexDirection: 'column',
          },
          buttonsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          radioField: {
            marginTop: '10px',
          },
          title: {
            paddingBottom: '0px',
          },
        };
      case 'cadillaccan_gmc':
        return {
          modalSubText: {
            fontSize: '16px',
            textAlign: 'center',
            maxWidth: '100%',
          },
          card: {
            margin: '50px 0px 0px',
          },
          buttonsContainer: {
            display: 'flex',
            justifyContent: 'space-between',
          },
          formLabel: {
            color: 'white',
            textTransform: 'uppercase',
          },
          indicatesRequiredFields: {
            fontSize: '14px',
            textAlign: 'center',
            marginBottom: '15px',
          },
          topLabels: {
            fontFamily: 'CadillacGothic-Regular',
          },
          title: {
            paddingBottom: '0px',
          },
        };
      default:
        return {};
    }
  };

  return (
    <>
      {loadingStatus === 'loading' ? (
        <LoadingScreen />
      ) : (
        loadingStatus === 'success' && (
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: '',
              languageCode,
              vehicleId:
                token && reservation
                  ? reservation.vehicleId
                  : isPreSelected && !(isEVLive || isEnergy)
                    ? selectedTourIds
                    : '',
              topicId:
                token && reservation
                  ? reservation.topicId
                  : isPreSelected && (isEVLive || isEnergy)
                    ? selectedTourIds
                    : [],
              tourName: token && reservation ? reservation.tourName : '',
              date: token && reservation ? reservation.date.toDate() : '',
              firstName: token && reservation ? reservation.firstName : '',
              lastName: token && reservation ? reservation.lastName : '',
              zipCode: token && reservation ? reservation.zipCode : '',
              postalCode: token && reservation ? reservation.postalCode : '',
              email: token && reservation ? reservation.email : '',
              time: token && reservation ? reservation.time : '',
              confirmEmail: token && reservation ? reservation.confirmEmail : '',
              phoneNumber: token && reservation ? reservation.phoneNumber : '',
              status: reservation ? 'Modified' : 'Active',
              localDate: token && reservation ? reservation.localDate : '',
              campaignCode: token && reservation ? reservation.campaignCode : '',
              ...receiveUpdates,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .transform((_, transformedValue) => trimSpaces(transformedValue))
                .max(40, t('First Name must be at most 40 characters.'))
                .required(t('Please provide a first name.'))
                .matches(/^[aA-zZ\s]+$/, t('Contains invalid characters.')),
              lastName: Yup.string()
                .transform((_, transformedValue) => trimSpaces(transformedValue))
                .max(40, t('Last Name must be at most 40 characters.'))
                .required(t('Please provide a last name.'))
                .matches(/^[aA-zZ\s]+$/, t('Contains invalid characters.')),
              zipCode:
                !isCadillacCanada && !isEVLiveCanada
                  ? Yup.string()
                    .min(5, t('Zip Code is not valid.'))
                    .max(5, t('Zip Code must be at most 5 characters.'))
                    .matches(/^\d{5}(?:[-\s]\d{4})?$/, t('Zip Code is not valid.'))
                    .required(t('Zip Code is not valid.'))
                  : Yup.string(),
              postalCode:
                isCadillacCanada || isEVLiveCanada
                  ? Yup.string()
                    .min(6, t('Postal Code is not valid.'))
                    .max(6, t('Postal Code must be at most 6 characters.'))
                    .matches(
                      /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
                      t('Postal Code is not valid.'),
                    )
                    .required(t('Postal Code is not valid.'))
                  : Yup.string(),
              email: Yup.string()
                .email(t('Please provide a properly formatted email address.'))
                .max(80, t('Email must be at most 80 characters.'))
                .matches(regexEmailHelper, t('Email is incomplete or characters not permitted'))
                .required(t('Please provide a properly formatted email address.')),
              confirmEmail: Yup.string()
                .oneOf([Yup.ref('email'), null], t('Email addresses do not match.'))
                .max(80, t('Email must be at most 80 characters.'))
                .matches(regexEmailHelper, t('Email is incomplete or characters not permitted'))
                .required(t('Email addresses do not match.')),
              phoneNumber: isEnergy
                ? Yup.string()
                  .min(10, t('Please provide a valid phone number.'))
                  .matches(
                    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                    t('Phone number is limited to Canada and USA'),
                  )
                : Yup.string(),
              time: Yup.string(),
              date: Yup.date()
                .required(t('Please select a date and time.'))
                .typeError(t('Please select a date and time.')),
              vehicleId:
                isEVLive || isEnergy
                  ? Yup.string()
                  : Yup.string().required(t('Please select a vehicle.')),
              topicId: !(isEVLive || isEnergy)
                ? Yup.string()
                : Yup.array().min(1, t('Please select a topic.')).required(t('Please select a topic.')),
              receiveUpdates: receiveUpdatesFormCondition,
            })}
            onSubmit={async (values, { setStatus, setSubmitting }): Promise<void> => {
              setIsLoading(true);
              const dateString = values.date.toString();
              const localDate = moment(dateString).format('YYYYMMDD').toString();

              //pass t3 variables to t3QueryStringVariables for reservation doc
              let t3Variables = [];
              if (!isModifyReservation) {
                if (bac) {t3Variables.push({"key": "bac", "value": bac});}
                if (evar122) {t3Variables.push({"key": "evar122", "value": evar122});}
                if (evar109){t3Variables.push({"key": "evar109", "value": evar109});}
                if (evar25){t3Variables.push({"key": "evar25", "value": evar25});}
              }

              const topicNames = topics.filter((topic) => selectedTourIds.includes(topic.id)).map((tour) => tour.name);
              const tourName = topicNames.join(', ');

              const reservation: Partial<Reservation> = {
                ...values,
                id: reservationId,
                dateString,
                localDate,
                timezoneOffset: new Date().getTimezoneOffset(),
                brandId,
                queryStringVariables: queryParams,
                tourName,
              };

              if (t3Variables && t3Variables.length > 0) {
                reservation.t3QueryStringVariables = t3Variables;
              }

              if (t3RedirectUrl) {
                reservation.t3RedirectUrl = t3RedirectUrl;
              }

              if (transferRequest) {
                reservation.transferRequest = transferRequest;
              }
              try {
                await setDoc(ref, reservation, { merge: true });
                trackAdobe('single-tour');
                onSubmit(reservation);
                onTransferReq();
              } catch (err) {
                console.error(err);
                toast.error(`Something went wrong! ${err.message}`);
                setStatus({ success: false });
                setIsLoading(false);
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              touched,
              values,
              isValid,
              submitForm,
              validateForm,
            }): JSX.Element => {
              const handleCheckForm = (event: React.ChangeEvent<HTMLFormElement>) => {
                event.preventDefault();
                if (isValid) {
                  handleSubmit(event);
                }
              };
              trackErrorMessages(errors);

              return (
                <Card
                  style={transferStylesInline(`${sessionBrand}_${brandId}`).card}
                  className={clsx(styles.root)}
                >
                  {isEnergy && !transferRequest && <Box className={styles.topLeftBackgroundImage} />}

                  <Grid
                    className={clsx(transferRequest ? styles.gridWrapperGMCEV : styles.gridWrapper, 'grid-wrapper')}
                    style={transferStylesInline(`${sessionBrand}_${brandId}`).gridWrapper}
                  >
                    {isLoading ? (
                      <Box>
                        <LoadingScreen />
                      </Box>
                    ) : (
                      <>
                        <Element name="errorElement" className={styles.errorElement}>
                          <ErrorComponent
                            containerId="errors"
                            condition={!isValid && validateErrors}
                            errors={errors}
                            dataDTM="book 1-1 session"
                          />
                        </Element>

                        <GridWrapper
                          container
                          className={clsx(styles.textBox, 'form-header--page')}
                          style={transferStylesInline(`${sessionBrand}_${brandId}`).textBox}
                        >
                          {isChevy && (
                            <Typography
                              variant="modalTitle"
                              component="h2"
                              style={transferStylesInline(`${sessionBrand}_${brandId}`).title}
                              dangerouslySetInnerHTML={{
                                __html: t<string>('Schedule your one-to-one tour'),
                              }}
                            />
                          )}

                          {!(isChevy || isEnergy) && (
                            <Typography
                              variant="modalTitle"
                              component="h2"
                              className={styles.modalTitle}
                              style={transferStylesInline(`${sessionBrand}_${brandId}`).title}
                              dangerouslySetInnerHTML={{
                                __html: t<string>('Book Your Live Tour'),
                              }}
                            />
                          )}

                          {isEnergy && (
                            <Typography
                              variant="modalTitle"
                              component="h2"
                              style={transferStylesInline(`${sessionBrand}_${brandId}`).title}
                            >
                              {t('Book Your ')}
                              <span className="blue-text">{t('1-on-1 Demo')}</span>
                            </Typography>
                          )}

                          {isBuick && (
                            <Typography
                              variant={['gmc_buick'].includes(`${sessionBrand}_${brandId}`) ? "modalSubText" : "modalSubTextAlt"}
                              component="p"
                              style={
                                transferStylesInline(`${sessionBrand}_${brandId}`).modalSubText
                              }
                            >
                              {t(
                                'Fill out the form below to be connected with a Buick Live Product Specialist at your preferred time.',
                              )}
                            </Typography>
                          )}

                          {isChevy && (
                            <Typography
                              variant="modalSubText"
                              component="p"
                              className={styles.modalSubText}
                              style={{
                                ...transferStylesInline(`${sessionBrand}_${brandId}`).modalSubText,
                                ...transferStylesInline(`${sessionBrand}_${brandId}`)
                                  .modalSubTextOne,
                              }}
                            >
                              {t(
                                "Have a question about a vehicle or just want to explore the showroom? Join a one-to-one live tour of Chevrolet’s latest vehicles, where you can speak via chat or audio to a Product Specialist in real-time. Don’t worry, our Product Specialists can't see you; it’s a one-way camera only. Select a date and vehicle of interest—we’ll take care of the rest. Times are displayed in your current time zone.",
                              )}
                            </Typography>
                          )}

                          {isEnergy && (
                            <Typography
                              variant="modalSubText"
                              component="p"
                              className={styles.modalSubText}
                              style={{
                                ...transferStylesInline(`${sessionBrand}_${brandId}`).modalSubText,
                                ...transferStylesInline(`${sessionBrand}_${brandId}`)
                                  .modalSubTextOne,
                              }}
                            >
                              {t(
                                'Choose the Ultium Home topic and session date to get your questions answered in a group demo hosted by a Home Energy Specialist in real-time. Times are displayed in your current time zone.',
                              )}
                            </Typography>
                          )}

                          <Typography
                            variant="modalSubText"
                            component="p"
                            className={clsx(
                              styles.modalSubText,
                              !transferRequest ? 'modalSubTextBottom mb-0' : '',
                              !isBuick ? styles.modalSubTextBottom : '',
                            )}
                            style={transferStylesInline(`${sessionBrand}_${brandId}`).modalSubText}
                            dangerouslySetInnerHTML={{
                              __html: t<string>(
                                'By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information.',
                              ),
                            }}
                          />

                          {(isCadillacUS || isCadillacCanada || isGMC) && (
                            <>
                              <Typography
                                variant="modalSubText"
                                component="p"
                                style={
                                  transferStylesInline(`${sessionBrand}_${brandId}`)
                                    .indicatesRequiredFields
                                }
                                dangerouslySetInnerHTML={{
                                  __html: t<string>('* Indicates Required Fields'),
                                }}
                              />
                              <Typography
                                variant="modalSubText"
                                component="p"
                                style={
                                  transferStylesInline(`${sessionBrand}_${brandId}`).modalSubText
                                }
                                dangerouslySetInnerHTML={{
                                  __html: t<string>(
                                    'Fill out the form below to be connected with a Cadillac Live agent at your preferred time.',
                                  ),
                                }}
                              />
                            </>
                          )}

                          {(isEVLive || isEVLiveCanada) && (
                            <Typography
                              variant="modalSubText"
                              component="p"
                              style={
                                transferStylesInline(`${sessionBrand}_${brandId}`)
                                  .indicatesRequiredFields
                              }
                              dangerouslySetInnerHTML={{
                                __html: t<string>('Reservation: * Indicates Required Field'),
                              }}
                            />
                          )}
                        </GridWrapper>

                        {isBuick && (
                          <Typography
                            variant="modalSubText"
                            component="p"
                            className={styles.modalSubTextBottom}
                            style={
                              transferStylesInline(`${sessionBrand}_${brandId}`)
                                .indicatesRequiredFields
                            }
                            dangerouslySetInnerHTML={{
                              __html: t<string>('* Indicates Required Fields'),
                            }}
                          >
                          </Typography>
                        )}

                        {/* Form begins */}
                        <form
                          id="single-tour"
                          className={clsx(styles.form,
                            'form',
                            transferRequest && styles.formEnergyMuiGridContainer
                          )}
                          style={transferStylesInline(`${sessionBrand}_${brandId}`).form}
                          onSubmit={handleCheckForm}
                          noValidate
                        >
                          <Box
                            className={styles.boxContainer}
                            style={transferStylesInline(`${sessionBrand}_${brandId}`).boxContainer}
                          >
                            <Container
                              className={styles.wrapperContainer}
                              style={
                                transferStylesInline(`${sessionBrand}_${brandId}`).wrapperContainer
                              }
                            >
                              <Grid>
                                <Grid
                                  container
                                  spacing={3}
                                  className={clsx(styles.gridContainer, 'grid-container')}
                                  style={
                                    transferStylesInline(`${sessionBrand}_${brandId}`).gridContainer
                                  }
                                >
                                  {vehicles && vehicles.length > 0 && !(isEVLive || isEnergy) && (
                                    <GridWrapper item md={6} xs={12} id="vehicleId" className='vehicleId'>
                                      <InputLabel
                                        className={
                                          ['ev_gmc', 'evcan_gmc'].includes(
                                            `${sessionBrand}_${brandId}`,
                                          )
                                            ? null
                                            : styles.inputLabel
                                        }
                                        style={{
                                          ...transferStylesInline(`${sessionBrand}_${brandId}`)
                                            .formLabel,
                                          ...transferStylesInline(`${sessionBrand}_${brandId}`)
                                            .topLabels,
                                        }}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: t('Select a Vehicle*'),
                                          }}
                                        />
                                      </InputLabel>

                                      {/* {isChevy && (
                                        <GridWrapper
                                          className={styles.selectWrapper}
                                          id={transferRequest ? 'transferRequest' : ''}
                                        >
                                          <SelectField
                                            className="stat-dropdown"
                                            data-dtm="book 1-1 session:vehicle"
                                            variant="outlined"
                                            fullWidth
                                            name="vehicleId"
                                            defaultValue={values.vehicleId || selectedTourId || t('Available Vehicles')}
                                            error={Boolean(touched.vehicleId && errors.vehicleId)}
                                            onChange={(event) => {
                                              const vehicle = vehicles.find(
                                                (x) => x.id === event.target.value,
                                              ) as Vehicle;

                                              setFieldValue('vehicleId', event.target.value);
                                              setFieldValue('tourName', vehicle.name);
                                              loadAvailableTimes(
                                                selectedDate,
                                                values,
                                                setFieldValue,
                                                vehicle.id,
                                              );
                                              setSelectedTourId(vehicle.id);
                                              handleChange(event);
                                              setIsPreSelected(false);
                                              setCalendarOpen(true);
                                            }}
                                            input={<OutlinedInput />}
                                          // required
                                          >
                                            <option
                                              value={t('Available Vehicles') as string}
                                              disabled
                                            >
                                              {t('Available Vehicles')}
                                            </option>
                                            {vehicles.map((vehicle) => (
                                              <option key={vehicle.id} value={vehicle.id}>
                                                {t(vehicle.name)}
                                              </option>
                                            ))}
                                          </SelectField>

                                          {['ev_buick', 'ev_chevy', 'evcan_chevy'].includes(
                                            `${sessionBrand}_${brandId}`,
                                          ) ? (
                                            <div
                                              data-dtm="book 1-1 session:vehicle"
                                              className="chevron-icon"
                                              style={{
                                                backgroundImage: `${vehicleSelectOpen
                                                  ? theme.palette.background.chevronOpenStateIcon
                                                  : theme.palette.background
                                                    .chevronClosedStateIcon
                                                  }`,
                                              }}
                                            />
                                          ) : (
                                            <div
                                              data-dtm="book 1-1 session:vehicle"
                                              className="dropdown-icon"
                                              style={
                                                transferStylesInline(`${sessionBrand}_${brandId}`)
                                                  .selectArrowConatiner
                                              }
                                            >
                                              <ArrowDropDownIcon
                                                sx={{ color: theme.palette.background.paper }}
                                              />
                                            </div>
                                          )}
                                        </GridWrapper>
                                      )} */}

                                      {/* {(isCadillacUS ||
                                        isCadillacCanada ||
                                        isGMC ||
                                        isBuick ||
                                        isEVLive) && (
                                          <GridWrapper
                                            className={clsx(
                                              styles.selectWrapper,
                                              vehicleSelectOpen
                                                ? styles.selectOpenState
                                                : styles.selectCloseState,
                                            )}
                                          >
                                            <TextFieldForm
                                              id="vehicle"
                                              type="text"
                                              className={clsx(
                                                styles.vehicleSelect,
                                                vehicleSelectOpen ? styles.vehicleSelectOpen : null,
                                                'stat-expand-icon',
                                              )}
                                              error={Boolean(touched.vehicleId && errors.vehicleId)}
                                              data-dtm="book 1-1 session:vehicle"
                                              defaultValue={
                                                (isCadillacUS ||
                                                  isCadillacCanada ||
                                                  isGMC ||
                                                  isBuick ||
                                                  isEVLive) &&
                                                  transferRequest
                                                  ? 'Vehicles'
                                                  : t<string>('Vehicles')
                                              }
                                              InputProps={{
                                                onClick: handleToggle,
                                                readOnly: true,
                                              }}
                                            />

                                            {vehicleSelectOpen && (
                                              <RadioField
                                                name="vehicleId"
                                                value={values.vehicleId || t('Available Vehicles')}
                                                onChange={async (event) => {
                                                  const vehicle = vehicles.find(
                                                    (x) => x.id === event.target.value,
                                                  ) as Vehicle;

                                                  setFieldValue('vehicleId', vehicle.id);
                                                  setFieldValue('tourName', vehicle.name);
                                                  loadAvailableTimes(
                                                    selectedDate,
                                                    values,
                                                    setFieldValue,
                                                    vehicle.id,
                                                  );
                                                  setSelectedTourId(vehicle.id);
                                                  handleChange(event);
                                                  setIsPreSelected(false);
                                                  setCalendarOpen(true);
                                                }}
                                              >
                                                {vehicles &&
                                                  vehicles.map((vehicle) => (
                                                    <FormControlLabel
                                                      className={clsx(
                                                        'stat-checkbox',
                                                        ['ev', 'evcan'].includes(sessionBrand) &&
                                                        transferRequest &&
                                                        'transfer-form-control-label',
                                                      )}
                                                      style={
                                                        transferStylesInline(
                                                          `${sessionBrand}_${brandId}`,
                                                        ).formControlLabel
                                                      }
                                                      data-dtm="book 1-1 session:vehicle"
                                                      key={vehicle.id}
                                                      value={vehicle.id}
                                                      control={
                                                        <Radio
                                                          value={vehicle.id}
                                                          checkedIcon={
                                                            <>
                                                              <CheckIcon
                                                                className={styles.checkIcon}
                                                                style={
                                                                  transferStylesInline(
                                                                    `${sessionBrand}_${brandId}`,
                                                                  ).checkIcon
                                                                }
                                                              />
                                                              <CheckBoxOutlineBlankIcon
                                                                className={styles.checkboxOutline}
                                                                style={
                                                                  transferStylesInline(
                                                                    `${sessionBrand}_${brandId}`,
                                                                  ).checkboxOutline
                                                                }
                                                              />
                                                            </>
                                                          }
                                                          icon={
                                                            <CheckBoxOutlineBlankIcon
                                                              className={styles.checkboxOutline}
                                                              style={
                                                                transferStylesInline(
                                                                  `${sessionBrand}_${brandId}`,
                                                                ).checkboxOutline
                                                              }
                                                            />
                                                          }
                                                          checked={vehicle.id === selectedTourId}
                                                        />
                                                      }
                                                      label={t(vehicle.name).toString()}
                                                    />
                                                  ))}
                                              </RadioField>
                                            )}
                                            {(isCadillacUS ||
                                              isCadillacCanada ||
                                              isEVLive ||
                                              isGMC ||
                                              ['ev_buick', 'ev_chevy', 'evcan_buick', 'chevy_gmc'].includes(
                                                `${sessionBrand}_${brandId}`,
                                              )) && (
                                                <div
                                                  className="chevron-icon"
                                                  style={{
                                                    backgroundImage: `${vehicleSelectOpen
                                                      ? theme.palette.background.chevronOpenStateIcon
                                                      : theme.palette.background
                                                        .chevronClosedStateIcon
                                                      }`,
                                                  }}
                                                />
                                              )}
                                            {(isBuick ||
                                              ['chevy_gmc', 'chevy_cadillac', 'chevy_cadillaccan'].includes(`${sessionBrand}_${brandId}`))
                                              && !['ev_buick', 'ev_chevy', 'evcan_buick'].includes(
                                                `${sessionBrand}_${brandId}`,
                                              ) && (
                                                <div
                                                  className={clsx(
                                                    'chevron-icon',
                                                    ['buick_buick'].includes(
                                                      `${sessionBrand}_${brandId}`,
                                                    ) && 'select-arrow-container-mod-1',
                                                  )}
                                                  style={{
                                                    backgroundColor: transferRequest
                                                      ? '#808080'
                                                      : theme.palette.grey[800],
                                                    ...transferStylesInline(
                                                      `${sessionBrand}_${brandId}`,
                                                    ).selectDropDown,
                                                  }}
                                                >
                                                  {vehicleSelectOpen ? (
                                                    <ExpandLessIcon
                                                      sx={{
                                                        color: theme.palette.grey[50],
                                                        fontSize: '2rem',
                                                      }}
                                                    />
                                                  ) : (
                                                    <ExpandMoreIcon
                                                      sx={{
                                                        color: theme.palette.grey[50],
                                                        fontSize: '2rem',
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              )}
                                          </GridWrapper>
                                        )} */}

                                      {touched.vehicleId && errors.vehicleId && (
                                        <FormHelperText error>
                                          {t('Please select a vehicle.')}
                                        </FormHelperText>
                                      )}
                                    </GridWrapper>
                                  )}

                                  {topics && (isEVLive || isEnergy) && (
                                    <GridWrapper
                                      item
                                      md={6}
                                      xs={12}
                                      id="vehicleId"
                                      style={
                                        transferStylesInline(`${sessionBrand}_${brandId}`)
                                          .leftColumn
                                      }
                                      className={
                                        [
                                          'buick_energy',
                                          'cadillac_energy',
                                          'ev_energy',
                                          'evcan_energy',
                                          'cadillaccan_energy',
                                          'gmc_energy',
                                          'chevy_energy',
                                        ].includes(`${sessionBrand}_${brandId}`)
                                          ? 'transfer-left-column'
                                          : ''
                                      }
                                    >
                                      {!isEnergy && (
                                        <InputLabel
                                          className={styles.inputLabel}
                                          style={
                                            transferStylesInline(`${sessionBrand}_${brandId}`)
                                              .formLabel
                                          }
                                        >
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: t('Select a Topic*'),
                                            }}
                                          />
                                        </InputLabel>
                                      )}

                                      <Box className={styles.topicErrorMobile}>
                                        {maxTopicsSelected && vehicleSelectOpen && (
                                          <FormHelperText error>
                                          {t('You selected the limit of 5 topics.')}
                                        </FormHelperText>
                                        )}
                                      </Box> 

                                      <GridWrapper
                                        className={clsx(
                                          styles.selectWrapper,
                                          vehicleSelectOpen
                                            ? styles.selectOpenState
                                            : styles.selectCloseState,
                                        )}
                                      >
                                        <FormControl
                                          variant={isEnergy && !transferRequest ? 'filled' : 'outlined'}
                                          className={clsx(
                                            styles.vehicleSelect,
                                            vehicleSelectOpen ? styles.vehicleSelectOpen : null,
                                            isEVLive ? 'stat-expand-icon' : null,
                                          )}
                                          error={Boolean(touched.topicId && errors.topicId)}
                                          style={{ width: '100%', color: 'white' }}
                                        >
                                          <InputLabel id="multiple-topic">
                                            {isEnergy && !transferRequest ? t<string>('Select up to 5 topics') : t<string>('Select Topic')}
                                          </InputLabel>
                                          <Select
                                            labelId="multiple-topic"
                                            id="topicId"
                                            name="multipleTopic"
                                            multiple
                                            className={styles.multipleSelect}
                                            value={selectedTourIds}
                                            onChange={async (event) => {
                                              const {
                                                target: { value },
                                              } = event;

                                              // Handle the selected values as an array of IDs
                                              const selectedTopicIds = typeof value === 'string' ? value.split(',') : value;

                                              // Check if the user is trying to select more than 5 topics
                                              if (selectedTopicIds.length === 5){
                                                setMaxTopicsSelected(true); // Enable warning for selecting more than 5 topics
                                              } else if (selectedTopicIds.length < 5) {
                                                setMaxTopicsSelected(false);
                                              }

                                              // Update the form field value with selected topic IDs
                                              setFieldValue('topicId', selectedTopicIds);

                                              if (selectedTopicIds.length > 0) {
                                                // const topicId = selectedTopicIds[0];  // Load available times for first selected topic
                                                loadAvailableTimes(selectedDate, values, setFieldValue, selectedTopicIds);
                                                setSelectedTourIds(selectedTopicIds);
                                                handleChange(event);
                                                if (!isPreSelected) setIsPreSelected(false);
                                                setCalendarOpen(true);
                                              }
                                            }}
                                            renderValue={(selected) => {
                                              // Render the names of selected topics in the Select field or if multiple specified
                                              const selectedTopics = topics
                                                .filter((topic) => selected.includes(topic.id));
                                              return selectedTopics.length > 1 ? "Multiple topics selected" : selectedTopics.map((topic) => topic.name).join(', ');
                                            }}
                                            open={vehicleSelectOpen}
                                            onClose={handleToggle}
                                            onOpen={handleToggle}
                                            MenuProps={{
                                              PaperProps: {
                                                style: {
                                                  maxHeight: 350,
                                                  border: '1px solid black',
                                                  overflowY: 'auto',
                                                  boxShadow: 'none',
                                                },
                                              },
                                              autoFocus: true,
                                              disableAutoFocusItem: true,
                                              disableEnforceFocus: true,
                                              disableAutoFocus: true,
                                            }}
                                          >
                                            {topics
                                              .filter((t) => t?.topics[0].language === languageCode)
                                              .map((topic) => (
                                                <MenuItem key={topic.id} value={topic.id} disabled={maxTopicsSelected && !values.topicId?.includes(topic.id)}>
                                                  <Checkbox checked={values.topicId?.includes(topic.id)} />
                                                  <ListItemText primary={t(topic.name).toString()} sx={{ whiteSpace: 'break-spaces' }} />
                                                </MenuItem>
                                              ))}
                                          </Select>
                                        </FormControl>
                                        <div
                                          className={clsx(
                                            'chevron-icon',
                                            ['buick_energy'].includes(
                                              `${sessionBrand}_${brandId}`,
                                            ) && 'transfer-mod-1',
                                          )}
                                          style={{
                                            ...transferStylesInline(`${sessionBrand}_${brandId}`)
                                              .selectArrowContainer,
                                            backgroundImage: [
                                              'buick_energy',
                                              'buick_ev',
                                              'buick_evcan',
                                              'chevy_ev',
                                              'cadillac_cadillaccan',
                                              'chevy_energy',
                                              'chevy_evcan',
                                            ].includes(`${sessionBrand}_${brandId}`)
                                              ? vehicleSelectOpen
                                                ? theme.palette.background
                                                  .chevronOpenStateIconTransfer
                                                : theme.palette.background.chevronClosedStateIcon
                                              : `${vehicleSelectOpen
                                                ? theme.palette.background.chevronOpenStateIcon
                                                : theme.palette.background
                                                  .chevronClosedStateIcon
                                              }`,
                                          }}
                                        />
                                      </GridWrapper>

                                      {touched.topicId && errors.topicId && (
                                        <FormHelperText error>
                                          {t('Please select a topic.')}
                                        </FormHelperText>
                                      )}
                                    
                                      {maxTopicsSelected && vehicleSelectOpen && (
                                        <FormHelperText error className={styles.topicErrorDesktop}>
                                          {t('You selected the limit of 5 topics.')}
                                        </FormHelperText>
                                        )}
                                    </GridWrapper>
                                  )}

                                  <GridWrapper
                                    item
                                    md={6}
                                    xs={12}
                                    id={transferRequest ? 'dateTransfer' : 'date'}
                                  >
                                    {!isEnergy && (
                                      <InputLabel
                                        className={
                                          ['ev_gmc', 'evcan_gmc'].includes(
                                            `${sessionBrand}_${brandId}`,
                                          )
                                            ? null
                                            : styles.inputLabel
                                        }
                                        style={{
                                          ...transferStylesInline(`${sessionBrand}_${brandId}`)
                                            .formLabel,
                                          ...transferStylesInline(`${sessionBrand}_${brandId}`)
                                            .topLabels,
                                        }}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: t('Select a date (Required):'),
                                          }}
                                        />
                                      </InputLabel>
                                    )}

                                    <GridWrapper className={styles.selectWrapper}>
                                      <TextFieldForm
                                        disabled={!selectedTourIds}
                                        sx={{ background: !selectedTourIds ? theme.palette.grey[200] : '' }}
                                        type="text"
                                        required
                                        error={Boolean(touched.date && errors.date)}
                                        style={
                                          transferStylesInline(`${sessionBrand}_${brandId}`)
                                            .datePicker
                                        }
                                        className={
                                          !transferRequest &&
                                            sessionBrand !== 'gmc' &&
                                            !isCadillacUS
                                            ? clsx(
                                              styles.datePicker,
                                              calendarOpen ? styles.calendarOpen : null,
                                              selectedDate ? styles.dateSelected : null,
                                            )
                                            : ''
                                        }
                                        variant={
                                          isEnergy && !transferRequest ? 'filled' : 'outlined'
                                        }
                                        label={
                                          isEnergy && !transferRequest
                                            ? t<string>('Select A Date')
                                            : null
                                        }
                                        InputProps={{
                                          readOnly: true,
                                          onClick: handleDateToggle,
                                          value: selectedDate
                                            ? format(selectedDate, dateFormat, { locale })
                                            : '',
                                        }}
                                      />

                                      {touched.date && errors.date && !calendarOpen && (
                                        <FormHelperText error>
                                          {t('Please select a date and time.')}
                                        </FormHelperText>
                                      )}

                                      {calendarOpen && (
                                        // TODO: use adapterLocale instead of locale
                                        <div
                                          className={`mui-calendar-picker-container${languageCode === 'en-FR'
                                            ? ' mui-calendar-picker-container--french'
                                            : ''
                                            }`}
                                        >
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={locale}
                                          >
                                            <StaticDatePicker
                                              displayStaticWrapperAs="desktop"
                                              minDate={today}
                                              maxDate={addDates}
                                              openTo="day"
                                              value={selectedDate || null}
                                              shouldDisableDate={isBlackoutDate}
                                              onChange={async (value) => {
                                                setShowTimePicker(true);
                                                setSelectedDate(value);
                                                if (values.time) {
                                                  setFieldValue('date', new Date(value));
                                                  setFieldValue('dateString', value.toString());
                                                }
                                                loadAvailableTimes(
                                                  value,
                                                  values,
                                                  setFieldValue,
                                                  values.vehicleId || values.topicId,
                                                );
                                              }}
                                              label=" "
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  name="date"
                                                  helperText={touched.date && errors.date}
                                                  error={Boolean(touched.date && errors.date)}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  variant="outlined"
                                                  fullWidth
                                                  required
                                                />
                                              )}
                                              allowSameDateSelection
                                            />
                                          </LocalizationProvider>
                                        </div>
                                      )}

                                      <div
                                        role="button"
                                        className={
                                          transferRequest
                                            ? clsx(
                                              'calendar-icon-transfer-base',
                                              [
                                                'gmc_ev',
                                                'buick_ev',
                                                'buick_evcan',
                                                'buick_energy',
                                              ].includes(`${sessionBrand}_${brandId}`) &&
                                              'transfer-mod-1',
                                              [
                                                'ev_buick',
                                                'ev_cadillac',
                                                'ev_cadillaccan',
                                                'ev_chevy',
                                                'ev_energy',
                                                'ev_ev',
                                                'ev_evcan',
                                                'ev_gmc',
                                                'evcan_buick',
                                                'evcan_cadillac',
                                                'evcan_cadillaccan',
                                                'evcan_chevy',
                                                'evcan_energy',
                                                'evcan_ev',
                                                'evcan_evcan',
                                                'evcan_gmc',
                                              ].includes(`${sessionBrand}_${brandId}`) &&
                                              'transfer-mod-2',
                                              [
                                                'buick_chevy',
                                                'buick_gmc',
                                                'cadillac_chevy',
                                                'buick_buick',
                                                'buick_cadillac',
                                                'buick_cadillaccan',
                                              ].includes(`${sessionBrand}_${brandId}`) &&
                                              'transfer-mod-3',
                                              [
                                                'cadillac_chevy',
                                                'cadillac_buick',
                                                'cadillac_energy',
                                                'cadillac_ev',
                                                'cadillac_cadillac',
                                                'cadillac_cadillaccan',
                                                'cadillac_evcan',
                                                'cadillac_gmc',
                                                'cadillaccan_evcan',
                                                'cadillaccan_cadillaccan',
                                                'cadillaccan_buick',
                                                'cadillaccan_cadillac',
                                                'cadillaccan_ev',
                                                'cadillaccan_energy',
                                                'cadillaccan_gmc',
                                                'chevy_chevy',
                                                'chevy_ev',
                                                'chevy_cadillac',
                                                'chevy_cadillaccan',
                                                'chevy_buick',
                                                'chevy_evcan',
                                                'chevy_gmc',
                                                'chevy_energy'
                                              ].includes(`${sessionBrand}_${brandId}`) &&
                                              'transfer-mod-4',
                                              ['cadillaccan_chevy'].includes(
                                                `${sessionBrand}_${brandId}`,
                                              ) && 'transfer-mod-5',
                                              [
                                                'energy_gmc',
                                                'energy_evcan',
                                                'energy_ev',
                                                'energy_energy',
                                                'energy_chevy',
                                                'energy_cadillaccan',
                                                'energy_cadillac',
                                                'energy_buick',
                                              ].includes(`${sessionBrand}_${brandId}`) &&
                                              'transfer-mod-6',
                                              [
                                                'gmc_gmc',
                                                'gmc_ev',
                                                'gmc_buick',
                                                'gmc_cadillac',
                                                'gmc_chevy',
                                                'gmc_cadillaccan',
                                                'gmc_energy',
                                              ].includes(`${sessionBrand}_${brandId}`) &&
                                              'transfer-mod-7',
                                            )
                                            : isCadillacUS || isCadillacCanada
                                              ? 'calendar-icon-cadillac'
                                              : isGMC || isBuick
                                                ? 'calendar-icon-gmc'
                                                : `calendar-icon ${!selectedTourIds ? 'calendar-icon-disabled' : ''}`
                                        }
                                        style={
                                          transferStylesInline(`${sessionBrand}_${brandId}`)
                                            .calendarIcon
                                        }
                                        tabIndex={0}
                                        aria-hidden="true"
                                        onClick={handleDateToggle}
                                      >
                                        {(isCadillacUS ||
                                          isCadillacCanada ||
                                          isChevy ||
                                          isEVLive ||
                                          [
                                            'buick_energy',
                                            'ev_buick',
                                            'ev_gmc',
                                            'ev_energy',
                                            'evcan_buick',
                                            'evcan_energy',
                                            'evcan_gmc',
                                            'cadillaccan_buick',
                                            'cadillac_buick',
                                            'cadillac_gmc',
                                            'cadillaccan_energy',
                                            'cadillac_energy',
                                            'energy_energy',
                                            'energy_gmc',
                                            'energy_buick',
                                            'gmc_energy',
                                            'gmc_gmc',
                                            'gmc_buick',
                                            'chevy_gmc',
                                            'buick_gmc',
                                            'chevy_buick',
                                            'chevy_energy',
                                            'buick_buick'
                                          ].includes(`${sessionBrand}_${brandId}`)) && (
                                            <CalendarTodayOutlinedIcon
                                              sx={{
                                                fontSize: '18px',
                                                color: theme.palette.background.paper,
                                              }}
                                            />
                                          )}
                                        {(transferRequest &&
                                          [
                                            'ev_buick',
                                            'ev_cadillac',
                                            'ev_cadillaccan',
                                            'ev_chevy',
                                            'ev_energy',
                                            'ev_ev',
                                            'ev_evcan',
                                            'ev_gmc',
                                          ].includes(`${sessionBrand}_${brandId}`)) ||
                                          (!transferRequest && (isGMC || isBuick) && (
                                            <img src={CalendarIcon.default} alt="calendar" />
                                          ))}
                                      </div>

                                      {/* Select your preferred time */}
                                      {showTimePicker && (
                                        <Grid
                                          className={clsx(
                                            styles.timePickerContainer,
                                            ['ev_energy', 'evcan_energy'].includes(
                                              `${sessionBrand}_${brandId}`,
                                            ) && 'transfer-time-picker-container',
                                          )}
                                        >
                                          <Typography
                                            component="p"
                                            variant="timeTitle"
                                            style={
                                              transferStylesInline(`${sessionBrand}_${brandId}`)
                                                .timeTitle
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html: t('Select your preferred tour time'),
                                            }}
                                          />

                                          <GridWrapper
                                            className={clsx(
                                              'select-time-container',
                                              ['ev_energy', 'evcan_energy'].includes(
                                                `${sessionBrand}_${brandId}`,
                                              ) && 'transfer-select-time-container',
                                            )}
                                          >
                                            <Typography component="p" variant="timeDateTitle">
                                              {format(selectedDate, dateFormat, { locale })}
                                            </Typography>

                                            {loadingHours ? (
                                              <LinearProgress />
                                            ) : (
                                              <>
                                                {timeSlots &&
                                                  timeSlots?.length > 0 &&
                                                  showTimePicker ? (
                                                  <RadioGroup
                                                    name="date"
                                                    style={
                                                      transferStylesInline(
                                                        `${sessionBrand}_${brandId}`,
                                                      ).radioGroup
                                                    }
                                                    onChange={(event) => {
                                                      const date = event.currentTarget.value;
                                                      setSelectedDate(new Date(date));
                                                      setFieldValue('date', new Date(date));
                                                      setFieldValue('dateString', date.toString());
                                                    }}
                                                  >
                                                    {timeSlots.map((time) => {
                                                      const timeValue = moment(time).format('HH:mm');
                                                      const checked = timeValue === values.time;
                                                      const reservedTopics = Array.isArray(values.topicId) ? values.topicId : [values.topicId];
                                                      // const remaining = getReservationRemaining(time, values.topicId || values.vehicleId);
                                                      const remaining = getAllReservationRemaining(time, reservedTopics);

                                                      const handleTimeSlotClick = () => {
                                                        if (checked) {
                                                          setFieldValue('time', '');
                                                          setFieldValue('date', '');
                                                        } else {
                                                          setFieldValue('time', timeValue);
                                                          setTimeout(() =>
                                                            setFieldTouched('time', true),
                                                          );
                                                        }
                                                      };

                                                      return (
                                                        <FormControlLabel
                                                          className={clsx(
                                                            'stat-checkbox',
                                                            styles.timeWrapper,
                                                            checked ? styles.timeSelected : '',
                                                          )}
                                                          style={
                                                            transferStylesInline(
                                                              `${sessionBrand}_${brandId}`,
                                                            ).timeWrapper
                                                          }
                                                          data-dtm={
                                                            isEnergy
                                                              ? 'book your 1-1 demo:time'
                                                              : 'book 1-1 session:time'
                                                          }
                                                          key={time.toString()}
                                                          control={
                                                            <Radio
                                                              disabled={remaining <= 0}
                                                              checked={checked}
                                                              value={time}
                                                              onClick={handleTimeSlotClick}
                                                              // checkedIcon={
                                                              //   isCadillacUS || isCadillacCanada || ['cadillac_buick'].includes(`${sessionBrand}_${brandId}`) ? (
                                                              //     <>
                                                              //       <CheckIcon
                                                              //         className={styles.checkIcon}
                                                              //         style={
                                                              //           transferStylesInline(
                                                              //             `${sessionBrand}_${brandId}`,
                                                              //           ).checkIcon
                                                              //         }
                                                              //       />
                                                              //       <CheckBoxOutlineBlankIcon
                                                              //         style={
                                                              //           transferStylesInline(
                                                              //             `${sessionBrand}_${brandId}`,
                                                              //           ).checkboxOutline
                                                              //         }
                                                              //       />
                                                              //     </>
                                                              //   ) : isGMC ? (
                                                              //     <CheckIcon
                                                              //       sx={{
                                                              //         backgroundColor:
                                                              //           theme.palette.color.checkboxGrey,
                                                              //         color: theme.palette.grey[800],
                                                              //       }}
                                                              //       style={
                                                              //         transferStylesInline(
                                                              //           `${sessionBrand}_${brandId}`,
                                                              //         ).checkIcon
                                                              //       }
                                                              //     />
                                                              //   ) : isEVLive ? (
                                                              //     isChevyBrandIdUrl ? (
                                                              //       <CheckBoxIcon
                                                              //         style={
                                                              //           transferStylesInline(
                                                              //             `${sessionBrand}_${brandId}`,
                                                              //           ).checkIcon
                                                              //         }
                                                              //       />
                                                              //     ) : (
                                                              //       <>
                                                              //         <CheckIcon
                                                              //           sx={{
                                                              //             backgroundColor:
                                                              //               theme.palette.grey[200],
                                                              //             fontSize: '1.3rem',
                                                              //             color:
                                                              //               theme.palette.grey[300],
                                                              //           }}
                                                              //           className="check-icon"
                                                              //           style={
                                                              //             transferStylesInline(
                                                              //               `${sessionBrand}_${brandId}`,
                                                              //             ).checkIcon
                                                              //           }
                                                              //         />
                                                              //         <CheckBoxOutlineBlankIcon
                                                              //           className="checkbox-square-icon"
                                                              //           sx={{
                                                              //             display: 'none',
                                                              //           }}
                                                              //           style={
                                                              //             transferStylesInline(
                                                              //               `${sessionBrand}_${brandId}`,
                                                              //             ).checkboxOutline
                                                              //           }
                                                              //         />
                                                              //       </>
                                                              //     )
                                                              //   ) : (
                                                              //     <CheckBoxIcon
                                                              //       className={!['cadillaccan_chevy'].includes(`${sessionBrand}_${brandId}`) && clsx(styles.checkboxIcon, 'check-icon')}
                                                              //       style={
                                                              //         transferStylesInline(
                                                              //           `${sessionBrand}_${brandId}`,
                                                              //         ).checkIcon
                                                              //       }
                                                              //     />
                                                              //   )
                                                              // }
                                                              // icon={
                                                              //   isGMC ? (
                                                              //     <CheckBoxOutlineBlankIcon
                                                              //       sx={!['cadillac_gmc'].includes(`${sessionBrand}_${brandId}`) && {
                                                              //         backgroundColor:
                                                              //           theme.palette.color.checkboxGrey,
                                                              //         '& path': { display: 'none' },
                                                              //       }}
                                                              //       style={
                                                              //         transferStylesInline(
                                                              //           `${sessionBrand}_${brandId}`,
                                                              //         ).checkIconBlank
                                                              //       }
                                                              //     />
                                                              //   ) : isEVLive ? (
                                                              //     isChevyBrandIdUrl ? (
                                                              //       <CheckBoxOutlineBlankIcon
                                                              //         style={
                                                              //           transferStylesInline(
                                                              //             `${sessionBrand}_${brandId}`,
                                                              //           ).checkIconBlank
                                                              //         }
                                                              //       />
                                                              //     ) : (
                                                              //       <CheckBoxOutlineBlankIcon
                                                              //         className="checkbox-outlined-icon"
                                                              //         sx={{
                                                              //           backgroundColor:
                                                              //             theme.palette.grey[200],
                                                              //           '& path': {
                                                              //             display: 'none',
                                                              //           },
                                                              //           fontSize: '1.3rem',
                                                              //         }}
                                                              //         style={
                                                              //           transferStylesInline(
                                                              //             `${sessionBrand}_${brandId}`,
                                                              //           ).checkIconBlank
                                                              //         }
                                                              //       />
                                                              //     )
                                                              //   ) : (
                                                              //     <CheckBoxOutlineBlankIcon
                                                              //       style={
                                                              //         transferStylesInline(
                                                              //           `${sessionBrand}_${brandId}`,
                                                              //         ).checkIconBlank
                                                              //       }
                                                              //     />
                                                              //   )
                                                              // }
                                                            />
                                                          }
                                                          // TODO: The below box should be a reusable component
                                                          label={
                                                            <Box
                                                              className={styles.labelTime}
                                                              sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                fontSize: {
                                                                  xs: isEnergy
                                                                    ? '0.9rem'
                                                                    : '0.6rem',
                                                                  md: isEnergy ? '1rem' : '0.9rem',
                                                                },
                                                              }}
                                                            >
                                                              {languageCode !== 'en-US'
                                                                ? timeValue
                                                                : moment(time).format('hh:mm A')}
                                                              {remaining <= warningSlots ? (
                                                                <Box
                                                                  className={clsx(styles.numberOfOpenSlots, 'open-slot')}
                                                                >
                                                                  {remaining}{' '}
                                                                  {isEVLive || isEnergy
                                                                    ? t('Remaining')
                                                                    : t('REMAINING')}
                                                                </Box>
                                                              ) : (
                                                                ''
                                                              )}
                                                            </Box>
                                                          }
                                                        />
                                                      );
                                                    })}
                                                  </RadioGroup>
                                                ) : (
                                                  <Typography component="p" variant="timeTitle">
                                                    {t('No times available')}
                                                  </Typography>
                                                )}
                                              </>
                                            )}
                                          </GridWrapper>
                                        </Grid>
                                      )}
                                    </GridWrapper>
                                  </GridWrapper>

                                  {/* {(isEVLiveCanada && languageCode === 'en-FR') &&
                                  <Grid item xs={12} md={6}>
                                    <Typography variant="modalSubText" component="p">
                                    {t('Des spécialistes des VÉ francophones sont disponibles de 11 h à 19 h, tous les jours de la semaine. En dehors de ces heures, des spécialistes des VÉ anglophones peuvent guider votre visite.')}</Typography>
                                  </Grid>
                                  } */}

                                  <Grid item xs={12}>
                                    <Typography
                                      component="h3"
                                      variant="yourInformation"
                                      className={styles.inputHeader}
                                      style={
                                        transferStylesInline(`${sessionBrand}_${brandId}`).yourInfo
                                      }
                                    >
                                      {t('Your Information')}
                                    </Typography>
                                  </Grid>

                                  <GridWrapper
                                    item
                                    md={6}
                                    xs={12}
                                    id="firstName"
                                    style={
                                      transferStylesInline(`${sessionBrand}_${brandId}`).leftColumn
                                    }
                                    className={
                                      [
                                        'buick_energy',
                                        'cadillac_energy',
                                        'ev_energy',
                                        'evcan_energy',
                                        'cadillaccan_energy',
                                        'gmc_energy',
                                        'chevy_energy',
                                      ].includes(`${sessionBrand}_${brandId}`)
                                        ? 'transfer-left-column'
                                        : ''
                                    }
                                  >
                                    <InputLabel
                                      className={styles.formLabel}
                                      style={
                                        transferStylesInline(`${sessionBrand}_${brandId}`).formLabel
                                      }
                                    >
                                      <span
                                        className={
                                          [
                                            'buick_evcan',
                                            'cadillac_energy',
                                            'cadillaccan_cadillaccan',
                                            'cadillaccan_cadillac',
                                            'cadillaccan_chevy',
                                            'cadillaccan_ev',
                                          ].includes(`${sessionBrand}_${brandId}`)
                                            ? 'transfer-shrink-text'
                                            : ''
                                        }
                                        dangerouslySetInnerHTML={{
                                          __html: t('First Name (Required):'),
                                        }}
                                      />
                                    </InputLabel>

                                    <TextFieldForm
                                      className="stat-input-field"
                                      data-dtm={
                                        isEnergy ? 'book your 1-1 demo' : 'book 1-1 session'
                                      }
                                      error={Boolean(touched.firstName && errors.firstName)}
                                      fullWidth
                                      helperText={touched.firstName && errors.firstName}
                                      name="firstName"
                                      type={hideInputData ? 'password' : 'text'}
                                      disabled={hideInputData}
                                      onChange={handleChange}
                                      onBlur={(e) => {
                                        setFieldValue('firstName', trimSpaces(e.target.value));
                                        handleBlur(e);
                                      }}
                                      required
                                      value={values.firstName}
                                      variant={isEnergy && !transferRequest ? 'filled' : 'outlined'}
                                      label={isEnergy && !transferRequest ? t('First Name') : null}
                                    />
                                  </GridWrapper>

                                  <GridWrapper
                                    item
                                    md={6}
                                    xs={12}
                                    id={transferRequest ? 'lastNameTransfer' : 'lastName'}
                                  >
                                    <InputLabel
                                      className={styles.formLabel}
                                      style={
                                        transferStylesInline(`${sessionBrand}_${brandId}`).formLabel
                                      }
                                    >
                                      <span
                                        className={
                                          [
                                            'buick_evcan',
                                            'cadillac_energy',
                                            'cadillac_ev',
                                            'cadillac_cadillac',
                                            'cadillac_cadillaccan',
                                            'cadillac_evcan',
                                            'cadillaccan_cadillaccan',
                                            'cadillaccan_cadillac',
                                            'cadillaccan_chevy',
                                            'cadillaccan_ev',
                                          ].includes(`${sessionBrand}_${brandId}`)
                                            ? 'transfer-shrink-text'
                                            : ''
                                        }
                                        dangerouslySetInnerHTML={{
                                          __html: t('Last Name (Required):'),
                                        }}
                                      />
                                    </InputLabel>
                                    <TextFieldForm
                                      className="stat-input-field"
                                      data-dtm={
                                        isEnergy ? 'book your 1-1 demo' : 'book 1-1 session'
                                      }
                                      error={Boolean(touched.lastName && errors.lastName)}
                                      fullWidth
                                      helperText={touched.lastName && errors.lastName}
                                      name="lastName"
                                      type={hideInputData ? 'password' : 'text'}
                                      disabled={hideInputData}
                                      onBlur={(e) => {
                                        setFieldValue('lastName', trimSpaces(e.target.value));
                                        handleBlur(e);
                                      }}
                                      onChange={handleChange}
                                      required
                                      value={values.lastName}
                                      variant={isEnergy && !transferRequest ? 'filled' : 'outlined'}
                                      label={isEnergy && !transferRequest ? t('Last Name') : null}
                                    />
                                  </GridWrapper>

                                  {isCadillacCanada || isEVLiveCanada ? (
                                    <GridWrapper
                                      item
                                      md={6}
                                      xs={12}
                                      id={
                                        ['energy_evcan', 'energy_cadillaccan'].includes(
                                          `${sessionBrand}_${brandId}`,
                                        )
                                          ? 'adjustPadding'
                                          : 'postalCode'
                                      }
                                    >
                                      <InputLabel>
                                        <span
                                          dangerouslySetInnerHTML={{ __html: t('Postal Code*') }}
                                        />
                                      </InputLabel>
                                      <TextFieldForm
                                        className="stat-input-field"
                                        data-dtm="book 1-1 session"
                                        error={Boolean(touched.postalCode && errors.postalCode)}
                                        fullWidth
                                        helperText={touched.postalCode && errors.postalCode}
                                        name="postalCode"
                                        type={hideInputData ? 'password' : 'text'}
                                        disabled={hideInputData}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        value={values.postalCode}
                                        variant="outlined"
                                      />
                                    </GridWrapper>
                                  ) : (
                                    <GridWrapper
                                      item
                                      md={6}
                                      xs={12}
                                      id="zipCode"
                                      style={
                                        transferStylesInline(`${sessionBrand}_${brandId}`)
                                          .leftColumn
                                      }
                                      className={
                                        [
                                          'buick_energy',
                                          'cadillac_energy',
                                          'ev_energy',
                                          'evcan_energy',
                                          'cadillaccan_energy',
                                          'gmc_energy',
                                          'chevy_energy',
                                        ].includes(`${sessionBrand}_${brandId}`)
                                          ? 'transfer-left-column'
                                          : ''
                                      }
                                    >
                                      <InputLabel
                                        className={styles.formLabel}
                                        style={
                                          transferStylesInline(`${sessionBrand}_${brandId}`)
                                            .formLabel
                                        }
                                      >
                                        <span
                                          className={
                                            [
                                              'buick_evcan',
                                              'cadillac_energy',
                                              'cadillac_ev',
                                              'cadillac_cadillac',
                                              'cadillac_cadillaccan',
                                              'cadillac_evcan',
                                              'cadillac_gmc',
                                              'cadillaccan_buick',
                                              'cadillaccan_cadillac',
                                              'cadillaccan_chevy',
                                              'cadillaccan_chevy',
                                              'cadillaccan_ev',
                                            ].includes(`${sessionBrand}_${brandId}`)
                                              ? 'transfer-shrink-text'
                                              : ''
                                          }
                                          dangerouslySetInnerHTML={{
                                            __html: t('Zip Code (Required):'),
                                          }}
                                        />
                                      </InputLabel>
                                      <TextFieldForm
                                        className="stat-input-field"
                                        data-dtm={
                                          isEnergy ? 'book your 1-1 demo' : 'book 1-1 session'
                                        }
                                        error={Boolean(touched.zipCode && errors.zipCode)}
                                        fullWidth
                                        helperText={touched.zipCode && errors.zipCode}
                                        name="zipCode"
                                        type={hideInputData ? 'password' : 'text'}
                                        disabled={hideInputData}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        value={values.zipCode}
                                        variant={
                                          isEnergy && !transferRequest ? 'filled' : 'outlined'
                                        }
                                        label={isEnergy && !transferRequest ? t('Zip Code') : null}
                                      />
                                    </GridWrapper>
                                  )}
                                  {isEnergy && (
                                    <GridWrapper
                                      item
                                      md={6}
                                      xs={12}
                                      id={transferRequest ? 'adjustPadding' : 'phoneNumber'}
                                    >
                                      {transferRequest && isEnergy && (
                                        <InputLabel
                                          className={styles.formLabel}
                                          style={
                                            transferStylesInline(`${sessionBrand}_${brandId}`)
                                              .formLabel
                                          }
                                        >
                                          <span
                                            className={
                                              [
                                                'buick_evcan',
                                                'cadillac_energy',
                                                'cadillac_ev',
                                                'cadillac_cadillac',
                                                'cadillac_cadillaccan',
                                                'cadillac_evcan',
                                                'cadillac_gmc',
                                                'cadillaccan_evcan',
                                                'cadillaccan_cadillaccan',
                                                'cadillaccan_buick',
                                                'cadillaccan_cadillac',
                                                'cadillaccan_chevy',
                                                'cadillaccan_chevy',
                                                'cadillaccan_ev',
                                              ].includes(`${sessionBrand}_${brandId}`)
                                                ? 'transfer-shrink-text'
                                                : ''
                                            }
                                            dangerouslySetInnerHTML={{
                                              __html: t('Phone Number'),
                                            }}
                                          />
                                        </InputLabel>
                                      )}
                                      <TextFieldForm
                                        className="stat-input-field"
                                        data-dtm={
                                          isEnergy ? 'book your 1-1 demo' : 'book 1-1 session'
                                        }
                                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                        name="phoneNumber"
                                        type={hideInputData ? 'password' : 'tel'}
                                        disabled={hideInputData}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                        variant={transferRequest ? 'outlined' : 'filled'}
                                        label={!transferRequest ? t('Phone Number') : ''}
                                        fullWidth
                                        required
                                      />
                                      {!(touched.phoneNumber && errors.phoneNumber) && (
                                        <FormHelperText>{t('Optional')}</FormHelperText>
                                      )}
                                    </GridWrapper>
                                  )}
                                  <GridWrapper
                                    item
                                    md={6}
                                    xs={12}
                                    id="email"
                                    style={
                                      transferStylesInline(`${sessionBrand}_${brandId}`).leftColumn
                                    }
                                    className={
                                      [
                                        'buick_energy',
                                        'cadillac_energy',
                                        'ev_energy',
                                        'evcan_energy',
                                        'cadillaccan_energy',
                                        'gmc_energy',
                                        'chevy_energy',
                                      ].includes(`${sessionBrand}_${brandId}`)
                                        ? 'transfer-left-column'
                                        : ''
                                    }
                                  >
                                    <InputLabel
                                      className={styles.formLabel}
                                      style={
                                        transferStylesInline(`${sessionBrand}_${brandId}`).formLabel
                                      }
                                    >
                                      <span
                                        className={
                                          [
                                            'buick_evcan',
                                            'buick_ev',
                                            'buick_chevy',
                                            'cadillac_chevy',
                                            'cadillac_buick',
                                            'cadillac_energy',
                                            'cadillac_ev',
                                            'cadillac_cadillac',
                                            'cadillac_cadillaccan',
                                            'cadillac_evcan',
                                            'cadillac_gmc',
                                            'cadillaccan_evcan',
                                            'cadillaccan_cadillaccan',
                                            'cadillaccan_buick',
                                            'cadillaccan_cadillac',
                                            'cadillaccan_chevy',
                                            'cadillaccan_chevy',
                                            'cadillaccan_ev',
                                          ].includes(`${sessionBrand}_${brandId}`)
                                            ? 'transfer-shrink-text'
                                            : ''
                                        }
                                        dangerouslySetInnerHTML={{
                                          __html: t('Email Address (Required):'),
                                        }}
                                      />
                                    </InputLabel>
                                    <TextFieldForm
                                      className="stat-input-field"
                                      data-dtm={
                                        isEnergy ? 'book your 1-1 demo' : 'book 1-1 session'
                                      }
                                      error={Boolean(touched.email && errors.email)}
                                      helperText={touched.email && errors.email}
                                      name="email"
                                      type={hideInputData ? 'password' : 'email'}
                                      disabled={hideInputData}
                                      onBlur={handleBlur}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        let email = e.target.value;
                                        if (isCadillacUS || isCadillacCanada) {
                                          email = email.toLowerCase();
                                        }
                                        setFieldValue('email', email);
                                      }}
                                      value={values.email}
                                      variant={isEnergy && !transferRequest ? 'filled' : 'outlined'}
                                      label={isEnergy && !transferRequest ? t('Email') : null}
                                      fullWidth
                                      required
                                    />
                                  </GridWrapper>

                                  <GridWrapper
                                    item
                                    md={6}
                                    xs={12}
                                    id={transferRequest ? 'confirmEmailTransfer' : 'confirmEmail'}
                                  >
                                    <InputLabel
                                      className={styles.formLabel}
                                      style={
                                        transferStylesInline(`${sessionBrand}_${brandId}`).formLabel
                                      }
                                    >
                                      <span
                                        className={
                                          [
                                            'buick_evcan',
                                            'buick_ev',
                                            'buick_energy',
                                            'buick_chevy',
                                            'cadillac_chevy',
                                            'cadillac_buick',
                                            'cadillac_energy',
                                            'cadillac_ev',
                                            'cadillac_cadillac',
                                            'cadillac_cadillaccan',
                                            'cadillac_evcan',
                                            'cadillac_gmc',
                                            'cadillaccan_evcan',
                                            'cadillaccan_cadillaccan',
                                            'cadillaccan_buick',
                                            'cadillaccan_cadillac',
                                            'cadillaccan_chevy',
                                            'cadillaccan_chevy',
                                            'cadillaccan_ev',
                                            'cadillaccan_energy',
                                            'cadillaccan_gmc',
                                            'gmc_ev',
                                            'buick_cadillaccan',
                                          ].includes(`${sessionBrand}_${brandId}`)
                                            ? 'transfer-shrink-text'
                                            : ''
                                        }
                                        dangerouslySetInnerHTML={{
                                          __html: t('Confirm Email Address (Required):'),
                                        }}
                                      />
                                    </InputLabel>
                                    <TextFieldForm
                                      className="stat-input-field"
                                      data-dtm={
                                        isEnergy ? 'book your 1-1 demo' : 'book 1-1 session'
                                      }
                                      error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                                      helperText={touched.confirmEmail && errors.confirmEmail}
                                      name="confirmEmail"
                                      type={hideInputData ? 'password' : 'email'}
                                      disabled={hideInputData}
                                      onBlur={handleBlur}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        let email = e.target.value;
                                        if (isCadillacUS || isCadillacCanada) {
                                          email = email.toLowerCase();
                                        }
                                        setFieldValue('confirmEmail', email);
                                      }}
                                      value={values.confirmEmail}
                                      variant={isEnergy && !transferRequest ? 'filled' : 'outlined'}
                                      label={
                                        isEnergy && !transferRequest ? t('Confirm Email') : null
                                      }
                                      fullWidth
                                      required
                                    />
                                  </GridWrapper>

                                  {(isCadillacCanada || isEVLiveCanada) && (
                                    <RadioGroup
                                      id="receiveUpdates"
                                      name="receiveUpdates"
                                      value={values.receiveUpdates}
                                      onChange={handleChange}
                                      sx={{
                                        flexWrap: 'nowrap',
                                        [theme.breakpoints.up('md')]: {
                                          flexDirection: 'row',
                                        },
                                      }}
                                    >
                                      <GridWrapper
                                        item
                                        md={6}
                                        xs={12}
                                        id="optIn"
                                        style={
                                          transferStylesInline(`${sessionBrand}_${brandId}`)
                                            .optWrapper
                                        }
                                      >
                                        <FormControlLabel
                                          className="stat-checkbox"
                                          data-dtm="book 1-1 session"
                                          style={
                                            transferStylesInline(`${sessionBrand}_${brandId}`).optIn
                                          }
                                          value="Y"
                                          control={
                                            <Radio
                                              checkedIcon={
                                                isCadillacCanada ? (
                                                  <>
                                                    <CheckIcon
                                                      className={styles.checkIcon}
                                                      style={
                                                        transferStylesInline(
                                                          `${sessionBrand}_${brandId}`,
                                                        ).checkIcon
                                                      }
                                                    />
                                                    <CheckBoxOutlineBlankIcon
                                                      style={
                                                        transferStylesInline(
                                                          `${sessionBrand}_${brandId}`,
                                                        ).checkIconBlank
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <CheckIcon
                                                      className="check-icon"
                                                      style={
                                                        transferStylesInline(
                                                          `${sessionBrand}_${brandId}`,
                                                        ).checkIcon
                                                      }
                                                    />
                                                    <CheckBoxOutlineBlankIcon
                                                      className="checkbox-square-icon"
                                                      style={
                                                        transferStylesInline(
                                                          `${sessionBrand}_${brandId}`,
                                                        ).checkIconBlank
                                                      }
                                                    />
                                                  </>
                                                )
                                              }
                                              icon={
                                                <CheckBoxOutlineBlankIcon
                                                  className={
                                                    !['cadillaccan_cadillaccan'].includes(
                                                      `${sessionBrand}_${brandId}`,
                                                    )
                                                      ? 'checkbox-outlined-icon'
                                                      : ''
                                                  }
                                                  style={
                                                    transferStylesInline(
                                                      `${sessionBrand}_${brandId}`,
                                                    ).checkIconBlank
                                                  }
                                                />
                                              }
                                            />
                                          }
                                          label={t<string>(
                                            'Opt In to Electronic Marketing Communications',
                                          )}
                                        />
                                        <Typography
                                          variant="modalSubText"
                                          component="p"
                                          dangerouslySetInnerHTML={{
                                            __html: t(
                                              'YES! I want to receive email and other electronic communications with news, offers and updates about products and services from Cadillac and General Motors of Canada Company that are relevant to my interests based on information you have about me. I understand that I may withdraw my consent at any time*',
                                            ),
                                          }}
                                        />
                                      </GridWrapper>

                                      <GridWrapper
                                        item
                                        md={6}
                                        xs={12}
                                        id="optOut"
                                        style={
                                          transferStylesInline(`${sessionBrand}_${brandId}`)
                                            .optWrapper
                                        }
                                      >
                                        <FormControlLabel
                                          className="stat-checkbox"
                                          data-dtm="book 1-1 session"
                                          value="N"
                                          style={
                                            transferStylesInline(`${sessionBrand}_${brandId}`).optIn
                                          }
                                          control={
                                            <Radio
                                              checkedIcon={
                                                isCadillacCanada ? (
                                                  <>
                                                    <CheckIcon
                                                      className={styles.checkIcon}
                                                      style={
                                                        transferStylesInline(
                                                          `${sessionBrand}_${brandId}`,
                                                        ).checkIcon
                                                      }
                                                    />
                                                    <CheckBoxOutlineBlankIcon
                                                      style={
                                                        transferStylesInline(
                                                          `${sessionBrand}_${brandId}`,
                                                        ).checkboxOutline
                                                      }
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <CheckIcon
                                                      className="check-icon"
                                                      style={
                                                        transferStylesInline(
                                                          `${sessionBrand}_${brandId}`,
                                                        ).checkIcon
                                                      }
                                                    />
                                                    <CheckBoxOutlineBlankIcon
                                                      className="checkbox-square-icon"
                                                      style={
                                                        transferStylesInline(
                                                          `${sessionBrand}_${brandId}`,
                                                        ).checkboxIcon
                                                      }
                                                    />
                                                  </>
                                                )
                                              }
                                              icon={
                                                <CheckBoxOutlineBlankIcon
                                                  className={
                                                    !['cadillaccan_cadillaccan'].includes(
                                                      `${sessionBrand}_${brandId}`,
                                                    )
                                                      ? 'checkbox-outlined-icon'
                                                      : ''
                                                  }
                                                  style={
                                                    transferStylesInline(
                                                      `${sessionBrand}_${brandId}`,
                                                    ).checkIconBlank
                                                  }
                                                />
                                              }
                                            />
                                          }
                                          label={`${t('Opt Out')}`}
                                        />
                                        <Typography
                                          variant="modalSubText"
                                          component="p"
                                          dangerouslySetInnerHTML={{
                                            __html: t(
                                              'General Motors of Canada Company would like to periodically provide you with information on new products and services that are relevant to you based on information we have about you, and use your information in accordance with our privacy policy . If you wish the information provided be used solely to respond to your request, simply check the box.',
                                            ),
                                          }}
                                        />
                                      </GridWrapper>
                                    </RadioGroup>
                                  )}

                                  {/* {isCadillacCanada && (
                                    <Grid item xs={12}>
                                      <GridWrapper item md={6} xs={12} id="receiveUpdates">
                                        <FormGroup>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className="stat-checkbox"
                                                data-dtm="book 1-1 session"
                                                value={values.receiveUpdates}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                              />
                                            }
                                            label={t<string>('Receive Updates*')}
                                            name="receiveUpdates"
                                          />
                                        </FormGroup>
                                        <Typography variant="modalSubText" component="p">
                                          {t(
                                            'Opt in for additional updates and offers. YES! I also want to receive email and other electronic communications with news, offers and updates about products and services from Cadillac and General Motors of Canada company that are relevant to my interests based on information you have about me. I understand that I may withdraw my consent at any time*',
                                          )}
                                        </Typography>
                                      </GridWrapper>
                                    </Grid>
                                  )} */}
                                </Grid>

                                <GridWrapper
                                  container
                                  className={clsx(
                                    styles.buttonContainer,
                                    'form-button-container',
                                    `${isModifyReservation ? 'form-button-container--modify' : ''}`,
                                    `${isModifyReservation ? styles.buttonContainerisModifyReservation : ''}`,
                                  )}
                                  style={
                                    transferStylesInline(`${sessionBrand}_${brandId}`)
                                      .buttonsContainer
                                  }
                                >
                                  <GridWrapper
                                    container
                                    className={clsx(
                                      styles.buttonsContainer,
                                      'form-buttons-container',
                                      `${isModifyReservation ? 'form-buttons-container--modify' : ''}`,
                                      `${isModifyReservation ? styles.buttonsContainerIsModifyReservation : ''}`,
                                    )}
                                    style={
                                      transferStylesInline(`${sessionBrand}_${brandId}`).bookNow
                                    }
                                  >
                                    {isModifyReservation && (
                                      <TextButton
                                        className={clsx(
                                          styles.cancelReservation,
                                          'cancel-reservation-button',
                                          `${isGMC || isBuick ? 'stat-button-link' : 'stat-text-link'}`
                                        )}
                                        data-dtm={
                                          isEnergy ? 'book your 1-1 demo' : 'book 1-1 session'
                                        }
                                        onClick={handelCancelReservation}
                                        disableRipple
                                        variant={
                                          isChevy
                                            ? 'secondary'
                                            : isGMC ||
                                              isBuick ||
                                              isEVLive ||
                                              isEVLiveCanada ||
                                              isEnergy
                                              ? 'tertiary'
                                              : 'primary'
                                        }
                                        type="button"
                                        aria-label="Cancel Reservation"
                                      >
                                        {t('Cancel Reservation')}
                                      </TextButton>
                                    )}

                                    <LoadingButton
                                      loading={isLoading}
                                      style={
                                        transferStylesInline(`${sessionBrand}_${brandId}`)
                                          .submitButton
                                      }
                                      className={clsx('stat-button-link', styles.submitButton, 'submit-button')}
                                      data-dtm={
                                        isEnergy ? 'book your 1-1 demo' : 'book 1-1 session'
                                      }
                                      onClick={() => {
                                        validateForm()
                                        .then(() => submitForm())
                                        .catch((e) => new Error(e));
                                        setValidateErrors(true);
                                        executeScroll();
                                      }}
                                      disabled={isSubmitting}
                                      variant={
                                        transferRequest &&
                                          sessionBrand !== 'ev' &&
                                          sessionBrand !== 'evcan'
                                          ? 'contained'
                                          : 'primary'
                                      }

                                      type="submit"
                                      aria-label="Submit Reservation"
                                    >
                                      {isEnergy && !isModifyReservation
                                        ? t('Book now')
                                        : t('Submit')}
                                    </LoadingButton>
                                  </GridWrapper>
                                  <Button
                                    style={
                                      transferStylesInline(`${sessionBrand}_${brandId}`)
                                        .cancelButton
                                    }
                                    className={clsx(
                                      styles.noThanksButton,
                                      'no-thanks-button',
                                      `${isGMC || (isBuick && sessionBrand !== 'ev' && sessionBrand !== 'evcan') || isEnergy ? 'stat-button-link' : 'stat-text-link'}`
                                    )}
                                    data-dtm={isEnergy ? 'book your 1-1 demo' : 'book 1-1 session'}
                                    onClick={goBack}
                                    variant={
                                      (!isModifyReservation &&
                                        isGMC &&
                                        sessionBrand !== 'ev' &&
                                        sessionBrand !== 'evcan') ||
                                        (isBuick && sessionBrand !== 'ev' && sessionBrand !== 'evcan')
                                        ? 'tertiary'
                                        : 'text'
                                    }
                                    type="button"
                                    aria-label="No thanks"
                                    disableRipple
                                  >
                                    {isModifyReservation ? t('Return to brand') : t('No thanks')}

                                    {isModifyReservation && (isGMC || isBuick) && (
                                      <Box
                                        className={styles.buttonArrow}
                                        sx={{
                                          backgroundImage:
                                            theme.palette.background.chevronRightArrowIcon,
                                        }}
                                      />
                                    )}
                                  </Button>
                                </GridWrapper>

                                {(isCadillacCanada || isEVLiveCanada) && (
                                  <Grid sx={{ mt: '50px' }}>
                                    <Typography
                                      variant="modalSubText"
                                      component="p"
                                      dangerouslySetInnerHTML={{
                                        __html: t<string>(
                                          '* General Motors of Canada Company (GM) is located at 500 Wentworth Street West, Oshawa, ON, L1J 0C5, 1-800-GM DRIVE, gm.ca, contact us, privacy policy. You may withdraw your consent for receiving marketing emails from GM, any time by contacting us. If you contact us to unsubscribe you will no longer receive marketing messages from us, but we may still send you other electronic messages such as those relating to your transactions with us, health and safety matters or those that we are required or permitted by law to send to you. If you do not click “YES”, your marketing status with us will not change but you can contact us anytime to change your marketing preferences.',
                                        ),
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Container>
                          </Box>
                        </form>
                        {/* Form ends */}
                      </>
                    )}
                  </Grid>
                </Card>
              );
            }}
          </Formik>
        )
      )}
    </>
  );
};

export default ReservationView;
