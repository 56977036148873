import { Box } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandType } from '@types';
import ModalIconButton from './ModalIconButton';

type TroubleshootModalProps = {
  onClose?: () => void;
  brandId: BrandType;
};

const TroubleshootModal = (props: TroubleshootModalProps): ReactElement => {
  const { onClose, brandId } = props;
  const { t } = useTranslation();
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isEnergy = brandId.toLowerCase() === 'energy';

  const useStyles = makeStyles({
    ml10: {
      marginLeft: 10,
    },
    ml20: {
      marginLeft: 20,
    },
    ml30: {
      marginLeft: 30,
    },
    ml40: {
      marginLeft: 40,
    },
    ml50: {
      marginLeft: 50,
    },
    mb27: {
      marginBottom: 27,
    },
    mb40: {
      marginBottom: 40,
    },
    fs20: {
      fontSize: 20,
    },
    fs24: {
      fontSize: 24,
    },
    fs22: {
      fontSize: 22,
    },
  });
  const styles = useStyles();

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
        />
      )}
      <Box className="modal-container">
        {!isEnergy && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
          />
        )}
        <Box className={styles.mb40}>
          <Typography variant="modalTitle" component="h2">
            {t('Troubleshooting')}
          </Typography>
          {isGMC && <Box className="modal-border" />}
          <Typography className={styles.mb27} variant="troubleshootQuestion" component="h2">
            {t('What are the minimum system requirements and supported browsers?')}
          </Typography>

          <Box style={{ marginBottom: '40px' }}>
            <Typography variant="troubleshootDT" component="p">
              {t('SUPPORTED BROWSERS')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Google Chrome (latest release version)')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Google Chrome for Android (latest release version)')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Firefox (latest release version)')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Firefox for Android (latest release version)')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Safari 11')}
            </Typography>
            <Typography variant="troubleshootDD" component="span">
              {t('Firefox (latest release version)')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iOS 11')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Opera (latest release version)')}
            </Typography>

            <Typography variant="troubleshootDT" component="p">
              {t('BANDWIDTH REQUIREMENTS')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Video: 300 kbps per stream (recommended lowest level)')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Audio: 50 kbps per stream (recommended lowest level)')}
            </Typography>

            <Typography variant="troubleshootDT" component="p">
              {t('iOS')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Operating system')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iOS 9 or higher')}
            </Typography>

            <Typography variant="troubleshootDT" component="p">
              {t('Hardware')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iPhone X/XR/XS')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iPhone 8/8+')}
            </Typography>

            <Typography variant="troubleshootDD" component="p">
              {t('iPhone 6/6+/6S/6S+')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iPhone SE')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iPhone 5S')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Pad Pro 1st/2nd generation')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iPad (2017)')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iPad Air/Air 2')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iPad 4th generation')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iPad mini 2/3/4')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iPod')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('iPod touch 6th generation')}
            </Typography>

            <Typography variant="troubleshootDT" component="p">
              {t('ANDROID')}
            </Typography>
            <Typography variant="troubleshootDT" component="p">
              {t('Operating system')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('Any Android 4.1+ device (Jelly Bean, API Level 16)')}
            </Typography>

            <Typography variant="troubleshootDT" component="p">
              {t('Hardware')}
            </Typography>
            <Typography variant="troubleshootDT" component="p">
              {t('.2 GHz quad-core ARM Cortex-A7 or better')}
            </Typography>
            <Typography variant="troubleshootDD" component="p">
              {t('1 GB RAM (ex. Samsung Galaxy S3 - 2012)')}
            </Typography>
          </Box>

          <Typography className={styles.mb27} variant="troubleshootQuestion" component="h3">
            {t(
              'As long as neither the user or Product Specialist end the session, refreshing the page will not end the session.',
            )}
          </Typography>
          <Typography variant="troubleshootAnswer" component="p">
            {t(
              'As long as neither the user or Product Specialist end the session, refreshing the page will not end the session.',
            )}
          </Typography>

          <Typography
            variant="troubleshootQuestion"
            component="h3"
            className={clsx(styles.mb27, styles.fs24)}
          >
            {t('What doesn’t work?')}
          </Typography>

          <Typography
            variant="troubleshootHeading"
            component="h4"
            className={clsx(styles.ml10, styles.fs24)}
          >
            {t('PS interface')}
          </Typography>

          <Typography
            variant="troubleshootHeading"
            component="h5"
            className={clsx(styles.ml20, styles.fs20)}
          >
            {t('iPhone')}
          </Typography>

          <Typography
            variant="troubleshootQuestion"
            component="h6"
            className={clsx(styles.ml30, styles.fs20)}
          >
            {t('Sound not coming through to PS')}
          </Typography>

          <Box className={styles.mb40}>
            <Typography className={styles.ml40} variant="troubleshootDT" component="h3">
              {t('Did the user allow microphone access')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t(
                'Edge - try refreshing the page, edge will prompt the user to allow permissions again',
              )}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t(
                'Chrome - in the address bar on the right side there is a little icon of a camera with a red circle (indicating denied access) click on this and allow access to the microphone and refresh the page',
              )}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Safari - Need access to a mac to check on this one')}
            </Typography>
            <Typography className={styles.ml40} variant="troubleshootDT" component="h3">
              {t('Is the user using the correct microphone')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t(
                'Some users have alternate microphones that may be activated, this should be indicated by some type of background noise',
              )}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="h3">
              {t('Is the bluetooth on/paired')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Bluetooth could be on a paired to a device the user is not using at the time')}
            </Typography>
          </Box>

          <Typography className={styles.fs20} variant="troubleshootQuestion" component="h6">
            {t('Sound not transmitting to user')}
          </Typography>
          <Box className={styles.mb40}>
            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Is the mic on')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Check the battery level on the PS headset if the power is on')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Is the microphone muted')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Check to see if the PS headset is on mute')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t("Are the user's speakers on")}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Some users have bluetooth headsets that may be activated')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Check that the user has the volume up on there speakers')}
            </Typography>
          </Box>

          <Typography className={styles.fs20} variant="troubleshootQuestion" component="h6">
            {t('Sound is coming from the phone')}
          </Typography>
          <Box className={styles.mb40}>
            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Is the bluetooth connected')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Check that the phone’s bluetooth is on')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Check the the correct phone is connected to the bluetooth')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Is the headset on')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Check that the headset has been turned on')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Check that the headset is charged')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Are the user’s speakers on')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Some users have bluetooth headsets that may be activated')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Check that the user has the volume up on there speakers')}
            </Typography>
          </Box>

          <Typography className={styles.fs20} variant="troubleshootQuestion" component="h6">
            {t('Video is not transmitting')}
          </Typography>
          <Box className={styles.mb40}>
            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Is something in front of the camera')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Are the phone and the ipad signed in to the same PS account')}
            </Typography>
          </Box>

          <Typography sx={{ fontSize: 22 }} variant="troubleshootHeading" component="h5">
            {t('iPad')}
          </Typography>
          <Box className={styles.mb40}>
            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('The video isn’t visible')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Are the phone and ipad signed into the same PS account')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Refresh the ipad')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('The chat doesn’t work')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Try refreshing the ipad screen')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('The interface isn’t visible')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Try refreshing the ipad screen')}
            </Typography>
          </Box>

          <Typography sx={{ fontSize: 24 }} variant="troubleshootHeading" component="h4">
            {t('User Interface')}
          </Typography>
          <Box className={styles.mb40}>
            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Features don’t appear')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t("Try a different model, limited edition models (like the 2021 escalade) sometimes don't have feature sections included.")}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Try refreshing the page')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Is the options menu open? Features are hidden to save space while the menus are open')}
            </Typography>
            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Menu isn’t accessible')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Try refreshing the page')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Is the features menu open? The side menu is visible but not accessible while the features menu is open')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Sound isn’t coming through')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Is the PS on mute')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Is the user’s volume up and active on the correct speakers')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Video isn’t coming through')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Is something in front of the camera')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Try refreshing the browser')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Chat isn’t functioning')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Try refreshing the browser')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Menu doesn’t sync')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Sometimes the PS menu can lag, touching the ipad screen can fix this')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Try refreshing the browser')}
            </Typography>

            <Typography className={styles.ml40} variant="troubleshootDT" component="p">
              {t('Interface isn’t visible')}
            </Typography>
            <Typography className={styles.ml50} variant="troubleshootDD" component="p">
              {t('Try refreshing the browser')}
            </Typography>
          </Box>

          <Typography variant="troubleshootAnswer" component="p">
            {t('If all of the above options fail, try having the user come back in a new session using a different or incognito browser.')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default TroubleshootModal;
