import { Box, Typography, Button } from "@mui/material";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type CloseToastModalProps = {
  onClose?: () => void;
  onOpen?: () => void;
}

const CloseToastModal = (props: CloseToastModalProps) => {
  const { t } = useTranslation();
  const { onClose, onOpen } = props;

  const useStyles = makeStyles({
    toastModal: {
      border: '3px solid #8DF7FD',
      padding: '8px',
      '@media screen and (min-width: 1200px)': {
        padding: '16px',
      },
    },
    toastModal_text: {
      color: '#000000',
      fontFamily: 'Overpass',
      fontSize: 14,
      fontWeight: '700',
      lineHeight: '20px',
      '@media screen and (min-width: 1200px)': {
        fontSize: 17,
      },
    },
    toastModal_buttons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: '16px',
      '@media screen and (min-width: 1200px)': {

      },
    },
    toastModal_button: {
      width: '100% !important',
      maxWidth: '100% !important',
    },
  });
  const styles = useStyles();

  return (
    <Box className={styles.toastModal}>
      <Typography
        className={styles.toastModal_text}
        dangerouslySetInnerHTML={{
          __html: t('Are you sure you want to close this form? All your data will be lost.'),
        }}
      />
      <Box className={styles.toastModal_buttons}>
        <Button
          sx={{
            marginBottom: '8px',
          }}
          variant="primary"
          className={clsx('stat-button-link', styles.toastModal_button)}
          data-dtm="modal:close this form"
          onClick={onOpen}
          type="button"
          disableRipple
        >
          {t('No')}
        </Button>
        <Button
          variant="text"
          className={clsx('stat-button-link', styles.toastModal_button)}
          data-dtm="modal:close this form"
          onClick={onClose}
          type="button"
          disableRipple
        >
          {t('Yes')}
        </Button>
      </Box>
    </Box>
  )
};

export default CloseToastModal;