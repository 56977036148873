import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
// import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
// import IconButton from '@mui/material/IconButton';
import {
  Button,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Typography,
  FormGroup,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Field, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
// import { doc, setDoc } from 'firebase/firestore';
// import { useFirestore, useAuth } from 'reactfire';
// import { ErrorComponent, TextButton } from '@components';
import { ErrorComponent, ModalIconButton } from '@components';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandType, Vehicle, Nameplate, Topic } from '@types';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { NameplatePopupModalStyles } from './NameplatePopupModal.style';
import * as Scroll from 'react-scroll';
// import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@material-ui/core/styles';

type NameplatePopupModalProps = {
  brandId: BrandType;
  nameplates: (Vehicle | Topic)[];
  onClose?: () => void;
  onSubmit: (nameplate: Nameplate) => void;
};

const NameplatePopupModal = (props: NameplatePopupModalProps): ReactElement => {
  const { onClose, onSubmit, nameplates, brandId } = props;
  const [validateErrors, setValidateErrors] = useState<boolean>(false);
  const { t } = useTranslation();
  const isChevy = brandId.toLowerCase() === 'chevy';
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev' || brandId.toLowerCase() === 'evcan';
  const useStyles = makeStyles(NameplatePopupModalStyles(brandId));
  const styles = useStyles();
  const theme = useTheme();
  const scroll = Scroll.animateScroll;
  const { Element } = Scroll;
  const isEnergy = brandId.toLowerCase() === 'energy';
  let dataDTM = 'nameplate';

  const executeScroll = () => {
    window.scrollTo(0, 0);
    scroll.scrollToTop('errorElement');
  };

  const renderCheckedIcon = () => {
    if (isEVLive) {
      return (
        <>
          <CheckIcon className="check-icon" />
          <CheckBoxOutlineBlankIcon className="checkbox-square-icon" />
        </>
      );
    }

    if (isCadillacUS || isCadillacCanada) {
      return (
        <>
          <CheckIcon className={styles.checkIcon} />
          <CheckBoxOutlineBlankIcon />
        </>
      );
    }

    return <CheckBoxIcon />;
  };

  const renderIcon = () => {
    if (isEVLive) {
      return (
        <CheckBoxOutlineBlankIcon className="checkbox-outlined-icon" />
      );
    }

    return <CheckBoxOutlineBlankIcon />;
  };

  return (
    <>
      {nameplates && (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            discussedNameplate: '',
            appliedNameplate: [],
          }}
          validationSchema={Yup.object().shape({
            appliedNameplate: isEVLive
              ? Yup.array().required(t('Please select applied nameplates.')).min(1)
              : null,
            discussedNameplate: !isEVLive
              ? Yup.string().when('appliedNameplate', {
                is: (appliedNameplate) => !appliedNameplate || appliedNameplate.length === 0,
                then: Yup.string().required(t('Please select at least one nameplate or brand awareness.')),
              })
              : null,
          })}
          onSubmit={async (values, { setStatus, setSubmitting }): Promise<void> => {
            try {
              // const ref = doc(firestore, 'nameplate', currentUser.uid);
              // await setDoc(
              //   ref,
              //   {
              //     ...values,
              //   },
              //   { merge: true },
              // );
              setStatus({ success: true });
              setSubmitting(false);
              onSubmit(values);
              onClose();
            } catch (err) {
              console.error(err);
              toast.error(`Something went wrong! ${err.message}`);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleSubmit,
            isSubmitting,
            touched,
            setFieldValue,
            values,
            isValid,
          }): JSX.Element => (
            <>
              {isEnergy && (
                <ModalIconButton
                  onClose={onClose}
                  brandId={brandId}
                  dataDTM={dataDTM}
                />
              )}
            <Box className="modal-container">
              <Element
                name="errorElement"
                className={styles.errorElement}
              >
                <ErrorComponent
                  containerId="errors"
                  condition={!isValid && validateErrors}
                  errors={errors}
                />
              </Element>
              {/* Hide Modal Close Button */}
              {/* <IconButton
                onClick={onClose}
                className="modal-close-button"
                color="primary"
                aria-label="Close modal"
                component="span"
              >
                {(isBuick || isEVLive) && <CloseIcon />}
                {(isChevy || isGMC) && <CancelOutlinedIcon />}
                {(isCadillacUS || isCadillacCanada) && (
                  <Box
                    className="modal-close-icon"
                    sx={{
                      backgroundImage: theme.palette.background.modalCloseWhiteIcon
                    }}
                  />
                )}
              </IconButton> */}
              <Box>
                <Typography variant="modalTitle" component="h2">
                  {t('Which Vehicle did you conduct a tour of?')}
                </Typography>
                {isGMC && <Box className="modal-border" />}
              </Box>

              <form id="nameplates" onSubmit={handleSubmit} noValidate>
                <FormLabel component="legend">
                  <Typography
                    className={styles.allNameplatesText}
                    component="h3"
                    variant="formBody"
                  >
                    {t('Please select all nameplates that were discussed in-session.')}
                  </Typography>
                </FormLabel>
                {!isEVLive && (
                  <FormGroup className={styles.formGroupBrandAwareness}>
                    <Field name="discussedNameplate">
                      {() => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="CA00034032"
                              checked={values.discussedNameplate === 'CA00034032'}
                              checkedIcon={
                                isCadillacUS || isCadillacCanada ? (
                                  <>
                                    <CheckIcon className={styles.checkIcon} />
                                    <CheckBoxOutlineBlankIcon />
                                  </>
                                ) : (
                                  <CheckBoxIcon />
                                )
                              }
                              icon={<CheckBoxOutlineBlankIcon />}
                              onChange={(event, checked) => {
                                if (checked) {
                                  setFieldValue('discussedNameplate', event.target.value);
                                } else {
                                  setFieldValue('discussedNameplate', '');
                                }
                              }}
                            />
                          }
                          label={t('Brand Awareness').toString()}
                        />
                      )}
                    </Field>
                  </FormGroup>
                )}
                {/* {touched.discussedNameplate && errors.discussedNameplate && (
                  <FormHelperText error>{t('Please select discussed nameplates.')}</FormHelperText>
                )} */}
                <Grid container sx={{ marginTop: '32px' }}>
                  <Grid item xs={12}>
                    <FormLabel component="legend">
                      <Typography
                        component="h3"
                        variant="formBody"
                        className={styles.selectAllNameplatesText}
                      >
                        {t('Select all nameplates that apply')}
                      </Typography>
                    </FormLabel>
                  </Grid>

                  <FormGroup className={styles.formGroup}>
                    <FieldArray name="appliedNameplate">
                      {({ remove, push }) => (
                        <>
                          {nameplates.map((nameplate: Vehicle) => (
                            <Grid item id="appliedNameplate" key={nameplate.id} xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={nameplate.name}
                                    // checkedIcon={
                                    //   isCadillacUS || isCadillacCanada ? (
                                    //     <>
                                    //       <CheckIcon className={styles.checkIcon} />
                                    //       <CheckBoxOutlineBlankIcon />
                                    //     </>
                                    //   ) : (
                                    //     <CheckBoxIcon />
                                    //   )
                                    // }
                                    // icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={renderCheckedIcon()}
                                    icon={renderIcon()}
                                    onChange={(event, checked) => {
                                      if (checked) {
                                        push(event.target.value);
                                      } else {
                                        const selectedIndex = values.appliedNameplate.indexOf(
                                          nameplate.name,
                                        );
                                        remove(selectedIndex);
                                      }
                                    }}
                                  />
                                }
                                label={t(nameplate.name).toString()}
                              />
                            </Grid>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </FormGroup>

                  {touched.appliedNameplate && errors.appliedNameplate && (
                    <FormHelperText error>{t('Please select applied nameplates.')}</FormHelperText>
                  )}
                </Grid>

                <Box className={styles.buttonContainer}>
                  <Button
                    onClick={() => { setValidateErrors(true); executeScroll(); }}
                    disabled={isSubmitting}
                    variant="primary"
                    type="submit"
                    aria-label="Submit form"
                  >
                    {t('Submit')}
                  </Button>
                  {/* hid the cancel button on EV */}
                  {/* {isEVLive && (
                    <TextButton
                      className={`${styles.cancelButton} stat-text-link`}
                      onClick={onClose}
                      disableRipple
                      variant="text"
                      type="button"
                      aria-label="Cancel Nameplates"
                    >
                      {t('No Thanks')}
                    </TextButton>
                  )} */}
                </Box>
              </form>
            </Box>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default NameplatePopupModal;
