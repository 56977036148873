import { NativeSelect, Select } from '@mui/material';
import { alpha, styled } from '@material-ui/core/styles';

const SelectField = styled(NativeSelect)(({ theme }) => ({
  '&.MuiInputBase-root': {
    display: 'block',
    width: '100%',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    backgroundColor: `${alpha(theme.palette.background.paper, 1)}`,
    backgroundClip: 'paddingBox',
    border: 'none',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    height: 'calc(1.5em + 0.75rem + 4px)',
    paddingTop: '7px',
    paddingBottom: '7px',
    borderRadius: '0',
    '&.Mui-error': {
      '.MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        borderColor: theme.palette.error.main,
      },
    },
    '&:hover': {
      backgroundColor: `${theme.palette.background.selectBackgroundHover}`,
      '.MuiOutlinedInput-notchedOutline': {
        border: '2px solid #0009FF !important',
      },
    },
    '&.Mui-focused': {
      backgroundColor: `${theme.palette.background.selectBackgroundHover}`,
      '.MuiOutlinedInput-notchedOutline': {
        border: '2px solid #000000 !important',
      },
    },
  },
}));

const SelectBasicField = styled(Select)(({ theme }) => ({
  '&.MuiInputBase-root': {
    display: 'block',
    width: '100%',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    backgroundColor: `${alpha(theme.palette.background.paper, 1)}`,
    backgroundClip: 'paddingBox',
    border: 'none',
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    height: 'calc(1.5em + 0.75rem + 4px)',
    paddingTop: '7px',
    paddingBottom: '7px',
    borderRadius: '0',
    '&.Mui-error': {
      '.MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        borderColor: theme.palette.error.main,
      },
    },
    '&:hover': {
      backgroundColor: `${theme.palette.background.selectBackgroundHover}`,
      '.MuiOutlinedInput-notchedOutline': {
        border: '2px solid #0009FF !important',
      },
    },
    '&.Mui-focused': {
      backgroundColor: `${theme.palette.background.selectBackgroundHover}`,
      '.MuiOutlinedInput-notchedOutline': {
        border: '2px solid #000000 !important',
      },
    },
  },
}));

export default SelectField;
export { SelectBasicField };
