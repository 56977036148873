import {
  Grid,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@material-ui/core/styles';
import { useScrollToAnchor } from '@hooks';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useQueryString } from '@hooks';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface ErrorProps {
  condition: boolean;
  errors: any;
  containerId: string;
  dataDTM?: string;
}

const ErrorComponent: FC<ErrorProps> = ({ condition, errors, containerId, dataDTM }) => {
  const theme = useTheme();
  const { urlSearchParams } = useQueryString();
  const brandId = urlSearchParams.get('brandId');
  const isGMC = brandId === 'gmc';
  const isBuick = brandId === 'buick';
  const isEVLive = brandId === 'ev' || brandId === 'evcan';
  const isEnergy = brandId === 'energy';
  const { t } = useTranslation();

  let errorComponentStyles = {};
  const errorComponentGMCStyles = {
    errorContainer: {
      paddingTop: 24,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
      marginTop: 0,
      marginBottom: 20,
      [theme.breakpoints.up('md')]: {
        paddingTop: 30,
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 30,
        marginBottom: 40,
      },
      '@global': {
        '.MuiGrid-container': {
          width: '100% !important',
          marginLeft: '0px !important',
        },
      },
    },
  };
  const errorComponentBuickStyles = {
    errorContainer: {
      paddingTop: 24,
      paddingLeft: 52,
      paddingRight: 52,
      paddingBottom: 24,
      marginTop: 0,
      marginBottom: 25,
      [theme.breakpoints.up('md')]: {
        paddingTop: 27,
        paddingLeft: 43,
        paddingRight: 43,
        paddingBottom: 27,
        marginBottom: 95,
      },
      '@global': {
        '.MuiGrid-container': {
          width: '100% !important',
          marginLeft: '0px !important',
        },
      },
    },
  };
  const errorComponentEVLiveStyles = {
    errorContainer: {
      paddingTop: 24,
      paddingLeft: 52,
      paddingRight: 52,
      paddingBottom: 24,
      marginTop: 0,
      marginBottom: 20,
      [theme.breakpoints.up('md')]: {
        paddingTop: 30,
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 30,
        marginBottom: 40,
      },
      '@global': {
        '.MuiGrid-container': {
          width: '100% !important',
          marginLeft: '0px !important',
        },
      },
    },
  };
  const errorComponentEnergyStyles = {
    container: {},
    errorContainer: {
      marginTop: 0,
      marginBottom: 16,
      padding: 16,
      [theme.breakpoints.up(768)]: {
        marginBottom: 32,
      },
    },
    itemText: {
      color: `${theme.palette.primary.light} !important`,
      '& .MuiTypography-root': {
        color: `${theme.palette.primary.light} !important`,
      },
    },
    '@global': {
      '.MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  };

  if (isGMC) {
    errorComponentStyles = {
      ...errorComponentGMCStyles,
    };
  }

  if (isBuick) {
    errorComponentStyles = {
      ...errorComponentBuickStyles,
    };
  }

  if (isEVLive) {
    errorComponentStyles = {
      ...errorComponentEVLiveStyles,
    };
  }

  if (isEnergy) {
    errorComponentStyles = {
      ...errorComponentEnergyStyles,
    };
  }

  const useStyles = makeStyles({
    container: {
      padding: '0px !important',
    },
    errorContainer: {
      margin: '0 0 30px 0',
      padding: '27px 32px',
      backgroundColor: theme.palette.background.errorBackground,
    },
    listItem: {
      padding: 0,
    },
    itemText: {
      margin: 0,
      textDecoration: 'underline !important',
    },
    [theme.breakpoints.up('xs')]: {
      ...errorComponentStyles,
    },
  });
  const styles = useStyles();

  return (
    <>
      <Container
        id={containerId}
        className={styles.container}
        sx={{
          display: condition === true ? 'block' : 'none',
        }}
      >
        <Grid container className={clsx(styles.errorContainer, 'error-container')}>
          <Grid container className="error-wrapping-container">
            <Grid container direction="column" className="error-wrapping-container">
              <Grid container direction="row" className="error-wrapping-container">
                {(isBuick || isEVLive) && (
                  <Grid>
                    <WarningAmberIcon
                      sx={{
                        ml: '-29px',
                        fontSize: '1.3rem',
                        color: isBuick ? theme.palette.warning.main : '#fff',
                      }}
                    />
                  </Grid>
                )}
                <Typography component="h2" variant="errorTitle">
                  {t('There are items that require your attention')}
                </Typography>
              </Grid>
              <List>
                {Object.keys(errors).map((key) => (
                  <ListItem
                    key={key}
                    disableGutters
                    className={styles.listItem}
                    onClick={() => {
                      useScrollToAnchor(key);
                    }}
                  >
                    <ListItemButton
                      component="a"
                      className="stat-text-link"
                      data-dtm={dataDTM || ''}
                    >
                      <ListItemText className={styles.itemText}>{t(errors[key])}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ErrorComponent;
