import { makeStyles } from '@mui/styles';
import { Box, Button, Typography, InputLabel, FormLabel } from '@mui/material';
import { Grid } from '@material-ui/core';
import { TextFieldForm, ErrorComponent, TextButton, ModalIconButton, GMToast } from '@components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import { useFirestore, useAuth } from 'reactfire';
import { ReactElement, useState } from 'react';
import { BrandType, Language, Toast } from '@types';
import { useReporting } from '@hooks';
import { useTranslation } from 'react-i18next';
import { doc, setDoc } from 'firebase/firestore';
import { InviteAFriendModalStyles } from './InviteAFriendModal.style';
import clsx from 'clsx';
import * as Scroll from 'react-scroll';
import { regexEmailHelper } from '@constants';
import { trimSpaces } from '@utils';

type InviteAFriendProps = {
  brandId: BrandType;
  languageCode: Language;
  sessionId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isProductSpecialist: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
};

const InviteAFriendModal = (props: InviteAFriendProps): ReactElement => {
  const { trackAdobe } = useReporting();
  const {
    brandId,
    languageCode,
    sessionId,
    firstName,
    lastName,
    email,
    isProductSpecialist,
    onClose,
    onSubmit
   } = props;
  const { t } = useTranslation();
  const [validateErrors, setValidateErrors] = useState<boolean>(false);
  const isCadillacUS = brandId.toLowerCase() === 'cadillac';
  const isCadillacCanada = brandId.toLowerCase() === 'cadillaccan';
  const isChevy = brandId.toLowerCase() === 'chevy';
  const isGMC = brandId.toLowerCase() === 'gmc';
  const isBuick = brandId.toLowerCase() === 'buick';
  const isEVLive = brandId.toLowerCase() === 'ev';
  const isEVLiveCanada = brandId.toLowerCase() === 'evcan';
  const isEnergy = brandId.toLowerCase() === 'energy';
  const gmcGridItemMdSize = isGMC || isBuick || isEVLive || isEVLiveCanada ? 6 : 12;
  const useStyles = makeStyles(InviteAFriendModalStyles(brandId));
  const styles = useStyles();
  const firestore = useFirestore();
  const { currentUser } = useAuth();
  const scroll = Scroll.animateScroll;
  const { Element } = Scroll;

  const executeScroll = () => {
    window.scrollTo(0, 0);
    scroll.scrollToTop('errorElement');
  };

  let dataDTM = 'invite friend';

  if (isEVLive || isEVLiveCanada || isEnergy) dataDTM = 'invite a friend modal';

  const handleToast = (message: string, variant: any, dataDTM?: string) => {
    toast((t: Toast) => (
      <GMToast
        variant={variant}
        message={message}
        dismiss={() => toast.dismiss(t.id)}
        dataDTM={dataDTM}
      />
    ));
  };

  return (
    <Formik
      validateOnChange
      validateOnBlur
      initialValues={{
        firstName: isEnergy && !isProductSpecialist && firstName !== 'Anonymous' ? firstName : '',
        sessionId,
        lastName: isEnergy && !isProductSpecialist ? lastName : '',
        email: isEnergy && !isProductSpecialist ? email : '',
        confirmEmail: '',
        timezoneOffset: new Date().getTimezoneOffset(),
        friendFirstName: '',
        friendEmail: '',
        friendConfirmEmail: '',
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('First Name must be at most 40 characters.'))
          .required(t('Please provide a first name.')),
        lastName: Yup.string()
          .transform((_, transformedValue) => trimSpaces(transformedValue))
          .max(40, t('Last Name must be at most 40 characters.'))
          .required(t('Please provide a last name.')),
        //   Yup.string().max(255).when([], {
        //   is: () => !isEnergy,
        //   then: Yup.string().required(t('Please provide a last name.')),
        //   otherwise: Yup.string(),
        // }),
        email: Yup.string()
          .email(t('Please provide a properly formatted email address.'))
          .max(80, t('Email must be at most 80 characters.'))
          .matches(regexEmailHelper, t('Email is incomplete or characters not permitted.'))
          .required(t('Please provide a properly formatted email address.')),
        confirmEmail: Yup.string()
          .oneOf([Yup.ref('email'), null], t('Email addresses do not match.'))
          .max(80, t('Email must be at most 80 characters.'))
          .matches(regexEmailHelper, t('Email is incomplete or characters not permitted.'))
          .required(t('Email addresses do not match.')),
        friendFirstName: isEnergy
          ? Yup.string()
            .transform((_, transformedValue) => trimSpaces(transformedValue))
            .max(40, t('First Name must be at most 40 characters.'))
            .required(t('Please provide a first name for your friend'))
          : Yup.string(),
        friendEmail: isEnergy
          ? Yup.string()
            .email(t('Please provide a properly formatted email address for your friend'))
            .max(80, t('Email must be at most 80 characters.'))
            .matches(regexEmailHelper, t('Email is incomplete or characters not permitted.'))
            .required(t('Please provide a properly formatted email address for your friend.'))
          : Yup.string(),
        friendConfirmEmail: isEnergy
          ? Yup.string()
            .oneOf([Yup.ref('friendEmail'), null], t('Email addresses do not match for your friend'))
            .max(80, t('Email must be at most 80 characters.'))
            .matches(regexEmailHelper, t('Email is incomplete or characters not permitted.'))
            .required(t('Email addresses do not match for your friend'))
          : Yup.string(),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }): Promise<void> => {
        try {
          let id = currentUser.uid;
          const ref = doc(firestore, 'invites', sessionId);
          await setDoc(ref, {
            ...values,
            brandId,
            languageCode
          });
          setStatus({ success: true });
          trackAdobe('invite-friend');
          setSubmitting(false);
          handleToast('Your form has been submitted.', 'confirmation', 'modal:form submitted');
          onSubmit();
          onClose();
        } catch (err) {
          console.error(err);
          handleToast(`Something went wrong! ${err.message}`, 'warning', 'modal:form submitted');
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
        isValid,
        submitForm,
        validateForm,
      }): JSX.Element => (
        <>
          {isEnergy && (
            <ModalIconButton
              onClose={onClose}
              brandId={brandId}
              dataDTM={dataDTM}
            />
          )}
          <Box
            className={clsx("modal-container", isEnergy ? styles.modalContainer : null)}
          >
            {!isEnergy && (
              <ModalIconButton
                onClose={onClose}
                brandId={brandId}
                dataDTM={dataDTM}
              />
            )}

            <Element
              name="errorElement"
              className={styles.errorElement}
            >
              <ErrorComponent
                containerId="errors"
                condition={!isValid && validateErrors}
                errors={errors}
                dataDTM={dataDTM}
              />
            </Element>
            <Box
              className="form-header"
            >
              <Typography
                variant="modalTitle"
                component="h2"
              >
                {t('Invite a friend')}
              </Typography>

              {isGMC && <Box className="modal-border" />}

              <Typography
                variant="modalSubTitle"
                component="p"
                className={styles.modalSubTitle}
              >
                {t(
                  "Invite a friend or family member to join your Chevy MyWay experience*. Enter your info below and we'll send them a custom invite link.",
                )}
              </Typography>

              <Box className={styles.modalDisclaimer}>
                <Typography
                  variant="modalSubText"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t('By providing Chevrolet with this information, you acknowledge that you have informed your friend that you will be providing Chevrolet with their name and email address. Sending this Session Invite to your friend does not add their email address to the Chevrolet marketing list.')
                  }}
                />
              </Box>

              {(isChevy || isGMC || isEVLive || isEVLiveCanada) && (
                <Typography
                  variant="modalSubText"
                  component="p"
                  className={clsx(styles.modalProvideEmailAddress)}
                  dangerouslySetInnerHTML={{
                    __html: t('*You may only provide the email address of friends or family.')
                  }}
                />
              )}
              {isBuick && (
                <Typography
                  variant="modalSubText"
                  component="p"
                  className={styles.modalProvideEmailAddress}
                  dangerouslySetInnerHTML={{
                    __html: t('* Indicates Required Fields')
                  }}
                />
              )}
            </Box>
            <form
              id="invite-friend"
              onSubmit={handleSubmit}
              noValidate
              className={styles.form}
            >

              {isEVLiveCanada && (
                <FormLabel
                  className={styles.formLegend}
                  component="legend"
                >{t('Information Field:')}</FormLabel>
              )}

              <Box className={styles.formContainer}>

                <Box className={styles.formContainerLeft}>
                  {isEnergy && (
                    <h2 className={clsx(styles.yourInformation)}>{t('Your Information')}</h2>
                  )}

                  <Grid
                    container
                    spacing="30px"
                  >

                    {/* First name */}
                    <Grid
                      item
                      xs={12}
                      md={gmcGridItemMdSize}
                      id="firstName"
                    >
                      {!isEnergy && (
                        <InputLabel
                        >
                          <span
                            dangerouslySetInnerHTML={{ __html: t('First Name (Required):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        name="firstName"
                        onBlur={(e) => {
                          setFieldValue('firstName', trimSpaces(e.target.value));
                          handleBlur(e);
                        }}
                        onChange={handleChange}
                        placeholder={isEnergy ? t('First Name') : ''}
                        required
                        value={values.firstName}
                        className="stat-input-field"
                        data-dtm={dataDTM}
                        variant={isEnergy ? "filled" : "outlined"}
                        label={isEnergy ? t('First Name') : null}
                      />
                    </Grid>

                    {/* Last name */}
                    <Grid
                      item
                      xs={12}
                      md={gmcGridItemMdSize}
                      id="lastName"
                    >
                      {!isEnergy && (
                        <InputLabel
                        >
                          <span
                            dangerouslySetInnerHTML={{ __html: t('Last Name (Required):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        name="lastName"
                        onBlur={(e) => {
                          setFieldValue('lastName', trimSpaces(e.target.value));
                          handleBlur(e);
                        }}
                        onChange={handleChange}
                        placeholder={isEnergy ? t('Last Name') : ''}
                        required
                        value={values.lastName}
                        className="stat-input-field"
                        data-dtm={dataDTM}
                        variant={isEnergy ? "filled" : "outlined"}
                        label={isEnergy ? t('Last Name') : null}
                      />
                    </Grid>

                    {/* Email */}
                    <Grid
                      item
                      xs={12}
                      md={gmcGridItemMdSize}
                      id="email"
                    >
                      {!isEnergy && (
                        <InputLabel>
                          <span dangerouslySetInnerHTML={{ __html: t('Email Address (Required):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        error={Boolean(touched.email && errors.email)}
                        type="email"
                        fullWidth
                        helperText={touched.email && errors.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={isEnergy ? 'Email' : ''}
                        required
                        value={values.email}
                        className="stat-input-field"
                        data-dtm={dataDTM}
                        variant={isEnergy ? "filled" : "outlined"}
                        label={isEnergy ? t('Email') : null}
                      />
                    </Grid>

                    {/* Confirm Email */}
                    <Grid
                      item
                      xs={12}
                      md={gmcGridItemMdSize}
                      id="confirmEmail"
                    >
                      {!isEnergy && (
                        <InputLabel>
                          <span dangerouslySetInnerHTML={{ __html: t('Confirm Email Address (Required):') }} />
                        </InputLabel>
                      )}
                      <TextFieldForm
                        error={Boolean(touched.confirmEmail && errors.confirmEmail)}
                        type="email"
                        fullWidth
                        helperText={touched.confirmEmail && errors.confirmEmail}
                        name="confirmEmail"
                        onBlur={handleBlur}
                        required
                        placeholder={isEnergy ? 'Confirm Email' : ''}
                        value={values.confirmEmail}
                        className="stat-input-field"
                        data-dtm={dataDTM}
                        variant={isEnergy ? "filled" : "outlined"}
                        label={isEnergy ? t('Confirm Email') : null}
                        onChange={(e) => {
                          let email = e.target.value;
                          if (isCadillacUS || isCadillacCanada) { email = email.toLowerCase(); }
                          setFieldValue('confirmEmail', email);
                        }}
                      />
                    </Grid>

                  </Grid>
                </Box>

                {isEnergy && (
                  <Box className={styles.divider} />
                )}

                {/* Your friend's information */}
                {isEnergy && (
                  <Box className={styles.formContainerRight}>
                    <Box className={styles.yourFriendInformationCont}>
                      <h2 className={clsx(styles.yourInformation, styles.yourFriendInformation)}>{t('Your Friend\'s Information')}</h2>

                      <Typography
                        component="p"
                        className={clsx(styles.yourFriendInformationRequire)}
                        dangerouslySetInnerHTML={{
                          __html: t('*You may only provide the email address of friends or family.')
                        }}
                      />
                    </Box>

                    <Grid
                      container
                      spacing="30px"
                    >
                      {/* First name */}
                      <Grid
                        item
                        xs={12}
                        md={gmcGridItemMdSize}
                        id="friendFirstName"
                      >
                        {!isEnergy && (
                          <InputLabel
                          >
                            <span
                              dangerouslySetInnerHTML={{ __html: t('First Name (Required):') }} />
                          </InputLabel>
                        )}
                        <TextFieldForm
                          error={Boolean(touched.friendFirstName && errors.friendFirstName)}
                          fullWidth
                          helperText={touched.friendFirstName && errors.friendFirstName}
                          name="friendFirstName"
                          onBlur={(e) => {
                            setFieldValue('friendFirstName', trimSpaces(e.target.value));
                            handleBlur(e);
                          }}
                          onChange={handleChange}
                          placeholder={isEnergy ? t('First Name') : ''}
                          required
                          value={values.friendFirstName}
                          className="stat-input-field"
                          data-dtm={dataDTM}
                          variant={isEnergy ? "filled" : "outlined"}
                          label={isEnergy ? t('First Name') : null}
                        />
                      </Grid>

                      {/* Email */}
                      <Grid
                        item
                        xs={12}
                        md={gmcGridItemMdSize}
                        id="friendEmail"
                      >
                        {!isEnergy && (
                          <InputLabel>
                            <span dangerouslySetInnerHTML={{ __html: t('Email Address (Required):') }} />
                          </InputLabel>
                        )}
                        <TextFieldForm
                          error={Boolean(touched.friendEmail && errors.friendEmail)}
                          type="email"
                          fullWidth
                          helperText={touched.friendEmail && errors.friendEmail}
                          name="friendEmail"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder={isEnergy ? 'Email' : ''}
                          required
                          value={values.friendEmail}
                          className="stat-input-field"
                          data-dtm={dataDTM}
                          variant={isEnergy ? "filled" : "outlined"}
                          label={isEnergy ? t('Email') : null}
                        />
                      </Grid>

                      {/* Confirm Email */}
                      <Grid
                        item
                        xs={12}
                        md={gmcGridItemMdSize}
                        id="friendConfirmEmail"
                      >
                        {!isEnergy && (
                          <InputLabel>
                            <span dangerouslySetInnerHTML={{ __html: t('Confirm Email Address (Required):') }} />
                          </InputLabel>
                        )}
                        <TextFieldForm
                          error={Boolean(touched.friendConfirmEmail && errors.friendConfirmEmail)}
                          type="email"
                          fullWidth
                          helperText={touched.friendConfirmEmail && errors.friendConfirmEmail}
                          name="friendConfirmEmail"
                          onBlur={handleBlur}
                          required
                          placeholder={isEnergy ? 'Confirm Email' : ''}
                          value={values.friendConfirmEmail}
                          className="stat-input-field"
                          data-dtm={dataDTM}
                          variant={isEnergy ? "filled" : "outlined"}
                          label={isEnergy ? t('Confirm Email') : null}
                          onChange={(e) => {
                            let email = e.target.value;
                            if (isCadillacUS || isCadillacCanada) { email = email.toLowerCase(); }
                            setFieldValue('friendConfirmEmail', email);
                          }}
                        />
                      </Grid>

                    </Grid>
                  </Box>
                )}

              </Box>

              {isEnergy && (
                <Box
                  sx={{
                    marginTop: '41px',
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '20px',
                  }}
                >
                  {t('By providing this information, you acknowledge that you have informed your friend that you will be providing their name and email address. Sending this demo invite to your friend does not add their email address to the Ultium Home Energy marketing list.')}
                </Box>
              )}

              <Box className={styles.buttonContainer}>
                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    validateForm()
                      .then(() => submitForm())
                      .catch((e) => new Error(e));
                    setValidateErrors(true);
                    executeScroll();
                  }}
                  variant="primary"
                  type="submit"
                  className={clsx('stat-button-link', styles.submitButton)}
                  data-dtm={dataDTM}
                  aria-label="Submit form"
                >
                  {t('Send invite')}
                </Button>
                {(isCadillacUS || isCadillacCanada || isEVLive || isEVLiveCanada) && (
                  <TextButton
                    className={clsx('stat-text-link', styles.cancelBtn)}
                    disableRipple
                    variant="text"
                    type="button"
                    onClick={onClose}
                    data-dtm={dataDTM}
                    aria-label="Cancel form"
                  >
                    {t('No thanks')}
                  </TextButton>
                )}
                {(isGMC || isBuick || isEnergy) && (
                  <Button
                    onClick={onClose}
                    variant="tertiary"
                    type="button"
                    className={clsx('stat-button-link', styles.cancelBtn)}
                    data-dtm={dataDTM}
                    aria-label="Cancel form"
                  >
                    {isEnergy ? t('Cancel') : t('No thanks')}
                  </Button>
                )}
              </Box>
            </form>
          </Box>
        </>
      )}
    </Formik>
  );
};

export default InviteAFriendModal;
