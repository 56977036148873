import { FC, useState } from 'react';
// import './i18n';
import { I18nextProvider, initReactI18next, useTranslation } from 'react-i18next';
import { createInstance } from 'i18next';
import { useCurrentLanguage } from '@hooks';

interface LanguageProps {
  brandId?: string;
}

const Language: FC<LanguageProps> = (props) => {
  const { brandId } = props;
  const translation = useCurrentLanguage(brandId);
  //let { i18n } = useTranslation();
  //const [i18nInstance, seti18nInstance] = useState(i18n);
  console.log(translation);
  if (translation) {
    const i18n = createInstance({
      fallbackLng: translation.lang,
      debug: true,
      lng: translation.lang,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      resources: translation.resources,
    });
    i18n.use(initReactI18next).init();
    // if (i18n.resolvedLanguage !== translation.lang) {
    //   i18n = createInstance({
    //     fallbackLng: translation.lang,
    //     debug: true,
    //     lng: translation.lang,
    //     interpolation: {
    //       escapeValue: false, // not needed for react as it escapes by default
    //     },

    //     resources: translation.resources,
    //   });
    //   i18n.use(initReactI18next).init();
    // }


    return (
      <I18nextProvider i18n={i18n}>
        {props.children}
      </I18nextProvider>);
  }
  return null;
};

export default Language;
