import { FC, ReactNode } from 'react';
import { useCurrentBrand, useScripts } from '@hooks';
import { Brand } from '@types';
import firebase from 'firebase/compat/app';

const StackdriverErrorReporter = require('stackdriver-errors-js/dist/stackdriver-errors-concat.min.js');


interface ScriptProps {
  children: ReactNode;
  brand: Brand;
}

const Scripts: FC<ScriptProps> = (props) => {
  console.log('process.env.REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT)
  const { children, brand } = props;
  const scripts = [
    {
      id: 'gbaal',
      src: process.env.REACT_APP_ENVIRONMENT === 'Release' ? 'https://brands.gm-cdn.com/gbaal/code/prod/gbaal.js' : 'https://brands.gm-cdn.com/gbaal/code/dev/gbaal.js',
      async: false
    },
    {
      id: 'jquery',
      //src: 'https://code.jquery.com/jquery-3.6.0.min.js',
      src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js',
      async: false
    }, {
      id: 'opentok',
      src: 'https://static.opentok.com/v2/js/opentok.min.js',
      async: false

    }, {
      id: 'responsiveIframe',
      src: 'https://assets.gm.com/responsive-iframe.min.js',
      async: false
    }];

  if (brand.adobeScriptSource) {
    scripts.push({
      id: 'adobe',
      src: brand.adobeScriptSource,
      async: false
    })
  }

  if (process.env.NODE_ENV !== 'development') {
    scripts.push({

      id: 'stackdriver',
      src: 'https://cdn.jsdelivr.net/npm/stackdriver-errors-js@0.12.0/dist/stackdriver-errors-concat.min.js',
      async: false
    })
  }

  // if(process.env.REACT_APP_ENVIRONMENT === 'Release'){
  //   scripts.push({
  //     id: 'gbaal',
  //     src: 'https://brands.gm-cdn.com/gbaal/code/prod/gbaal.js',
  //     async: false
  //   })

  // }

  const loaded = useScripts(scripts);

  if (loaded) {





    window.addEventListener('DOMContentLoaded', () => {
      if (process.env.NODE_ENV !== 'development') {
        const errorHandler = new StackdriverErrorReporter();
        errorHandler.start({
          key: process.env.REACT_APP_FIREBASE_API_KEY,
          projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
          context: { user: currentUser ? currentUser.uid : 'N/A', brandId: brand ? brand.name : 'N/A' }
          // Other optional arguments can also be supplied, see below.
        });
      }
    });

    const { currentUser } = firebase.auth();


    return <>{children}</>;
  }

  return null;
};

interface PreloadScriptProps {
  children: ReactNode;
}

const PreloadScripts: FC<PreloadScriptProps> = (props) => {
  const { children } = props;
  const brand = useCurrentBrand();

  if (brand) {
    return <>{brand && <Scripts brand={brand}>{children}</Scripts>}</>;
  }

  return null;
};

export default PreloadScripts;
