import { useDeviceData } from 'react-device-detect';

export const getIsIpad = () => {
  const { browser, device, os } = useDeviceData(navigator.userAgent);

  const isIpad = !device.model
  && (/^iP/.test(navigator.platform)
    || (/^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 1));
  if (browser.name === 'InternetExplorer') browser.name = 'IE';
  if (browser.name === 'Mobile Safari') browser.name = 'Safari';
  if (isIpad) os.name = 'iOS';

  return (isIpad);
};
