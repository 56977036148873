import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useQueryString } from '@hooks';
import NotFound from '../pages/NotFound';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { doc } from 'firebase/firestore';

interface BrandGuardProps {
  children: ReactNode;
}

const BrandGuard: FC<BrandGuardProps> = (props) => {
  const { children } = props;
  const { urlSearchParams } = useQueryString();
  const id = urlSearchParams.get('brandId');
  const brandId = id || 'null';

  const firestore = useFirestore();
  const brandRef = doc(firestore, 'brands', brandId);
  const brandData = useFirestoreDocData(brandRef, { idField: 'id' });
  const [isDisabled, setIsDisabled] = useState(false);

  // Check if the brand is able to be accessed.
  // If it is disabled, check isDisabled useState to true to reference and block visibility to the page.
  useEffect(() => {
    if (brandId !== null) {
      const fetchData = async () => {
        const { data } = await brandData;
        if (data && data.isDisabled === true) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      };
      fetchData();
    }
  }, [brandData, brandId]);

  // If check for brand being disabled is true, show them the Not Found page.
  if (id && isDisabled) {
    return <NotFound />;
  }

  return <>{children}</>;
};

export default BrandGuard;
