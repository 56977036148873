class OverviewRepository {
  update = async (): Promise<void> => {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateWidgets`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    });
  };
}

export const overviewRepository = new OverviewRepository();
