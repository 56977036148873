/* eslint-disable require-jsdoc */
import { BrandType, Hours } from '@types';
import { ShowroomHour } from '../types/hours';
import { fetchJson } from './fetchJson';
import { ExceptionDayLocations } from '../types/exceptionDay';

class HoursOfOperationRepository {
  getWeeklyHoursOfOperation = async (date: Date, brandId: BrandType, timezoneOffset): Promise<ShowroomHour[]> => {
    const body = {
      date,
      brandId,
      timezoneOffset,
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/getWeeklyHoursOfOperation`, body);
  };

  getReservationsByDay = async (
    brandId: BrandType,
    dateString: string,
    timezoneOffset: number,
    vehicleId: string
  ): Promise<Date[]> => {
    const body = {
      brandId,
      dateString,
      timezoneOffset,
      vehicleId,
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/getReservationsByDay`, body);
  };

  getHoursOfOperation = async (
    date: Date,
    brandId: BrandType,
  ): Promise<Hours> => {
    const body = {
      date: date.toString(),
      brandId,
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/getHoursOfOperation`, body);
  };

  getReservationBlackoutDates = async (
    date: Date,
    brandId: BrandType,
    timezoneOffset: number,
    vehicleId: string,
    location: ExceptionDayLocations
  ): Promise<{}> => {
    const body = {
      date,
      brandId,
      timezoneOffset,
      vehicleId,
      location
    };
    return fetchJson(`${process.env.REACT_APP_API_BASE_URL}/getReservationBlackoutDates`, body);
  };
}

export const hoursOfOperationRepository = new HoursOfOperationRepository();
