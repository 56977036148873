import { createContext } from 'react';
import type { FC, ReactNode } from 'react';
import { useFirestore } from 'reactfire';
import { collection, addDoc } from 'firebase/firestore';
import { useDeviceData } from 'react-device-detect';
import { Brand, TrackingEventType, PageView, TrackingEvent } from '@types';
import { brands, languages } from '@constants';
import { useQueryString } from '@hooks';
import { Timestamp } from '@firebase/firestore';
import firebase from 'firebase/compat';

declare let window: any;
declare let _satellite: any;

// declare global {
//   interface Window {
//     _satellite: any;
//     digitalData: any;
//   }
// }

interface ReportingContextValue {
  track: (event: TrackingEventType, data?: any, trackAdobe?: boolean) => Promise<void>;
  trackAdobe: (event: TrackingEventType) => Promise<void>;
  page: (pageName: string, uid: string) => Promise<void>;
}

interface ReportingProviderProps {
  children: ReactNode;
  brand: Brand;
}

const ReportingContext = createContext<ReportingContextValue>({
  page: () => Promise.resolve(),
  track: () => Promise.resolve(),
  trackAdobe: () => Promise.resolve(),
});

export const ReportingProvider: FC<ReportingProviderProps> = (props) => {
  const { children, brand } = props;
  const firestore = useFirestore();
  const { browser, device, os } = useDeviceData(navigator.userAgent);
  const { urlSearchParams } = useQueryString();
  // set chevy as a default - may need to change in the future
  const brandId = urlSearchParams.get('brandId') || brands[0];
  const lang = urlSearchParams.get('lang') || languages[0];
  const date = Timestamp.now();

  const trackDigitalData = (currentLanguage: string, deviceType: string, brandId: string) => {
    const isGMC = brandId === 'gmc';
    const isBuick = brandId === 'buick';
    const isEVLive = brandId === 'ev';
    const isEVLiveCanada = brandId === 'evcan';
    const isEnergy = brandId === 'energy';

    window.digitalData = window.digitalData || {};
    window.digitalData.pageInfo = window.digitalData.pageInfo || {};

    // clear the formName
    if (window.digitalData.pageInfo.formName) {
      delete window.digitalData.pageInfo.formName;
    }

    let title = '';
    let siteSectionsLevel1 = brand.adobeBrandKey ? brand.adobeBrandKey.toLocaleLowerCase() : 'undefined';
    let siteSectionsLevel2 = 'undefined';
    let siteSectionsLevel3 = 'undefined';
    let isToastIframe = false;
    let isHoursIframe = false;

    let seoStrategyPageName = '';

    let currentURL = location.pathname;

    const loungeURL = currentURL === '/lounge'
      || currentURL === '/queue'
      || currentURL === '/lounge-V2';
    const tranferLoungeURL = '/lounge' || '/queue' || '/lounge-V2';

    const groupLoungeURL = currentURL === '/group-countdown'
      || currentURL === '/group-lounge';

    const missedTourURL = currentURL === '/missed-group-tour'
      || currentURL.includes('missed-grour-tour')
      || currentURL === '/group-tour-ended'
      || currentURL.includes('group-tour-ended');

    window.digitalData.formInfo = window.digitalData.formInfo || {};
    window.digitalData.formInfo.filterType = 'undefined';
    window.digitalData.formInfo.filterValue = 'undefined';
    window.digitalData.formInfo.filterSelected = 'undefined';

    let pageName = '';

    if (currentURL === '/' || currentURL === '/home' || currentURL === '/live') {
      title = 'homepage';
      if (isGMC) title = 'home';
      if (isEVLive) title = 'homepage';
      if (isEVLiveCanada) {
        title = 'live';
        isToastIframe = true;
      }
      if (isEnergy) isToastIframe = true;

      currentURL = '/';
      // pageType = 'homepage';
      seoStrategyPageName = `Discover ${brand.secondaryName}: Digital Showroom`;
    } else if (currentURL === '/reservation' || currentURL === '/live/reservation') {
      title = 'reservation';
      // pageType = 'forms';
      seoStrategyPageName = `Make a 1:1 Reservation | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Make a 1:1 Reservation`;
      window.digitalData.formInfo.formName = 'book reservation';
      window.digitalData.formInfo.formFieldName = '{vehicle: 0, date: 0, time: 0, firstName: 0, lastName: 0, postalCode: 0, email: 0, confirmEmail: 0}';
    } else if (currentURL === '/modify-reservation' || currentURL === '/live/modify-reservation' || currentURL === '/modify-reservation-v2' || currentURL === '/live/modify-reservation-v2') {
      title = 'modify-reservation';
      // pageType = 'forms';
      seoStrategyPageName = `Modify a 1:1 Reservation | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Modify a 1:1 Reservation`;
      window.digitalData.formInfo.formName = 'modify reservation';
      window.digitalData.formInfo.formFieldName = '{vehicle: 0, date: 0, time: 0, firstName: 0, lastName: 0, postalCode: 0, email: 0, confirmEmail: 0}';
      // window.digitalData.formInfo.errorMessage = 'Please select vehicle, Please select date, Please select time, Please input valid first name, Please input valid last name, Please input valid zip code, Please input valid email address, Please input valid confirm email';
    } else if (currentURL === '/modify-group-reservation' || currentURL === '/live/modify-group-reservation') {
      title = 'modify-group-reservation';
      // pageType = 'forms';
      seoStrategyPageName = `Modify a 1:many Reservation | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Modify a 1:many Reservation`;
      window.digitalData.formInfo.formName = 'modify group reservation';
      window.digitalData.formInfo.formFieldName = '{vehicle: 0, date: 0, time: 0, firstName: 0, lastName: 0, postalCode: 0, email: 0, confirmEmail: 0}';
      // window.digitalData.formInfo.errorMessage = 'Please select vehicle, Please select date, Please select time, Please input valid first name, Please input valid last name, Please input valid zip code, Please input valid email address, Please input valid confirm email';
    } else if (currentURL === '/group-reservation' || currentURL === '/live/group-reservation') {
      title = 'group-reservation';
      // pageType = 'forms';
      seoStrategyPageName = `Schedule a Group Tour | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Schedule a Group Tour`;
      window.digitalData.formInfo.formName = 'group reservation';
      window.digitalData.formInfo.formFieldName = '{vehicle: 0, date: 0, time: 0, firstName: 0, lastName: 0, postalCode: 0, email: 0, confirmEmail: 0}';
      // window.digitalData.formInfo.errorMessage = 'Please select vehicle, Please select date, Please select time, Please input valid first name, Please input valid last name, Please input valid zip code, Please input valid email address, Please input valid confirm email';
    } else if (loungeURL || currentURL.includes(tranferLoungeURL)) {
      title = 'queue';
      if (isGMC || isEVLive || isEVLiveCanada || isEnergy) title = 'lounge';
      if (isBuick) title = 'waiting-lounge';

      // pageType = 'custom';
      seoStrategyPageName = `1:1 Waiting Lounge | ${brand.secondaryName}`;
      if (isEVLive || isEVLiveCanada) seoStrategyPageName = `one-to-one waiting lounge | virtual showroom | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | lounge | digital showroom`;
    } else if (groupLoungeURL) {
      title = 'group-lounge';
      // pageType = 'custom';
      seoStrategyPageName = `Group Tour Waiting Lounge | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Group Tour Waiting Lounge`;
    } else if (currentURL === '/reservation-confirm' || currentURL === '/live/reservation-confirm') {
      title = 'reservation-confirm';
      // pageType = 'custom';
      seoStrategyPageName = `Reservation Confirmed | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Reservation Confirmed`;
    } else if (currentURL === '/showroom' || currentURL === '/live/showroom') {
      title = 'showroom';
      // pageType = 'showroom';
      seoStrategyPageName = `1:1 Showroom | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | 1:1 Showroom`;
    } else if (currentURL === '/tracker' || currentURL === '/live/tracker') {
      title = 'tracker';
      // pageType = 'tracker';
      seoStrategyPageName = `Tracker | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Tracker`;
    } else if (currentURL === '/group-showroom' || currentURL === '/live/group-showroom') {
      title = 'group-showroom';
      // pageType = 'showroom';
      seoStrategyPageName = `Group Tour Showroom | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Group Tour Showroom`;
    } else if (currentURL === '/add-to-calendar' || currentURL === '/live/add-to-calendar') {
      title = 'add-to-calendar';
      // pageType = 'showroom';
      seoStrategyPageName = `Add to calendar | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Add to calendar`;
    } else if (currentURL === '/thank-you' || currentURL === '/live/thank-you') {
      title = 'thank-you';
      // pageType = 'forms';
      seoStrategyPageName = `Thank you | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Thank you`;
      window.digitalData.formInfo.formName = 'thank you';
      window.digitalData.formInfo.formFieldName = '{overallExperience: 0, productSpeciallist: 0, videoQuality: 0, comments: 0}';
      // window.digitalData.formInfo.errorMessage = 'Please input valid overall experience, Please input valid product Sspeciallist, Please input valid video quality';
    } else if (currentURL === '/stay-updated' || currentURL === '/live/stay-updated') {
      title = 'stay-updated';
      // pageType = 'forms';
      seoStrategyPageName = `Stay Updated | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Stay Updated`;
      window.digitalData.formInfo.formName = 'stay updated';
      window.digitalData.formInfo.formFieldName = '{firstName: 0, lastName: 0, email: 0, confirmEmail: 0, city: 0, postalCode: 0, receiveUpdates: 0, vehicle: 0}';
      // window.digitalData.formInfo.errorMessage = 'Please input valid first name, Please input valid last name, Please input valid zip code, Please input valid email address, Please input valid confirm email, Please select vehicle, Please input valid receive updates';
    } else if (currentURL === '/hours-of-operation' || currentURL === '/live/hours-of-operation') {
      title = 'hours-of-operation';
      if (isGMC) title = 'home';
      if (isBuick) title = 'homepage';
      if (isEVLive) title = 'homepage';
      if (isEVLiveCanada) {
        title = 'live';
        isHoursIframe = true;
      }
      if (isEnergy) isHoursIframe = true;

      // pageType = 'custom';
      seoStrategyPageName = `Hours of Operation | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Hours of Operation`;
    } else if (currentURL === '/user-toast' || currentURL === '/live/user-toast') {
      title = 'homepage';
      if (isGMC) title = 'home';
      if (isEVLive) title = 'homepage';
      if (isEVLiveCanada) {
        title = 'live';
        isToastIframe = true;
      }
      if (isEnergy) isToastIframe = true;
      // pageType = 'custom';
      seoStrategyPageName = `User Toast | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | User Toast`;
    } else if (currentURL === '/group-toast' || currentURL === '/live/group-toast') {
      title = 'homepage';
      if (isGMC) title = 'home';
      if (isEVLive) title = 'homepage';
      if (isEVLiveCanada) {
        title = 'live';
        isToastIframe = true;
      }
      if (isEnergy) isToastIframe = true;
      // pageType = 'custom';
      seoStrategyPageName = `Group Toast | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Group Toast`;
    } else if (missedTourURL) {
      title = 'missed-group-tour';
      seoStrategyPageName = `Missed Group Tour | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Missed Group Tour`;
    } else if (currentURL === '/dealer-referral') {
      title = 'dealer referral';
      seoStrategyPageName = `Dealer Referral | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Dealer Referral`;
      window.digitalData.formInfo.formName = 'dealer referral';
    } else if (currentURL === '/powershift-claim') {
      title = 'claim free powershift';
      seoStrategyPageName = `Claim Free PowerShift | ${brand.secondaryName}`;
      if (isBuick) seoStrategyPageName = `${brand.secondaryName} | Claim Free PowerShift`;
      window.digitalData.formInfo.formName = 'claim free powershift';
    }

    let renderedExperience = 'large';
    if (innerWidth < 500) {
      renderedExperience = 'small';
    } else if (innerWidth < 1000) {
      renderedExperience = 'small';
    }

    pageName = `${brand.brandCode}:${brand.continentCode}:${brand.countryCode}:${currentLanguage}:t1:${brand.adobeBrandKey}:${title}`.toLocaleLowerCase();
    siteSectionsLevel2 = title;
    let siteSectionsLevel5 = `${brand.adobeBrandKey && brand.adobeBrandKey.toLocaleLowerCase()}:${title}`;
    // const currentParentHost = getParentHost(brandId as BrandType, currentLanguage as LanguageCode, brand.parentHost);

    if (isEVLive) {
      pageName = `${brand.make}:${brand.continentCode}:${brand.countryCode}:${currentLanguage}:t1:${brand.adobeBrandKey}:${title}`.toLocaleLowerCase();
      siteSectionsLevel1 = brand.adobeBrandKey;
      siteSectionsLevel5 = `${brand.adobeBrandKey}:${title}`;
    }

    if (isEVLiveCanada && (isToastIframe || isHoursIframe)) {
      pageName = `${brand.brandCode}:${brand.continentCode}:${brand.countryCode}:${currentLanguage}:t1:${brand.adobeBrandKey}`.toLocaleLowerCase();
      siteSectionsLevel2 = 'undefined';
      siteSectionsLevel5 = 'live';
    }

    // Brand Code (brand.brandCode) and Make (brand.make) to ultiumhome
    if (isEnergy) {
      // ENERGYL-346 - Brand Code (brand.brandCode) and Make (brand.make) to gmenergy
      siteSectionsLevel1 = 'home-products';
      siteSectionsLevel2 = brand.adobeBrandKey;
      siteSectionsLevel3 = title;
      siteSectionsLevel5 = `${siteSectionsLevel1}:${brand.adobeBrandKey}:${title}`;
      pageName = `${brand.brandCode}:${brand.continentCode}:${brand.countryCode}:${currentLanguage}:t1:${siteSectionsLevel1}:${brand.adobeBrandKey}:${title}`.toLocaleLowerCase();
      if (isToastIframe || isHoursIframe) {
        // pageName = `${brand.brandCode}:${brand.continentCode}:${brand.countryCode}:${currentLanguage}:t1:${brand.adobeBrandKey}`.toLocaleLowerCase();
        // siteSectionsLevel2 = 'undefined';
        // siteSectionsLevel5 = brand.adobeBrandKey;
        pageName = `${brand.brandCode}:${brand.continentCode}:${brand.countryCode}:${currentLanguage}:t1:${siteSectionsLevel1}:${brand.adobeBrandKey}`.toLocaleLowerCase();
        siteSectionsLevel5 = `${siteSectionsLevel1}:${brand.adobeBrandKey}`;
      }
    }

    window.digitalData.pageInfo.siteSectionsLevel1 = siteSectionsLevel1;
    window.digitalData.pageInfo.siteSectionsLevel2 = siteSectionsLevel2;
    window.digitalData.pageInfo.siteSectionsLevel3 = siteSectionsLevel3;
    window.digitalData.pageInfo.siteSectionsLevel4 = 'undefined';
    window.digitalData.pageInfo.siteSectionsLevel5 = siteSectionsLevel5;
    // window.digitalData.pageInfo.siteSectionsLevel5 = `${brand.secondaryName && brand.secondaryName.toLocaleLowerCase()}:${title}`;
    window.digitalData.pageInfo.pageName = pageName;
    // window.digitalData.pageInfo.url = currentParentHost + currentURL + location.search;
    window.digitalData.pageInfo.url = location.href;
    window.digitalData.pageInfo.languageSelected = currentLanguage;
    window.digitalData.pageInfo.siteName = isEVLiveCanada ? 'ev-live_canada' : brand.secondaryName && brand.secondaryName.toLocaleLowerCase();
    // window.digitalData.pageInfo.brand = brand.name && brand.name.toLocaleLowerCase();
    window.digitalData.pageInfo.brand = brand.make && brand.make.toLocaleLowerCase();
    window.digitalData.pageInfo.country = brand.countryCode === 'ca' ? 'canada' : 'united states';
    window.digitalData.pageInfo.region = 'north america';
    // window.digitalData.pageInfo.pageType = pageType;
    window.digitalData.pageInfo.pageType = brand.secondaryName && brand.secondaryName.toLocaleLowerCase();
    window.digitalData.pageInfo.renderedExperience = deviceType;
    window.digitalData.pageInfo.viewport = `${innerWidth}x${innerHeight}`;
    window.digitalData.pageInfo.renderedExperience = renderedExperience;
    window.digitalData.pageInfo.orientation = `${window?.screen?.orientation?.type}` || 'landscape';

    window.digitalData.pageInfo.seoStrategyPageName = seoStrategyPageName;

    console.log('tracking digitalData', window.digitalData);
  };

  const page = async (name: string, uid: string): Promise<any> => {
    // TODO: add adobe analytics

    // TO DO: navigator.platform is deprecated -> alt = userAgentData.platform - but not supported yet
    // isIpad will be working on safari - ipad can be filtered as maxTouchPoints has 5 while macOS has 0
    const isIpad = !device.model
      && (/^iP/.test(navigator.platform)
        || (/^Mac/.test(navigator.platform) && navigator.maxTouchPoints > 1));
    if (browser.name === 'InternetExplorer') browser.name = 'IE';
    if (browser.name === 'Mobile Safari') browser.name = 'Safari';
    if (isIpad) os.name = 'iOS'; // manually set as iOS because it detects as macOS on safari

    // "en-US" | "es-MX" | "en-FR"

    let currentLanguage = 'en';
    switch (lang) {
      case 'en-US':
        currentLanguage = 'en';
        break;
      case 'es-MX':
      case 'es-us':
        currentLanguage = 'es';
        break;
      case 'en-FR':
      case 'fr':
        currentLanguage = 'fr';
        break;
    }

    const ref = collection(firestore, 'pageViews');
    const docData: PageView = {
      brandId,
      date,
      name,
      browser: browser.name || 'unknown',
      device: os.name || 'unknown',
      uid,
    };

    trackDigitalData(currentLanguage, device.type, brandId);
    if (brandId !== 'energy') {
      _satellite?.track('page-view');
    }
    return addDoc(ref, docData);
  };

  const trackAdobe = async (event: TrackingEventType): Promise<any> => {
    _satellite?.track(event);
    console.log('tracking adobe event', event);
  };

  const track = async (event: TrackingEventType, data: any = {}): Promise<any> => {
    const ref = collection(firestore, 'tracking');
    const currentUser: firebase.User = await firebase.auth().currentUser;
    const { uid } = currentUser;
    const date = Timestamp.now();
    const docData: TrackingEvent = { event, date, uid, brandId };
    if (data) docData.data = data;
    return addDoc(ref, docData);
  };

  return (
    <ReportingContext.Provider
      value={{
        page,
        track,
        trackAdobe,
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};

export default ReportingContext;
