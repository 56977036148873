import { Box } from '@mui/material';
import { ReactElement, useMemo, useState } from 'react';
import { BrandType, TransferRequest } from '@types';
import * as firebase from 'firebase/auth';
import { useCurrentLanguageParam, useQueryString } from '@hooks';
import LoadingScreen from './LoadingScreen';
import ReservationView from './ReservationView';
import { userRepository } from '@repositories';
import ModalIconButton from './ModalIconButton';
import { Language } from '@components';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

type Props = {
  transferRequest: TransferRequest;
  sessionId: string;
  onClose?: () => void;
  onTransferReq?: () => void;
};

const ReservationModal = (props: Props): ReactElement => {
  const { onClose, transferRequest, sessionId, onTransferReq } = props;
  const { brand, transferNow } = transferRequest;
  const { lang } = useCurrentLanguageParam();
  const [userId, setUserId] = useState<string>(sessionId);

  const getUserId = async () => {
    userRepository.generateToken(sessionId).then((response: any) => {
      firebase.signInWithCustomToken(firebase.getAuth(), response.token).then((credential: any) => {
        setUserId(credential.user.uid);
      });
    });
  };

  useMemo(() => {
    getUserId();
  }, []);
  // session brand
  const { urlSearchParams } = useQueryString();
  const sessionBrand = urlSearchParams.get('brandId');

  // brand
  const isGMC = brand.toLowerCase() === 'gmc';
  const isEnergy = sessionBrand === 'energy';

  const useStyles = makeStyles({
    paddingMobile: {
      '@media screen and (max-width: 425px)': {
        paddingLeft: '15px !important',
        paddingRight: '15px !important',
      },
      '& .MuiIconButton-edgeStart': {
        marginRight: '15px !important',
      },
    },
  });

  const styles = useStyles();
  let dataDTM = 'reservation modal';
  return (
    <>
      {brand && (
        <>
          {isEnergy && (
            <ModalIconButton onClose={onClose} brandId={sessionBrand}
              dataDTM={dataDTM}
            />
          )}
          <Language brandId={brand}>
            <Box
              className={clsx('modal-container', styles.paddingMobile)}
              style={
                sessionBrand === 'ev' || sessionBrand === 'evcan'
                  ? { height: 'auto', marginLeft: '24px' }
                  : sessionBrand === 'energy' ? { top: '60px' } : {}
              }
            >
              {!isEnergy && <ModalIconButton onClose={onClose} brandId={sessionBrand} />}
              {isGMC && <Box className="modal-border" />}
              <Box>
                {userId ? (
                  <ReservationView
                    onSubmit={() => onClose()}
                    onGoBack={() => onClose()}
                    sessionBrand={sessionBrand}
                    transferRequest={transferRequest}
                    brandId={brand as BrandType}
                    languageCode={lang}
                    reservationId={userId}
                    onTransferReq={() => onTransferReq()}
                  // preSelectedTour={vehicleId}
                  />
                ) : (
                  <LoadingScreen />
                )}
              </Box>
            </Box>
          </Language>
        </>
      )}
    </>
  );
};

export default ReservationModal;
