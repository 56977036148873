import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      'es-MX': {
        translation: {
          Email: 'Email',
          'Thank You': 'Thank You',
          'Rate Your Experience': 'Rate Your Experience',
          'Overall Experience (1/3)': 'Overall Experience (1/3)',
          'Overall Experience rating is required': 'Overall Experience rating is required',
          'MyWay product specialist (2/3)': 'MyWay product specialist (2/3)',
          'Product Specialist rating is required': 'Product Specialist rating is required',
          'Video quality (3/3)': 'Video quality (3/3)',
          'Video Quality rating is required': 'Video Quality rating is required',
          'Thank you': 'Thank you',
          'Is there anything else youâ€™d like to share? (Optional):':
            'Is there anything else youâ€™d like to share? (Optional):',
          Submit: 'Submit',
          'No thanks': 'No thanks',
          'Please provide a first name.': 'Please provide a first name.',
          'Please provide a last name.': 'Please provide a last name.',
          'Please provide a city.': 'Please provide a city.',
          'Zip Code is not valid.': 'Zip Code is not valid.',
          'Zip Code must be at most 5 characters.': 'Zip Code must be at most 5 characters.',
          'Please provide a properly formatted email address.':
            'Please provide a properly formatted email address.',
          'Email addresses do not match.': 'Email addresses do not match.',
          'Please select a date.': 'Please select a date.',
          'Please select a vehicle.': 'Please select a vehicle.',
          'Please select a purchase type.': 'Please select a purchase type.',
          'Please select discussed nameplates.': 'Please select discussed nameplates.',
          'First Name (Required):': 'First Name (Required):',
          'Last Name (Required):': 'Last Name (Required):',
          'Email Address (Required):': 'Email Address (Required):',
          'Confirm Email Address (Required):': 'Confirm Email Address (Required):',
          'City (Required):': 'City (Required):',
          'Zip Code (Required):': 'Zip Code (Required):',
          'SELECT MODEL (REQUIRED):': 'SELECT MODEL (REQUIRED):',
          'Select Your Vehicle (Required):': 'Select Your Vehicle (Required):',
          'All Vehicles': 'All Vehicles',
          Vehicles: 'Vehicles',
          'Available Vehicles': 'Available Vehicles',
          'ESTIMATED PURCHASE DATE (REQUIRED):': 'ESTIMATED PURCHASE DATE (REQUIRED):',
          '--Select--': '--Select--',
          'Please select a purchase date.': 'Please select a purchase date.',
          "I'M PLANNING TO (REQUIRED):": "I'M PLANNING TO (REQUIRED):",
          'Select a date (Required):': 'Select a date (Required):',
          'Please provide a valid phone number.': 'Please provide a valid phone number.',
          'Please select a dealership.': 'Please select a dealership.',
          'Phone number (Required):': 'Phone number (Required):',
          'Select a vehicle (Optional):': 'Select a vehicle (Optional):',
          'Select a Dealership (Required):': 'Select a Dealership (Required):',
          'All Dealerships': 'All Dealerships',
          'Please select a brand.': 'Please select a brand.',
          'Please select a product specialist.': 'Please select a product specialist.',
          'You may be eligible to take advantage of an exclusive offer program from GM. Please select the program below that you would like to learn more about. (Optional) <a style={{ fontWeight: 700, textDecoration: "underline" }}> Explore GM Offers </a>':
            'You may be eligible to take advantage of an exclusive offer program from GM. Please select the program below that you would like to learn more about. (Optional) <a style={{ fontWeight: 700, textDecoration: "underline" }}> Explore GM Offers </a>',
          'Chevy Truck Legends (Optional): A community for legendary truck owners and their Chevy Trucks. Please select yes to receive more information':
            'Chevy Truck Legends (Optional): A community for legendary truck owners and their Chevy Trucks. Please select yes to receive more information',
          'My Chevrolet Rewards (Optional): A new way to earn points and save on GM Product and Services. Please select yes to receive more information.':
            'My Chevrolet Rewards (Optional): A new way to earn points and save on GM Product and Services. Please select yes to receive more information.',
          Purchase: 'Purchase',
          Lease: 'Lease',
          'GM Military Offer': 'GM Military Offer',
          'GM First Responder': 'GM First Responder',
          'GM Supplier Discount': 'GM Supplier Discount',
          'GM Educator Offer': 'GM Educator Offer',
          'GM College Offer': 'GM College Offer',
          Yes: 'Yes',
          'Schedule your group tour': 'Schedule your group tour',
          'Have a question about a vehicle or just want to explore the showroom? Join other people on a live group tour of Chevroletâ€™s latest vehicles, where you can post questions and get answers in real-time. Donâ€™t worry, our Product Specialists or others canâ€™t see or hear you; itâ€™s a one-way camera only. Select a time and tour typeâ€”weâ€™ll take care of the rest. Times are displayed in your current time zone.':
            'Have a question about a vehicle or just want to explore the showroom? Join other people on a live group tour of Chevroletâ€™s latest vehicles, where you can post questions and get answers in real-time. Donâ€™t worry, our Product Specialists or others canâ€™t see or hear you; itâ€™s a one-way camera only. Select a time and tour typeâ€”weâ€™ll take care of the rest. Times are displayed in your current time zone.',
          'By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information.':
            'By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information.',
          'By providing my contact information below, I consent that GM and/or a GM dealer can contact me with offers and product information.':
            'By providing my contact information below, I consent that GM and/or a GM dealer can contact me with offers and product information. <a href="https://www.gm.com/privacy-statement?evar25=ev-hub_ev-live_book-test-drive" class="stat-text-link" data-dtm="test drive" target="_blank">GM Privacy Statement</a>.',
          'GM Privacy Statement': 'GM Privacy Statement',
          'SELECT YOUR TOUR (Required):': 'SELECT YOUR TOUR (Required):',
          'Select from next available dates (Required):':
            'Select from next available dates (Required):',
          'Please select a date and time.': 'Please select a date and time.',
          'Select another tour date:': 'Select another tour date:',
          Legend: 'Legend',
          'Your Information': 'Your Information',
          'Cancel Reservation': 'Cancel Reservation',
          Second: 'Second',
          Minute: 'Minute',
          'Schedule your one-on-one tour': 'Schedule your one-on-one tour',
          "Have a question about a vehicle or just want to explore the showroom? Join a 1:1 live tour of Chevroletâ€™s latest vehicles, where you can speak via chat or audio to a Product Specialist in real-time. Donâ€™t worry, our Product Specialists can't see you; itâ€™s a one-way camera only. Select a date and vehicle of interestâ€”weâ€™ll take care of the rest. Times are displayed in your current time zone.":
            "Have a question about a vehicle or just want to explore the showroom? Join a 1:1 live tour of Chevroletâ€™s latest vehicles, where you can speak via chat or audio to a Product Specialist in real-time. Donâ€™t worry, our Product Specialists can't see you; itâ€™s a one-way camera only. Select a date and vehicle of interestâ€”weâ€™ll take care of the rest. Times are displayed in your current time zone.",
          'Select your preferred tour time': 'Select your preferred tour time',
          'No times avaiable': 'No times avaiable',
          '401: Authorization required': '401: Authorization required',
          'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.':
            'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
          'Back to Home': 'Back to Home',
          'YOUR ESTIMATED WAIT TIME IN QUEUE IS': 'YOUR ESTIMATED WAIT TIME IN QUEUE IS',
          'YOUR 1:GROUP LIVE TOUR WITH A PRODUCT SPECIALIST WILL START MOMENTARILY':
            'YOUR 1:GROUP LIVE TOUR WITH A PRODUCT SPECIALIST WILL START MOMENTARILY',
          'End Session': 'End Session',
          'Need Help?': 'Need Help?',
          'Book Test Drive': 'Book Test Drive',
          Hours: 'Hours',
          Holidays: 'Holidays',
          'Join Queue': 'Join Queue',
          'Make 1 - 1 Reservation': 'Make 1 - 1 Reservation',
          'Make Group Reservation': 'Make Group Reservation',
          'YOUR ONE-ON-ONE LIVE TOUR WITH A PRODUCT SPECIALIST WILL START MOMENTARILY':
            'YOUR ONE-ON-ONE LIVE TOUR WITH A PRODUCT SPECIALIST WILL START MOMENTARILY',
          '404: The page you are looking for isnâ€™t here':
            '404: The page you are looking for isnâ€™t here',
          '500: Internal Server Error': '500: Internal Server Error',
          'STAY CONNECTED': 'STAY CONNECTED',
          'Sign up to get notifications on the latest Chevrolet products, current offers,events, and more.':
            'Sign up to get notifications on the latest Chevrolet products, current offers,events, and more.',
          'Thank you for your interest in Chevrolet and/or other GM vehicles. U.S. residents, please fill out the required fields below. Non-U.S. residents, please visit your country-specific GM website and/or your local dealer for further information.':
            'Thank you for your interest in Chevrolet and/or other GM vehicles. U.S. residents, please fill out the required fields below. Non-U.S. residents, please visit your country-specific GM website and/or your local dealer for further information.',
          'Book a test drive': 'Book a test drive',
          'Comments (Optional):': 'Comments (Optional):',
          Save: 'Save',
          'Enable your audio': 'Enable your audio',
          "If you'd like to speak to the Product Specialist directly, ensure your audio setting is enabled to hear the stream. You may update the audio permissions by clicking the lock icon in the address bar above or refreshing the page.":
            "If you'd like to speak to the Product Specialist directly, ensure your audio setting is enabled to hear the stream. You may update the audio permissions by clicking the lock icon in the address bar above or refreshing the page.",
          Resume: 'Resume',
          'ARE YOU SURE WANT TO LEAVE THIS TOUR?': 'ARE YOU SURE WANT TO LEAVE THIS TOUR?',
          'Once you exit, your session will end. If you change your mind, you will need to join a new tour.':
            'Once you exit, your session will end. If you change your mind, you will need to join a new tour.',
          'Yes, end my experience': 'Yes, end my experience',
          'Nope, not yet': 'Nope, not yet',
          'There are items that require your attention':
            'There are items that require your attention',
          'Invite a friend': 'Invite a friend',
          "Invite a friend or family member to join your Chevy MyWay experience*. Enter your info below and we'll send them a custom invite link.":
            "Invite a friend or family member to join your Chevy MyWay experience*. Enter your info below and we'll send them a custom invite link.",
          'By providing Chevrolet with this information, you acknowledge that you have informed your friend that you will be providing Chevrolet with their name and email address. Sending this Session Invite to your friend does not add their email address to the Chevrolet marketing list.':
            'By providing Chevrolet with this information, you acknowledge that you have informed your friend that you will be providing Chevrolet with their name and email address. Sending this Session Invite to your friend does not add their email address to the Chevrolet marketing list.',
          '*You may only provide the email address of friends or family.':
            '*You may only provide the email address of friends or family.',
          'Which Vehicle did you conduct a tour of?': 'Which Vehicle did you conduct a tour of?',
          'Please select all nameplates that were discussed in-session.':
            'Please select all nameplates that were discussed in-session.',
          'Select all nameplates that apply': 'Select all nameplates that apply',
          'Please select applied nameplates.': 'Please select applied nameplates.',
          'What is Chevy MyWay?': 'What is Chevy MyWay?',
          'Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet Product Specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a dealer all from the comfort of your home or office.':
            'Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet Product Specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a dealer all from the comfort of your home or office.',
          'Is my tour going to be recorded?': 'Is my tour going to be recorded?',
          'No, your tour will not be recorded.': 'No, your tour will not be recorded.',
          'How do I book a tour?': 'How do I book a tour?',
          'Please visit chevrolet.com/myway/reservation and we will make arrangements.':
            'Please visit chevrolet.com/myway/reservation and we will make arrangements.',
          'How do I test drive a vehicle?': 'How do I test drive a vehicle?',
          'Following your tour of the showroom, our Product Specialist will be happy to assist you in setting up at a time for a test drive with your preferred dealer':
            'Following your tour of the showroom, our Product Specialist will be happy to assist you in setting up at a time for a test drive with your preferred dealer',
          'How long are wait times?': 'How long are wait times?',
          'Wait times may vary but we strive to keep them to a minimum.':
            'Wait times may vary but we strive to keep them to a minimum.',
          'I require special assistance.': 'I require special assistance.',
          'Please contact the Chevrolet Customer Care center at 1-800-950-CHEV':
            'Please contact the Chevrolet Customer Care center at 1-800-950-CHEV',
          'Can the agent see me?': 'Can the agent see me?',
          'No, you will only see the agent and the showroom from their perspective.':
            'No, you will only see the agent and the showroom from their perspective.',
          'I have another question or comment.': 'I have another question or comment.',
          'Will my information be shared with a third-party?':
            'Will my information be shared with a third-party?',
          'No.': 'No.',
          'How long is a tour?': 'How long is a tour?',
          'You can end a tour whenever you prefer. You are not committed to a pre-determined time.':
            'You can end a tour whenever you prefer. You are not committed to a pre-determined time.',
          'What are my obligations after a tour?': 'What are my obligations after a tour?',
          'Following your tour there are no obligations. You can choose to arrange to see a dealer for a test drive, but only if you wish.':
            'Following your tour there are no obligations. You can choose to arrange to see a dealer for a test drive, but only if you wish.',
          'By signing up will I end up on a mailing list?':
            'By signing up will I end up on a mailing list?',
          'No, unless you choose otherwise, you will not receive additional communications from Chevrolet.':
            'No, unless you choose otherwise, you will not receive additional communications from Chevrolet.',
          'How much does a tour cost?': 'How much does a tour cost?',
          'Your experience with Chevy MyWay is entirely complimentary and free of obligations.':
            'Your experience with Chevy MyWay is entirely complimentary and free of obligations.',
          'Is Chevy MyWay available to everyone in the United States?':
            'Is Chevy MyWay available to everyone in the United States?',
          'Yes, anyone over 18 in the United States is invited to experience Chevy MyWay.':
            'Yes, anyone over 18 in the United States is invited to experience Chevy MyWay.',
          'CHEVY MY WAY ADVISOR FAQS': 'CHEVY MY WAY ADVISOR FAQS',
          Summary: 'Summary',
          'Question/Answer': 'Question/Answer',
          'Where can I access Chevy MyWay?': 'Where can I access Chevy MyWay?',
          'How does Chevy MyWay work?': 'How does Chevy MyWay work?',
          'Where is the Chevy MyWay showroom located?':
            'Where is the Chevy MyWay showroom located?',
          'At this time, the available vehicles are:': 'At this time, the available vehicles are:',
          'Will a Chevy MyWay Product Specialist be able to answer any questions that I have?':
            'Will a Chevy MyWay Product Specialist be able to answer any questions that I have?',
          'Will my information be shared via a third party?':
            'Will my information be shared via a third party?',
          'What are the hours of operation for Chevy MyWay?':
            'What are the hours of operation for Chevy MyWay?',
          'The Chevy MyWay showroom can be accessed directly via 7 days a week, Monday â€“ Thursday: 9am-12am ET, Friday: 9am-9pm ET, and Saturday â€“ Sunday: 11am-7pm ET.':
            'The Chevy MyWay showroom can be accessed directly via 7 days a week, Monday â€“ Thursday: 9am-12am ET, Friday: 9am-9pm ET, and Saturday â€“ Sunday: 11am-7pm ET.',
          'Will the Chevy MyWay Product Specialist be able to see a customer during the conversation?':
            'Will the Chevy MyWay Product Specialist be able to see a customer during the conversation?',
          'Chevy MyWay is a one-way video chat. A customer will be able to see the product specialist and any vehicles or features of interest, but the product specialist will be unable to see the customer. Customers also have the capability to converse with the product specialist via chat if they would not like to vocally chat with an agent.':
            'Chevy MyWay is a one-way video chat. A customer will be able to see the product specialist and any vehicles or features of interest, but the product specialist will be unable to see the customer. Customers also have the capability to converse with the product specialist via chat if they would not like to vocally chat with an agent.',
          'How far in advance can I book an appointment? Can I book an appointment on the spot?':
            'How far in advance can I book an appointment? Can I book an appointment on the spot?',
          'Appointments can be booked on the spot and up to five weeks in advance.':
            'Appointments can be booked on the spot and up to five weeks in advance.',
          'What if I need to cut my appointment short? Can I be connected to the same product specialist at a different time and date?':
            'What if I need to cut my appointment short? Can I be connected to the same product specialist at a different time and date?',
          'You can end a tour whenever you prefer. You are not committed to a pre-determined time. You can reschedule your Chevy MyWay appointment for another time but cannot book with a specific product specialist.':
            'You can end a tour whenever you prefer. You are not committed to a pre-determined time. You can reschedule your Chevy MyWay appointment for another time but cannot book with a specific product specialist.',
          'Can I buy a car directly from a product specialist when on Chevy MyWay?':
            'Can I buy a car directly from a product specialist when on Chevy MyWay?',
          'Once consumers are interested in learning more about a specific model from their session, where can they go to test drive a car?':
            'Once consumers are interested in learning more about a specific model from their session, where can they go to test drive a car?',
          'During a session, Chevy MyWay offers customers the choice to be connected with a local dealer by preference or proximity.':
            'During a session, Chevy MyWay offers customers the choice to be connected with a local dealer by preference or proximity.',
          'On average, how long does a consumer spend on Chevy MyWay with the product specialist?':
            'On average, how long does a consumer spend on Chevy MyWay with the product specialist?',
          'It is ultimately up to the customer on how long theyâ€™d like their session to last.':
            'It is ultimately up to the customer on how long theyâ€™d like their session to last.',
          'Is this the first time Chevy MyWay is launching?':
            'Is this the first time Chevy MyWay is launching?',
          'GM implemented a Virtual Showroom for Cadillac in the US last year. This is the first experience of this kind for Chevrolet.':
            'GM implemented a Virtual Showroom for Cadillac in the US last year. This is the first experience of this kind for Chevrolet.',
          'What deciding factors led to the US launch?':
            'What deciding factors led to the US launch?',
          'Chevy MyWay offers an innovative experience that addresses changing consumer behaviors. We are excited to see what this does to brand perception, how it drives sales, as well as bring new customers to the brand.':
            'Chevy MyWay offers an innovative experience that addresses changing consumer behaviors. We are excited to see what this does to brand perception, how it drives sales, as well as bring new customers to the brand.',
          'Where did the concept for Chevy MyWay originate?':
            'Where did the concept for Chevy MyWay originate?',
          'Understanding how busy consumers are with limited time for vehicle research, and looking at data and trends about how consumersâ€™ shopping habits have changed, Chevrolet wanted to create a tool that allowed customers to learn (shop) more about the brandâ€™s vehicle line-up from the comfort of their home, office or even at the gym with someone who could walk them through a personalized experience.':
            'Understanding how busy consumers are with limited time for vehicle research, and looking at data and trends about how consumersâ€™ shopping habits have changed, Chevrolet wanted to create a tool that allowed customers to learn (shop) more about the brandâ€™s vehicle line-up from the comfort of their home, office or even at the gym with someone who could walk them through a personalized experience.',
          'Why did Chevrolet see the need to create a virtual dealership?':
            'Why did Chevrolet see the need to create a virtual dealership?',
          'Digital research is standard for most vehicle shoppers today and average visits to dealerships are decreasing. Chevy MyWay brings the research experience to shoppers on their schedule and from the comfort of anywhere that suits them best.':
            'Digital research is standard for most vehicle shoppers today and average visits to dealerships are decreasing. Chevy MyWay brings the research experience to shoppers on their schedule and from the comfort of anywhere that suits them best.',
          'How has/will this affect car sales?': 'How has/will this affect car sales?',
          'Suggested & Required Verbiage (Chat Transfers):':
            'Suggested & Required Verbiage (Chat Transfers):',
          'Initial offer to transfer a customer to Chevy MyWay:':
            'Initial offer to transfer a customer to Chevy MyWay:',
          'If the customer agrees to join Chevy MyWay queue:':
            'If the customer agrees to join Chevy MyWay queue:',
          'Click HERE when you are ready to join the Chevy MyWay queue. After you click the link, you will be able to view your place in line. Please note that upon clicking the link this chat will be closed. (Link: TBD)':
            'Click HERE when you are ready to join the Chevy MyWay queue. After you click the link, you will be able to view your place in line. Please note that upon clicking the link this chat will be closed. (Link: TBD)',
          'Wait for customer response for up to 3 minutes: Enter closing statement (Always allow a customer 3 minutes to click the link before closing the chat)':
            'Wait for customer response for up to 3 minutes: Enter closing statement (Always allow a customer 3 minutes to click the link before closing the chat)',
          'If customer confirms/signals they will click the link before the 3 minutes: Enter closing statement (Always allow a customer 3 minutes to click the link before closing the chat)':
            'If customer confirms/signals they will click the link before the 3 minutes: Enter closing statement (Always allow a customer 3 minutes to click the link before closing the chat)',
          'If a customer is not available to video chat with a product specialist at this time:':
            'If a customer is not available to video chat with a product specialist at this time:',
          'I understand right now may not be a good time for you. If you would like, I can provide a link to Chevy MyWay where you can book a session to one-way video chat with a product specialist at a time that is more convenient for you.':
            'I understand right now may not be a good time for you. If you would like, I can provide a link to Chevy MyWay where you can book a session to one-way video chat with a product specialist at a time that is more convenient for you.',
          'Wait for POSITIVE customer response (agrees/confirms):':
            'Wait for POSITIVE customer response (agrees/confirms):',
          'Click HERE to book a Chevy MyWay one-on-one session. Is there anything else I can assist you with today? (Always allow a customer 3 minutes to click the link before closing the chat) (Link: TBD)':
            'Click HERE to book a Chevy MyWay one-on-one session. Is there anything else I can assist you with today? (Always allow a customer 3 minutes to click the link before closing the chat) (Link: TBD)',
          'If a customer declines offer to join Chevy MyWay queue or Book a Session page:':
            'If a customer declines offer to join Chevy MyWay queue or Book a Session page:',
          'I understand that video chatting with a product specialist on Chevy MyWay is not of interest to you at this time. I am still here to answer any questions that you may have. Is there anything else I can assist you with?':
            'I understand that video chatting with a product specialist on Chevy MyWay is not of interest to you at this time. I am still here to answer any questions that you may have. Is there anything else I can assist you with?',
          "Chevrolet is expanding their digital presence and launching a unique Virtual Showroom experience in the U.S called Chevy MyWay. This personalized, immersive experience takes the consumer through the Chevrolet lineup, allowing them to learn more about the vehicles via a one-on-one video conversation with trained product specialists from the comfort of their home. Using any mobile or desktop device, users can ask questions, invite friends to join, book a test drive, inquire about quotes, and more, offering a&nbsp;<a style='text-decoration: underline; cursor: pointer'>shopping</a> experience&nbsp;<a style='text-decoration: underline; cursor: pointer'>that</a> is self-led and on their own terms. Chevrolet understands US consumersâ€™ shopping habits and the increasing need to innovate during these unprecedented times, delivering the most dynamic car-shopping experience.":
            "Chevrolet is expanding their digital presence and launching a unique Virtual Showroom experience in the U.S called Chevy MyWay. This personalized, immersive experience takes the consumer through the Chevrolet lineup, allowing them to learn more about the vehicles via a one-on-one video conversation with trained product specialists from the comfort of their home. Using any mobile or desktop device, users can ask questions, invite friends to join, book a test drive, inquire about quotes, and more, offering a&nbsp;<a style='text-decoration: underline; cursor: pointer'>shopping</a> experience&nbsp;<a style='text-decoration: underline; cursor: pointer'>that</a> is self-led and on their own terms. Chevrolet understands US consumersâ€™ shopping habits and the increasing need to innovate during these unprecedented times, delivering the most dynamic car-shopping experience.",
          "Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet product specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a&nbsp; <a style='text-decoration: underline; cursor: pointer'>participating</a> dealer all from the comfort of your home or office.":
            "Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet product specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a&nbsp; <a style='text-decoration: underline; cursor: pointer'>participating</a> dealer all from the comfort of your home or office.",
          "Chevy MyWay is available to anyone <a style='text-decoration: underline; cursor: pointer'>over 18</a>&nbsp; in the United States to access and experience via chevrolet.com/myway.":
            "Chevy MyWay is available to anyone <a style='text-decoration: underline; cursor: pointer'>over 18</a>&nbsp; in the United States to access and experience via chevrolet.com/myway.",
          "Once on the site, shoppers can choose to begin a new session with an agent or schedule a session for a future date. Shoppers can also invite&nbsp; <a style='text-decoration: underline; cursor: pointer'>other individuals</a> to join the live session with them. When the customerâ€™s turn arrives, a one-way video chat appears on the customers phone, tablet, or computer and the customer is now able to view the vehicle live, talk or chat with the agent, and ask questions. The service gives shoppers virtual tours of the showroom, which features 15 vehicles from Chevroletâ€™s lineup on display.":
            "Once on the site, shoppers can choose to begin a new session with an agent or schedule a session for a future date. Shoppers can also invite&nbsp; <a style='text-decoration: underline; cursor: pointer'>other individuals</a> to join the live session with them. When the customerâ€™s turn arrives, a one-way video chat appears on the customers phone, tablet, or computer and the customer is now able to view the vehicle live, talk or chat with the agent, and ask questions. The service gives shoppers virtual tours of the showroom, which features 15 vehicles from Chevroletâ€™s lineup on display.",
          "The Virtual Showroom is a 10,000 plus sq. foot studio located in <a style='text-decoration: underline; cursor: pointer'>metro</a> Detroit, Michigan, USA.":
            "The Virtual Showroom is a 10,000 plus sq. foot studio located in <a style='text-decoration: underline; cursor: pointer'>metro</a> Detroit, Michigan, USA.",
          "Chevy MyWay product specialists are product experts and should be able to answer the questions that you have, but in the event that they are unable to answer a question that you <a style='text-decoration: underline; cursor: pointer'>may have, you</a> can always chat back in on chevrolet.com and speak with an advisor, or contact the Chevrolet Customer Care center at 1-800-950-CHEV.":
            "Chevy MyWay product specialists are product experts and should be able to answer the questions that you have, but in the event that they are unable to answer a question that you <a style='text-decoration: underline; cursor: pointer'>may have, you</a> can always chat back in on chevrolet.com and speak with an advisor, or contact the Chevrolet Customer Care center at 1-800-950-CHEV.",
          'No. Chevy MyWay is a tool designed to collaborate with and support Chevrolet dealers. Chevy MyWay enhances the customer journey to help find which vehicle is right for them. Customers can be connected to a local Chevy MyWay dealer to take next steps on their path to purchasing a Chevrolet or begin the vehicle shopping process online via Shop.Click.Drive.':
            'No. Chevy MyWay is a tool designed to collaborate with and support Chevrolet dealers. Chevy MyWay enhances the customer journey to help find which vehicle is right for them. Customers can be connected to a local Chevy MyWay dealer to take next steps on their path to purchasing a Chevrolet or begin the vehicle shopping process online via Shop.Click.Drive.',
          "The goal is to drive more sales <a style='text-decoration: underline; cursor: pointer'>at Chevrolet dealers</a>.":
            "The goal is to drive more sales <a style='text-decoration: underline; cursor: pointer'>at Chevrolet dealers</a>.",
          "Chevroletâ€™s (nameplate) has so many (wonderful/impressive/beautiful/ <a style='text-decoration: underline;cursor: pointer'>advanced</a> etc.) features! If you would like, I can provide a link to Chevy MyWay where you would be able to one-way video chat with one of our product specialists and he or she would be able to show you features you are interested in.":
            "Chevroletâ€™s (nameplate) has so many (wonderful/impressive/beautiful/ <a style='text-decoration: underline;cursor: pointer'>advanced</a> etc.) features! If you would like, I can provide a link to Chevy MyWay where you would be able to one-way video chat with one of our product specialists and he or she would be able to show you features you are interested in.",
          'Expire Session': 'Expire Session',
          'Mute Video': 'Mute Video',
          Close: 'Close',
          Post: 'Post',
          'Chat Now': 'Chat Now',
          Color: 'Color',
          Model: 'Model',
          Transfer: 'Transfer',
          More: 'More',
          Troubleshoot: 'Troubleshoot',
          Questions: 'Questions',
          COUNTDOWN: 'COUNTDOWN',
          ONLINE: 'ONLINE',
          OFFLINE: 'OFFLINE',
          'Shareable Hyperlinks': 'Shareable Hyperlinks',
          "HANG TIGHT, WE'LL BE WITH YOU SHORTLY.": "HANG TIGHT, WE'LL BE WITH YOU SHORTLY.",
          'Safety Systems': 'Safety Systems',
          Copy: 'Copy',
          "By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information. <a style=' font-weight: 700, text-ecoration: underline'>GM Privacy Statement.</a>":
            "By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information. <a style=' font-weight: 700, text-ecoration: underline'>GM Privacy Statement.</a>",
          'Select a brand:': 'Select a brand:',
          'All Brands': 'All Brands',
          'Select a Product Specialists:': 'Select a Product Specialists:',
          'All Product Specialists': 'All Product Specialists',
          Troubleshooting: 'Troubleshooting',
          'What are the minimum system requirements and supported browsers?':
            'What are the minimum system requirements and supported browsers?',
          'SUPPORTED BROWSERS': 'SUPPORTED BROWSERS',
          'Google Chrome (latest release version)': 'Google Chrome (latest release version)',
          'Google Chrome for Android (latest release version)':
            'Google Chrome for Android (latest release version)',
          'Firefox (latest release version)': 'Firefox (latest release version)',
          'Firefox for Android (latest release version)':
            'Firefox for Android (latest release version)',
          'Safari 11': 'Safari 11',
          'iOS 11': 'iOS 11',
          'Opera (latest release version)': 'Opera (latest release version)',
          'BANDWIDTH REQUIREMENTS': 'BANDWIDTH REQUIREMENTS',
          'Video: 300 kbps per stream (recommended lowest level)':
            'Video: 300 kbps per stream (recommended lowest level)',
          'Audio: 50 kbps per stream (recommended lowest level)':
            'Audio: 50 kbps per stream (recommended lowest level)',
          iOS: 'iOS',
          'Operating system': 'Operating system',
          'iOS 9 or higher': 'iOS 9 or higher',
          Hardware: 'Hardware',
          'iPhone X/XR/XS': 'iPhone X/XR/XS',
          'iPhone 8/8+': 'iPhone 8/8+',
          'iPhone 6/6+/6S/6S+': 'iPhone 6/6+/6S/6S+',
          'iPhone SE': 'iPhone SE',
          'iPhone 5S': 'iPhone 5S',
          'Pad Pro 1st/2nd generation': 'Pad Pro 1st/2nd generation',
          'iPad (2017)': 'iPad (2017)',
          'iPad Air/Air 2': 'iPad Air/Air 2',
          'iPad 4th generation': 'iPad 4th generation',
          'iPad mini 2/3/4': 'iPad mini 2/3/4',
          iPod: 'iPod',
          'iPod touch 6th generation': 'iPod touch 6th generation',
          ANDROID: 'ANDROID',
          'Any Android 4.1+ device (Jelly Bean, API Level 16)':
            'Any Android 4.1+ device (Jelly Bean, API Level 16)',
          '.2 GHz quad-core ARM Cortex-A7 or better': '.2 GHz quad-core ARM Cortex-A7 or better',
          '1 GB RAM (ex. Samsung Galaxy S3 - 2012)': '1 GB RAM (ex. Samsung Galaxy S3 - 2012)',
          'As long as neither the user or Product Specialist end the session, refreshing the page will not end the session.':
            'As long as neither the user or Product Specialist end the session, refreshing the page will not end the session.',
          'What doesnâ€™t work?': 'What doesnâ€™t work?',
          'PS interface': 'PS interface',
          iPhone: 'iPhone',
          'Sound not coming through to PS': 'Sound not coming through to PS',
          'Did the user allow microphone access': 'Did the user allow microphone access',
          'Edge - try refreshing the page, edge will prompt the user to allow permissions again':
            'Edge - try refreshing the page, edge will prompt the user to allow permissions again',
          'Chrome - in the address bar on the right side there is a little icon of a camera with a red circle (indicating denied access) click on this and allow access to the microphone and refresh the page':
            'Chrome - in the address bar on the right side there is a little icon of a camera with a red circle (indicating denied access) click on this and allow access to the microphone and refresh the page',
          'Safari - Need access to a mac to check on this one':
            'Safari - Need access to a mac to check on this one',
          'Is the user using the correct microphone': 'Is the user using the correct microphone',
          'Some users have alternate microphones that may be activated, this should be indicated by some type of background noise':
            'Some users have alternate microphones that may be activated, this should be indicated by some type of background noise',
          'Is the bluetooth on/paired': 'Is the bluetooth on/paired',
          'Bluetooth could be on a paired to a device the user is not using at the time':
            'Bluetooth could be on a paired to a device the user is not using at the time',
          'Sound not transmitting to user': 'Sound not transmitting to user',
          'Is the mic on': 'Is the mic on',
          'Check the battery level on the PS headset if the power is on':
            'Check the battery level on the PS headset if the power is on',
          'Is the microphone muted': 'Is the microphone muted',
          'Check to see if the PS headset is on mute': 'Check to see if the PS headset is on mute',
          "Are the user's speakers on": "Are the user's speakers on",
          'Some users have bluetooth headsets that may be activated':
            'Some users have bluetooth headsets that may be activated',
          'Check that the user has the volume up on there speakers':
            'Check that the user has the volume up on there speakers',
          'Sound is coming from the phone': 'Sound is coming from the phone',
          'Is the bluetooth connected': 'Is the bluetooth connected',
          'Check that the phoneâ€™s bluetooth is on': 'Check that the phoneâ€™s bluetooth is on',
          'Check the the correct phone is connected to the bluetooth':
            'Check the the correct phone is connected to the bluetooth',
          'Is the headset on': 'Is the headset on',
          'Check that the headset has been turned on': 'Check that the headset has been turned on',
          'Check that the headset is charged': 'Check that the headset is charged',
          'Are the userâ€™s speakers on': 'Are the userâ€™s speakers on',
          'Video is not transmitting': 'Video is not transmitting',
          'Is something in front of the camera': 'Is something in front of the camera',
          'Are the phone and the ipad signed in to the same PS account':
            'Are the phone and the ipad signed in to the same PS account',
          iPad: 'iPad',
          'The video isnâ€™t visible': 'The video isnâ€™t visible',
          'Are the phone and ipad signed into the same PS account':
            'Are the phone and ipad signed into the same PS account',
          'Refresh the ipad': 'Refresh the ipad',
          'The chat doesnâ€™t work': 'The chat doesnâ€™t work',
          'Try refreshing the ipad screen': 'Try refreshing the ipad screen',
          'The interface isnâ€™t visible': 'The interface isnâ€™t visible',
          'User Interface': 'User Interface',
          'Features donâ€™t appear': 'Features donâ€™t appear',
          "Try a different model, limited edition models (like the 2021 escalade) sometimes don't have feature sections included.":
            "Try a different model, limited edition models (like the 2021 escalade) sometimes don't have feature sections included.",
          'Try refreshing the page': 'Try refreshing the page',
          'Is the options menu open? Features are hidden to save space while the menus are open':
            'Is the options menu open? Features are hidden to save space while the menus are open',
          'Menu isnâ€™t accessible': 'Menu isnâ€™t accessible',
          'Is the features menu open? The side menu is visible but not accessible while the features menu is open':
            'Is the features menu open? The side menu is visible but not accessible while the features menu is open',
          'Sound isnâ€™t coming through': 'Sound isnâ€™t coming through',
          'Is the PS on mute': 'Is the PS on mute',
          'Is the userâ€™s volume up and active on the correct speakers':
            'Is the userâ€™s volume up and active on the correct speakers',
          'Video isnâ€™t coming through': 'Video isnâ€™t coming through',
          'Try refreshing the browser': 'Try refreshing the browser',
          'Chat isnâ€™t functioning': 'Chat isnâ€™t functioning',
          'Menu doesnâ€™t sync': 'Menu doesnâ€™t sync',
          'Sometimes the PS menu can lag, touching the ipad screen can fix this':
            'Sometimes the PS menu can lag, touching the ipad screen can fix this',
          'Interface isnâ€™t visible': 'Interface isnâ€™t visible',
          'If all of the above options fail, try having the user come back in a new session using a different or incognito browser.':
            'If all of the above options fail, try having the user come back in a new session using a different or incognito browser.',
          'Vehicle Features': 'Vehicle Features',
          View: 'View',
          'Email is incomplete or characters not permitted.':
            'Email is incomplete or characters not permitted.',
          'This field is required in the standard format.':
            'This field is required in the standard format.',
          'SELECT VEHICLE TYPE(S) (REQUIRED):': 'SELECT VEHICLE TYPE(S) (REQUIRED):',
          'SELECT VEHICLE:': 'SELECT VEHICLE:',
          'You may be eligible to take advantage of an exclusive discount program from GM. Please select the program below that you would like to learn more about. (Optional)':
            'You may be eligible to take advantage of an exclusive discount program from GM. Please select the program below that you would like to learn more about. (Optional)',
          'Explore GM Discounts': 'Explore GM Discounts',
          'GM Military Discount': 'GM Military Discount',
          'GM Educator Discount': 'GM Educator Discount',
          'GM College Discount': 'GM College Discount',
          'Join Group Queue': 'Join Group Queue',
          'Please select an ownership type.': 'Please select an ownership type.',
          'Sign up to get notifications on the latest Chevrolet products, current offers, events, and more.':
            'Sign up to get notifications on the latest Chevrolet products, current offers, events, and more.',
        },
      },
      'en-US': {
        translation: {
          Email: 'Email',
          'Thank You': 'Thank You',
          'Rate Your Experience': 'Rate Your Experience',
          'Overall Experience (1/3)': 'Overall Experience (1/3)',
          'Overall Experience rating is required': 'Overall Experience rating is required',
          'MyWay product specialist (2/3)': 'MyWay product specialist (2/3)',
          'Product Specialist rating is required': 'Product Specialist rating is required',
          'Video quality (3/3)': 'Video quality (3/3)',
          'Video Quality rating is required': 'Video Quality rating is required',
          'Thank you': 'Thank you',
          'Is there anything else youâ€™d like to share? (Optional):':
            'Is there anything else youâ€™d like to share? (Optional):',
          Submit: 'Submit',
          'No thanks': 'No thanks',
          'Please provide a first name.': 'Please provide a first name.',
          'Please provide a last name.': 'Please provide a last name.',
          'Please provide a city.': 'Please provide a city.',
          'Zip Code is not valid.': 'Zip Code is not valid.',
          'Zip Code must be at most 5 characters.': 'Zip Code must be at most 5 characters.',
          'Please provide a properly formatted email address.':
            'Please provide a properly formatted email address.',
          'Email addresses do not match.': 'Email addresses do not match.',
          'Please select a date.': 'Please select a date.',
          'Please select a vehicle.': 'Please select a vehicle.',
          'Please select a purchase type.': 'Please select a purchase type.',
          'Please select discussed nameplates.': 'Please select discussed nameplates.',
          'First Name (Required):': 'First Name (Required):',
          'Last Name (Required):': 'Last Name (Required):',
          'Email Address (Required):': 'Email Address (Required):',
          'Confirm Email Address (Required):': 'Confirm Email Address (Required):',
          'City (Required):': 'City (Required):',
          'Zip Code (Required):': 'Zip Code (Required):',
          'SELECT MODEL (REQUIRED):': 'SELECT MODEL (REQUIRED):',
          'Select Your Vehicle (Required):': 'Select Your Vehicle (Required):',
          'All Vehicles': 'All Vehicles',
          Vehicles: 'Vehicles',
          'ESTIMATED PURCHASE DATE (REQUIRED):': 'ESTIMATED PURCHASE DATE (REQUIRED):',
          '--Select--': '--Select--',
          'Please select a purchase date.': 'Please select a purchase date.',
          "I'M PLANNING TO (REQUIRED):": "I'M PLANNING TO (REQUIRED):",
          'Select a date (Required):': 'Select a date (Required):',
          'Please provide a valid phone number.': 'Please provide a valid phone number.',
          'Please select a dealership.': 'Please select a dealership.',
          'Phone number (Required):': 'Phone number (Required):',
          'Select a vehicle (Optional):': 'Select a vehicle (Optional):',
          'Select a Dealership (Required):': 'Select a Dealership (Required):',
          'All Dealerships': 'All Dealerships',
          'Please select a brand.': 'Please select a brand.',
          'Please select a product specialist.': 'Please select a product specialist.',
          'You may be eligible to take advantage of an exclusive offer program from GM. Please select the program below that you would like to learn more about. (Optional) <a style={{ fontWeight: 700, textDecoration: "underline" }}> Explore GM Offers </a>':
            'You may be eligible to take advantage of an exclusive offer program from GM. Please select the program below that you would like to learn more about. (Optional) <a style={{ fontWeight: 700, textDecoration: "underline" }}> Explore GM Offers </a>',
          'Chevy Truck Legends (Optional): A community for legendary truck owners and their Chevy Trucks. Please select yes to receive more information':
            'Chevy Truck Legends (Optional): A community for legendary truck owners and their Chevy Trucks. Please select yes to receive more information',
          'My Chevrolet Rewards (Optional): A new way to earn points and save on GM Product and Services. Please select yes to receive more information.':
            'My Chevrolet Rewards (Optional): A new way to earn points and save on GM Product and Services. Please select yes to receive more information.',
          Purchase: 'Purchase',
          Lease: 'Lease',
          'GM Military Offer': 'GM Military Offer',
          'GM First Responder': 'GM First Responder',
          'GM Supplier Discount': 'GM Supplier Discount',
          'GM Educator Offer': 'GM Educator Offer',
          'GM College Offer': 'GM College Offer',
          Yes: 'Yes',
          'Schedule your group tour': 'Schedule your group tour',
          'Have a question about a vehicle or just want to explore the showroom? Join other people on a live group tour of Chevroletâ€™s latest vehicles, where you can post questions and get answers in real-time. Donâ€™t worry, our Product Specialists or others canâ€™t see or hear you; itâ€™s a one-way camera only. Select a time and tour typeâ€”weâ€™ll take care of the rest. Times are displayed in your current time zone.':
            'Have a question about a vehicle or just want to explore the showroom? Join other people on a live group tour of Chevroletâ€™s latest vehicles, where you can post questions and get answers in real-time. Donâ€™t worry, our Product Specialists or others canâ€™t see or hear you; itâ€™s a one-way camera only. Select a time and tour typeâ€”weâ€™ll take care of the rest. Times are displayed in your current time zone.',
          'By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information.':
            'By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information.',
          'GM Privacy Statement': 'GM Privacy Statement',
          'SELECT YOUR TOUR (Required):': 'SELECT YOUR TOUR (Required):',
          'Select from next available dates (Required):':
            'Select from next available dates (Required):',
          'Please select a date and time.': 'Please select a date and time.',
          'Select another tour date:': 'Select another tour date:',
          Legend: 'Legend',
          'Your Information': 'Your Information',
          'Cancel Reservation': 'Cancel Reservation',
          Second: 'Second',
          Minute: 'Minute',
          'Schedule your one-on-one tour': 'Schedule your one-on-one tour',
          "Have a question about a vehicle or just want to explore the showroom? Join a 1:1 live tour of Chevroletâ€™s latest vehicles, where you can speak via chat or audio to a Product Specialist in real-time. Donâ€™t worry, our Product Specialists can't see you; itâ€™s a one-way camera only. Select a date and vehicle of interestâ€”weâ€™ll take care of the rest. Times are displayed in your current time zone.":
            "Have a question about a vehicle or just want to explore the showroom? Join a 1:1 live tour of Chevroletâ€™s latest vehicles, where you can speak via chat or audio to a Product Specialist in real-time. Donâ€™t worry, our Product Specialists can't see you; itâ€™s a one-way camera only. Select a date and vehicle of interestâ€”weâ€™ll take care of the rest. Times are displayed in your current time zone.",
          'Select your preferred tour time': 'Select your preferred tour time',
          'No times avaiable': 'No times avaiable',
          '401: Authorization required': '401: Authorization required',
          'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.':
            'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
          'Back to Home': 'Back to Home',
          'YOUR ESTIMATED WAIT TIME IN QUEUE IS': 'YOUR ESTIMATED WAIT TIME IN QUEUE IS',
          'YOUR 1:GROUP LIVE TOUR WITH A PRODUCT SPECIALIST WILL START MOMENTARILY':
            'YOUR 1:GROUP LIVE TOUR WITH A PRODUCT SPECIALIST WILL START MOMENTARILY',
          'End Session': 'End Session',
          'Need Help?': 'Need Help?',
          'Book Test Drive': 'Book Test Drive',
          Hours: 'Hours',
          Holidays: 'Holidays',
          'Join Queue': 'Join Queue',
          'Make 1 - 1 Reservation': 'Make 1 - 1 Reservation',
          'Make Group Reservation': 'Make Group Reservation',
          'YOUR ONE-ON-ONE LIVE TOUR WITH A PRODUCT SPECIALIST WILL START MOMENTARILY':
            'YOUR ONE-ON-ONE LIVE TOUR WITH A PRODUCT SPECIALIST WILL START MOMENTARILY',
          '404: The page you are looking for isnâ€™t here':
            '404: The page you are looking for isnâ€™t here',
          '500: Internal Server Error': '500: Internal Server Error',
          'STAY CONNECTED': 'STAY CONNECTED',
          'Sign up to get notifications on the latest Chevrolet products, current offers,events, and more.':
            'Sign up to get notifications on the latest Chevrolet products, current offers,events, and more.',
          'Thank you for your interest in Chevrolet and/or other GM vehicles. U.S. residents, please fill out the required fields below. Non-U.S. residents, please visit your country-specific GM website and/or your local dealer for further information.':
            'Thank you for your interest in Chevrolet and/or other GM vehicles. U.S. residents, please fill out the required fields below. Non-U.S. residents, please visit your country-specific GM website and/or your local dealer for further information.',
          'Book a test drive': 'Book a test drive',
          'Comments (Optional):': 'Comments (Optional):',
          Save: 'Save',
          'Enable your audio': 'Enable your audio',
          "If you'd like to speak to the Product Specialist directly, ensure your audio setting is enabled to hear the stream. You may update the audio permissions by clicking the lock icon in the address bar above or refreshing the page.":
            "If you'd like to speak to the Product Specialist directly, ensure your audio setting is enabled to hear the stream. You may update the audio permissions by clicking the lock icon in the address bar above or refreshing the page.",
          Resume: 'Resume',
          'ARE YOU SURE WANT TO LEAVE THIS TOUR?': 'ARE YOU SURE WANT TO LEAVE THIS TOUR?',
          'Once you exit, your session will end. If you change your mind, you will need to join a new tour.':
            'Once you exit, your session will end. If you change your mind, you will need to join a new tour.',
          'Yes, end my experience': 'Yes, end my experience',
          'Nope, not yet': 'Nope, not yet',
          'There are items that require your attention':
            'There are items that require your attention',
          'Invite a friend': 'Invite a friend',
          'Invite a friend or family member to join your Chevy MyWay experience*. Enter your info below and we&apos;ll send them a custom invite link.':
            'Invite a friend or family member to join your Chevy MyWay experience*. Enter your info below and we&apos;ll send them a custom invite link.',
          'By providing Chevrolet with this information, you acknowledge that you have informed your friend that you will be providing Chevrolet with their name and email address. Sending this Session Invite to your friend does not add their email address to the Chevrolet marketing list.':
            'By providing Chevrolet with this information, you acknowledge that you have informed your friend that you will be providing Chevrolet with their name and email address. Sending this Session Invite to your friend does not add their email address to the Chevrolet marketing list.',
          '*You may only provide the email address of friends or family.':
            '*You may only provide the email address of friends or family.',
          'Which Vehicle did you conduct a tour of?': 'Which Vehicle did you conduct a tour of?',
          'Please select all nameplates that were discussed in-session.':
            'Please select all nameplates that were discussed in-session.',
          'Select all nameplates that apply': 'Select all nameplates that apply',
          'Please select applied nameplates.': 'Please select applied nameplates.',
          'What is Chevy MyWay?': 'What is Chevy MyWay?',
          'Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet Product Specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a dealer all from the comfort of your home or office.':
            'Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet Product Specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a dealer all from the comfort of your home or office.',
          'Is my tour going to be recorded?': 'Is my tour going to be recorded?',
          'No, your tour will not be recorded.': 'No, your tour will not be recorded.',
          'How do I book a tour?': 'How do I book a tour?',
          'Please visit chevrolet.com/myway/reservation and we will make arrangements.':
            'Please visit chevrolet.com/myway/reservation and we will make arrangements.',
          'How do I test drive a vehicle?': 'How do I test drive a vehicle?',
          'Following your tour of the showroom, our Product Specialist will be happy to assist you in setting up at a time for a test drive with your preferred dealer':
            'Following your tour of the showroom, our Product Specialist will be happy to assist you in setting up at a time for a test drive with your preferred dealer',
          'How long are wait times?': 'How long are wait times?',
          'Wait times may vary but we strive to keep them to a minimum.':
            'Wait times may vary but we strive to keep them to a minimum.',
          'I require special assistance.': 'I require special assistance.',
          'Please contact the Chevrolet Customer Care center at 1-800-950-CHEV':
            'Please contact the Chevrolet Customer Care center at 1-800-950-CHEV',
          'Can the agent see me?': 'Can the agent see me?',
          'No, you will only see the agent and the showroom from their perspective.':
            'No, you will only see the agent and the showroom from their perspective.',
          'I have another question or comment.': 'I have another question or comment.',
          'Will my information be shared with a third-party?':
            'Will my information be shared with a third-party?',
          'No.': 'No.',
          'How long is a tour?': 'How long is a tour?',
          'You can end a tour whenever you prefer. You are not committed to a pre-determined time.':
            'You can end a tour whenever you prefer. You are not committed to a pre-determined time.',
          'What are my obligations after a tour?': 'What are my obligations after a tour?',
          'Following your tour there are no obligations. You can choose to arrange to see a dealer for a test drive, but only if you wish.':
            'Following your tour there are no obligations. You can choose to arrange to see a dealer for a test drive, but only if you wish.',
          'By signing up will I end up on a mailing list?':
            'By signing up will I end up on a mailing list?',
          'No, unless you choose otherwise, you will not receive additional communications from Chevrolet.':
            'No, unless you choose otherwise, you will not receive additional communications from Chevrolet.',
          'How much does a tour cost?': 'How much does a tour cost?',
          'Your experience with Chevy MyWay is entirely complimentary and free of obligations.':
            'Your experience with Chevy MyWay is entirely complimentary and free of obligations.',
          'Is Chevy MyWay available to everyone in the United States?':
            'Is Chevy MyWay available to everyone in the United States?',
          'Yes, anyone over 18 in the United States is invited to experience Chevy MyWay.':
            'Yes, anyone over 18 in the United States is invited to experience Chevy MyWay.',
          'CHEVY MY WAY ADVISOR FAQS': 'CHEVY MY WAY ADVISOR FAQS',
          Summary: 'Summary',
          'Question/Answer': 'Question/Answer',
          'Where can I access Chevy MyWay?': 'Where can I access Chevy MyWay?',
          'How does Chevy MyWay work?': 'How does Chevy MyWay work?',
          'Where is the Chevy MyWay showroom located?':
            'Where is the Chevy MyWay showroom located?',
          'At this time, the available vehicles are:': 'At this time, the available vehicles are:',
          'Will a Chevy MyWay Product Specialist be able to answer any questions that I have?':
            'Will a Chevy MyWay Product Specialist be able to answer any questions that I have?',
          'Will my information be shared via a third party?':
            'Will my information be shared via a third party?',
          'What are the hours of operation for Chevy MyWay?':
            'What are the hours of operation for Chevy MyWay?',
          'The Chevy MyWay showroom can be accessed directly via 7 days a week, Monday â€“ Thursday: 9am-12am ET, Friday: 9am-9pm ET, and Saturday â€“ Sunday: 11am-7pm ET.':
            'The Chevy MyWay showroom can be accessed directly via 7 days a week, Monday â€“ Thursday: 9am-12am ET, Friday: 9am-9pm ET, and Saturday â€“ Sunday: 11am-7pm ET.',
          'Will the Chevy MyWay Product Specialist be able to see a customer during the conversation?':
            'Will the Chevy MyWay Product Specialist be able to see a customer during the conversation?',
          'Chevy MyWay is a one-way video chat. A customer will be able to see the product specialist and any vehicles or features of interest, but the product specialist will be unable to see the customer. Customers also have the capability to converse with the product specialist via chat if they would not like to vocally chat with an agent.':
            'Chevy MyWay is a one-way video chat. A customer will be able to see the product specialist and any vehicles or features of interest, but the product specialist will be unable to see the customer. Customers also have the capability to converse with the product specialist via chat if they would not like to vocally chat with an agent.',
          'How far in advance can I book an appointment? Can I book an appointment on the spot?':
            'How far in advance can I book an appointment? Can I book an appointment on the spot?',
          'Appointments can be booked on the spot and up to five weeks in advance.':
            'Appointments can be booked on the spot and up to five weeks in advance.',
          'What if I need to cut my appointment short? Can I be connected to the same product specialist at a different time and date?':
            'What if I need to cut my appointment short? Can I be connected to the same product specialist at a different time and date?',
          'You can end a tour whenever you prefer. You are not committed to a pre-determined time. You can reschedule your Chevy MyWay appointment for another time but cannot book with a specific product specialist.':
            'You can end a tour whenever you prefer. You are not committed to a pre-determined time. You can reschedule your Chevy MyWay appointment for another time but cannot book with a specific product specialist.',
          'Can I buy a car directly from a product specialist when on Chevy MyWay?':
            'Can I buy a car directly from a product specialist when on Chevy MyWay?',
          'Once consumers are interested in learning more about a specific model from their session, where can they go to test drive a car?':
            'Once consumers are interested in learning more about a specific model from their session, where can they go to test drive a car?',
          'During a session, Chevy MyWay offers customers the choice to be connected with a local dealer by preference or proximity.':
            'During a session, Chevy MyWay offers customers the choice to be connected with a local dealer by preference or proximity.',
          'On average, how long does a consumer spend on Chevy MyWay with the product specialist?':
            'On average, how long does a consumer spend on Chevy MyWay with the product specialist?',
          'It is ultimately up to the customer on how long theyâ€™d like their session to last.':
            'It is ultimately up to the customer on how long theyâ€™d like their session to last.',
          'Is this the first time Chevy MyWay is launching?':
            'Is this the first time Chevy MyWay is launching?',
          'GM implemented a Virtual Showroom for Cadillac in the US last year. This is the first experience of this kind for Chevrolet.':
            'GM implemented a Virtual Showroom for Cadillac in the US last year. This is the first experience of this kind for Chevrolet.',
          'What deciding factors led to the US launch?':
            'What deciding factors led to the US launch?',
          'Chevy MyWay offers an innovative experience that addresses changing consumer behaviors. We are excited to see what this does to brand perception, how it drives sales, as well as bring new customers to the brand.':
            'Chevy MyWay offers an innovative experience that addresses changing consumer behaviors. We are excited to see what this does to brand perception, how it drives sales, as well as bring new customers to the brand.',
          'Where did the concept for Chevy MyWay originate?':
            'Where did the concept for Chevy MyWay originate?',
          'Understanding how busy consumers are with limited time for vehicle research, and looking at data and trends about how consumersâ€™ shopping habits have changed, Chevrolet wanted to create a tool that allowed customers to learn (shop) more about the brandâ€™s vehicle line-up from the comfort of their home, office or even at the gym with someone who could walk them through a personalized experience.':
            'Understanding how busy consumers are with limited time for vehicle research, and looking at data and trends about how consumersâ€™ shopping habits have changed, Chevrolet wanted to create a tool that allowed customers to learn (shop) more about the brandâ€™s vehicle line-up from the comfort of their home, office or even at the gym with someone who could walk them through a personalized experience.',
          'Why did Chevrolet see the need to create a virtual dealership?':
            'Why did Chevrolet see the need to create a virtual dealership?',
          'Digital research is standard for most vehicle shoppers today and average visits to dealerships are decreasing. Chevy MyWay brings the research experience to shoppers on their schedule and from the comfort of anywhere that suits them best.':
            'Digital research is standard for most vehicle shoppers today and average visits to dealerships are decreasing. Chevy MyWay brings the research experience to shoppers on their schedule and from the comfort of anywhere that suits them best.',
          'How has/will this affect car sales?': 'How has/will this affect car sales?',
          'Suggested & Required Verbiage (Chat Transfers):':
            'Suggested & Required Verbiage (Chat Transfers):',
          'Initial offer to transfer a customer to Chevy MyWay:':
            'Initial offer to transfer a customer to Chevy MyWay:',
          'If the customer agrees to join Chevy MyWay queue:':
            'If the customer agrees to join Chevy MyWay queue:',
          'Click HERE when you are ready to join the Chevy MyWay queue. After you click the link, you will be able to view your place in line. Please note that upon clicking the link this chat will be closed. (Link: TBD)':
            'Click HERE when you are ready to join the Chevy MyWay queue. After you click the link, you will be able to view your place in line. Please note that upon clicking the link this chat will be closed. (Link: TBD)',
          'Wait for customer response for up to 3 minutes: Enter closing statement (Always allow a customer 3 minutes to click the link before closing the chat)':
            'Wait for customer response for up to 3 minutes: Enter closing statement (Always allow a customer 3 minutes to click the link before closing the chat)',
          'If customer confirms/signals they will click the link before the 3 minutes: Enter closing statement (Always allow a customer 3 minutes to click the link before closing the chat)':
            'If customer confirms/signals they will click the link before the 3 minutes: Enter closing statement (Always allow a customer 3 minutes to click the link before closing the chat)',
          'If a customer is not available to video chat with a product specialist at this time:':
            'If a customer is not available to video chat with a product specialist at this time:',
          'I understand right now may not be a good time for you. If you would like, I can provide a link to Chevy MyWay where you can book a session to one-way video chat with a product specialist at a time that is more convenient for you.':
            'I understand right now may not be a good time for you. If you would like, I can provide a link to Chevy MyWay where you can book a session to one-way video chat with a product specialist at a time that is more convenient for you.',
          'Wait for POSITIVE customer response (agrees/confirms):':
            'Wait for POSITIVE customer response (agrees/confirms):',
          'Click HERE to book a Chevy MyWay one-on-one session. Is there anything else I can assist you with today? (Always allow a customer 3 minutes to click the link before closing the chat) (Link: TBD)':
            'Click HERE to book a Chevy MyWay one-on-one session. Is there anything else I can assist you with today? (Always allow a customer 3 minutes to click the link before closing the chat) (Link: TBD)',
          'If a customer declines offer to join Chevy MyWay queue or Book a Session page:':
            'If a customer declines offer to join Chevy MyWay queue or Book a Session page:',
          'I understand that video chatting with a product specialist on Chevy MyWay is not of interest to you at this time. I am still here to answer any questions that you may have. Is there anything else I can assist you with?':
            'I understand that video chatting with a product specialist on Chevy MyWay is not of interest to you at this time. I am still here to answer any questions that you may have. Is there anything else I can assist you with?',
          "Chevrolet is expanding their digital presence and launching a unique Virtual Showroom experience in the U.S called Chevy MyWay. This personalized, immersive experience takes the consumer through the Chevrolet lineup, allowing them to learn more about the vehicles via a one-on-one video conversation with trained product specialists from the comfort of their home. Using any mobile or desktop device, users can ask questions, invite friends to join, book a test drive, inquire about quotes, and more, offering a&nbsp;<a style='text-decoration: underline; cursor: pointer'>shopping</a> experience&nbsp;<a style='text-decoration: underline; cursor: pointer'>that</a> is self-led and on their own terms. Chevrolet understands US consumersâ€™ shopping habits and the increasing need to innovate during these unprecedented times, delivering the most dynamic car-shopping experience.":
            "Chevrolet is expanding their digital presence and launching a unique Virtual Showroom experience in the U.S called Chevy MyWay. This personalized, immersive experience takes the consumer through the Chevrolet lineup, allowing them to learn more about the vehicles via a one-on-one video conversation with trained product specialists from the comfort of their home. Using any mobile or desktop device, users can ask questions, invite friends to join, book a test drive, inquire about quotes, and more, offering a&nbsp;<a style='text-decoration: underline; cursor: pointer'>shopping</a> experience&nbsp;<a style='text-decoration: underline; cursor: pointer'>that</a> is self-led and on their own terms. Chevrolet understands US consumersâ€™ shopping habits and the increasing need to innovate during these unprecedented times, delivering the most dynamic car-shopping experience.",
          "Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet product specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a&nbsp; <a style='text-decoration: underline; cursor: pointer'>participating</a> dealer all from the comfort of your home or office.":
            "Chevy MyWay is a digital showroom that allows you to connect with a Chevrolet product specialist in real-time and experience an intimate first-person view of our newest vehicles, ask questions, then book an appointment with a&nbsp; <a style='text-decoration: underline; cursor: pointer'>participating</a> dealer all from the comfort of your home or office.",
          "Chevy MyWay is available to anyone <a style='text-decoration: underline; cursor: pointer'>over 18</a>&nbsp; in the United States to access and experience via chevrolet.com/myway.":
            "Chevy MyWay is available to anyone <a style='text-decoration: underline; cursor: pointer'>over 18</a>&nbsp; in the United States to access and experience via chevrolet.com/myway.",
          "Once on the site, shoppers can choose to begin a new session with an agent or schedule a session for a future date. Shoppers can also invite&nbsp; <a style='text-decoration: underline; cursor: pointer'>other individuals</a> to join the live session with them. When the customerâ€™s turn arrives, a one-way video chat appears on the customers phone, tablet, or computer and the customer is now able to view the vehicle live, talk or chat with the agent, and ask questions. The service gives shoppers virtual tours of the showroom, which features 15 vehicles from Chevroletâ€™s lineup on display.":
            "Once on the site, shoppers can choose to begin a new session with an agent or schedule a session for a future date. Shoppers can also invite&nbsp; <a style='text-decoration: underline; cursor: pointer'>other individuals</a> to join the live session with them. When the customerâ€™s turn arrives, a one-way video chat appears on the customers phone, tablet, or computer and the customer is now able to view the vehicle live, talk or chat with the agent, and ask questions. The service gives shoppers virtual tours of the showroom, which features 15 vehicles from Chevroletâ€™s lineup on display.",
          "The Virtual Showroom is a 10,000 plus sq. foot studio located in <a style='text-decoration: underline; cursor: pointer'>metro</a> Detroit, Michigan, USA.":
            "The Virtual Showroom is a 10,000 plus sq. foot studio located in <a style='text-decoration: underline; cursor: pointer'>metro</a> Detroit, Michigan, USA.",
          "Chevy MyWay product specialists are product experts and should be able to answer the questions that you have, but in the event that they are unable to answer a question that you <a style='text-decoration: underline; cursor: pointer'>may have, you</a> can always chat back in on chevrolet.com and speak with an advisor, or contact the Chevrolet Customer Care center at 1-800-950-CHEV.":
            "Chevy MyWay product specialists are product experts and should be able to answer the questions that you have, but in the event that they are unable to answer a question that you <a style='text-decoration: underline; cursor: pointer'>may have, you</a> can always chat back in on chevrolet.com and speak with an advisor, or contact the Chevrolet Customer Care center at 1-800-950-CHEV.",
          'No. Chevy MyWay is a tool designed to collaborate with and support Chevrolet dealers. Chevy MyWay enhances the customer journey to help find which vehicle is right for them. Customers can be connected to a local Chevy MyWay dealer to take next steps on their path to purchasing a Chevrolet or begin the vehicle shopping process online via Shop.Click.Drive.':
            'No. Chevy MyWay is a tool designed to collaborate with and support Chevrolet dealers. Chevy MyWay enhances the customer journey to help find which vehicle is right for them. Customers can be connected to a local Chevy MyWay dealer to take next steps on their path to purchasing a Chevrolet or begin the vehicle shopping process online via Shop.Click.Drive.',
          "The goal is to drive more sales <a style='text-decoration: underline; cursor: pointer'>at Chevrolet dealers</a>.":
            "The goal is to drive more sales <a style='text-decoration: underline; cursor: pointer'>at Chevrolet dealers</a>.",
          "Chevroletâ€™s (nameplate) has so many (wonderful/impressive/beautiful/ <a style='text-decoration: underline;cursor: pointer'>advanced</a> etc.) features! If you would like, I can provide a link to Chevy MyWay where you would be able to one-way video chat with one of our product specialists and he or she would be able to show you features you are interested in.":
            "Chevroletâ€™s (nameplate) has so many (wonderful/impressive/beautiful/ <a style='text-decoration: underline;cursor: pointer'>advanced</a> etc.) features! If you would like, I can provide a link to Chevy MyWay where you would be able to one-way video chat with one of our product specialists and he or she would be able to show you features you are interested in.",
          'Expire Session': 'Expire Session',
          'Mute Video': 'Mute Video',
          Close: 'Close',
          Post: 'Post',
          'Chat Now': 'Chat Now',
          Color: 'Color',
          Model: 'Model',
          Transfer: 'Transfer',
          More: 'More',
          Troubleshoot: 'Troubleshoot',
          Questions: 'Questions',
          COUNTDOWN: 'COUNTDOWN',
          ONLINE: 'ONLINE',
          OFFLINE: 'OFFLINE',
          'Shareable Hyperlinks': 'Shareable Hyperlinks',
          "HANG TIGHT, WE'LL BE WITH YOU SHORTLY.": "HANG TIGHT, WE'LL BE WITH YOU SHORTLY.",
          'Safety Systems': 'Safety Systems',
          Copy: 'Copy',
          "By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information. <a style=' font-weight: 700, text-ecoration: underline'>GM Privacy Statement.</a>":
            "By providing my contact information below, I consent that Chevrolet, GM and/or a GM dealer can contact me with offers and product information. <a style=' font-weight: 700, text-ecoration: underline'>GM Privacy Statement.</a>",
          'Select a brand:': 'Select a brand:',
          'All Brands': 'All Brands',
          'Select a Product Specialists:': 'Select a Product Specialists:',
          'All Product Specialists': 'All Product Specialists',
          Troubleshooting: 'Troubleshooting',
          'What are the minimum system requirements and supported browsers?':
            'What are the minimum system requirements and supported browsers?',
          'SUPPORTED BROWSERS': 'SUPPORTED BROWSERS',
          'Google Chrome (latest release version)': 'Google Chrome (latest release version)',
          'Google Chrome for Android (latest release version)':
            'Google Chrome for Android (latest release version)',
          'Firefox (latest release version)': 'Firefox (latest release version)',
          'Firefox for Android (latest release version)':
            'Firefox for Android (latest release version)',
          'Safari 11': 'Safari 11',
          'iOS 11': 'iOS 11',
          'Opera (latest release version)': 'Opera (latest release version)',
          'BANDWIDTH REQUIREMENTS': 'BANDWIDTH REQUIREMENTS',
          'Video: 300 kbps per stream (recommended lowest level)':
            'Video: 300 kbps per stream (recommended lowest level)',
          'Audio: 50 kbps per stream (recommended lowest level)':
            'Audio: 50 kbps per stream (recommended lowest level)',
          iOS: 'iOS',
          'Operating system': 'Operating system',
          'iOS 9 or higher': 'iOS 9 or higher',
          Hardware: 'Hardware',
          'iPhone X/XR/XS': 'iPhone X/XR/XS',
          'iPhone 8/8+': 'iPhone 8/8+',
          'iPhone 6/6+/6S/6S+': 'iPhone 6/6+/6S/6S+',
          'iPhone SE': 'iPhone SE',
          'iPhone 5S': 'iPhone 5S',
          'Pad Pro 1st/2nd generation': 'Pad Pro 1st/2nd generation',
          'iPad (2017)': 'iPad (2017)',
          'iPad Air/Air 2': 'iPad Air/Air 2',
          'iPad 4th generation': 'iPad 4th generation',
          'iPad mini 2/3/4': 'iPad mini 2/3/4',
          'Available Vehicles': 'Available Vehicles',
          iPod: 'iPod',
          'iPod touch 6th generation': 'iPod touch 6th generation',
          ANDROID: 'ANDROID',
          'Any Android 4.1+ device (Jelly Bean, API Level 16)':
            'Any Android 4.1+ device (Jelly Bean, API Level 16)',
          '.2 GHz quad-core ARM Cortex-A7 or better': '.2 GHz quad-core ARM Cortex-A7 or better',
          '1 GB RAM (ex. Samsung Galaxy S3 - 2012)': '1 GB RAM (ex. Samsung Galaxy S3 - 2012)',
          'As long as neither the user or Product Specialist end the session, refreshing the page will not end the session.':
            'As long as neither the user or Product Specialist end the session, refreshing the page will not end the session.',
          'What doesnâ€™t work?': 'What doesnâ€™t work?',
          'PS interface': 'PS interface',
          iPhone: 'iPhone',
          'Sound not coming through to PS': 'Sound not coming through to PS',
          'Did the user allow microphone access': 'Did the user allow microphone access',
          'Edge - try refreshing the page, edge will prompt the user to allow permissions again':
            'Edge - try refreshing the page, edge will prompt the user to allow permissions again',
          'Chrome - in the address bar on the right side there is a little icon of a camera with a red circle (indicating denied access) click on this and allow access to the microphone and refresh the page':
            'Chrome - in the address bar on the right side there is a little icon of a camera with a red circle (indicating denied access) click on this and allow access to the microphone and refresh the page',
          'Safari - Need access to a mac to check on this one':
            'Safari - Need access to a mac to check on this one',
          'Is the user using the correct microphone': 'Is the user using the correct microphone',
          'Some users have alternate microphones that may be activated, this should be indicated by some type of background noise':
            'Some users have alternate microphones that may be activated, this should be indicated by some type of background noise',
          'Is the bluetooth on/paired': 'Is the bluetooth on/paired',
          'Bluetooth could be on a paired to a device the user is not using at the time':
            'Bluetooth could be on a paired to a device the user is not using at the time',
          'Sound not transmitting to user': 'Sound not transmitting to user',
          'Is the mic on': 'Is the mic on',
          'Check the battery level on the PS headset if the power is on':
            'Check the battery level on the PS headset if the power is on',
          'Is the microphone muted': 'Is the microphone muted',
          'Check to see if the PS headset is on mute': 'Check to see if the PS headset is on mute',
          "Are the user's speakers on": "Are the user's speakers on",
          'Some users have bluetooth headsets that may be activated':
            'Some users have bluetooth headsets that may be activated',
          'Check that the user has the volume up on there speakers':
            'Check that the user has the volume up on there speakers',
          'Sound is coming from the phone': 'Sound is coming from the phone',
          'Is the bluetooth connected': 'Is the bluetooth connected',
          'Check that the phoneâ€™s bluetooth is on': 'Check that the phoneâ€™s bluetooth is on',
          'Check the the correct phone is connected to the bluetooth':
            'Check the the correct phone is connected to the bluetooth',
          'Is the headset on': 'Is the headset on',
          'Check that the headset has been turned on': 'Check that the headset has been turned on',
          'Check that the headset is charged': 'Check that the headset is charged',
          'Are the userâ€™s speakers on': 'Are the userâ€™s speakers on',
          'Video is not transmitting': 'Video is not transmitting',
          'Is something in front of the camera': 'Is something in front of the camera',
          'Are the phone and the ipad signed in to the same PS account':
            'Are the phone and the ipad signed in to the same PS account',
          iPad: 'iPad',
          'The video isnâ€™t visible': 'The video isnâ€™t visible',
          'Are the phone and ipad signed into the same PS account':
            'Are the phone and ipad signed into the same PS account',
          'Refresh the ipad': 'Refresh the ipad',
          'The chat doesnâ€™t work': 'The chat doesnâ€™t work',
          'Try refreshing the ipad screen': 'Try refreshing the ipad screen',
          'The interface isnâ€™t visible': 'The interface isnâ€™t visible',
          'User Interface': 'User Interface',
          'Features donâ€™t appear': 'Features donâ€™t appear',
          "Try a different model, limited edition models (like the 2021 escalade) sometimes don't have feature sections included.":
            "Try a different model, limited edition models (like the 2021 escalade) sometimes don't have feature sections included.",
          'Try refreshing the page': 'Try refreshing the page',
          'Is the options menu open? Features are hidden to save space while the menus are open':
            'Is the options menu open? Features are hidden to save space while the menus are open',
          'Menu isnâ€™t accessible': 'Menu isnâ€™t accessible',
          'Is the features menu open? The side menu is visible but not accessible while the features menu is open':
            'Is the features menu open? The side menu is visible but not accessible while the features menu is open',
          'Sound isnâ€™t coming through': 'Sound isnâ€™t coming through',
          'Is the PS on mute': 'Is the PS on mute',
          'Is the userâ€™s volume up and active on the correct speakers':
            'Is the userâ€™s volume up and active on the correct speakers',
          'Video isnâ€™t coming through': 'Video isnâ€™t coming through',
          'Try refreshing the browser': 'Try refreshing the browser',
          'Chat isnâ€™t functioning': 'Chat isnâ€™t functioning',
          'Menu doesnâ€™t sync': 'Menu doesnâ€™t sync',
          'Sometimes the PS menu can lag, touching the ipad screen can fix this':
            'Sometimes the PS menu can lag, touching the ipad screen can fix this',
          'Interface isnâ€™t visible': 'Interface isnâ€™t visible',
          'If all of the above options fail, try having the user come back in a new session using a different or incognito browser.':
            'If all of the above options fail, try having the user come back in a new session using a different or incognito browser.',
          'Vehicle Features': 'Vehicle Features',
          View: 'View',
          'Email is incomplete or characters not permitted.':
            'Email is incomplete or characters not permitted.',
          'This field is required in the standard format.':
            'This field is required in the standard format.',
          'SELECT VEHICLE TYPE(S) (REQUIRED):': 'SELECT VEHICLE TYPE(S) (REQUIRED):',
          'SELECT VEHICLE:': 'SELECT VEHICLE:',
          'You may be eligible to take advantage of an exclusive discount program from GM. Please select the program below that you would like to learn more about. (Optional)':
            'You may be eligible to take advantage of an exclusive discount program from GM. Please select the program below that you would like to learn more about. (Optional)',
          'Explore GM Discounts': 'Explore GM Discounts',
          'GM Military Discount': 'GM Military Discount',
          'GM Educator Discount': 'GM Educator Discount',
          'GM College Discount': 'GM College Discount',
          'Join Group Queue': 'Join Group Queue',
          'Please select an ownership type.': 'Please select an ownership type.',
          'Sign up to get notifications on the latest Chevrolet products, current offers, events, and more.':
            'Sign up to get notifications on the latest Chevrolet products, current offers, events, and more.',
          '*General Motors of Canada Company (GM) is located at 1908 Colonel Sam Drive, Oshawa, ON, L1H 8P7, 1-800-GM DRIVE, gm.ca, contact us, privacy policy. You may withdraw your consent for receiving marketing emails from GM, any time by contacting us. If you contact us to unsubscribe you will no longer receive marketing messages from us, but we may still send you other electronic messages such as those relating to your transactions with us, health and safety matters or those that we are required or permitted by law to send to you. If you do not click â€œYESâ€, your marketing status with us will not change but you can contact us anytime to change your marketing preferences.':
            '*General Motors of Canada Company (GM) is located at 1908 Colonel Sam Drive, Oshawa, ON, L1H 8P7, 1-800-GM DRIVE, gm.ca, contact us, privacy policy. You may withdraw your consent for receiving marketing emails from GM, any time by contacting us. If you contact us to unsubscribe you will no longer receive marketing messages from us, but we may still send you other electronic messages such as those relating to your transactions with us, health and safety matters or those that we are required or permitted by law to send to you. If you do not click â€œYESâ€, your marketing status with us will not change but you can contact us anytime to change your marketing preferences.',
          'Book Your Live Tour': 'Book Your Live Tour',
          'By providing my contact information below, I consent that Cadillac, GM and/or a GM dealer can contact me with offers and product information. GM Privacy Statement.':
            'By providing my contact information below, I consent that Cadillac, GM and/or a GM dealer can contact me with offers and product information. GM Privacy Statement.',
          '* Indicates Required Fields': '* Indicates Required Fields',
          'Fill out the form below to be connected with a Cadillac Live agent at your preferred time.':
            'Fill out the form below to be connected with a Cadillac Live agent at your preferred time.',
          'Select a Vehicle': 'Select a Vehicle',
          'Select a date': 'Select a date',
          'Last Name': 'Last Name',
          'First Name': 'First Name',
          'Zip Code': 'Zip Code',
          'Email Address': 'Email Address',
          City: 'City',
          'Confirm Email Address:': 'Confirm Email Address:',
          'Sign up for a live group tour': 'Sign up for a live group tour',
          'By providing my contact information below, I consent that Cadillac, GM and/or a GM dealer can contact me with offers and product information.':
            'By providing my contact information below, I consent that Cadillac, GM and/or a GM dealer can contact me with offers and product information.',
          'Times are displayed in your current time zone.':
            'Times are displayed in your current time zone.',
          'Select your tour': 'Select your tour',
          'Select from upcoming tour': 'Select from upcoming tour',
          "By providing my contact information below, I acknowledge that my information will be handled in accordance with GM's Privacy Statement, and I consent that Cadillac, GM, and/or a GM dealer can contact me with offers and product information.":
            "By providing my contact information below, I acknowledge that my information will be handled in accordance with GM's Privacy Statement, and I consent that Cadillac, GM, and/or a GM dealer can contact me with offers and product information.",
          'Phone number': 'Phone number',
          'Select a vehicle': 'Select a vehicle',
          'SELECT VEHICLE TYPE(S)': 'SELECT VEHICLE TYPE(S)',
          'ESTIMATED PURCHASE DATE': 'ESTIMATED PURCHASE DATE',
        },
      },
    },
  });

export default i18n;
