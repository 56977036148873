import { languages } from '@constants';
import { LanguageCode } from '@types';
import { useQueryString } from './useQueryString';

export const useCurrentLanguageParam = (): any => {
  const { urlSearchParams } = useQueryString();
  const langParam = urlSearchParams.get('lang');
  let lang = languages[0] as LanguageCode;
  if (langParam) {
    // we have to accomidate for the format that motionpoint is sending us
    switch (langParam) {
      case 'es-us':
      case 'es-MX':
        lang = 'es-MX';
        break;
      case 'fr-ca':
      case 'en-FR':
      case 'fr':
        lang = 'en-FR';
        break;
      default:
        lang = 'en-US';
        break;
    }
  }
  return { lang };
};
