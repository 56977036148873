import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalIconButton from './ModalIconButton';

type ContactDealerSubmittedModalProps = {
  onClose?: () => void;
  brandId: string;
};

const ContactDealerSubmittedModal = (props: ContactDealerSubmittedModalProps) => {
  const { onClose, brandId } = props;
  const { t } = useTranslation();
  const isEnergy = brandId.toLocaleLowerCase() === 'energy';

  return (
    <>
      {isEnergy && (
        <ModalIconButton
          onClose={onClose}
          brandId={brandId}
        />
      )}
      <Box className="modal-container">
        {!isEnergy && (
          <ModalIconButton
            onClose={onClose}
            brandId={brandId}
          />
        )}
        <Box className="modal-text-container">
          <Typography
            variant="modalTitle"
            component="h2"
          >
            {t('Thank you.')}
          </Typography>
          <Typography
            align="left"
            variant="modalSubText"
          >
            {t('Your request has been recieved.')}
          </Typography>
        </Box>

        <Box className="modal-container-buttons">
          <Button
            onClick={onClose}
            variant="primary"
            type="button"
            disableRipple
            aria-label="close modal"
          >
            {t('Resume')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ContactDealerSubmittedModal;
