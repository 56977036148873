import { doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { Brand } from '@types';
import { useQueryString } from './useQueryString';
import { brands } from '@constants';

export const useCurrentBrand = (): Brand => {
  const { urlSearchParams } = useQueryString();
  const brandId = urlSearchParams.get('brandId') || brands[0];

  if (!brandId) {
    alert('invalid brandId');
    return null;
  }

  const brandsRef = doc(useFirestore(), `brands/${brandId}`);
  const { data: brand } = useFirestoreDocData(brandsRef, {
    idField: 'id',
  });
  if (brand) {
    return brand as Brand;
  }
  return null;
};
