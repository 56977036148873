import { RadioGroup } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useQueryString } from '@hooks';

const RadioFieldStyles = (theme: any) => {
  const { urlSearchParams } = useQueryString();
  const brandId = urlSearchParams.get('brandId');
  const isCadillacCanada = brandId === 'cadillaccan';
  const isCadillacUS = brandId === 'cadillac';
  const isGMC = brandId === 'gmc';
  const isBuick = brandId === 'buick';
  const isEVLive = brandId === 'ev' || brandId === 'evcan';
  const isEnergy = brandId === 'energy';

  let radioFieldStyles = {};

  // const radioFieldCadillacCanadaStyles = {
  //   '& .MuiFormControlLabel-label': {
  //     fontFamily: 'CadillacSansA-Light',
  //     fontWeight: 400,
  //     fontSize: '14px',
  //     cursor: 'pointer',
  //     textTransform: 'uppercase',
  //     letterSpacing: '3px',
  //     display: 'block',
  //     position: 'relative',
  //     marginBottom: '0 !important',
  //     color: theme.palette.primary.main,
  //   },
  //   '&.radio-field-vehicle-modal': {
  //     '& .MuiFormControlLabel-root': {
  //       borderLeft: `1px solid ${theme.palette.grey[800]} `,
  //       borderBottom: `1px solid ${theme.palette.grey[800]}`,
  //       borderRight: `1px solid ${theme.palette.grey[800]}`,
  //     },
  //     '& .MuiFormControlLabel-label': { fontFamily: 'CadillacGothic-Medium' },
  //   },
  // };

  const radioFieldGMCStyles = {
    '& .MuiFormControlLabel-root': {
      borderLeft: `2px solid ${theme.palette.grey[900]}`,
      borderBottom: `2px solid ${theme.palette.grey[900]}`,
      borderRight: `2px solid ${theme.palette.grey[900]}`,
    },
    '&.radio-field-vehicle-modal': {
      '& .MuiFormControlLabel-root': {
        borderBottom: 'none',
      },
      '& .MuiFormControlLabel-root:last-child': {
        borderBottom: `2px solid ${theme.palette.grey[900]}`,
      },
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '16px !important',
      // fontFamily: 'muliregular !important', // GL-1470 (Remove)
      fontFamily: 'StratumGMC-Regular', // GL-1470
    },
  };

  const radioFieldBuickStyles = {
    '& .MuiFormControlLabel-root': {
      borderLeft: `1px solid ${theme.palette.grey[600]}`,
      borderBottom: `1px solid ${theme.palette.grey[600]}`,
      borderRight: `1px solid ${theme.palette.grey[600]}`,
    },
  };

  const radioFieldEVLiveStyles = {
    // '& .MuiFormControlLabel-root': {
    //   borderLeft: `1px solid ${theme.palette.grey[200]}`,
    //   borderBottom: `1px solid ${theme.palette.grey[200]}`,
    //   borderRight: `1px solid ${theme.palette.grey[200]}`,
    // },
    '& .MuiFormControlLabel-root': {
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      borderBottom: 'none',
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
  };

  const radioFieldCadillacStyles = {
    '&.radio-field-vehicle-modal': {
      '& .MuiFormControlLabel-root': {
        borderLeft: `1px solid ${theme.palette.grey[800]} `,
        borderBottom: `1px solid ${theme.palette.grey[800]}`,
        borderRight: `1px solid ${theme.palette.grey[800]}`,
      },
      '& .MuiFormControlLabel-label': { fontFamily: 'CadillacGothic-Medium' },
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: 'CadillacGothic-Regular',
      fontWeight: 400,
      fontSize: '14px',
      cursor: 'pointer',
      textTransform: 'uppercase',
      letterSpacing: '3px',
      display: 'block',
      position: 'relative',
      marginBottom: '0 !important',
      color: theme.palette.primary.main,
    },
  };

  const radioFieldEnergyStyles = {
    '&.MuiFormGroup-root': {
      borderLeft: `1px solid ${theme.palette.grey[800]}`,
      borderRight: `1px solid ${theme.palette.grey[800]}`,
      borderBottom: `1px solid ${theme.palette.grey[800]}`,
    },
    '& .MuiFormControlLabel-root': {
      marginRight: '0 !important',
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Overpass',
    },
  };

  if (isCadillacUS || isCadillacCanada) {
    radioFieldStyles = {
      ...radioFieldCadillacStyles,
    };
  }

  // if (isCadillacCanada) {
  //   radioFieldStyles = {
  //     ...radioFieldCadillacCanadaStyles,
  //   };
  // }

  if (isGMC) {
    radioFieldStyles = {
      ...radioFieldGMCStyles,
    };
  }

  if (isBuick) {
    radioFieldStyles = {
      ...radioFieldBuickStyles,
    };
  }

  if (isEVLive) {
    radioFieldStyles = {
      ...radioFieldEVLiveStyles,
    };
  }

  if (isEnergy) {
    radioFieldStyles = {
      ...radioFieldEnergyStyles,
    };
  }

  const styles = {
    '& .MuiInputBase-input': {
      padding: '2px 0 0 0',
      textFillColor: 'unset',
      cursor: 'pointer',
    },
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
    '& .MuiFormControlLabel-root': {
      height: '40px',
      margin: 0,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderLeft: `1px solid ${alpha(theme.palette.grey[300], 1)}`,
      borderBottom: `1px solid ${alpha(theme.palette.grey[300], 1)}`,
      borderRight: `1px solid ${alpha(theme.palette.grey[300], 1)}`,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: 'Roboto',
      display: 'block',
      position: 'relative',
      marginBottom: '0 !important',
      color: `${alpha(theme.palette.text.primary, 1)}`,
      letterSpacing: '0px',
      lineHeight: '16px',
      textTransform: 'initial',
      cursor: 'pointer',
    },
    [theme.breakpoints.up('xs')]: {
      ...radioFieldStyles,
    },
  };
  return {
    styles,
  };
};

const RadioField = styled(RadioGroup)(({ theme }) => RadioFieldStyles(theme).styles);

export default RadioField;
