import { useEffect, useState } from 'react';
import { Dealership } from '@types';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface DealershipsResult {
  sortedDealerships: Dealership[];
  allDealerships: Dealership[];
}

export const useSortedDealerships = (
  brandId: string,
  zipCode: string,
  postalCode: string,
  selectedBrand?: string
): DealershipsResult => {
  const functions = getFunctions();
  const getDealerships = httpsCallable(functions, 'getDealerships');
  const [sortedDealerships, setSortedDealerships] = useState<Dealership[]>([]);
  const [allDealerships, setAllDealerships] = useState<Dealership[]>([]);

  const filterDealerships = async () => {
    try {
      const resultAll = await getDealerships({ brandId, input: 'All' });
      const allDealers = (resultAll.data as any).allDealerships;
      setAllDealerships(allDealers as Dealership[]);

      let newDealerships: Dealership[] = [];

      if (zipCode && zipCode.length === 5) {
        const result = await getDealerships({ brandId, input: zipCode });
        newDealerships = (result.data as any).topDealerships;
      } else if (postalCode && postalCode.length === 6) {
        const result = await getDealerships({ brandId, input: postalCode });
        newDealerships = (result.data as any).topDealerships;
      }

      if (selectedBrand && selectedBrand !== 'All Brands') {
        newDealerships = newDealerships.filter((d) =>
          d.brands && d.brands.includes(selectedBrand));
      }

      setSortedDealerships(newDealerships as Dealership[]);
    } catch (error) {
      console.error('Error fetching dealerships: ', error);
      setSortedDealerships(allDealerships);
    }
  };

  useEffect(() => {
    filterDealerships();
  }, [zipCode, postalCode, selectedBrand]);

  return { sortedDealerships, allDealerships };
};
