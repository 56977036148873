// import { evliveColors, evliveColorsRGB } from '@constants';
import { evliveTheme } from './evlive.theme';

// Copied from ChevyThme
export const evliveCanadaTheme = {
  components: {
    ...evliveTheme.components,
    MuiDialog: {
      styleOverrides: {
        root: {
          ...evliveTheme.components.MuiDialog.styleOverrides.root,
          '&#modal-end-session': {
            '.modal-text-container': {
              '.MuiTypography-modalTitle': {
                marginBottom: '40px',
              },
            },
            '@media screen and (min-width: 768px)': {
              '.modal-text-container': {
                '.MuiTypography-modalTitle': {
                  marginBottom: '50px',
                },
              },
            },
          },
        },
      },
    },
  },
  palette: {
    ...evliveTheme.palette,
  },
  typography: {
    ...evliveTheme.typography,
  },
  shadows: {
    ...evliveTheme.shadows,
  },
  breakpoints: {
    ...evliveTheme.breakpoints,
  },
};
