import { UserInfo } from 'firebase/auth';

class DeviceKitRepository {
  update = async (uid: string, lastSignInTime: string): Promise<UserInfo> => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateDeviceKitLastLogin`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid,
        lastSignInTime,
      }),
    });

    return response.json();
  };
}

export const deviceKitRepository = new DeviceKitRepository();
